import React, { useCallback } from 'react';
import {
  LocalVideoTrackStats,
  RemoteVideoTrackStats,
  LocalAudioTrackStats,
  RemoteAudioTrackStats,
  Room,
  StatsReport,
} from 'twilio-video';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { VideoTrackStats } from '../../../interfaces/VideoTrackStats';
import useCalculateOverTime from '../../../hooks/useCalculateOverTime/useCalculateOverTime';

type ChromeRTCRTPStreamStats = RTCRTPStreamStats & {
  framesDecoded?: number;
  framesEncoded?: number;
};

type FpsBySsrc = { [ssrc: string]: number };

interface UnofficialRoom extends Room {
  _signaling: {
    _peerConnectionManager: {
      _peerConnections: Map<string, { _peerConnection: RTCPeerConnection }>;
    };
  };
}

function useFps(room: Room): () => Promise<FpsBySsrc> {
  const calculateFromLatest = useCalculateOverTime(true, true);

  return React.useCallback<() => Promise<FpsBySsrc>>(() => {
    try {
      const peerConnection = [
        ...(room as UnofficialRoom)._signaling._peerConnectionManager._peerConnections.values(),
      ].map((pcSignaling) => pcSignaling._peerConnection)[0];
      return peerConnection.getStats().then((reportStats: RTCStatsReport) => {
        let fpsBySsrc: FpsBySsrc = {};
        reportStats.forEach((x: ChromeRTCRTPStreamStats) => {
          // Rtp only
          if (!x.ssrc || !['inboundrtp', 'outboundrtp'].includes(x.type))
            return;

          fpsBySsrc = {
            ...fpsBySsrc,
            [x.ssrc]: Math.round(
              calculateFromLatest(
                x.ssrc,
                x.timestamp / 1000,
                x.type === 'inboundrtp' ? x.framesDecoded : x.framesEncoded
              ) || 0
            ),
          };
        });
        return fpsBySsrc;
      });
    } catch {
      // Undocumented and unofficial API so return empty array if error
      return Promise.resolve({});
    }
  }, [calculateFromLatest, room]);
}

interface ExtendedLocalVideoTrackStats extends LocalVideoTrackStats {
  mbps: number | null;
}

interface ExtendedRemoteVideoTrackStats extends RemoteVideoTrackStats {
  mbps: number | null;
}

interface ExtendedStatsReport extends StatsReport {
  localVideoTrackStats: ExtendedLocalVideoTrackStats[];
  remoteVideoTrackStats: ExtendedRemoteVideoTrackStats[];
  localAudioTrackStats: LocalAudioTrackStats[];
  remoteAudioTrackStats: RemoteAudioTrackStats[];
}

//A hook based on twilio-video's getStats API.
//https://github.com/twilio/twilio-video.js/blob/70b54f39e800c80535d607d30450242b12b6daf4/lib/room.js#L143
//Just to get a sense of some data when investigating any Video related problems.
export default function useStats() {
  const { room } = useVideoContext();
  const calculateMbpsOverTime = useCalculateOverTime(true, true);
  const acquireRemoteFps = useFps(room);
  const acquireLatest = useCallback(() => {
    return Promise.all([room.getStats(), acquireRemoteFps()]).then(
      ([twilioRemoteStats, fpsBySsrc]: [StatsReport[], FpsBySsrc]) => {
        const statReport = (twilioRemoteStats && twilioRemoteStats[0]) || {};

        // attach fps for each track using the SSRC
        const addCalculatedStats = (videoTrack: VideoTrackStats) => {
          if (!videoTrack.ssrc) {
            return videoTrack;
          }

          return {
            ...videoTrack,
            mbps:
              Math.round(
                (calculateMbpsOverTime(
                  videoTrack.ssrc,
                  videoTrack.timestamp / 1000,
                  videoTrack.bytesSent || videoTrack.bytesReceived
                ) || 0) / 125
              ) / 1000,
            frameRate: fpsBySsrc[videoTrack.ssrc] || videoTrack.frameRate,
          };
        };

        // return original statReport with enhanced videoTrackStats
        return {
          ...statReport,
          remoteVideoTrackStats: (statReport.remoteVideoTrackStats || []).map(
            addCalculatedStats
          ),
          localVideoTrackStats: (statReport.localVideoTrackStats || []).map(
            addCalculatedStats
          ),
          remoteAudioTrackStats: statReport.remoteAudioTrackStats,
          localAudioTrackStats: statReport.localAudioTrackStats,
        } as ExtendedStatsReport;
      }
    );
  }, [acquireRemoteFps, calculateMbpsOverTime, room]);

  return {
    acquireLatest,
  };
}
