import React from 'react';
import MediaSettings from '../../components/MediaSettings/MediaSettings';
import {
  RightPanelContentType,
  RightPanelKeyEnum,
} from '../../interfaces/RightPanelContentTypes';
import Chat from '../../components/Chat/Chat';
import InvitePanel from '../../components/InvitePanel/InvitePanel';
import AddInterpreter from '../../components/AddInterpreter/AddInterpreter';
import tr from '../../utils/tr';

export const mapContent: Record<RightPanelKeyEnum, RightPanelContentType> = {
  // eslint-disable-next-line @typescript-eslint/camelcase
  [RightPanelKeyEnum.MEDIA_SETTINGS]: {
    component: <MediaSettings />,
    title: tr('Video and audio settings ##modal title'),
  },
  [RightPanelKeyEnum.CHAT]: {
    component: <Chat />,
    title: tr('Chat ##modal title'),
    scroll: 'off',
  },
  [RightPanelKeyEnum.INVITE]: {
    component: <InvitePanel />,
    title: tr('Invite ##modal title'),
  },
  [RightPanelKeyEnum.INTERPRETER]: {
    component: <AddInterpreter />,
    title: tr('Add an Interpreter ##modal title'),
    back: RightPanelKeyEnum.INVITE,
  },
};

export function getFromMap(query: string | null) {
  // Any "any" type because this string comes from query and is not restricted yet
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (!query || !Object.values(RightPanelKeyEnum).includes(query as any))
    return null;
  // Not we have verified the content as being RightPanelKeyEnum
  return mapContent[query as RightPanelKeyEnum] || null;
}

export function existsInMap(query: string | null) {
  return !!getFromMap(query);
}
