import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { colors } from '../../theme';
import { Participant } from 'twilio-video';
import useDisplayStats from '../VideoProvider/useDisplayStats/useDisplayStats';
import { VideoTrackStats } from '../../interfaces/VideoTrackStats';

interface VideoStatsProps {
  participant: Participant;
}

interface CombinedVideoTrackType {
  trackId: string;
  trackSid: string;
  timestamp: number;
  ssrc: string;
  packetsLost: string;
  codec: string;
  frameRate?: string;
  mbps?: string;
  dimensions?: string;
  bytesReceived?: string;
  bytesSent?: string;
  roundTripTime?: string;
  packetsSent?: string;
  packetsReceived?: string;
}

const getFormatText = (text: string | number | null | undefined) => {
  return text || text === 0 ? text + ', ' : ', ';
};

const getCombinedVideoTrack = (videoTrackStats: VideoTrackStats[]) => {
  const combinedVideoTrack: CombinedVideoTrackType = {
    trackId: '',
    trackSid: '',
    timestamp: 0,
    ssrc: '',
    packetsLost: '',
    codec: '',
    frameRate: '',
    mbps: '',
    dimensions: '',
    bytesReceived: '',
    bytesSent: '',
    roundTripTime: '',
    packetsSent: '',
    packetsReceived: '',
  };

  videoTrackStats.forEach((track) => {
    combinedVideoTrack.trackId = track.trackId;
    combinedVideoTrack.trackSid = track.trackSid;
    combinedVideoTrack.timestamp = track.timestamp;
    combinedVideoTrack.ssrc += getFormatText(track.ssrc);
    combinedVideoTrack.codec += getFormatText(track.codec);
    combinedVideoTrack.frameRate += getFormatText(track.frameRate);
    combinedVideoTrack.mbps += getFormatText(track.mbps);
    combinedVideoTrack.dimensions += `${track.dimensions?.width} x ${track.dimensions?.height}, `;
    combinedVideoTrack.packetsLost += getFormatText(track.packetsLost);
    combinedVideoTrack.bytesReceived += getFormatText(track.bytesReceived);
    combinedVideoTrack.bytesSent += getFormatText(track.bytesSent);
    combinedVideoTrack.roundTripTime += getFormatText(track.roundTripTime);
    combinedVideoTrack.packetsSent += getFormatText(track.packetsSent);
    combinedVideoTrack.packetsReceived += getFormatText(track.packetsReceived);
  });
  return combinedVideoTrack;
};

const useStyles = makeStyles(() => ({
  debugInfo: {
    color: colors.grayscale.white,
    position: 'absolute',
    left: 0,
    top: 0,
    pointerEvents: 'none',
    paddingLeft: '10px',
    fontSize: '0.9em',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-evenly',
    marginTop: '40px',
  },
  title: {
    fontWeight: 500,
    fontSize: '20px',
  },
}));

export default function VideoAudioStats({ participant }: VideoStatsProps) {
  const classes = useStyles();
  const shouldDisplay = window?.location?.search?.includes(
    'enableRTCStats=true'
  );
  const [videoStats, audioStats] = useDisplayStats(shouldDisplay);

  if (!shouldDisplay) {
    return null;
  }

  let videoId = '';
  for (const value of participant.videoTracks.values()) {
    videoId = value.track ? value.track.mediaStreamTrack.id : '';
  }

  let audioId = '';
  for (const value of participant.audioTracks.values()) {
    audioId = value.track ? value.track.mediaStreamTrack.id : '';
  }

  const displayVideoStats = getCombinedVideoTrack(
    videoStats.get(videoId) || []
  );

  const {
    codec: videoCodec,
    trackId: videoTrackId,
    dimensions: videoDimensions,
    timestamp: videoTimestamp,
    frameRate: videoFrameRate,
    mbps: videoMbps,
    bytesReceived: videoBytesReceived,
    bytesSent: videoBytesSent,
    packetsLost: videoPacketsLost,
    packetsSent: videoPacketsSent,
    packetsReceived: videoPacketsReceived,
    roundTripTime: videoRoundTripTime,
  } = displayVideoStats;

  const {
    codec: audioCodec,
    trackId: audioTrackId,
    timestamp: audioTimeStamp,
    bytesReceived: audioBytesReceived,
    bytesSent: audioBytesSent,
    packetsLost: audioPacketsLost,
    packetsSent: audioPacketsSent,
    packetsReceived: audioPacketsReceived,
    jitter: audioJitter,
    audioLevel,
    roundTripTime: audioRoundTripTime,
  } = audioStats.get(audioId) || {};

  return (
    <div id="statsBackdrop" className={classes.debugInfo}>
      <Typography>
        <span className={classes.title}>Video Track Stats</span>
        <br />
        TrackId: {videoTrackId}
        <br />
        TimeStamp: {videoTimestamp}
        <br />
        Codec: {videoCodec}
        <br />
        Dimensions: {videoDimensions}
        <br />
        Frame Rate: {videoFrameRate}
        <br />
        Mbps: {videoMbps}
        <br />
        roundTripTime: {videoRoundTripTime}
        <br />
        bytesReceived: {videoBytesReceived}
        <br />
        bytesSent: {videoBytesSent}
        <br />
        packetsSent: {videoPacketsSent}
        <br />
        packetsReceived: {videoPacketsReceived}
        <br />
        packetsLost: {videoPacketsLost}
      </Typography>

      <Typography>
        <span className={classes.title}>Audio Track Stats</span>
        <br />
        TrackId: {audioTrackId}
        <br />
        TimeStamp: {audioTimeStamp}
        <br />
        Codec: {audioCodec}
        <br />
        audioLevel: {audioLevel}
        <br />
        packetsLost: {audioPacketsLost}
        <br />
        jitter: {audioJitter}
        <br />
        roundTripTime: {audioRoundTripTime}
        <br />
        bytesReceived: {audioBytesReceived}
        <br />
        bytesSent: {audioBytesSent}
        <br />
        packetsSent: {audioPacketsSent}
        <br />
        packetsReceived: {audioPacketsReceived}
        <br />
        packetsSent: {audioPacketsSent}
      </Typography>
    </div>
  );
}
