import {
  OutgoingBodyAddedParticipantType,
  OutgoingBodyParticipantMap,
  OutgoingBodyRemovedParticipantType,
} from './fetch/updateAppt';
import { ApptContentEnum } from '../../state/appt/apptTypes';
import {
  ApptParticipantWithTempStatusType,
  ParticipantTempStatusEnum,
} from '../../components/appt/AppointmentUpdate/AppointmentUpdateForm';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { clockMisalignmentUpToNSeconds } from '../../utils/settings';
import { AppointmentDetailType } from '../../components/appt/AppointmentsList/Appointment';
dayjs.extend(utc);

export function getAddedParticipantsFromParticipants(
  participants: ApptParticipantWithTempStatusType[]
): OutgoingBodyAddedParticipantType[] {
  return participants
    .filter(
      ({ content, type, tempStatus }) =>
        tempStatus === ParticipantTempStatusEnum.ADDED &&
        content &&
        (type === ApptContentEnum.EMAIL || type === ApptContentEnum.PHONE)
    )
    .map(
      ({ name, content, type }) =>
        ({
          name: name || '',
          content: content || '',
          type:
            OutgoingBodyParticipantMap[
              type as ApptContentEnum.EMAIL | ApptContentEnum.PHONE
            ],
        } as OutgoingBodyAddedParticipantType)
    );
}

export function getRemovedParticipantsFromParticipants(
  participants: ApptParticipantWithTempStatusType[]
): OutgoingBodyRemovedParticipantType[] {
  return participants
    .filter(
      ({ tempStatus }) => tempStatus === ParticipantTempStatusEnum.REMOVED
    )
    .map(({ id }) => ({ id } as OutgoingBodyRemovedParticipantType));
}

export function getTimeIsMisaligned(time: Dayjs): boolean {
  const now = dayjs.utc();
  return !time.isBetween(
    now.subtract(clockMisalignmentUpToNSeconds, 'second'),
    now.add(clockMisalignmentUpToNSeconds, 'second')
  );
}

export function sortApptListForDisplay(appointments: AppointmentDetailType[]) {
  // Sort by date/time, and then by id (to prevent fluttering of equal timestamps.
  // This is necessary because the API only holds appointment date/time to the minute.
  return [...appointments].sort((a, b) => {
    if (a.startTime.toISOString() + a.id > b.startTime.toISOString() + b.id) {
      return 1;
    }
    return a.startTime.toISOString() + a.id < b.startTime.toISOString() + b.id
      ? -1
      : 0;
  });
}

export function flagClosestTime(list: AppointmentDetailType[]) {
  list = [...list];
  const now = dayjs.utc();

  // [index, timeDifferenceInSeconds]
  const times: [number, number][] = list
    .map((appt, i) => {
      const rtn: [number, number] = [i, appt.startTime.diff(now, 'second')];
      // force to positive
      if (rtn[1] < 0) rtn[1] = -rtn[1];
      return rtn;
    })
    // sort by diff
    .sort((a, b) => {
      if (a[1] > b[1]) {
        return 1;
      }
      return a[1] < b[1] ? -1 : 0;
    });

  if (times.length > 0) {
    list[times[0][0]] = { ...list[times[0][0]], isClosestTime: true };
  }

  return list;
}
