import { useLocation } from 'react-router-dom';
import React from 'react';
import Telemetry from '../../utils/telemetry';

export default function useSetupTelemetry() {
  const { search } = useLocation();

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const { TELEM_MS, TELEM_CACHE, ENABLE_TELEM_REPORT } = window.config;
    const telemMs = parseInt(searchParams.get('telemMs') || TELEM_MS);
    const telemCacheSize = parseInt(
      searchParams.get('telemCache') || TELEM_CACHE
    );
    const enableTelemReport =
      (searchParams.get('enableTelemReport') || ENABLE_TELEM_REPORT) === 'true';
    Telemetry.initializeInstance(
      telemMs,
      telemCacheSize,
      enableTelemReport,
      null
    );
  }, [search]);
}
