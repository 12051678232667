import React from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import tr from '../../utils/tr';
import { isFirefox } from 'react-device-detect';

const useStyles = makeStyles({
  iconButton: {
    height: 36,
  },
});

export default function RightUpperButtons({
  showing,
  className,
  handleNewVisit,
  showArrowIcon = false,
}: {
  showing: boolean;
  className?: string;
  handleNewVisit?: Function;
  showArrowIcon?: boolean;
}) {
  const classes = useStyles();
  const { push } = useHistory();

  const handleOnclick = () => {
    handleNewVisit && handleNewVisit();
    if (window.opener?.name) {
      window.open(isFirefox ? window.opener.location : '', window.opener.name);
      window.close();
      return;
    }
    push('/appointment');
  };

  return showing ? (
    <div className={className}>
      <Button
        color="primary"
        className={classes.iconButton}
        data-testid="new-visit-button"
        onClick={handleOnclick}
        startIcon={showArrowIcon && <ArrowBack />}
      >
        {tr('Appointments ##button')}
      </Button>
    </div>
  ) : null;
}
