import React from 'react';
import { windowIsHidden } from '../../utils';
export default function useTabIsHidden() {
  const [isTabHidden, setIsTabHidden] = React.useState<boolean>(false);

  React.useEffect(() => {
    const callSetIsWindowHidden = () => {
      setIsTabHidden(windowIsHidden());
    };
    document.addEventListener('visibilitychange', callSetIsWindowHidden);
    return () => {
      document.removeEventListener('visibilitychange', callSetIsWindowHidden);
    };
  }, []);

  return isTabHidden;
}
