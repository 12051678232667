import React from 'react';
import { useApptStateContext } from '../../../state/appt/ApptStateProvider';
import { useParams } from 'react-router-dom';

export default function useCurrentAppt(blockUpdating = false) {
  const {
    state: { appointments },
    getAppointments,
  } = useApptStateContext();
  const { appointmentId } = useParams() as { appointmentId: string | null };

  const [currentAppointment, setCurrentAppointment] = React.useState(appointments?.find(({ id }) => id === appointmentId));
  React.useEffect(() => {
    if (!blockUpdating || (!currentAppointment && appointments)) {
      setCurrentAppointment(appointments?.find(({ id }) => id === appointmentId));
    }
  }, [appointmentId, appointments, blockUpdating, currentAppointment]);

  return { currentAppointment, refetchCurrentAppointment: getAppointments, appointmentsLoaded: appointments !== null };
}
