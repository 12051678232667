import React from 'react';
import {
  ApptActionEnum,
  ApptActionsType,
} from '../../../state/appt/ApptActionObjectType';
import { validateEmailOrPhone } from '../../../components/appt/utils';
import updateAppt, {
  OutgoingBodyParticipantTypeEnum,
} from '../fetch/updateAppt';
import { useApptStateContext } from '../../../state/appt/ApptStateProvider';
import useAuthBaseContext from '../../useAuthBaseContext/useAuthBaseContext';
import { ApptType, ApptContentEnum } from '../../../state/appt/apptTypes';
import {
  ApptFetchErrorTypeEnum,
  ApptFetchErrorAboutEnum,
} from '../apptHookTypes';
import {
  getAddedParticipantsFromParticipants,
  getRemovedParticipantsFromParticipants,
} from '../apptHookUtils';
import { ApptParticipantWithTempStatusType } from '../../../components/appt/AppointmentUpdate/AppointmentUpdateForm';

interface UseApptUpdateInterface {
  participants: ApptParticipantWithTempStatusType[];
  appointment: ApptType;
  content: string;
  name: string;
  inviteMessage: string;
}

export default function useApptUpdate({
  participants,
  appointment,
  content,
  name,
  inviteMessage,
}: UseApptUpdateInterface): () => Promise<boolean> {
  const { dispatch } = useApptStateContext();
  const { baseAccessToken } = useAuthBaseContext();

  // Used to track whether the invite message has been edited and needs updated
  const [initialInviteMessage, ] = React.useState(inviteMessage);

  return React.useCallback(async () => {
    if (!baseAccessToken) {
      dispatch({
        type: ApptActionEnum.ERROR_ACTION,
        error: {
          message: 'Unable to update appointment due to missing access token',
          type: ApptFetchErrorTypeEnum.ACCESS_DENIED,
          about: ApptFetchErrorAboutEnum.UPDATE_APPOINTMENT,
        },
      });
      return false;
    }
    if (!appointment?.id) {
      dispatch({
        type: ApptActionEnum.ERROR_ACTION,
        error: {
          message: 'Unable to update appointment due to no appointment id',
          type: ApptFetchErrorTypeEnum.INVALID_REQUEST,
          about: ApptFetchErrorAboutEnum.UPDATE_APPOINTMENT,
        },
      });
      return false;
    }
    // Added Participants
    const addedParticipants = getAddedParticipantsFromParticipants(
      participants
    );
    if (content) {
      const validation = validateEmailOrPhone(content);
      if (
        validation.type === ApptContentEnum.PHONE ||
        validation.type === ApptContentEnum.EMAIL
      ) {
        addedParticipants.push({
          type:
            validation.type === ApptContentEnum.PHONE
              ? OutgoingBodyParticipantTypeEnum.PHONE
              : OutgoingBodyParticipantTypeEnum.EMAIL,
          name,
          content: validation.cleaned,
        });
      }
    }
    // Removed Participants
    const removedParticipants = getRemovedParticipantsFromParticipants(
      participants
    );

    console.log(initialInviteMessage)
    const res = await updateAppt({
      appointmentId: appointment.id,
      addedParticipants,
      removedParticipants,
      inviteMessage:
        inviteMessage === initialInviteMessage
          ? undefined
          : inviteMessage,
      baseAccessToken,
      targetAdid: appointment.ownedBy,
    });
    if (res.error) {
      dispatch({
        type: ApptActionEnum.ERROR_ACTION,
        error: res.error,
      } as ApptActionsType);
      return false;
    }
    return true;
  }, [
    content,
    name,
    baseAccessToken,
    inviteMessage,
    initialInviteMessage,
    participants,
    appointment,
    dispatch,
  ]);
}
