import React from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';
import useOnInterval from '../useOnInterval/useOnInterval';

export default function useLocalNetworkQuality(averageOverNSeconds = 5) {
  const {
    room: { localParticipant },
  } = useVideoContext();

  const [recentQualities, setRecentQualities] = React.useState<number[]>([]);

  const updateQualities = React.useCallback(() => {
    if (localParticipant) {
      setRecentQualities((currQualities) => {
        if (
          typeof localParticipant.networkQualityLevel !== 'undefined' &&
          localParticipant.networkQualityLevel !== null
        ) {
          currQualities = [
            ...currQualities,
            localParticipant.networkQualityLevel,
          ];
        }
        return currQualities.length <= averageOverNSeconds
          ? currQualities
          : currQualities.slice(-averageOverNSeconds);
      });
    }
  }, [averageOverNSeconds, localParticipant]);

  useOnInterval(updateQualities, !!localParticipant, 1);

  // Wrap in an object to provide every-1-sec mutation as an option
  return {
    average:
      localParticipant && recentQualities.length > 0
        ? Math.round(
            (recentQualities.reduce((cumm, curr) => cumm + curr, 0) /
              recentQualities.length) *
              10
          ) / 10
        : null,
  };
}
