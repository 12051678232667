import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { IconButton, Menu } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { colors } from '../../../theme';
import { noListItem } from '../InvitedParticipantList/InvitedParticipantList';
import {
  ApptContentEnum,
  ApptParticipantStatusType,
} from '../../../state/appt/apptTypes';
import getExpectedApptStatuses from '../getExpectedApptStatusMap';
import InvitedParticipantStatusRows from './InvitedParticipantStatusRows';
import tr from '../../../utils/tr';

interface InvitedParticipantInfoInterface {
  id: number;
  participantDisplayName: string;
  isWaiting?: boolean;
  statuses: ApptParticipantStatusType[];
  type: ApptContentEnum;
}

/* istanbul ignore next */
const useStyles = makeStyles(() =>
  /* istanbul ignore next */
  createStyles({
    buttonContainer: {
      position: 'relative',
      display: 'inline-block',
    },
    infoButton: {
      padding: '8px',
    },
    waitingWarningColor: {
      color: colors.primaryPalette.brandPurple,
    },
    nonWaitingWarningColor: {
      color: colors.grayscale.gray10,
    },
    dropdown: {
      position: 'absolute',
      top: '80%',
      right: '20%',
      width: '300px',
      zIndex: 2,
      border: '1px solid rgba(0, 0, 0, 0.04)',
      boxShadow: '0 16px 24px 2px rgba(0, 0, 0, 0.14)',
      background: 'white',
    },
    noListItem,
    listItem: {
      padding: '8px 12px',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.14)',
        cursor: 'pointer',
      },
    },
  })
);

export default function InvitedParticipantInfoButton({
  id,
  participantDisplayName,
  isWaiting = false,
  statuses,
  type,
}: InvitedParticipantInfoInterface) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isOpen = Boolean(anchorEl);
  const expectedApptStatuses = getExpectedApptStatuses(statuses, type);

  return (
    <div className={classes.buttonContainer}>
      <IconButton
        classes={{ root: classes.infoButton }}
        onClick={handleClick}
        aria-label={`${tr(
          'invite status for ##participant aria'
        )} ${participantDisplayName}`}
        disabled={expectedApptStatuses.length === 0}
      >
        <InfoOutlinedIcon
          className={
            isWaiting
              ? classes.waitingWarningColor
              : classes.nonWaitingWarningColor
          }
        />
      </IconButton>
      <div>
        <Menu
          id={id.toString()}
          open={isOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <InvitedParticipantStatusRows
            expectedApptStatuses={expectedApptStatuses}
            participantDisplayName={participantDisplayName}
          />
        </Menu>
      </div>
    </div>
  );
}
