import React from 'react';
import { IdleTimerMethods } from '@ascension/ascensionid-sdk';
import {
  checkAutoLogoutEveryNSeconds,
  logoutAfterIdleMinutes,
} from '../../utils/settings';
import { ResetIdleEvents } from '../../components/AuthBase/AuthBase';

interface UseAutoLogoutWarningInterface {
  isLoggedIn: boolean;
  addOnSecondsIdleListener: IdleTimerMethods['addOnSecondsIdleListener'];
  isShowAutoLogoutWarning: boolean;
  setIsShowAutoLogoutWarning: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function useAutoLogoutWarning({
  isLoggedIn,
  addOnSecondsIdleListener,
  isShowAutoLogoutWarning,
  setIsShowAutoLogoutWarning,
}: UseAutoLogoutWarningInterface) {
  const warningLogoutOnMs =
    logoutAfterIdleMinutes * 60 - checkAutoLogoutEveryNSeconds;

  const setToWarningLogout = React.useCallback(() => {
    setIsShowAutoLogoutWarning(true);
  }, [setIsShowAutoLogoutWarning]);

  const setIdleListener = React.useCallback(() => {
    if (isShowAutoLogoutWarning) {
      addOnSecondsIdleListener(warningLogoutOnMs, setToWarningLogout);
      setIsShowAutoLogoutWarning(false);
    }
  }, [
    isShowAutoLogoutWarning,
    setIsShowAutoLogoutWarning,
    addOnSecondsIdleListener,
    warningLogoutOnMs,
    setToWarningLogout,
  ]);

  React.useEffect(() => {
    if (!isLoggedIn || warningLogoutOnMs <= 0) {
      return;
    }
    addOnSecondsIdleListener(warningLogoutOnMs, setToWarningLogout);
  }, [
    addOnSecondsIdleListener,
    isLoggedIn,
    setToWarningLogout,
    warningLogoutOnMs,
  ]);

  React.useEffect(() => {
    const preservedEventList = ResetIdleEvents.concat();
    // Watch ResetIdleEvents changes to know user is active
    preservedEventList.forEach((eventName) => {
      document.addEventListener(eventName, setIdleListener);
    });

    return () => {
      preservedEventList.forEach((eventName) => {
        document.removeEventListener(eventName, setIdleListener);
      });
    };
  }, [setIdleListener]);
}
