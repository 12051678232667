import React from 'react';

export default function useOnInterval(
  cb: (() => void) | (() => Promise<void>),
  isOn = false,
  everyNSeconds = 60
) {
  React.useEffect(() => {
    let currInterval = null as null | NodeJS.Timeout;
    if (isOn) {
      currInterval = setInterval(() => {
        cb();
      }, everyNSeconds * 1000);
    }
    // On teardown, stop interval and clear
    return () => {
      if (currInterval) {
        clearInterval(currInterval);
      }
    };
  }, [everyNSeconds, cb, isOn]);
}
