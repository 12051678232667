import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import ToggleFullscreenButton from './ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';

import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Grid } from '@material-ui/core';
import { LOGO_HEIGHT, VIDEO_TOOLBAR_HEIGHT_UNITS } from '../../constants';
import MediaSettingsButton from '../MediaSettingsButton/MediaSettingsButton';
import ChatButton from '../ChatButton/ChatButton';

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      left: 0,
      top: 0,
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      height: theme.spacing(VIDEO_TOOLBAR_HEIGHT_UNITS),
      display: 'grid',
    },
    toolbar: {
      padding: theme.spacing(0, 6),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 0, 0, 1),
      },
    },
    leftTitleContainer: {
      display: 'flex',
      alignItems: 'start',
      width: 'auto',
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      color: theme.palette.text.primary,
    },
  })
);

export default function VideoMenuBar() {
  const classes = useStyles();
  const { room } = useVideoContext();

  return (
    <AppBar className={classes.container}>
      <Toolbar className={classes.toolbar}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          className={classes.leftTitleContainer}
        >
          <img
            src="/static/ascension-logo.png"
            alt="Ascension"
            height={LOGO_HEIGHT + 'px'}
          />
        </Grid>
        <div className={classes.rightButtonContainer}>
          <FlipCameraButton />
          <ToggleFullscreenButton />
        </div>
        {room.name && <ChatButton />}
        <MediaSettingsButton />
      </Toolbar>
    </AppBar>
  );
}
