import React from 'react';
import { useApptStateContext } from '../../../state/appt/ApptStateProvider';
import ErrorDialog from '../../ErrorDialog/ErrorDialog';
import { isExpiredOrInvalidOrFutureTokenError } from '../../../utils';

export default function GeneralError() {
  const { error, setError, tokenError } = useApptStateContext();
  if (tokenError && isExpiredOrInvalidOrFutureTokenError(tokenError)) {
    return null;
  }
  return <ErrorDialog dismissError={() => setError(null)} error={error} />;
}
