import React from 'react';
import { Button, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { colors } from '../../../theme';
import tr from '../../../utils/tr';
import { ApptContentEnum, ApptType } from '../../../state/appt/apptTypes';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../../AnalyticsConfiguration/analyticsConstant';
import { RouteEnum } from '../../../RouteEnum';
import { getTextDirectionOriginIsRight } from '../../../utils';

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(1.5),
      alignItems: getTextDirectionOriginIsRight() ? 'flex-end' : 'flex-start',
    },
    fullButton: {
      width: '300px',
      '&.MuiButton-root': {
        margin: '0',
      },
      '&.Mui-disabled': {
        backgroundColor: colors.grayscale.gray3,
      },
      '&:hover': {
        backgroundColor: '#0F3C97',
      },
    },
  })
);

export default function CheckAndJoinButton({
  appointment,
}: {
  appointment: ApptType;
}) {
  const classes = useStyles();
  const domain = window.location.origin;
  const disabled = !appointment.id || appointment.appointmentCancelled === true;
  const token = appointment.participants.find(
    ({ token: participantToken, type }) =>
      participantToken && type === ApptContentEnum.SELF
  )?.token;

  const onClick = () => {
    analyticEvent({
      label: 'Join Visit',
      action: ACTION_CALL_TO_ACTION,
    });
    window.name = 'parent';
    window.open(`${domain}${RouteEnum.CALL}${token}`);
  };
  return (
    <div className={classes.container}>
      <div style={{ margin: 'auto', display: 'none' }}>
        <span style={{ fontWeight: 500, color: colors.grayscale.gray2 }}>
          {tr('Want to test your devices? ##new-appt video-preview label')}
        </span>
        <Button
          style={{ padding: '0px 8px', height: '40px' }}
          onClick={() => {
            alert('Check devices');
          }}
          color="primary"
        >
          {tr('Check ##new-appt video-preview button')}
        </Button>
      </div>
      <Button
        className={classes.fullButton}
        variant="contained"
        color="primary"
        type="submit"
        disabled={disabled}
        onClick={onClick}
      >
        {tr('Join Visit ##new-appt join button')}
      </Button>
    </div>
  );
}
