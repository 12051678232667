import useSocketContext from '../../hooks/useSocketContext/useSocketContext';
import React from 'react';
import {
  Divider,
  List,
  ListItem,
  Typography,
  ListItemTypeMap,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/styles';
import { colors } from '../../theme';
import socketIdentifier from '../../utils/socketIdentifier';
import useSocketTimedOut from '../../hooks/useSocketTimedOut/useSocketTimedOut';
import clsx from 'clsx';
import tr from '../../utils/tr';
import DotsIcon from '../SpinnerButton/DotsIcon';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationError from '../NotificationError/NotificationError';
import TimeDiffWatch from './TimeDiffWatch';
import { updateSocketExpiresAtEveryNSeconds } from '../../utils/settings';
import useNotifications from '../../hooks/useNotifications/useNotifications';
import NotificationsToggleBar from './NotificationsToggleBar';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';
import { getTextDirectionOriginIsRight } from '../../utils';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

const useStyles = makeStyles({
  participants: {
    backgroundColor: colors.grayscale.gray11,
    alignSelf: 'flex-start',
    minWidth: '205px',
  },
  item: {
    listStyleType: 'none',
  },
  timeInWait: {
    color: colors.secondary.violet,
    padding: 5,
  },
  errorIcon: {
    marginTop: '3px',
  },
  listText: {
    padding: 5,
  },
  participant: {
    color: colors.grayscale.gray2,
  },
  loadingAnimation: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hr: {
    color: 'lightgray',
    marginLeft: '15px',
    marginRight: '15px',
  },
  notificationsIcon: {
    color: colors.primaryPalette.brandBlue,
    height: '0.8em',
    width: '0.8em',
    marginRight: '10px',
  },
  allowNotifying: {
    color: colors.primaryPalette.brandBlue,
  },
  textAlignRight: {
    textAlign: 'right',
  },
});

export const ListItemJustified: OverridableComponent<ListItemTypeMap<
  { button?: false },
  'li'
>> = ({ ...props }) => {
  return (
    <ListItem
      {...props}
      style={{
        justifyContent: getTextDirectionOriginIsRight() ? 'right' : 'unset',
        ...props.style,
      }}
    />
  );
};

export default function PreRoomParticipants() {
  const classes = useStyles();
  const { participants, connectionStatus } = useSocketContext();
  const socketTimedOut = useSocketTimedOut();

  const participantsNotMe = participants
    ? participants.filter((participant) => participant.id !== socketIdentifier)
    : [];

  const {
    notificationsPermission,
    notificationsError,
    setNotificationsError,
    requestNotifications,
  } = useNotifications();

  const handleDismiss = React.useCallback(() => {
    analyticEvent({
      label: 'Close Notification Access Instruction Dialog',
      action: ACTION_CALL_TO_ACTION,
    });
    setNotificationsError(false);
  }, [setNotificationsError]);

  const notificationsBlock = () => {
    if (notificationsPermission === 'granted') {
      return (
        <ListItemJustified data-testid="notificationsOn">
          <NotificationsToggleBar />
        </ListItemJustified>
      );
    }
    return (
      <>
        <ListItemJustified
          style={{ cursor: 'pointer' }}
          alignItems="flex-start"
          data-testid="notificationsPermission"
          onClick={() => {
            analyticEvent({
              label: 'Clicked Get Notified When Others Join',
              action: ACTION_CALL_TO_ACTION,
            });
            requestNotifications();
          }}
        >
          <NotificationsIcon className={classes.notificationsIcon} />
          <Typography variant="subtitle2" className={classes.allowNotifying}>
            {tr('GET NOTIFIED WHEN OTHERS JOIN ##notification link')}
          </Typography>
        </ListItemJustified>
        {notificationsError && <NotificationError onDismiss={handleDismiss} />}
      </>
    );
  };

  return (
    <List className={classes.participants} data-testid="participantList">
      <ListItemJustified>
        <b
          style={{
            ...(getTextDirectionOriginIsRight() ? { direction: 'rtl' } : {}),
          }}
        >
          {tr('In call: ##preroom title')}
        </b>
      </ListItemJustified>
      {socketTimedOut && (
        <ListItemJustified alignItems="flex-start" data-testid="socketError">
          <ErrorIcon color="error" className={classes.errorIcon} />
          <Typography variant="subtitle2" className={classes.listText}>
            {tr(
              'Unable to display the list of participants on this call. ##preroom error'
            )}
          </Typography>
        </ListItemJustified>
      )}
      {(connectionStatus === 'connecting' || !participants) && (
        <ListItemJustified
          style={{ justifyContent: 'center' }}
          data-testid="loadingAnimation"
        >
          <div style={{ height: '24px' }}>
            <DotsIcon
              size={4}
              className={classes.loadingAnimation}
              color={colors.primaryPalette.brandBlue}
            />
          </div>
        </ListItemJustified>
      )}
      {!socketTimedOut &&
        connectionStatus !== 'connecting' &&
        participants &&
        (participantsNotMe.length === 0 ? (
          <ListItemJustified>
            <Typography>
              {tr('No one has joined yet ##preroom participant')}
            </Typography>
          </ListItemJustified>
        ) : (
          participantsNotMe.map((p) => (
            <ListItemJustified key={p.id}>
              <img src="/static/person_24px.svg" alt="participant" />
              <Typography
                variant="subtitle2"
                className={clsx(classes.listText, classes.participant)}
              >
                {p.name}
              </Typography>
              <Typography variant="subtitle2" className={classes.timeInWait}>
                <TimeDiffWatch
                  timeInterval="minute"
                  time={p.joinTime}
                  checkEveryNSeconds={updateSocketExpiresAtEveryNSeconds}
                />{' '}
                min
              </Typography>
              <Divider />
            </ListItemJustified>
          ))
        ))}
      {notificationsPermission && (
        <>
          <div>
            <hr className={classes.hr} />
          </div>
          {notificationsBlock()}
        </>
      )}
    </List>
  );
}
