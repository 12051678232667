import { createStyles, IconButton, Theme } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { colors } from '../../../theme';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogWrap: {
      '& .MuiPaper-root': {},
    },
    scrollArea: {
      width: '100%',
      overflowY: 'auto',
    },
    arrowBack: {
      color: theme.palette.grey[500],
      padding: theme.spacing(1.5),
    },
    rightPanelBar: {
      flex: 1,
    },
  })
);

export function ResponsiveDialogBackButton({
  onClick,
  color = colors.grayscale.white,
}: {
  onClick: () => void;
  color?: string;
}) {
  const classes = useStyles();
  return (
    <IconButton
      aria-label="close"
      className={classes.arrowBack}
      onClick={onClick}
    >
      <ArrowBack style={{ fill: color }} />
    </IconButton>
  );
}
