import React, { ReactNode } from 'react';
import { LocalVolumeProvider } from '../../hooks/useLocalVolumeContext/useLocalVolumeContext';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { AudioTrackType } from '../VideoProvider/useMedia/UseMediaTypes';

const nullTrack = { track: null };

export default function WithLocalVolume({ children }: { children: ReactNode }) {
  const { localTracks, audioInDisabled } = useVideoContext();
  const localAudioTrack = (localTracks.find(
    (track) => track.track.kind === 'audio'
  ) || nullTrack) as AudioTrackType;

  return (
    <LocalVolumeProvider track={localAudioTrack} disabled={audioInDisabled}>
      {children}
    </LocalVolumeProvider>
  );
}
