import React from 'react';
import { styled } from '@material-ui/core';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import theme from '../../theme';
import tr from '../../utils/tr';

const BandwidthWarningContainer = styled('div')({
  position: 'absolute',
  zIndex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(1),
});

const Warning = styled('h3')({
  textAlign: 'center',
  margin: '0.6em 0',
});

export default function BandwidthWarning() {
  return (
    <BandwidthWarningContainer>
      <div>
        <ErrorOutlineIcon fontSize="large" />
      </div>
      <Warning>{tr('Insufficient Bandwidth ##video overlay')}</Warning>
    </BandwidthWarningContainer>
  );
}
