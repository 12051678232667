export const ACTION_IN_APPOINTMENT = 'in-appointment';
export const ACTION_CALL_FEEDBACK = 'call-feedback';
export const ACTION_NOTE_PREFIX = 'Note: ';
export const ACTION_TELEMETRY = 'telemetry';

export const ACTION_PROPERTY_STAR_RATING = 'star_rating';
export const ACTION_PROPERTY_APPOINTMENT_ID = 'appointment_id';
export const ACTION_PROPERTY_USER_IDENTIFIER = 'user_identifier';
export const SESSION_PROPERTY_USER_AUTH_ROLE = 'user_auth_role';
export const ACTION_PROPERTY_NETWORK_QUALITY = 'network_quality';
export const ACTION_PROPERTY_MBPS_DOWNLINK = 'mbps_downlink';
