import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import {
  AidEnvironment,
  AidSignInPolicy,
  AuthCoreConfig,
  WithAuthentication,
  AccessTokenValidationOptions,
} from '@ascension/ascensionid-sdk';
import AuthBaseContextProvider from '../../hooks/useAuthBaseContext/AuthBaseContextProvider';
import {
  refreshAccessTokenBufferNSeconds,
  logoutAfterIdleMinutes,
} from '../../utils/settings';
import { LogLevel } from '@azure/msal-common';
import { reportError } from '@ascension/report-event';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';
import { useLocation, matchPath } from 'react-router';
import { RouteEnum } from '../../RouteEnum';

export const ResetIdleEvents: Array<keyof DocumentEventMap> = [
  'mousedown',
  'click',
  'keypress',
  'scroll',
  'visibilitychange',
];

const accessTokenValidationOptions: AccessTokenValidationOptions = {
  ignoreNotBefore: true,
};

const ascensionIDConfig: AuthCoreConfig = {
  aidEnvironment: window.config.ASCENSION_ID_ENVIRONMENT as AidEnvironment,
  clientId: window.config.ASCENSION_ID_APPLICATION_ID,
  scopes: ['video-chat-api/app.access'],
  redirectUri: `${window.location.origin}/appointment`,
  postLogoutRedirectUri: `${window.location.origin}${RouteEnum.LOGOUT}`,
  msalConfiguration: {
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (
          level: LogLevel,
          message: string,
          containsPii: boolean
        ): void => {
          if (containsPii) {
            return;
          }
          if (level === 0) {
            reportError('Auth error: ' + message);
            analyticEvent({
              label: 'Ascension ID error',
              action: ACTION_CALL_TO_ACTION,
            });
          }
        },
      },
    },
  },
  signInPolicy: AidSignInPolicy.B2C_1A_signin_adfs,
};

const AuthBase = ({ children }: { children: ReactNode }) => {
  const { pathname } = useLocation();
  const isInCallPage = !!matchPath<{ appointmentId: string }>(pathname, {
    path: RouteEnum.CALL + ':URLRoomToken',
  });

  return (
    <WithAuthentication
      config={ascensionIDConfig}
      attemptSilentSsoOnLoad={true}
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      logoutAfterIdleMinutes={isInCallPage ? null : logoutAfterIdleMinutes}
      refreshTokenBufferSeconds={refreshAccessTokenBufferNSeconds}
      resetIdleEvents={ResetIdleEvents}
      accessTokenValidationOptions={accessTokenValidationOptions}
    >
      <AuthBaseContextProvider>{children}</AuthBaseContextProvider>
    </WithAuthentication>
  );
};

AuthBase.propTypes = {
  children: PropTypes.object.isRequired,
};

export default AuthBase;
