import React from 'react';
import { FeatureEnum } from './features';
import { FeatureGroupsType, FeaturesType } from './featureTypes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

interface UseFeatureIsDisplayable {
  registeredFeatureIds: FeatureEnum[];
  featureGroups: FeatureGroupsType;
  setFeatures: React.Dispatch<React.SetStateAction<FeaturesType>>;
}

// Only for use by useFeaturesContext
export default function useFeatureIsDisplayable({
  registeredFeatureIds,
  featureGroups,
  setFeatures,
}: UseFeatureIsDisplayable) {
  // Flag features with isDisplayable
  React.useEffect(() => {
    setFeatures((currFeatures) => {
      return Object.keys(currFeatures).reduce(
        (cummFeatures, currFeatureKey) => {
          const currentFeature = currFeatures[currFeatureKey as FeatureEnum];
          const group = featureGroups[currentFeature.groupId];
          // Consider displayable if not expired & registered (currently visible on the page)
          currentFeature.isDisplayable =
            group.expirationUTC.isAfter(dayjs.utc()) &&
            registeredFeatureIds.includes(currFeatureKey as FeatureEnum);
          currentFeature.isSelected = group.isSelected;

          return {
            ...cummFeatures,
            [currFeatureKey as FeatureEnum]: currentFeature,
          };
        },
        {} as FeaturesType
      );
    });
  }, [registeredFeatureIds, featureGroups, setFeatures]);
}
