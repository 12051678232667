import React from 'react';
import { formatInviteTarget } from '../../../utils';
import { Theme } from '@material-ui/core';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/styles';
import { colors } from '../../../theme';
import tr from '../../../utils/tr';
import {
  grayWord,
  smallGrayWord,
  rowList,
  rowListMDScreen,
  rowListTwoColumns,
  overflow,
  cell,
  noListItem,
} from '../InvitedParticipantList/InvitedParticipantList';
import { InvitedParticipantType } from '../AppointmentPageTypes';
import InvitedParticipantInfoButton from '../InvitedParticipantInfoButton/InvitedParticipantInfoButton';
import { ApptParticipantStatusNameEnum } from '../../../state/appt/apptTypes';
import ResendButton from '../ResendButton/ResendButton';

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inviteRowList: {
      ...rowList,
      [theme.breakpoints.down('md')]: {
        ...rowListMDScreen,
      },
    },
    rowListTwoColumns,
    noListItem,
    cell,
    rightCell: {
      ...noListItem,
      textAlign: 'right',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    overflow,
    grayWord,
    smallGrayWord,
    statusHint: {
      ...grayWord,
      paddingRight: '8px',
      fontSize: '0.8em',
      fontWeight: 500,
    },
    infoButton: {
      padding: '8px',
    },
    waitingWarning: {
      color: colors.primaryPalette.brandPurple,
      fontWeight: 600,
      fontSize: '16px',
    },
    inviteError: {
      color: colors.notifications.error,
      paddingRight: '8px',
      fontSize: '0.8em',
      fontWeight: 500,
    },
  })
);

interface InvitedParticipantInterface {
  invitedParticipant: InvitedParticipantType;
  showName?: boolean;
  screenIsSmall: boolean;
  disabled: boolean;
}

export default function InvitedParticipant({
  invitedParticipant,
  showName = true,
  screenIsSmall,
  disabled,
}: InvitedParticipantInterface) {
  const classes = useStyles();
  const {
    id,
    content,
    name,
    isWaiting,
    waitingDuration,
    statuses,
    type,
  } = invitedParticipant;
  const contactContent = (
    <div
      className={clsx(
        classes.overflow,
        showName && classes.grayWord,
        showName && screenIsSmall && classes.smallGrayWord
      )}
    >
      {content
        ? formatInviteTarget(content)
        : type === 'INTERPRETER'
        ? 'VOYCE'
        : ''}
    </div>
  );
  const contactContentCell = <li className={classes.cell}>{contactContent}</li>;
  const resendInvite = () => console.log('no implemented yet');

  const isError = statuses.find(
    (item) => item.statusType === ApptParticipantStatusNameEnum.INVITE_FAILED
  );

  return (
    <li className={classes.noListItem} data-testid={`participant-${id}`}>
      <ul
        className={clsx(
          classes.inviteRowList,
          (!showName || screenIsSmall) && classes.rowListTwoColumns
        )}
      >
        {showName && (
          <li className={classes.cell}>
            <div className={classes.overflow}>
              {name || (type === 'INTERPRETER' ? 'Interpreter' : '')}
            </div>
            {screenIsSmall && contactContent}
          </li>
        )}
        {showName && !screenIsSmall && contactContentCell}
        {!showName && contactContentCell}
        <li className={classes.rightCell}>
          {isWaiting === undefined ? (
            // hiding Resend button for now
            !disabled && false && <ResendButton resendInvite={resendInvite} />
          ) : isWaiting ? (
            <span className={classes.waitingWarning}>
              {tr('Waiting ##new-appt waitingWarning') + ' '}
              {waitingDuration}
              {tr('m ##suffix-minute')}
            </span>
          ) : (
            <>
              {isError ? (
                <span className={classes.inviteError}>
                  {tr('Unable to send invite ##new-appt inviteError')}
                </span>
              ) : (
                <span className={classes.statusHint}>
                  {tr('Not here yet ##new-appt statusHint')}
                </span>
              )}
              {/* hiding Resend button for now */}
              {!disabled && false && (
                <ResendButton resendInvite={resendInvite} />
              )}
            </>
          )}
          {statuses.length > 0 && (
            <InvitedParticipantInfoButton
              isWaiting={isWaiting}
              id={id}
              participantDisplayName={name || content || ''}
              statuses={statuses}
              type={type}
            />
          )}
          {type === 'INTERPRETER' && (
            <InvitedParticipantInfoButton
              isWaiting={false}
              id={id}
              participantDisplayName={'Interpreter'}
              statuses={[]}
              type={type}
            />
          )}
        </li>
      </ul>
    </li>
  );
}
