import {
  TokenApptDetailsType,
  CallEndpointNameEnum,
} from '../../../state/appt/apptTypes';
import React from 'react';
import { useApptStateContext } from '../../../state/appt/ApptStateProvider';
import useURLRoomToken from '../../useURLRoomToken/useURLRoomToken';

export default function useTokenAppointmentDetails(): TokenApptDetailsType | null {
  const [
    appointmentDetails,
    setAppointmentDetails,
  ] = React.useState<TokenApptDetailsType | null>(null);
  const { getTokenAppointmentDetails } = useApptStateContext();
  const participantToken = useURLRoomToken();

  React.useEffect(() => {
    getTokenAppointmentDetails({
      participantToken,
      identity: '',
      endPointName: CallEndpointNameEnum.INFO,
    }).then((res) => {
      if (res) setAppointmentDetails(res);
    });
  }, [getTokenAppointmentDetails, participantToken]);

  return appointmentDetails;
}
