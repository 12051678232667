import React from 'react';
import getNewApptId from '../fetch/getNewApptId';
import {
  ApptActionEnum,
  ApptActionsType,
} from '../../../state/appt/ApptActionObjectType';
import { useApptStateContext } from '../../../state/appt/ApptStateProvider';
import useAuthBaseContext from '../../useAuthBaseContext/useAuthBaseContext';
import {
  ApptFetchErrorTypeEnum,
  ApptFetchErrorAboutEnum,
} from '../apptHookTypes';

export default function useGetNewApptId() {
  const { baseAccessToken } = useAuthBaseContext();
  const { selectedClinicianADID, dispatch } = useApptStateContext();

  return React.useCallback(async () => {
    if (!baseAccessToken) {
      dispatch({
        type: ApptActionEnum.ERROR_ACTION,
        error: {
          message: 'Unable to create appointment due to missing access token',
          type: ApptFetchErrorTypeEnum.ACCESS_DENIED,
          about: ApptFetchErrorAboutEnum.ADD_APPOINTMENT,
        },
      });
      return;
    }
    const res = await getNewApptId({
      baseAccessToken,
      selectedClinicianADID,
    });
    if (res.error) {
      dispatch({
        type: ApptActionEnum.ERROR_ACTION,
        error: res.error,
      } as ApptActionsType);
      return;
    }
    if (!res.content) {
      dispatch({
        type: ApptActionEnum.ERROR_ACTION,
        error: {
          message: 'Missing appointment content',
          type: ApptFetchErrorTypeEnum.SERVER_ERROR,
          about: ApptFetchErrorAboutEnum.ADD_APPOINTMENT,
        },
      } as ApptActionsType);
      return;
    }
    dispatch({
      type: ApptActionEnum.ADD_APPOINTMENT,
      appointment: { ...res.content, ownedBy: selectedClinicianADID },
    });
    return res.content.id;
  }, [dispatch, baseAccessToken, selectedClinicianADID]);
}
