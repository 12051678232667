import dayjs from 'dayjs';
import React from 'react';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default function TimeDiffWatch({
  time,
  timeInterval,
  checkEveryNSeconds = 10,
}: {
  time: string;
  timeInterval: 'hour' | 'minute' | 'second';
  checkEveryNSeconds?: number;
}) {
  const getDiff = React.useCallback(() => {
    return dayjs.utc().diff(dayjs.utc(time), timeInterval);
  }, [time, timeInterval]);
  const [diff, setDiff] = React.useState(getDiff());

  React.useEffect(() => {
    const check = () => {
      setDiff(getDiff);
    };
    const interval = setInterval(check, checkEveryNSeconds * 1000);

    return () => clearInterval(interval);
  }, [getDiff, checkEveryNSeconds, time, timeInterval]);

  return <>{diff || 0}</>;
}
