import { AnalyticsEventType, emitter } from '@ascension/analytic-event';
import * as amplitude from '@amplitude/analytics-browser';
import {
  CATEGORY_ERROR,
  CATEGORY_USAGE,
} from '../AnalyticsConfiguration/analyticsConstant';

export default function setAnalyticListeners() {
  emitter.on('error', (message: AnalyticsEventType) =>
    amplitude.track(message.label, {
      ...message,
      host: window.location.hostname,
      environment: window.config.QVS_ENVIRONMENT,
      category: CATEGORY_ERROR,
    })
  );
  emitter.on('usage', (message: AnalyticsEventType) =>
    amplitude.track(message.label, {
      ...message,
      host: window.location.hostname,
      environment: window.config.QVS_ENVIRONMENT,
      category: CATEGORY_USAGE,
    })
  );
}
