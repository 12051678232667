import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { LOGO_HEIGHT } from '../../../constants';
import tr from '../../../utils/tr';
import AppointmentToolbarGeneric from './AppointmentToolbarGeneric';
import { useAuthMethods } from '@ascension/ascensionid-sdk';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../../AnalyticsConfiguration/analyticsConstant';

/* istanbul ignore next */
const useStyles = makeStyles({
  signOutButton: {
    // Offset button pad, since the pad is invisible
    marginRight: '-8px',
  },
});

export default function AppointmentToolbar1() {
  const { logout } = useAuthMethods();
  const classes = useStyles();

  return (
    <AppointmentToolbarGeneric
      left={
        <img
          src="/static/ascension-logo.png"
          alt="Ascension"
          height={LOGO_HEIGHT + 'px'}
        />
      }
      right={
        <Button
          color="primary"
          onClick={() => {
            analyticEvent({ label: 'Sign Out', action: ACTION_CALL_TO_ACTION });
            return logout();
          }}
          className={classes.signOutButton}
        >
          {tr('Sign Out ##appt-bar button')}
        </Button>
      }
    />
  );
}
