import { fetchWithTimeout, getDefaultHeaders } from '../../../utils';
import {
  ApptFetchErrorAboutEnum,
  ApptFetchErrorTypeEnum,
  ApptFetchResponseType,
} from '../apptHookTypes';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { convertIncomingApptType } from './fetchUtils';
import {
  ApptType,
  IncomingApptType,
  SelectedClinicianADIDType,
} from '../../../state/appt/apptTypes';
import { TIME_FORMAT_FOR_API_GET } from '../../../constants';
dayjs.extend(utc);

interface GetNewApptIdInterface {
  baseAccessToken: string;
  from: Dayjs;
  to: Dayjs;
  selectedClinicianADID: SelectedClinicianADIDType;
}

export default function getApptList({
  baseAccessToken,
  from,
  to,
  selectedClinicianADID,
}: GetNewApptIdInterface): Promise<
  ApptFetchResponseType<{ timestamp: Dayjs | null; appointments: ApptType[] }>
> {
  return new Promise((resolve) => {
    const headers = getDefaultHeaders();
    headers.set('Authorization', `Bearer ${baseAccessToken}`);
    const { SCHEDULING_SERVICE } = window.config;

    const targetAdidParameter = selectedClinicianADID
      ? `&targetAdid=${selectedClinicianADID}`
      : '';

    fetchWithTimeout(
      `${SCHEDULING_SERVICE}/myAppointments?lowerBound=${from.format(
        TIME_FORMAT_FOR_API_GET
      )}&upperBound=${to.format(
        TIME_FORMAT_FOR_API_GET
      )}${targetAdidParameter}`,
      {
        method: 'GET',
        headers,
      }
    )
      .then(
        async (
          res
        ): Promise<{
          timestamp: Dayjs | null;
          appointments: IncomingApptType[];
        }> => {
          if (!res.ok) {
            let responseType = ApptFetchErrorTypeEnum.ACCESS_DENIED;
            if (res.status >= 500) {
              responseType = ApptFetchErrorTypeEnum.SERVER_ERROR;
            } else if (res.status >= 400) {
              responseType = ApptFetchErrorTypeEnum.INVALID_REQUEST;
            }
            resolve({
              error: {
                status: res.status,
                message: 'No valid response when requesting appointment list',
                type: responseType,
                about: ApptFetchErrorAboutEnum.GET_APPOINTMENT_LIST,
              },
            });
          }
          return {
            timestamp: res.headers.get('timestamp')
              ? dayjs.utc(res.headers.get('timestamp'))
              : null,
            appointments: await res.json(),
          };
        }
      )
      .then(({ timestamp, appointments }) => {
        resolve({
          content: {
            timestamp,
            appointments: convertIncomingApptType(appointments),
          },
        });
      })
      .catch((err) => {
        resolve({
          error: {
            message: 'Failure in requesting appointment list: ' + err,
            about: ApptFetchErrorAboutEnum.GET_APPOINTMENT_LIST,
          },
        });
      });
  });
}
