import React from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, Typography } from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import tr from '../../../utils/tr';
import CopyButton from '../CopyButton/CopyButton';
import AppointmentBreadcrumb from '../AppointmentBreadcrumb/AppointmentBreadcrumb';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { colors, whitneyFontAndFallbacks } from '../../../theme';
import { makeInvitationLink } from '../../../utils';
import AppointmentUpdateForm from './AppointmentUpdateForm';
import AppointmentMissing from '../AppointmentMissing/AppointmentMissing';
import useCurrentAppt from '../../../hooks/appt/useCurrentAppt/useCurrentAppt';
import AppointmentPageWrap from '../AppointmentPageWrap/AppointmentPageWrap';
import { ApptContentEnum, ApptTypeEnum } from '../../../state/appt/apptTypes';
import {AscensionSpinner} from "../../AscensionSpinner/AscensionSpinner";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      padding: `${theme.spacing(4)}px ${theme.spacing(6)}px `,
    },
    title: {
      marginBottom: '0.1em',
      fontWeight: 325,
    },
    subtext: {
      fontSize: '0.9em',
    },
    astrix: {
      color: colors.notifications.error,
      marginRight: '0.4em',
    },
    athenaHintWrap: {
      backgroundColor: '#F5F5F5',
      padding: '16px 16px 16px 18px',
      display: 'flex',
      flexDirection: 'row',
      borderRadius: '4px',
      marginTop: '20px',
    },
    athenaHint: {
      fontFamily: whitneyFontAndFallbacks,
      fontSize: '16px',
      fontWeight: 450,
      lineHeight: '24px',
      letterSpacing: '0.5px',
      textAlign: 'left',
      margin: '0 0 0 18px',
    },
    loading:{
        height: '100%',
    },
      loadingsvg: {
        height: '100%',
        margin: "auto"
      }
  })
);

export default function AppointmentUpdate({
  isAdd = false,
}: {
  isAdd?: boolean;
}) {
  const classes = useStyles();
  const { currentAppointment, refetchCurrentAppointment, appointmentsLoaded } = useCurrentAppt(true);
  const history = useHistory();

  //when appointments is null meaning fetching get appointments, return loading
  if(!appointmentsLoaded) {
      return (<div className={classes.loading}><AscensionSpinner className={classes.loadingsvg} /></div>);
  }

  //when currentAppointment not found return Appointment missing component
  if (!currentAppointment) return <AppointmentMissing />;

  const domain = window.location.origin;
  const token = currentAppointment.participants.find(
    ({ token: participantToken, type }) =>
      participantToken && type === ApptContentEnum.COPIED
  )?.token;

  return (
    <AppointmentPageWrap>
      <AppointmentBreadcrumb
        steps={[
          {
            text: tr('CANCEL ##add-appt breadcrumb'),
            url: `/appointment/${currentAppointment.id}`,
          },
        ]}
      />
      <Typography variant="h1" className={classes.title}>
        {isAdd
          ? tr('Add appointment ##appt-add title')
          : tr('Edit appointment ##appt-add title')}
      </Typography>
      <br />
      <CopyButton
        strToBeCopied={makeInvitationLink({ domain, token })}
        textCopy={tr('Copy Appointment Link ##appt-add button')}
        textCopied={tr('Link Copied ##appt-add button')}
      />
      <br />
      {currentAppointment.type === ApptTypeEnum.ATHENA && (
        <>
          <div className={classes.athenaHintWrap}>
            <Info color="primary" />
            <Typography variant="h3" className={classes.athenaHint}>
              {tr(
                'Changes made here are NOT recorded in Athena. ##appt-add athenaHint'
              )}
            </Typography>
          </div>
        </>
      )}
      <br />
      <AppointmentUpdateForm
        appointment={currentAppointment}
        refetchAppointment={refetchCurrentAppointment}
        onUpdated={() => {
          history.push(`/appointment/${currentAppointment.id}`);
        }}
      />
    </AppointmentPageWrap>
  );
}
