import React, { useState } from 'react';
import getApptDetails from '../fetch/getApptDetails';
import {
  ApptActionEnum,
  ApptActionsType,
} from '../../../state/appt/ApptActionObjectType';
import useAuthBaseContext from '../../useAuthBaseContext/useAuthBaseContext';
import {
  ApptFetchErrorTypeEnum,
  ApptFetchErrorAboutEnum,
} from '../apptHookTypes';
import { ApptType } from '../../../state/appt/apptTypes';
import { useApptStateContext } from '../../../state/appt/ApptStateProvider';

export default function useCallCurrentAppt(
  appointmentId: string | null
): { apptDetails: ApptType | null; getAppointmentDetail: () => Promise<void> } {
  const { dispatch } = useApptStateContext();
  const { baseAccessToken } = useAuthBaseContext();
  const [apptDetails, setApptDetails] = useState<ApptType | null>(null);

  const getAppointmentDetail = React.useCallback(async () => {
    if (baseAccessToken && appointmentId) {
      const res = await getApptDetails({
        baseAccessToken,
        appointmentId,
      });

      if (res.error) {
        // non-pilot user will get 403 on /appointment/apptId when the appointment is created by others
        if (res.error.status === 403) return;
        dispatch({
          type: ApptActionEnum.ERROR_ACTION,
          error: res.error,
        } as ApptActionsType);
        return;
      }

      if (!res.content) {
        dispatch({
          type: ApptActionEnum.ERROR_ACTION,
          error: {
            message: 'Missing appointment content',
            type: ApptFetchErrorTypeEnum.SERVER_ERROR,
            about: ApptFetchErrorAboutEnum.GET_APPOINTMENT_DETAILS,
          },
        } as ApptActionsType);
        return;
      }

      setApptDetails(res.content);
    }
  }, [dispatch, baseAccessToken, appointmentId]);

  React.useEffect(() => {
    getAppointmentDetail();
  }, [getAppointmentDetail]);

  return { apptDetails, getAppointmentDetail };
}
