import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles, useMediaQuery } from '@material-ui/core';
import React from 'react';
import AppointmentToolbar1 from '../AppointmentToolbar/AppointmentToolbar1';
import AppointmentToolbar2 from '../AppointmentToolbar/AppointmentToolbar2';
import { MAIN_TOOLBAR_HEIGHT_UNITS } from '../../../constants';
import useHeight from '../../../hooks/useHeight/useHeight';
import clsx from 'clsx';
import styleTheme, { barZIndex } from '../../../theme';

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  /* istanbul ignore next */
  createStyles({
    grid: {
      display: 'grid',
      gridTemplate:
        `"topBar1 topBar1" ${theme.spacing(MAIN_TOOLBAR_HEIGHT_UNITS)}px` +
        `"topBar2 topBar2" ${theme.spacing(MAIN_TOOLBAR_HEIGHT_UNITS)}px` +
        '"left right" auto / 1fr 2fr',
      width: '100%',
      overflow: 'hidden',
    },
    gridRightOnly: {
      gridTemplate:
        `"topBar1 topBar1" ${theme.spacing(MAIN_TOOLBAR_HEIGHT_UNITS)}px` +
        `"topBar2 topBar2" ${theme.spacing(MAIN_TOOLBAR_HEIGHT_UNITS)}px` +
        '"right right" auto / 1fr 2fr',
    },
    gridLeftOnly: {
      gridTemplate:
        `"topBar1 topBar1" ${theme.spacing(MAIN_TOOLBAR_HEIGHT_UNITS)}px` +
        `"topBar2 topBar2" ${theme.spacing(MAIN_TOOLBAR_HEIGHT_UNITS)}px` +
        '"left left" auto / 1fr 2fr',
    },
    topBar1: {
      gridArea: 'topBar1',
      zIndex: barZIndex,
    },
    topBar2: {
      gridArea: 'topBar2',
      zIndex: barZIndex,
    },
    left: {
      gridArea: 'left',
      overflow: 'hidden',
    },
    right: {
      gridArea: 'right',
      overflowY: 'auto',
    },
  })
);

interface ConsoleGridInterface {
  left: React.ReactNode;
  right: React.ReactNode;
  focusOn?: 'left' | 'right';
}

export default function ConsoleGrid({
  left,
  right,
  focusOn = 'left',
}: ConsoleGridInterface) {
  const classes = useStyles();
  const height = useHeight(0, true) as number;
  const screenIsSmall = useMediaQuery(styleTheme.breakpoints.down('sm'));
  const leftOnly = screenIsSmall && focusOn === 'left';
  const rightOnly = screenIsSmall && focusOn === 'right';

  return (
    <div
      style={{ height }}
      className={clsx(
        classes.grid,
        rightOnly && classes.gridRightOnly,
        leftOnly && classes.gridLeftOnly
      )}
    >
      <div className={classes.topBar1}>
        <AppointmentToolbar1 />
      </div>
      <div className={classes.topBar2}>
        <AppointmentToolbar2 />
      </div>
      {(!screenIsSmall || leftOnly) && (
        <div className={classes.left} data-testid="leftPanel">
          {left}
        </div>
      )}
      {(!screenIsSmall || rightOnly) && (
        <div className={classes.right} data-testid="rightPanel">
          {right}
        </div>
      )}
    </div>
  );
}
