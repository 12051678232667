import React from 'react';

// Note that state passed-in must be JSON-parsable
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useMemory(keyName: string, initialValue: any) {
  const getStorageValue = () => {
    let val = undefined;
    try {
      // Purposefully allow this to fail if value is not JSON-parsable
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      val = JSON.parse(window.localStorage.getItem(keyName));
    } catch (e) {
      // pass
    }
    return val;
  };

  // If a local-storage value exists, use it preferentially. Otherwise use what's specified.
  const [state, setState] = React.useState(getStorageValue() ?? initialValue);

  React.useEffect(() => {
    window.localStorage.setItem(keyName, JSON.stringify(state));
  }, [keyName, state]);

  return [state, setState];
}
