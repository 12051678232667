import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { colors } from '../../../theme';

const useStyles = makeStyles({
  root: {
    '& .MuiTypography-root': {
      display: 'inline-block',
      '&.MuiTypography-colorSecondary': {
        paddingLeft: '6px',
        color: colors.secondary.violet,
        fontWeight: 300,
      },
    },
  },
});

export default function ApplicationTitle({
  className,
}: {
  className?: string;
}) {
  const { root } = useStyles();
  return (
    <Box className={clsx(className, root)}>
      <Typography color="primary" variant="h3">
        Video Chat
      </Typography>
    </Box>
  );
}
