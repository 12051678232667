import React from 'react';
import AppointmentParticipant from './AppointmentParticipant';
import { makeStyles } from '@material-ui/styles';
import { ApptParticipantType } from '../../../state/appt/apptTypes';
import { filterParticipantListForDisplay } from '../utils';
import InviteAppointmentParticipant from './InviteAppointmentParticipant';

interface AppointmentParticipantListInterface {
  participants: ApptParticipantType[];
  onRemove: (participant: ApptParticipantType) => void;
  isInCallPage?: boolean;
}

const useStyles = makeStyles({
  noList: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
});

export default function AppointmentParticipantList({
  participants,
  onRemove,
  isInCallPage = false,
}: AppointmentParticipantListInterface) {
  const classes = useStyles();
  const participantListForDisplay = filterParticipantListForDisplay(
    participants
  );
  const hasName = participantListForDisplay.some(
    (participant) =>
      participant.name ||
      (!participant.name && participant.type === 'INTERPRETER')
  );

  return isInCallPage ? (
    <div>
      {participantListForDisplay.map((participant) => (
        <InviteAppointmentParticipant
          key={participant.id}
          participant={participant}
          onRemove={onRemove}
        />
      ))}
    </div>
  ) : (
    <ul className={classes.noList}>
      {participantListForDisplay.map((participant) => (
        <AppointmentParticipant
          key={participant.id}
          participant={participant}
          onRemove={onRemove}
          showName={hasName}
        />
      ))}
    </ul>
  );
}
