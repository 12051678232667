import React from 'react';
import { useLocalVolumeContext } from '../useLocalVolumeContext/useLocalVolumeContext';

export default function useMicIsWorking(
  delaySuccessByNSeconds = 1,
  delayFailureByExtraNSeconds = 0
) {
  const volume = useLocalVolumeContext();
  const [micIsWorking, setMicIsWorking] = React.useState(false);
  const volumeRef = React.useRef<number | null>(null);

  React.useEffect(() => {
    volumeRef.current = volume;
  }, [volume]);

  React.useEffect(() => {
    const delayBySegments = delaySuccessByNSeconds * 4;
    const bufferSegments = delayBySegments + delayFailureByExtraNSeconds * 4;

    let measurements: (number | null)[] = [];
    const interval = window.setInterval(() => {
      measurements = [volumeRef.current, ...measurements];
      // cap measurements per the failure delay segments
      measurements = measurements.slice(0, bufferSegments);
      // Set mic-is-working if 1-second of measurements and at least one is not-null/above-zero
      setMicIsWorking(
        measurements.length >= delayBySegments &&
          !!measurements.find((measurement) => measurement)
      );
    }, 250);
    return () => window.clearInterval(interval);
  }, [delayFailureByExtraNSeconds, delaySuccessByNSeconds]);

  return micIsWorking;
}
