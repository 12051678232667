import React from 'react';
import useHeight from './hooks/useHeight/useHeight';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';
import useRoomState from './hooks/useRoomState/useRoomState';
import { PreRoomPage } from './components/PreRoomPage/PreRoomPage';
import { RoomState } from './hooks/useRoomState/RoomState';
import VideoAudioError from './components/VideoAudioError/VideoAudioError';
import { userIdentifier } from './utils/userIdentifier';
import { shortRoomID } from './utils';
import {
  ACTION_IN_APPOINTMENT,
  ACTION_PROPERTY_APPOINTMENT_ID,
  ACTION_PROPERTY_USER_IDENTIFIER,
} from './dynatraceConstants';
import { makeStyles } from '@material-ui/styles';
import VideoMenuBar from './components/MenuBar/VideoMenuBar';
import theme, { panelShadow } from './theme';
import RightPanelRouter from './components/RightPanelRouter/RightPanelRouter';
import { VIDEO_TOOLBAR_HEIGHT_UNITS } from './constants';
import { useVideoRoomState } from './hooks/useVideoRoomState/VideoRoomStateProvider';
import { ChatProvider } from './hooks/useChatContext/ChatProvider';
import ChatToast from './components/ChatToast/ChatToast';
import useSocketContext from './hooks/useSocketContext/useSocketContext';
import useReportSocketStatus from './hooks/useReportSocketStatus/useReportSocketStatus';
import TestAudioVideoDialog from './components/TestAudioVideoDialog/TestAudioVideoDialog';
import useNotifyParticipantJoin from './hooks/useNotifyParticipantJoin/useNotifyParticipantJoin';
import ApptError from './components/appt/ApptError/ApptError';
import useIsShowingApptError from './hooks/appt/useIsShowingApptError/useIsShowingApptError';
import { ReservationProvider } from './hooks/useReservations/ReservationContext';
import { useHistory, useLocation } from 'react-router-dom';

const toolbarHeight = theme.spacing(VIDEO_TOOLBAR_HEIGHT_UNITS);

const useStyles = makeStyles({
  belowBar: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    // The below get overwritten by styles on the element
    top: 0,
    height: '100%',
  },
  roomVideoWrap: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    transition: '1s',
  },
  leftBar: {
    flex: 1,
    width: '100%',
    // Prevent a small overflow by subtracting 10px
    maxHeight: 'calc(100% - 10px)',
    display: 'flex',
    flexDirection: 'column',
  },
  rightBar: {
    flex: 0,
    display: 'block',
    boxShadow: panelShadow,
  },
});

export default function App() {
  const roomState = useRoomState();
  const location = useLocation();
  const history = useHistory();
  const { showingToolbar, tokenApptDetails } = useVideoRoomState();
  const classes = useStyles();
  const appointmentId = tokenApptDetails?.appointmentId || null;
  const {
    setAppointmentId,
    setConnectDesired,
    setInRoomDesired,
  } = useSocketContext();
  useReportSocketStatus();
  useNotifyParticipantJoin();
  const isShowingApptError = useIsShowingApptError();

  React.useEffect(() => {
    let actionID: null | number = null;
    if (window.dtrum) {
      if (appointmentId && roomState === RoomState.CONNECTED) {
        actionID = window.dtrum.enterAction(ACTION_IN_APPOINTMENT, 'Custom');
        window.dtrum.addActionProperties(actionID, undefined, undefined, {
          [ACTION_PROPERTY_APPOINTMENT_ID]: shortRoomID(appointmentId),
          [ACTION_PROPERTY_USER_IDENTIFIER]: userIdentifier,
        });
      }
    }
    return () => {
      if (window.dtrum && actionID) {
        window.dtrum.leaveAction(actionID);
      }
    };
  }, [roomState, appointmentId]);

  React.useEffect(() => {
    if (appointmentId) {
      setAppointmentId(appointmentId);
      setConnectDesired(true);
      setInRoomDesired(roomState !== RoomState.DISCONNECTED);
    }
  }, [
    setInRoomDesired,
    roomState,
    setConnectDesired,
    setAppointmentId,
    appointmentId,
  ]);

  React.useEffect(() => {
    if (roomState === RoomState.DISCONNECTED) {
      const queryParams = new URLSearchParams(location.search);

      if (queryParams.has('right_panel')) {
        queryParams.delete('right_panel');
        history.replace({
          search: queryParams.toString(),
        });
      }
    }
    // only on mount, remove the right_panel query param from the url so it's avoided showing on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fix for mobile-Safari, which does not account for URL-bar in `height: 100%`
  const height = useHeight(0, true) as number;
  return (
    <ReservationProvider>
      <ChatProvider>
        <ChatToast />
        <ApptError isShowing={isShowingApptError} />
        <div
          style={{
            height,
          }}
        >
          {showingToolbar && <VideoMenuBar />}
          <div
            className={classes.belowBar}
            style={{
              position: 'relative',
              height: showingToolbar
                ? height - toolbarHeight + 'px'
                : height + 'px',
            }}
          >
            <div className={classes.roomVideoWrap}>
              <div className={classes.leftBar}>
                {roomState === RoomState.DISCONNECTED ? (
                  <PreRoomPage />
                ) : (
                  <Room />
                )}
              </div>
              <div className={classes.rightBar}>
                <RightPanelRouter />
              </div>
            </div>
          </div>
          <VideoAudioError />
          <ReconnectingNotification />
          <TestAudioVideoDialog />
        </div>
      </ChatProvider>
    </ReservationProvider>
  );
}
