import React, { ChangeEvent, FormEvent, useEffect, useCallback } from 'react';
import {
  Grid,
  Container,
  TextField,
  ContainerProps,
  createStyles,
  Theme,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import SpinnerButton from '../SpinnerButton/SpinnerButton';
import useAuthBaseContext from '../../hooks/useAuthBaseContext/useAuthBaseContext';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import { shortRoomID } from '../../utils';
import useSocketContext from '../../hooks/useSocketContext/useSocketContext';
import tr from '../../utils/tr';
import useURLRoomToken from '../../hooks/useURLRoomToken/useURLRoomToken';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';
import { reportInfo } from '@ascension/report-event';
import { useApptStateContext } from '../../state/appt/ApptStateProvider';

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  /* istanbul ignore next */
  createStyles({
    form: {
      '& h1': {
        fontSize: '30px',
        marginBlockStart: 0,
      },
      '& .MuiInput-root.Mui-error': {
        borderColor: theme.palette.error.main,
      },
      '& .MuiFormHelperText-root.Mui-error': {
        fontWeight: 400,
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: theme.palette.text.primary,
      },
      '& .MuiButton-root': {
        marginLeft: 0,
        marginRight: 0,
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    subText: {
      fontWeight: 300,
      marginTop: '-12px',
      opacity: 0.7,
      fontSize: '15px',
      lineHeight: '20px',
    },
    fullButton: {
      width: '100%',
      '&.MuiButton-root': {
        marginLeft: '0',
        marginRight: '0',
      },
    },
  })
);
const NAME_REQUIRED = tr('Name required ##form error');

export const JoinForm = (props: ContainerProps) => {
  const { isLoggedIn, givenName, familyName } = useAuthBaseContext();
  const classes = useStyles();
  const URLRoomToken = useURLRoomToken();
  const { getTokenAppointmentDetails } = useApptStateContext();
  const { connect } = useVideoContext();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [nameValidationError, setNameValidationError] = React.useState<
    string
  >();
  const [displayName, setDisplayName] = React.useState('');
  const { setName } = useSocketContext();
  const participantToken = useURLRoomToken();
  const connectWithCallback = useCallback(connect, []);

  useEffect(() => {
    if (isLoggedIn && givenName && familyName) {
      if (givenName && familyName) {
        setName(`${givenName} ${familyName}`);
        setDisplayName(`${givenName} ${familyName}`);
      }
    }
  }, [setName, setDisplayName, isLoggedIn, familyName, givenName]);

  useEffect(() => {
    if (URLRoomToken) {
      reportInfo('Appointment ID: ' + shortRoomID(URLRoomToken));
    }
  }, [URLRoomToken]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNameValidationError(undefined);
    setDisplayName(event.target.value);
  };

  const handleSubmit = useCallback(
    async (event?: FormEvent<HTMLFormElement>) => {
      if (event) event.preventDefault();

      if (!displayName.trim()) {
        setNameValidationError(NAME_REQUIRED);
        return;
      }
      setIsSubmitting(true);
      analyticEvent({ label: 'Join Call', action: ACTION_CALL_TO_ACTION });

      const cleanedDisplayName = displayName.trimLeft();

      setName(cleanedDisplayName);
      const twilioToken = await getTokenAppointmentDetails({
        identity: cleanedDisplayName,
        participantToken,
      });
      if (twilioToken && twilioToken.twilioToken)
        connectWithCallback(twilioToken.twilioToken);
      setIsSubmitting(false);
    },
    [
      setNameValidationError,
      setIsSubmitting,
      connectWithCallback,
      getTokenAppointmentDetails,
      participantToken,
      displayName,
      setName,
    ]
  );

  useEffect(() => {
    if (nameValidationError === NAME_REQUIRED) {
      analyticEvent({
        label: 'Missing Name for Join Call',
        action: ACTION_CALL_TO_ACTION,
      });
    }
  }, [nameValidationError]);

  const helperText =
    nameValidationError ||
    tr('How your name will appear to participants. ##preroom help');

  return (
    <Container {...props} className={clsx([props.className, classes.form])}>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
        >
          <TextField
            id="displayName"
            label={tr('Display Name ##preroom input')}
            helperText={helperText}
            required={true}
            onChange={handleNameChange}
            error={!!nameValidationError}
            value={displayName}
            autoComplete="off"
          />
          <SpinnerButton isWaiting={isSubmitting}>
            <Button
              className={classes.fullButton}
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              onClick={() => handleSubmit}
            >
              {isSubmitting ? '' : tr('Join Call Now ##preroom button')}
            </Button>
          </SpinnerButton>
          <TermsAndConditions />
        </Grid>
      </form>
    </Container>
  );
};
