import {
  osVersion,
  browserVersion,
  isAndroid,
  isEdge,
  isIE,
  isIOS,
  isSafari,
  isChrome,
} from 'react-device-detect';

export type BrowserFeaturesType = {
  browserOk: boolean;
  mediaPresent: boolean;
  screensharePresent: boolean;
  isBadSafari14Dot2: boolean;
  isBadIos15: boolean;
  isOldEdge: boolean;
  isIOSWithWebRTC: boolean;
};

export type UseBrowserIsOkReturnType = {
  isOk: boolean;
  features: BrowserFeaturesType;
};

export default function useBrowserIsOk(): UseBrowserIsOkReturnType {
  // Use version number for Edge because react-device-detect does not currently detect
  // recent versions of Edge as Chromium
  const isIOSWithWebRTC = parseFloat(osVersion) >= 14.3;
  const isOldEdge =
    isEdge &&
    (parseInt(browserVersion) < 45 ||
      (!isAndroid && parseInt(browserVersion) < 79));
  const isOldIosAndNotSafari = isIOS && !isIOSWithWebRTC && !isSafari;
  const isNewIosAndNotSafariOrChrome =
    isIOS && isIOSWithWebRTC && !isSafari && !isChrome;
  const isBadSafari14Dot2 = isIOS && isSafari && parseFloat(osVersion) === 14.2;
  const isBadIos15 =
    isIOS && parseFloat(osVersion) >= 15 && parseFloat(osVersion) < 15.4;
  const userMedia =
    // eslint-disable-next-line @typescript-eslint/unbound-method
    navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
  const displayMedia =
    // eslint-disable-next-line @typescript-eslint/unbound-method
    navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;

  const features = {
    // Only approve if NOT IE, not old Edge, and not iOS + other-than-safari
    browserOk:
      !isIE &&
      !isOldEdge &&
      !isOldIosAndNotSafari &&
      !isNewIosAndNotSafariOrChrome &&
      !isBadSafari14Dot2,
    // Media is present if getUserMedia exists
    mediaPresent: !!userMedia,
    screensharePresent: !!displayMedia,
    isIOSWithWebRTC,
    // Flag bad 14.2 safari version
    isBadSafari14Dot2,
    // Flag bad 15 safari version
    isBadIos15,
    // Do not include screenShare as required for browser compatibility
    isOldEdge,
  };

  const isOk = features.browserOk && features.mediaPresent;

  return { isOk, features };
}
