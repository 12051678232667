import {
  cleanPhone,
  formatInviteTarget,
  isEmail,
  isPhone,
  makeFullInvitation,
} from '../../utils';
import { maxSMSTextLength } from '../../utils/settings';
import {
  ApptContentEnum,
  ApptParticipantType,
} from '../../state/appt/apptTypes';
import { InvitedParticipantType } from './AppointmentPageTypes';
import {
  ParticipantTempStatusEnum,
  ApptParticipantWithTempStatusType,
} from './AppointmentUpdate/AppointmentUpdateForm';

export type ValidateEmailOrPhoneType = {
  isValid: boolean;
  type: ApptContentEnum;
  cleaned: string;
  formatted: string;
};

export const getFirstParticipantName = (
  participants: ApptParticipantType[]
) => {
  const selectedParticipant = participants.find(({ name }) => name);
  return selectedParticipant?.name;
};

export const validateEmailOrPhone = (
  str: string | number
): ValidateEmailOrPhoneType => {
  const cStr = `${str}`.trim();
  const iscEmail = isEmail(cStr);
  const cPhone = cleanPhone(cStr);
  const iscPhone = isPhone(cStr);
  if (iscEmail) {
    return {
      isValid:
        /^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9.-]+$/gm.test(
          cStr
        ) && !/(^\.)|\.@|\.\./.test(cStr),
      type: ApptContentEnum.EMAIL,
      cleaned: cStr,
      formatted: formatInviteTarget(cStr),
    };
  }
  if (iscPhone) {
    return {
      isValid: cPhone.length === 10 && `${parseInt(cPhone)}` === cPhone,
      type: ApptContentEnum.PHONE,
      cleaned: cPhone,
      formatted: formatInviteTarget(cPhone),
    };
  }
  return {
    isValid: false,
    type: ApptContentEnum.NONE,
    cleaned: cStr,
    formatted: cStr,
  };
};

export const trimInviteString = (
  str: string,
  domain: string,
  roomId: string
) => {
  const allowedLength =
    maxSMSTextLength -
    makeFullInvitation({
      domain,
      token: roomId,
      invitationText: '',
    }).length;
  return str.substring(0, allowedLength);
};

export const filterParticipantListForDisplay = (
  participants:
    | ApptParticipantType[]
    | InvitedParticipantType[]
    | ApptParticipantWithTempStatusType[]
) => {
  return participants.filter(
    (participant) =>
      ![ApptContentEnum.SELF, ApptContentEnum.COPIED].includes(
        participant.type
      ) &&
      (!('tempStatus' in participant) ||
        (participant as ApptParticipantWithTempStatusType).tempStatus !==
          ParticipantTempStatusEnum.REMOVED) &&
      !participant.cancelled
  );
};
