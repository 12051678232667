import { Room, TwilioError } from 'twilio-video';
import { useEffect } from 'react';

import { ErrorCallback } from '../../../types';
import tr from '../../../utils/tr';

export default function useHandleRoomDisconnectionErrors(
  room: Room,
  onError: ErrorCallback
) {
  useEffect(() => {
    const onDisconnected = (_room: Room, error: TwilioError) => {
      if (error) {
        onError({
          title: tr('Connection lost. ##error modal'),
          message: tr(
            'The connection to Video Chat has been lost. Ensure that your connection to the internet is active. ##error modal'
          ),
        });
      }
    };

    room.on('disconnected', onDisconnected);
    return () => {
      room.off('disconnected', onDisconnected);
    };
  }, [room, onError]);
}
