import { useEffect, useState } from 'react';
import {
  LocalAudioTrack,
  LocalDataTrack,
  LocalTrackPublication,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteDataTrack,
  RemoteTrackPublication,
  RemoteVideoTrack,
} from 'twilio-video';
import { AnyTrackNullableType } from '../../components/VideoProvider/VideoProviderTypes';

type IncomingTrackType =
  | LocalVideoTrack
  | RemoteVideoTrack
  | LocalAudioTrack
  | RemoteAudioTrack
  | LocalDataTrack
  | RemoteDataTrack
  | null;

const nullTrack = { track: null };

export default function useTrack(
  publication: LocalTrackPublication | RemoteTrackPublication | undefined
) {
  const [track, setTrack] = useState<AnyTrackNullableType>(nullTrack);
  const [tempTrack, setTempTrack] = useState<AnyTrackNullableType>({
    track: publication ? publication.track : null,
  });

  useEffect(() => {
    // Use a temp-track to allow nulling-out of the track without getting in a circular useEffect loop
    setTempTrack({ track: publication ? publication.track : null });

    if (publication) {
      const removeTrack = () => {
        setTempTrack({ track: null });
      };
      const addTrack = (newTrack: IncomingTrackType) => {
        setTempTrack({ track: newTrack });
      };
      publication.on('subscribed', addTrack);
      publication.on('unsubscribed', removeTrack);
      return () => {
        publication.off('subscribed', addTrack);
        publication.off('unsubscribed', removeTrack);
      };
    }
  }, [publication]);

  useEffect(() => {
    const thisTrack = tempTrack.track;
    const triggerTrackChange = () => {
      setTrack({ track: thisTrack });
    };
    if (thisTrack) {
      thisTrack.on('started', triggerTrackChange);
      thisTrack.on('stopped', triggerTrackChange);
      thisTrack.on('enabled', triggerTrackChange);
      thisTrack.on('disabled', triggerTrackChange);
      triggerTrackChange();
    } else {
      setTrack({ track: null });
    }
    return () => {
      if (thisTrack) {
        thisTrack.off('started', triggerTrackChange);
        thisTrack.off('stopped', triggerTrackChange);
        thisTrack.off('enabled', triggerTrackChange);
        thisTrack.off('disabled', triggerTrackChange);
      }
    };
  }, [tempTrack]);

  return track;
}
