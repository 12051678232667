import React from 'react';
import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Appointment, { AppointmentDetailType } from './Appointment';
import AddAppointmentButton from './AddAppointmentButton';
import AppointmentsListHeader from './AppointmentsListHeader';
import tr from '../../../utils/tr';
import useApptListForDisplay from '../../../hooks/appt/useApptListForDisplay/useApptListForDisplay';
import clsx from 'clsx';
import { useApptStateContext } from '../../../state/appt/ApptStateProvider';
import DotsIcon from '../../SpinnerButton/DotsIcon';
import { colors } from '../../../theme';

export const apptListPadding = (theme: Theme) => {
  return {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  };
};

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  /* istanbul ignore next */
  createStyles({
    apptList: {
      backgroundColor: theme.palette.grey[100],
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    addAppt: {
      paddingTop: '20px',
      paddingBottom: '20px',
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      ...apptListPadding(theme),
    },
    apptGroup: {
      flex: 1,
      padding: 0,
      margin: 0,
      overflowY: 'auto',
      ...apptListPadding(theme),
    },
    noAppt: {
      padding: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    loadingAnimation: {
      position: 'absolute',
    },
  })
);

export default function AppointmentsList({
  appointmentId,
}: {
  appointmentId?: string;
}) {
  const apptListForDisplay = useApptListForDisplay();
  const { state } = useApptStateContext();
  const classes = useStyles();

  return (
    <div className={classes.apptList}>
      <AppointmentsListHeader appointmentsCount={apptListForDisplay.length} />
      {apptListForDisplay.length > 0 ? (
        <ol className={classes.apptGroup}>
          {apptListForDisplay.map((appointment: AppointmentDetailType) => (
            <Appointment
              key={appointment.id}
              appointmentDetail={appointment}
              isSelected={!!appointmentId && appointment.id === appointmentId}
              somethingIsSelected={!!appointmentId}
            />
          ))}
        </ol>
      ) : state.appointments ? (
        <div className={clsx(classes.apptGroup, classes.noAppt)}>
          <div>
            {tr('No Appointments Today ##appointments list no appointments')}
          </div>
        </div>
      ) : (
        <div className={clsx(classes.apptGroup, classes.noAppt)}>
          <DotsIcon
            size={5}
            className={classes.loadingAnimation}
            color={colors.primaryPalette.brandBlue}
          />
        </div>
      )}
      <div className={classes.addAppt}>
        <AddAppointmentButton />
      </div>
    </div>
  );
}
