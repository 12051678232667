import React from 'react';
import { socketIsFailedIfNotConnectedByNSeconds } from '../../utils/settings';
import useSocketContext from '../useSocketContext/useSocketContext';

export default function useSocketTimedOut() {
  const { connectDesired, connectionStatus } = useSocketContext();
  const [socketTimedOut, setSocketTimedOut] = React.useState(false);

  React.useEffect(() => {
    const timeout = window.setTimeout(() => {
      setSocketTimedOut(connectDesired && connectionStatus !== 'connected');
    }, socketIsFailedIfNotConnectedByNSeconds * 1000);

    return () => window.clearTimeout(timeout);
  }, [connectDesired, connectionStatus]);

  return socketTimedOut;
}
