import React from 'react';
import MenuBar from '../MenuBar/MenuBar';
import {
  Container,
  createStyles,
  Grid,
  Paper,
  Theme,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  APPLICATION_TITLE_HEIGHT,
  FLOATING_BUTTON_POSITION,
  QUERY_CALL_DURATION,
} from '../../constants';
import FeedbackForm from '../FeedbackForm/FeedbackForm';
import useAuthBaseContext from '../../hooks/useAuthBaseContext/useAuthBaseContext';
import useQuery from '../../hooks/useQuery/useQuery';
import { colors } from '../../theme';
import { Link as RouterLink } from 'react-router-dom';
import {
  getLongTimestampFromSeconds,
  getTextDirectionOriginIsRight,
} from '../../utils';
import tr from '../../utils/tr';
import useURLRoomToken from '../../hooks/useURLRoomToken/useURLRoomToken';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';
import { RouteEnum } from '../../RouteEnum';
import clsx from 'clsx';

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  /* istanbul ignore next */
  createStyles({
    parent: {
      marginTop: APPLICATION_TITLE_HEIGHT + -FLOATING_BUTTON_POSITION,
      [theme.breakpoints.down('xs')]: {
        '&.MuiTypography-h1': {
          fontSize: '32px',
        },
      },
    },
    inner: {
      padding: theme.spacing(2),
    },
    fullButton: {
      '&.MuiButton-root': {
        marginLeft: '0',
        marginRight: '0',
      },
    },
    paper: {
      backgroundColor: colors.grayscale.gray11,
      padding: theme.custom.boxPaperPadding,
      display: 'flex',
    },
    callDuration: {
      fontWeight: 500,
      marginBottom: '0.4em',
      fontSize: '20px',
    },
    participantCount: theme.custom.subscriptStyle,
    leftCallSummary: {
      display: 'flex',
      alignItems: 'center',
    },
    rightDirection: {
      direction: 'rtl',
    },
    title: {
      fontWeight: 325,
    },
    outer: {
      maxWidth: '800px',
    },
    callComplete: {
      flexWrap: 'unset',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
  })
);

export default function CallComplete({
  feedbackWasSent = false,
}: {
  feedbackWasSent?: boolean;
}) {
  const classes = useStyles();
  const { isLoggedIn } = useAuthBaseContext();
  const query = useQuery();
  const callDuration: number = isNaN(
    (query.get(QUERY_CALL_DURATION) as unknown) as number
  )
    ? 0
    : ((query.get(QUERY_CALL_DURATION) as unknown) as number);
  const URLRoomToken = useURLRoomToken();

  const handleRejoinAnalytics = () => {
    analyticEvent({ label: 'Rejoin Call', action: ACTION_CALL_TO_ACTION });
  };

  return (
    <>
      <MenuBar displaySignOut={true} showingRightUpperButtons={isLoggedIn} />
      <Grid container className={classes.parent}>
        <Container className={classes.outer}>
          <Container className={classes.inner}>
            <Typography variant="h1" className={classes.title}>
              {tr('You left the call ##summary title')}
            </Typography>
            <Paper className={classes.paper}>
              <Grid
                container
                alignItems="stretch"
                style={{ margin: 0 }}
                className={classes.callComplete}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.leftCallSummary}
                  justify={
                    getTextDirectionOriginIsRight() ? 'flex-end' : 'flex-start'
                  }
                >
                  <Typography
                    variant="h4"
                    className={clsx(
                      classes.callDuration,
                      getTextDirectionOriginIsRight()
                        ? classes.rightDirection
                        : {}
                    )}
                    data-testid="callDuration"
                  >
                    {tr('Call lasted ##summary') +
                      ' ' +
                      getLongTimestampFromSeconds(callDuration)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    component={RouterLink}
                    data-testid="backButton"
                    to={`${RouteEnum.CALL}${URLRoomToken}`}
                    onClick={handleRejoinAnalytics}
                  >
                    {tr('Rejoin ##summary button')}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            <br />
            <FeedbackForm feedbackWasSent={feedbackWasSent} />
          </Container>
        </Container>
      </Grid>
    </>
  );
}
