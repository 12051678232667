import useToastContext from '../useToastContext/useToastContext';
import React from 'react';

export default function useListenerForChildMessages() {
  const { add: addToast } = useToastContext();

  React.useEffect(() => {
    const listener = (event: MessageEvent) => {
      const { message, type } = event.data;
      if (type === 'toastMessage') {
        addToast({ message });
      }
    };

    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);
  }, [addToast]);
}
