import React, { useReducer } from 'react';
import { ApptActionsType } from './ApptActionObjectType';
import apptInitialState, { ApptStateType } from './apptInitialState';
import apptReducer from './apptReducer';
import useWatchApptList from '../../hooks/appt/useWatchApptList/useWatchApptList';
import { VideoErrorType } from '../../components/ErrorDialog/VideoErrorType';
import useGetTokenApptDetails, {
  UseGetTokenApptDetailsInterface,
} from '../../hooks/appt/useGetTokenApptDetails/useGetTokenApptDetails';
import {
  TokenApptDetailsType,
  ClinicianType,
  SelectedClinicianADIDType,
} from './apptTypes';
import useCliniciansList from '../../hooks/useCliniciansList/useCliniciansList';

export type UseApptStateContextResponse = {
  state: ApptStateType;
  dispatch: React.Dispatch<ApptActionsType>;
  getAppointments: () => void;
  isDefault: boolean;
  error: VideoErrorType | null;
  setError(error: VideoErrorType | null): void;
  tokenError: VideoErrorType | null | undefined;
  setTokenError(tokenError: VideoErrorType | null | undefined): void;
  clickRefreshBtn: boolean;
  setClickRefreshBtn(clickRefreshBtn: boolean): void;
  getTokenAppointmentDetails({
    identity,
    participantToken,
  }: UseGetTokenApptDetailsInterface): Promise<void | TokenApptDetailsType>;
  athenaUser: boolean | null;
  clinicians: ClinicianType[] | null;
  selectedClinicianADID: SelectedClinicianADIDType;
  setSelectedClinicianADID: React.Dispatch<
    React.SetStateAction<SelectedClinicianADIDType>
  >;
  changeClinician: boolean;
  setChangeClinician: (changeClinician: boolean) => void;
};

const ApptStateContext = React.createContext<UseApptStateContextResponse>({
  state: apptInitialState,
  dispatch: () => {
    // empty implementation
  },
  getAppointments: () => {
    // empty implementation
  },
  isDefault: true,
  error: null,
  setError: () => {
    // empty implementation
  },
  tokenError: undefined,
  setTokenError: () => {
    // empty implementation
  },
  clickRefreshBtn: false,
  setClickRefreshBtn: () => {
    // empty implementation
  },
  getTokenAppointmentDetails: () => Promise.resolve(),
  athenaUser: null,
  clinicians: null,
  selectedClinicianADID: '',
  setSelectedClinicianADID: () => {
    // empty implementation
  },
  changeClinician: false,
  setChangeClinician: () => {
    // empty implementation
  },
});

export default function ApptStateProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = useReducer(apptReducer, apptInitialState);
  const [forceGetApptList, setForceGetApptList] = React.useState({});
  const [error, setError] = React.useState<VideoErrorType | null>(null);
  const [clickRefreshBtn, setClickRefreshBtn] = React.useState<boolean>(false);
  const [changeClinician, setChangeClinician] = React.useState<boolean>(false);
  const [tokenError, setTokenError] = React.useState<
    VideoErrorType | null | undefined
  >();
  const {
    athenaUser,
    clinicians,
    selectedClinicianADID,
    setSelectedClinicianADID,
  } = useCliniciansList({
    dispatch,
    onApptPage: !window.location.href.includes('appointment'),
  });
  const getTokenAppointmentDetails = useGetTokenApptDetails({
    setTokenError,
    dispatch,
  });
  useWatchApptList({
    dispatch,
    from: state.apptFrom,
    to: state.apptTo,
    forceGet: forceGetApptList,
    selectedClinicianADID,
    changeClinician,
  });

  const getAppointments = React.useCallback(() => setForceGetApptList({}), []);

  return (
    <ApptStateContext.Provider
      value={{
        state,
        dispatch,
        getAppointments,
        isDefault: false,
        error,
        setError,
        tokenError,
        setTokenError,
        clickRefreshBtn,
        setClickRefreshBtn,
        getTokenAppointmentDetails,
        athenaUser,
        clinicians,
        selectedClinicianADID,
        setSelectedClinicianADID,
        changeClinician,
        setChangeClinician,
      }}
    >
      {children}
    </ApptStateContext.Provider>
  );
}

export function useApptStateContext() {
  const { isDefault, ...value } = React.useContext(ApptStateContext);
  if (isDefault) {
    throw new Error(
      'UseApptStateContext can only be used within ApptStateProvider'
    );
  }

  return value;
}
