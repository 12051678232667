import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import { colors } from '../../theme';

export const StyledChip = withStyles({
  root: {
    borderRadius: '16px',
    backgroundColor: colors.grayscale.gray11,
    color: colors.primaryPalette.brandBlue,
    fontWeight: 350,
    fontSize: '14px',
  },
})(Chip);

export const StyledSelectedChip = withStyles({
  root: {
    borderRadius: '16px',
    fontWeight: 350,
    fontSize: '14px',
  },
  colorPrimary: {
    backgroundColor: `${colors.primaryPalette.brandBlue} !important`,
    color: colors.grayscale.white,
  },
})(StyledChip);

const useStyles = makeStyles({
  titleLabel: {
    fontSize: '16px',
    marginBottom: '5px',
    fontWeight: 600,
  },
});

type RatingChipType = {
  id: string;
  message: string;
  from: number;
  to: number;
};

export default function RatingChips({
  title,
  chips = [],
  selectedChipIds,
  onChipClick,
  ...restProps
}: {
  title: string;
  chips?: Array<RatingChipType>;
  selectedChipIds: Array<string>;
  onChipClick: (chipLabel: string) => void;
}) {
  const classes = useStyles();

  return (
    <div {...restProps}>
      <Typography data-testid="ratingChipsTitle" className={classes.titleLabel}>
        {title}
      </Typography>

      {chips.map((chip) => {
        return selectedChipIds.includes(chip.id) ? (
          <StyledSelectedChip
            key={chip.id}
            icon={<DoneIcon />}
            label={chip.message}
            color="primary"
            onClick={() => onChipClick(chip.id)}
          />
        ) : (
          <StyledChip
            key={chip.id}
            label={chip.message}
            onClick={() => onChipClick(chip.id)}
          />
        );
      })}
    </div>
  );
}
