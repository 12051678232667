import React from 'react';
import useVideoTrack from './useVideoTrack';
import useAudioTrack from './useAudioTrack';
import useSpeaker from './useSpeaker';
import useDevices from './useDevices';
import { ErrorType } from './UseMediaTypes';
import { getErrorType } from './utils';

export default function useMedia(isFirefox = false) {
  const [videoInDeviceIdDesired, setVideoInDeviceIdDesired] = React.useState<
    string | null
  >(null);
  const [videoInDisableDesired, setVideoInDisableDesired] = React.useState(
    false
  );
  const [audioInDeviceIdDesired, setAudioInDeviceIdDesired] = React.useState<
    string | null
  >(null);
  const [audioInDisableDesired, setAudioInDisableDesired] = React.useState(
    false
  );
  const [audioOutDeviceIdDesired, setAudioOutDeviceIdDesired] = React.useState<
    string | null
  >(null);
  const [otherError, setOtherError] = React.useState<ErrorType | null>(null);
  const [audioError, setAudioError] = React.useState<ErrorType | null>(null);
  const [videoError, setVideoError] = React.useState<ErrorType | null>(null);
  const [retryVideoKey, setRetryVideoKey] = React.useState({});
  const [retryAudioKey, setRetryAudioKey] = React.useState({});

  const { devices, refresh: refreshDevices } = useDevices(
    React.useCallback(
      (error) => setOtherError({ error, type: getErrorType(isFirefox, error) }),
      [isFirefox]
    )
  );

  const {
    track: videoTrack,
    deviceId: videoInDeviceId,
    disabled: videoInDisabled,
    facing: videoInFacing,
  } = useVideoTrack({
    devices: devices.videoIn,
    refreshDevices,
    disableDesired: videoInDisableDesired,
    deviceIdDesired: videoInDeviceIdDesired,
    onError: React.useCallback(
      (error) => setVideoError({ error, type: getErrorType(isFirefox, error) }),
      [isFirefox]
    ),
    retryKey: retryVideoKey,
  });
  const {
    track: audioTrack,
    deviceId: audioInDeviceId,
    disabled: audioInDisabled,
  } = useAudioTrack({
    devices: devices.audioIn,
    refreshDevices,
    disableDesired: audioInDisableDesired,
    deviceIdDesired: audioInDeviceIdDesired,
    onError: React.useCallback(
      (error) => setAudioError({ error, type: getErrorType(isFirefox, error) }),
      [isFirefox]
    ),
    retryKey: retryAudioKey,
  });

  React.useEffect(() => {
    if (!audioInDisabled && audioTrack?.track?.isEnabled){
      //clear error
      setAudioError(null);
    }
  }, [audioTrack, audioInDisabled]);

  const { deviceId: audioOutDeviceId } = useSpeaker({
    devices: devices.audioOut,
    deviceIdDesired: audioOutDeviceIdDesired,
  });

  return {
    devices,
    videoTrack,
    videoInFacing,
    videoInDeviceId,
    videoInDeviceIdDesired,
    setVideoInDeviceIdDesired,
    videoInDisabled,
    setVideoInDisableDesired,
    retryVideoAcquire: () => setRetryVideoKey({}),
    audioTrack,
    audioInDeviceId,
    audioInDeviceIdDesired,
    setAudioInDeviceIdDesired,
    audioInDisabled,
    setAudioInDisableDesired,
    audioOutDeviceId,
    audioOutDeviceIdDesired,
    setAudioOutDeviceIdDesired,
    retryAudioAcquire: () => setRetryAudioKey({}),
    otherError,
    clearOtherError: React.useCallback(() => setOtherError(null), []),
    videoError,
    clearVideoError: React.useCallback(() => setVideoError(null), []),
    audioError,
    clearAudioError: React.useCallback(() => setAudioError(null), []),
  };
}
