import { fetchWithTimeout, getDefaultHeaders } from '../../../utils';
import {
  ApptFetchErrorTypeEnum,
  ApptFetchErrorAboutEnum,
  ApptFetchResponseType,
} from '../apptHookTypes';

interface CancelApptInterface {
  appointmentId: string;
  baseAccessToken: string;
  targetAdid: string | null;
}

export default function cancelAppt<T>({
  appointmentId,
  baseAccessToken,
  targetAdid,
}: CancelApptInterface): Promise<ApptFetchResponseType<undefined>> {
  return new Promise((resolve) => {
    const { SCHEDULING_SERVICE } = window.config;
    const headers = getDefaultHeaders();
    headers.set('Authorization', `Bearer ${baseAccessToken}`);
    headers.set('Content-Type', 'application/json');

    fetchWithTimeout(`${SCHEDULING_SERVICE}/appointment`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify({
        appointmentId,
        cancelled: true,
        targetAdid: targetAdid || null,
      }),
    })
      .then((res) => {
        if (!res) {
          return resolve({
            error: {
              message: 'Error cancelling appointment',
              type: ApptFetchErrorTypeEnum.SERVER_ERROR,
              about: ApptFetchErrorAboutEnum.CANCEL_APPOINTMENT,
            },
          });
        }
        if (res.status !== 200) {
          let responseType = ApptFetchErrorTypeEnum.ACCESS_DENIED;
          if (res.status >= 500) {
            responseType = ApptFetchErrorTypeEnum.SERVER_ERROR;
          } else if (res.status >= 400) {
            responseType = ApptFetchErrorTypeEnum.INVALID_REQUEST;
          }
          return resolve({
            error: {
              message: `Error cancelling appointment: ${res.status}`,
              type: responseType,
              status: res.status,
              about: ApptFetchErrorAboutEnum.CANCEL_APPOINTMENT,
            },
          });
        }
        resolve({});
      })
      .catch((err) => {
        resolve({
          error: {
            message: 'Error in cancelling appointment: ' + err,
            about: ApptFetchErrorAboutEnum.CANCEL_APPOINTMENT,
          },
        });
      });
  });
}
