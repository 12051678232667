import React from 'react';
import { FormControl, MenuItem, Select, InputLabel } from '@material-ui/core';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import InputMutedNotice from '../InputMutedNotice/InputMutedNotice';
import MiscMediaMessage from '../MiscMediaMessage/MiscMediaMessage';
import { ShowingMediaMessageType } from '../../../../interfaces/ShowingMediaMessageType';
import DeviceRow from '../DeviceRow/DeviceRow';
import tr from '../../../../utils/tr';
import { LocalVideoTrackType } from '../../../VideoProvider/VideoProviderTypes';
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles({
  select: {
    overflow: 'hidden',
  },
});

export default function VideoInputList() {
  const classes = useStyles();
  const {
    devices: { videoIn: videoInDevices },
    localTracks,
    setVideoInDeviceIdDesired,
    videoInDisabled,
    setVideoInDisableDesired,
    videoInDeviceId,
  } = useVideoContext();

  const localVideoTrack = localTracks.find(
    (track) => track.track.kind === 'video'
  ) as LocalVideoTrackType;

  let showingMessage = 'muted' as ShowingMediaMessageType;
  if (!videoInDisabled) {
    if (videoInDevices.length === 0) {
      showingMessage = 'no_options';
    } else if (videoInDevices.length === 1) {
      showingMessage = 'one_option';
    } else {
      showingMessage = 'multiple_options';
    }
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="select-audio-output">
        {tr('Camera ##device label')}
      </InputLabel>
      {showingMessage === 'muted' && (
        <InputMutedNotice
          text={tr('No Local Video ##device label')}
          onUnmute={() => setVideoInDisableDesired(!videoInDisabled)}
        />
      )}
      <DeviceRow>
        {showingMessage === 'multiple_options' && (
          <Select
            className={classes.select}
            onChange={(e) =>
              setVideoInDeviceIdDesired(e.target.value as string)
            }
            value={videoInDeviceId || videoInDevices[0]?.id || ''}
          >
            {videoInDevices.map((device) => (
              <MenuItem value={device.id} key={device.id}>
                {device.name}
              </MenuItem>
            ))}
          </Select>
        )}
        {showingMessage === 'one_option' && (
          <MiscMediaMessage
            text={
              localVideoTrack?.track.mediaStreamTrack.label ||
              tr('Unknown camera ##device label')
            }
          />
        )}
        {showingMessage === 'no_options' && (
          <MiscMediaMessage
            text={tr('No Local Video ##device label')}
            subtext={tr('Check that your device has a camera. ##device label')}
          />
        )}
      </DeviceRow>
    </FormControl>
  );
}
