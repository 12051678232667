import React from 'react';
import useSendMessage, { SentMetadata } from './useSendMessage';
import useParticipantsListener from './useParticipantsListener';
import { reportError } from '@ascension/report-event';
import usePastConfirmedDeadline from './usePastConfirmedDeadline';
import type { TimeSensitiveMessage } from './messageTypes';
import useHandleNotReceived from './useHandleNotReceived';
import { useClearOldMessages } from './useClearOldMessages';
import { useCurrentParticipants } from './useMessageUtils';

const defaultOnError = (err: string) => reportError('Reliable messaging error: ' + err);

export type SendReliablyProps<T> = {
  messageType: string;
  receiveMessage: (message: T & SentMetadata) => void;
  autoResend: boolean;
  onMessageNotReceived?: (message: T & SentMetadata) => void;
  onError?: (err: string) => void;
};

const useSendReliably = <T>({
  messageType,
  receiveMessage,
  autoResend = true,
  onMessageNotReceived = undefined,
  onError = defaultOnError,
}: SendReliablyProps<T>) => {
  const [messages, setMessages] = React.useState<TimeSensitiveMessage<T>[]>([]);
  const { participants } = useCurrentParticipants();

  const { sendMessage, resendMessage } = useSendMessage<T>({
    participants,
    messages,
    setMessages,
    messageType,
    onError,
  });
  useParticipantsListener({
    participants,
    messageType,
    acceptMessage: receiveMessage,
    setMessages,
    onError,
  });
  usePastConfirmedDeadline(messages, setMessages);
  useHandleNotReceived({
    messages,
    resendMessage,
    autoResend,
    onMessageNotReceived,
  });
  useClearOldMessages({ messages, setMessages });

  return { sendMessage, resendMessage };
};
export default useSendReliably;
