import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { IconButton } from '@material-ui/core';
import { createStyles, CSSProperties, makeStyles } from '@material-ui/styles';
import {
  formatInviteTarget,
  getTextDirectionOriginIsRight,
} from '../../../utils';
import clsx from 'clsx';
import { Theme } from '@material-ui/core/styles';
import AppointmentParticipantStatus from './AppointmentParticipantStatus';
import {
  ApptParticipantType,
  ApptContentEnum,
} from '../../../state/appt/apptTypes';
import { useVideoRoomState } from '../../../hooks/useVideoRoomState/VideoRoomStateProvider';
import InterpreterConfirmDialog from '../../InterpreterConfirmDialog/InterpreterConfirmDialog';
import tr from '../../../utils/tr';
import ReportProblemOutlined from '@material-ui/icons/ReportProblemOutlined';
import { colors } from '../../../theme';

const noListItem = {
  listStyle: 'none',
  margin: 0,
  padding: 0,
};
const cell: CSSProperties = {
  ...noListItem,
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 500,
};
const noOverflow: CSSProperties = {
  wordBreak: 'break-word',
};

export interface InviteAppointmentParticipantInterface {
  participant: ApptParticipantType;
  onRemove: (participant: ApptParticipantType) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warningIcon: {
      color: colors.notifications.warning2,
      width: '84px',
      height: '84px',
    },
    deleteButton: {
      padding: '0px 12px',
    },
    rowList: {
      ...noListItem,
      display: 'grid',
      gridTemplateColumns: '4fr 4fr 1fr',
      gridGap: theme.spacing(1),
      paddingTop: '5px',
      paddingBottom: '5px',
    },
    rowListRightDirection: {
      gridTemplateColumns: '2.5fr 2.5fr 1fr',
    },
    rowListTwoColumns: {
      gridTemplateColumns: '8fr 1fr',
    },
    rowListTwoColumnsRightDirection: {
      gridTemplateColumns: '5fr 1fr',
    },
    noListItem,
    cell,
    noOverflow,
    subCell: {
      ...noOverflow,
    },
    apptStatus: {
      alignItems: 'flex-end',
      marginTop: '0.4em',
    },
    inviteParticipant: {
      padding: '0px 16px',
      backgroundColor: '#FAFAFA',
      margin: '16px 0px',
      borderRadius: '8px',
      minHeight: '54px',
      display: 'grid',
      alignItems: 'center',
    },
  })
);

export default function InviteAppointmentParticipant({
  participant,
  onRemove,
}: InviteAppointmentParticipantInterface) {
  const classes = useStyles();
  const [showInterpreterConfirm, setShowInterpreterConfirm] = useState<boolean>(
    false
  );

  const onCloseInterpreterConfirm = () => setShowInterpreterConfirm(false);
  const onOkayInterpreterConfirm = () => {
    setShowInterpreterConfirm(false);
    onRemove(participant);
  };

  const handleRemove = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (participant.type === 'INTERPRETER') {
      setShowInterpreterConfirm(true);
    } else {
      onRemove(participant);
    }
  };

  const { interpreterLanguage } = useVideoRoomState();

  let { name, content } = participant;
  if (participant.type === ApptContentEnum.INTERPRETER) {
    name = 'Interpreter';
    content = interpreterLanguage;
  }

  return (
    <div className={classes.inviteParticipant}>
      <ul
        className={clsx(
          classes.rowList,
          getTextDirectionOriginIsRight() && classes.rowListRightDirection
        )}
      >
        <li className={classes.cell}>
          <div className={clsx(classes.subCell)}>{name || ''}</div>
          <div className={classes.noOverflow}>
            {content ? formatInviteTarget(content) : ''}
          </div>
        </li>
        <li className={clsx(classes.cell, classes.apptStatus)}>
          <AppointmentParticipantStatus
            className={classes.subCell}
            participantStatuses={participant.statuses}
            participantType={participant.type}
            isInCallPage={true}
          />
        </li>
        <li className={classes.cell}>
          <IconButton
            aria-label="close"
            className={classes.deleteButton}
            onClick={handleRemove}
          >
            <DeleteIcon color="primary" />
          </IconButton>
        </li>
      </ul>
      {showInterpreterConfirm && (
        <InterpreterConfirmDialog
          icon={<ReportProblemOutlined className={classes.warningIcon} />}
          onCancelClick={onCloseInterpreterConfirm}
          onOkayClick={onOkayInterpreterConfirm}
          message={tr(
            'Are you sure you want to cancel your request for an interpreter? ##interpreter-confirm content'
          )}
          cancelButtonLabel={tr('CANCEL ##interpreter-remove-cancel button')}
          okayButtonLabel={tr('YES ##interpreter-remove-yes button')}
        ></InterpreterConfirmDialog>
      )}
    </div>
  );
}
