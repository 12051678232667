import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Participant } from 'twilio-video';
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import BandwidthWarning from '../BandwidthWarning/BandwidthWarning';
import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';
import ScreenShare from '@material-ui/icons/ScreenShare';
import VideocamOff from '@material-ui/icons/VideocamOff';
import useParticipantNetworkQualityLevel from '../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import useTrack from '../../hooks/useTrack/useTrack';
import useStripId from '../VideoProvider/useStripId/useStripId';
import {
  LocalAudioTrackType,
  LocalVideoTrackNullableType,
  RemoteAudioTrackType,
  RemoteVideoTrackNullableType,
} from '../VideoProvider/VideoProviderTypes';
import { MicOff } from '@material-ui/icons';
import useFilteredPublications from '../../hooks/useFilteredPublications/useFilteredPublications';
import ParticipantInfoContainer from './ParticipantInfoContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      cursor: 'pointer',
      pointerEvents: 'none',
      '& video': {
        filter: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '10px',
      },
    },
    isVideoSwitchedOff: {
      '& video': {
        filter: 'blur(4px) grayscale(1) brightness(0.5)',
      },
    },
    iconWrap: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '3px',
      '& svg': {
        stroke: 'black',
        strokeWidth: '0.8px',
      },
    },
  })
);

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  enableScreenShare?: boolean;
}

export default function ParticipantInfo({
  participant,
  enableScreenShare,
  onClick = () => {
    // default empty on click implementation
  },
  children,
}: ParticipantInfoProps) {
  const publications = useFilteredPublications(participant, enableScreenShare);
  const isScreenshare = publications.some((p) =>
    p.trackName.includes('screen')
  );
  const audioPublication = publications.find((p) => p.kind === 'audio');
  const videoPublication = publications.find((p) => p.kind === 'video');
  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);

  const videoTrack = useTrack(videoPublication) as
    | LocalVideoTrackNullableType
    | RemoteVideoTrackNullableType;
  const videoEnabled = videoTrack.track?.isEnabled;
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack);
  const isBandwidthCapped = videoEnabled === true && isVideoSwitchedOff;

  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrackType
    | RemoteAudioTrackType;
  const audioEnabled = audioTrack.track?.isEnabled;

  const classes = useStyles();

  const stripId = useStripId();
  const niceIdentity = stripId(participant.identity);
  return (
    <div
      className={clsx(
        classes.container,
        isBandwidthCapped && classes.isVideoSwitchedOff
      )}
      onClick={onClick}
      data-cy-participant={participant.identity}
      data-testid="participant-info"
    >
      <ParticipantInfoContainer
        identity={niceIdentity}
        overlay={
          <div className={classes.iconWrap}>
            <NetworkQualityLevel qualityLevel={networkQualityLevel} />
            {audioEnabled ? (
              <AudioLevelIndicator
                audioTrack={audioTrack}
                background="white"
                enabled={audioEnabled}
              />
            ) : (
              <MicOff color="error" />
            )}
            {!videoEnabled && <VideocamOff className="cameraOffIcon" />}
            {isScreenshare && <ScreenShare className="screenSharingIcon" />}
          </div>
        }
      >
        {isBandwidthCapped && <BandwidthWarning />}
        {children}
      </ParticipantInfoContainer>
    </div>
  );
}
