import React from 'react';
import ChatIcon from '@material-ui/icons/Chat';
import { Badge, Button } from '@material-ui/core';
import tr from '../../utils/tr';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { isMobile } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chat: {
      padding: `${theme.spacing(0)}px ${theme.spacing(2)}px `,
      textTransform: 'uppercase',
      fontSize: `${theme.spacing(1.75)}px `,
      lineHeight: `${theme.spacing(2)}px `,
      [theme.breakpoints.down('xs')]: {
        padding: `${theme.spacing(0)}px ${theme.spacing(1)}px `,
        minWidth: 'auto',
      },
    },
  })
);

export default function ChatButtonIcon({
  onClick,
  isBadged = false,
}: {
  onClick: () => void;
  isBadged?: boolean;
}) {
  const classes = useStyles();

  return (
    <div aria-label="text chat">
      <Button
        color="primary"
        className={classes.chat}
        onClick={onClick}
        endIcon={
          isBadged ? (
            <Badge color="secondary" variant="dot">
              <ChatIcon />
            </Badge>
          ) : (
            <ChatIcon />
          )
        }
      >
        {!isMobile ? tr('Chat ##chat label') : ''}
      </Button>
    </div>
  );
}
