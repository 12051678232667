import { PreflightTestReport, runPreflight } from 'twilio-video';
import React from 'react';
import { useVideoRoomState } from '../useVideoRoomState/VideoRoomStateProvider';

export enum PreflightStepEnum {
  mediaAcquired = 'mediaAcquired',
  connected = 'connected',
  mediaSubscribed = 'mediaSubscribed',
  iceConnected = 'iceConnected',
  dtlsConnected = 'dtlsConnected',
  peerConnectionConnected = 'peerConnectionConnected',
  mediaStarted = 'mediaStarted',
  completed = 'completed',
}

export const preflightStepOrder: PreflightStepEnum[] = [
  PreflightStepEnum.mediaAcquired,
  PreflightStepEnum.connected,
  PreflightStepEnum.mediaSubscribed,
  PreflightStepEnum.iceConnected,
  PreflightStepEnum.dtlsConnected,
  PreflightStepEnum.peerConnectionConnected,
  PreflightStepEnum.mediaStarted,
  PreflightStepEnum.completed,
];

export type StepType = {
  step: PreflightStepEnum;
  stepNumber: number;
};

type UseTwilioPreflightResponse = {
  step: StepType | null;
  completed: boolean;
  message: string | null;
};

export default function useTwilioPreflight(): UseTwilioPreflightResponse {
  const { tokenApptDetails } = useVideoRoomState();
  const twilioToken = tokenApptDetails?.twilioToken;
  const [step, setStep] = React.useState<{
    stepNumber: number;
    step: PreflightStepEnum;
  } | null>(null);
  const [message, setMessage] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (!twilioToken) return;
    setMessage(null);
    const preflightTest = runPreflight(twilioToken);

    preflightTest.on('progress', (progress) => {
      const typedProgress = progress as PreflightStepEnum;
      setStep({
        step: typedProgress,
        stepNumber: preflightStepOrder.indexOf(typedProgress),
      });
    });

    preflightTest.on('failed', (error: Error) => {
      setMessage(`
          Error:
            ${error.toString()}
      `);
    });

    preflightTest.on('completed', (report: PreflightTestReport) => {
      setStep({
        step: PreflightStepEnum.completed,
        stepNumber: preflightStepOrder.indexOf(PreflightStepEnum.completed),
      });
      setMessage(`
        Completed:
           Connect time: ${report.networkTiming.connect?.duration}
           Receive time: ${report.networkTiming.media?.duration}
           Jitter:       ${report.stats.jitter?.average}
           RTT:          ${report.stats.rtt?.average}
           Packet Loss:  ${report.stats.packetLoss?.average}
      `);
    });
  }, [twilioToken]);

  return {
    step,
    completed: !!step?.step.includes(PreflightStepEnum.completed),
    message,
  };
}
