import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../theme';

const useStyles = makeStyles(() =>
  createStyles({
    infoContainer: {
      position: 'absolute',
      bottom: 0,
      zIndex: 1,
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      width: '100%',
      background: 'transparent',
    },
    infoRow: {
      overflowX: 'hidden',
      whiteSpace: 'nowrap',
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'flex-end',
    },
    identityWrap: {
      width: 'fit-content',
      maxWidth: '100%',
    },
    identity: {
      background: 'rgba(0, 0, 0, 0.7)',
      color: colors.grayscale.white,
      padding: '0.1em 0.3em',
      margin: 0,
      alignItems: 'center',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      justifyContent: 'space-between',
      maxWidth: '100%',
      overflowX: 'hidden',
    },
  })
);

interface ParticipantContainerProps {
  identity: string;
  overlay: React.ReactNode;
}

const ParticipantInfoContainer: React.FunctionComponent<ParticipantContainerProps> = ({
  identity,
  overlay,
  children,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.infoContainer}>
        <div className={classes.infoRow}>
          <div className={classes.identityWrap}>
            <h4 className={classes.identity}>{identity}</h4>
          </div>
        </div>
        {overlay}
      </div>
      {children}
    </>
  );
};
export default ParticipantInfoContainer;
