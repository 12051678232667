import { AppBar, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ResponsiveDialogCloseButton } from '../ResponsiveDialogCloseButton/ResponsiveDialogCloseButton';
import { ResponsiveDialogBackButton } from '../ResponsiveDialogBackButton/ResponsiveDialogBackButton';
import { getTextDirectionOriginIsRight } from '../../../utils';
import { colors } from '../../../theme';

/* istanbul ignore next */
const useStyles = makeStyles({
  dialogWrap: {
    '& .MuiPaper-root': {},
  },
  rightPanelBar: {
    flex: 'initial',
    backgroundColor: '#FFFFFF',
  },
  header: {
    background: '#F5F5F5',
  }
});

export function DialogBar({
  title,
  onDismiss,
  onBackClick,
}: {
  title: string;
  onDismiss: () => void;
  onBackClick?: () => void;
}) {
  const classes = useStyles();
  return (
    <AppBar className={classes.rightPanelBar} position="relative">
      <Toolbar variant="dense" className={classes.header}>
        {onBackClick && <ResponsiveDialogBackButton
            onClick={onBackClick}
            color={colors.primaryPalette.brandBlue}
        />}
        <Typography
          variant="h6"
          style={{
            color: '#000000',
            fontWeight: 600,
            ...(getTextDirectionOriginIsRight()
              ? { right: '50px', position: 'absolute' }
              : {}),
          }}
        >
          {title}
        </Typography>
        <ResponsiveDialogCloseButton
          onDismiss={onDismiss}
          color={colors.primaryPalette.brandBlue}
        />
      </Toolbar>
    </AppBar>
  );
}
