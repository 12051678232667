import useParticipants, {
  PlaceholderParticipant,
} from '../useParticipants/useParticipants';
import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import useSelectedParticipant from '../../components/VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useVideoContext from '../useVideoContext/useVideoContext';
import { LocalParticipant, RemoteParticipant } from 'twilio-video';
import React from 'react';

export interface WrappedParticipant {
  isLocal: boolean;
  showing: boolean;
  participant: LocalParticipant | RemoteParticipant | PlaceholderParticipant;
  i?: number;
}

export default function useFilteredParticipants(isMinimized: boolean) {
  const participants = useParticipants();
  const numParticipants = participants.length;
  const [everHadParticipants, setEverHadParticipants] = React.useState(
    numParticipants > 0
  );
  const {
    room: { localParticipant },
  } = useVideoContext();
  let filteredParticipants: WrappedParticipant[] = [
    { isLocal: true, showing: true, participant: localParticipant },
    ...participants.map((participant) => ({
      isLocal: false,
      showing: true,
      participant,
    })),
  ];
  const areOtherParticipants = participants.length > 0;
  const dominantSpeaker = useDominantSpeaker();
  const [selectedParticipant] = useSelectedParticipant();

  React.useEffect(() => {
    if (numParticipants > 0) {
      setEverHadParticipants(true);
    }
  }, [numParticipants, setEverHadParticipants]);

  // If minimized, show dominant participant who isn't pinned -> fallback to participant who isn't pinned
  if (isMinimized) {
    // First pick is talking participant, who is not pinned
    const dominantParticipant = participants.find(
      (part) => part === dominantSpeaker && part !== selectedParticipant
    );
    // Backup is anyone not pinned
    const nonPinnedParticipant = participants.find(
      (part) => part !== selectedParticipant
    );
    filteredParticipants = filteredParticipants.map((part, i) => {
      // If dominantParticipant present, show that; otherwise fallback to non-pinned participant, and then to first
      if (dominantParticipant) {
        part.showing = part.participant === dominantParticipant;
      } else if (nonPinnedParticipant) {
        part.showing = part.participant === nonPinnedParticipant;
      } else {
        part.showing = i === 0;
      }
      return part;
    });
  }
  let iShowing = 0;
  filteredParticipants = filteredParticipants.map((part) => {
    if (part.showing) {
      part.i = iShowing;
      iShowing += 1;
    }
    return part;
  });
  return { areOtherParticipants, filteredParticipants, everHadParticipants };
}
