import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Typography } from '@material-ui/core';
import tr from '../../../utils/tr';
import { whitneyFontAndFallbacks } from '../../../../src/theme';
import Info from '@material-ui/icons/Info';
import { useApptStateContext } from '../../../state/appt/ApptStateProvider';

const useStyles = makeStyles({
  wrap: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrap: {
    backgroundColor: '#F5F5F5',
    margin: '32px 110px auto 110px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
  },
  atheneHint: {
    fontFamily: whitneyFontAndFallbacks,
    fontSize: '16px',
    fontWeight: 450,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left',
    marginLeft: '18px',
  },
  logo: {
    height: '200px',
    width: 'auto',
  },
  title: {
    marginTop: '1em',
  },
});

export default function AppointmentDetailEmpty() {
  const classes = useStyles();
  const { athenaUser } = useApptStateContext();

  return (
    <div className={classes.wrap}>
      {!athenaUser ? (
        <>
          <img
            src="/static/ascension-logo.svg"
            className={classes.logo}
            alt="Ascension"
          />
          <Typography className={classes.title} variant="h4">
            {tr('Select an appointment to view details ##appt-empty title')}
          </Typography>
        </>
      ) : (
        <div className={classes.contentWrap}>
          <Info color="primary" />
          <Typography className={classes.atheneHint} variant="h4">
            {tr(
              'Please note that new appointments created here are NOT recorded in Athena. Edits to existing appointments are also NOT recorded in Athena. ##appt-empty sentence'
            )}
          </Typography>
        </div>
      )}
    </div>
  );
}
