import useShowInRightPanel from '../../useShowInRightPanel/useShowInRightPanel';
import { RightPanelKeyEnum } from '../../../interfaces/RightPanelContentTypes';
import { useLocation } from 'react-router-dom';
import useRoomState from '../../useRoomState/useRoomState';
import { RoomState } from '../../useRoomState/RoomState';
import { RouteEnum } from '../../../RouteEnum';

export default function useIsShowingApptError() {
  const roomState = useRoomState();
  const location = useLocation();
  const panel = useShowInRightPanel();

  return (
    roomState === RoomState.DISCONNECTED ||
    !location.pathname.includes(RouteEnum.CALL) ||
    panel.showingWhichQuery === RightPanelKeyEnum.INVITE
  );
}
