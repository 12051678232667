import { ApptFetchErrorType } from '../../hooks/appt/apptHookTypes';
import { ApptType, ClinicianType } from './apptTypes';

export enum ApptActionEnum {
  ERROR_ACTION = 'error-action',
  ADD_APPOINTMENT = 'add-appointment',
  REMOVE_APPOINTMENT = 'remove-appointment',
  SET_APPOINTMENTS = 'set-appointments',
  TIME_IS_MISMATCHED = 'time-is-mismatched',
  SET_CLINICIANS = 'set-clinicians',
  SET_SELECTED_CLINICIAN_ADID = 'set-selected-clinician-adid',
}

export type ApptActionsType =
  | {
      type: ApptActionEnum.ERROR_ACTION;
      error: ApptFetchErrorType | null;
    }
  | {
      type: ApptActionEnum.ADD_APPOINTMENT;
      appointment: ApptType;
    }
  | {
      type: ApptActionEnum.REMOVE_APPOINTMENT;
      appointmentId: string;
    }
  | {
      type: ApptActionEnum.SET_APPOINTMENTS;
      appointments: ApptType[];
      changeClinician: boolean;
    }
  | {
      type: ApptActionEnum.TIME_IS_MISMATCHED;
      timeIsMismatched: boolean;
    }
  | {
      type: ApptActionEnum.SET_CLINICIANS;
      clinicians: ClinicianType[];
    }
  | {
      type: ApptActionEnum.SET_SELECTED_CLINICIAN_ADID;
      adid: string;
    };
