import React from 'react';
import VideoTrack from '../VideoTrack/VideoTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import {
  Button,
  Container,
  ContainerProps,
  createStyles,
  Theme,
} from '@material-ui/core';
import Controls from '../Controls/Controls';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PersonBackdrop from '../PersonBackdrop/PersonBackdrop';
import { LocalVideoTrackType } from '../VideoProvider/VideoProviderTypes';
import { QUERY_TEST_AUDIO_VIDEO_STEP } from '../../constants';
import { useHistory } from 'react-router-dom';
import tr from '../../utils/tr';
import { isIOS } from 'react-device-detect';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';
import TwilioPreflightDebug from '../TwilioPreflightDebug/TwilioPreflightDebug';
import PreRoomParticipants from '../PreRoomParticipants/PreRoomParticipants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    videoWrap: {
      position: 'relative',
      height: '280px',
      width: '100%',
    },
    subText: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
      display: 'flex',
      justifyContent: 'space-between',
    },
    preview: {
      height: 'fit-content',
    },
    audiovideo: {
      textTransform: 'none',
      textDecoration: 'underline',
      padding: '0px',
      height: 'fit-content',
    },
  })
);

export function LocalVideoPreview({ className, ...rest }: ContainerProps) {
  const history = useHistory();
  const styled = useStyles();
  const { localTracks } = useVideoContext();
  const shouldDisplayPreflight = window?.location?.search?.includes(
    'enablePreflight=true'
  );

  const handleShowTestAudioVideo = () => {
    analyticEvent({
      label: 'Test Audio/Video open',
      action: ACTION_CALL_TO_ACTION,
    });
    history.replace({
      search: `?${QUERY_TEST_AUDIO_VIDEO_STEP}=0`,
    });
  };

  const videoTrack = localTracks.find(
    (track) => track.track.kind === 'video'
  ) as LocalVideoTrackType | undefined;

  return (
    <Container {...rest} className={clsx(styled.root, className)}>
      {!isIOS && (
        <div>
          <div className={styled.subText}>
            {' '}
            <b className={styled.preview}>
              {tr('Preview ##video-preview label', 'left')}
            </b>
            <Button
              onClick={handleShowTestAudioVideo}
              color="primary"
              className={styled.audiovideo}
            >
              {tr('Check Audio/Video ##video-preview button', 'left')}
            </Button>
          </div>
          <div className={styled.subText}>
            <Button
              onClick={handleShowTestAudioVideo}
              color="primary"
              className={styled.audiovideo}
            >
              {tr('Check Audio/Video ##video-preview button', 'right')}
            </Button>
            <b className={styled.preview}>
              {tr('Preview ##video-preview label', 'right')}
            </b>
          </div>
        </div>
      )}
      <div className={styled.videoWrap}>
        {videoTrack ? (
          <VideoTrack track={videoTrack} isLocal isMain position="absolute" />
        ) : null}
        <PersonBackdrop zIndex={-1} alt={tr('No video ##backdrop alt')} />
      </div>
      <Controls />
      <PreRoomParticipants />
      {shouldDisplayPreflight && <TwilioPreflightDebug />}
    </Container>
  );
}

export default LocalVideoPreview;
