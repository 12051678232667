import React from 'react';
import { ReservationType } from './reservationTypes';
import useReservations from './useReservations';

const defaultHandler = () => {
  console.error('Reservations not instantiated');
  return false;
};

export type ReservationContextType = {
  reservations: ReservationType[];
  claimReservation: (tag: string) => void;
  createReservation: (tag: string) => void;
  cancelReservationForAll: (tag: string) => void;
  setOwnReservation: React.Dispatch<React.SetStateAction<string | undefined>>;
};
const reservationContextDefault: ReservationContextType = {
  reservations: [],
  claimReservation: defaultHandler,
  createReservation: defaultHandler,
  setOwnReservation: defaultHandler,
  cancelReservationForAll: defaultHandler,
};

const ReservationContext = React.createContext<ReservationContextType>(
  reservationContextDefault
);

export const ReservationProvider: React.FC = ({ children }) => {
  const reservationProps = useReservations();

  return (
    <ReservationContext.Provider value={reservationProps}>
      {children}
    </ReservationContext.Provider>
  );
};

export default () => {
  const context = React.useContext(ReservationContext);
  if (!context) {
    throw new Error(
      'useReservationContext must be used within a ReservationProvider from ReservationContext'
    );
  }
  return context;
};
