import React from 'react';
import { expectReceiveByNSeconds } from '../../utils/settings';
import { TimeSensitiveMessage } from './messageTypes';

export default function usePastConfirmedDeadline<T>(
  messages: TimeSensitiveMessage<T>[],
  setMessages: React.Dispatch<React.SetStateAction<TimeSensitiveMessage<T>[]>>
) {
  React.useEffect(() => {
    let timeout: number | null = null;
    const run = () => {
      timeout = window.setTimeout(() => {
        // Check if any messages are now past their deadlines
        let changed = false;
        const newMessages = messages.map((message) => {
          const newPastConfirmedDeadline =
            message.sentToCount !== message.receivedByCount;
          if (
            message.sentByMe &&
            newPastConfirmedDeadline !== message.pastConfirmedDeadline
          ) {
            changed = true;
            message = {
              ...message,
              pastConfirmedDeadline: newPastConfirmedDeadline,
            };
          }
          return message;
        });

        // If any are past time, generate the new mapping object
        if (changed) {
          setMessages(newMessages);
        }
      }, expectReceiveByNSeconds * 1000);
    };
    run();

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [messages, setMessages]);
}
