import React from 'react';
import { Grid, Button } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import audioVideoSteps from './audioVideoSteps';
import useQuery from '../../hooks/useQuery/useQuery';
import { QUERY_TEST_AUDIO_VIDEO_STEP } from '../../constants';
import { colors } from '../../theme';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import tr from '../../utils/tr';

const useStyles = makeStyles({
  dotWrap: {
    textAlign: 'center',
    borderRadius: '100%',
    padding: '5px',
    margin: 0,
    minWidth: 'unset',
    width: 'unset',
    height: 'unset',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      background: 'none',
    },
  },
  dot: {
    background: colors.grayscale.gray4,
    borderRadius: '100%',
    width: '7px',
    height: '7px',
    minWidth: 'auto',
    padding: '0px',
    border: 'none',
  },
  selected: {
    background: colors.secondary.violetDark,
  },
  marginSpace: {
    marginBottom: '20px',
  },
  dotRow: {
    marginTop: '20px',
  },
  instruction: {
    fontSize: 'small',
  },
  errorMsg: {
    fontSize: 'small',
    color: 'red',
    fontWeight: 500,
  },
  deviceTestIcon: {
    '& .MuiSvgIcon-root': {
      height: '48px',
      width: 'auto',
    },
  },
  title: {
    marginTop: '5px',
    marginBottom: '0px',
  },
});

interface TestAudioVideoInterface {
  showStep: (step: number | null) => void;
}

export default function TestAudioVideo({ showStep }: TestAudioVideoInterface) {
  const query = useQuery();
  const currentStepIndex = query.get(QUERY_TEST_AUDIO_VIDEO_STEP);
  const [currentStepNoNull, setCurrentStepNoNull] = React.useState<number>(0);
  const classes = useStyles();

  // Stick with last step if trying to set to null (to prevent the interface changing to default while closing)
  React.useEffect(() => {
    if (currentStepIndex !== null) {
      setCurrentStepNoNull(parseInt(currentStepIndex));
    }
  }, [currentStepIndex]);
  const { audioInDisabled, videoInDisabled } = useVideoContext();
  const onNext = React.useCallback(() => {
    const nextIndex = audioVideoSteps[currentStepNoNull + 1]
      ? currentStepNoNull + 1
      : null;
    showStep(nextIndex);
  }, [currentStepNoNull, showStep]);

  const currentStep = audioVideoSteps[currentStepNoNull];
  const showError =
    currentStep.device === 'video' ? videoInDisabled : audioInDisabled;
  return (
    <>
      <Grid container justify="center" className={classes.deviceTestIcon}>
        {currentStep.icon}
      </Grid>
      <Grid container direction="column" alignItems="center">
        <h4 className={classes.title}>{currentStep.title}</h4>
        {showError ? (
          <span className={classes.errorMsg}>
            {tr('Please enable access to your device ##media error')}
          </span>
        ) : (
          <span className={classes.instruction}>{currentStep.instruction}</span>
        )}
      </Grid>
      <currentStep.component onNext={onNext} />
      <Grid
        container
        direction="row"
        justify="center"
        className={clsx(classes.marginSpace, classes.dotRow)}
      >
        {audioVideoSteps.map((thisStep, thisIndex) => (
          <Button
            key={thisIndex}
            className={classes.dotWrap}
            onClick={() => showStep(thisIndex)}
          >
            <div
              id={`${thisStep.device}Dot`}
              className={clsx(
                classes.dot,
                thisIndex === currentStepNoNull ? classes.selected : ''
              )}
            >
              {' '}
            </div>
          </Button>
        ))}
      </Grid>
    </>
  );
}
