import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import tr from '../../../utils/tr';

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelAppt: {
      width: '340px',
      margin: '10px',
      '& .MuiButton-root': {
        height: '40px',
        margin: '8px !important',
        '&.MuiButton-contained.MuiButton-containedSecondary': {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        },
      },
      [theme.breakpoints.down('xs')]: {
        width: '96%',
      },
      '@media (max-width: 340px)': {
        width: '340px !important',
      },
    },
    cancelApptText: {
      margin: theme.spacing(0.5, 5),
      fontSize: '12px',
    },
    cancelApptHdr: {
      margin: theme.spacing(3, 5, 0.5),
      fontSize: '18px',
      fontWeight: 'bold',
    },
  })
);

export default function CancelAppointment({
  onKeep,
  onCancel,
}: {
  onKeep: () => void;
  onCancel: () => void;
}) {
  const classes = useStyles();

  return (
    <Grid container className={classes.cancelAppt} direction="column">
      <Typography align="center" className={classes.cancelApptHdr}>
        {tr('Cancel appointment? ##cancelappointment')}
      </Typography>
      <Typography align="center" className={classes.cancelApptText}>
        {tr(
          'Are you sure you want to cancel this appointment? ##cancelappointment'
        )}
      </Typography>
      <Grid container direction="column" justify="space-between">
        <Button variant="outlined" color="primary" onClick={onKeep}>
          {tr('No, Keep ##cancelappointment')}
        </Button>
        <Button variant="contained" color="secondary" onClick={onCancel}>
          {tr('Yes, Cancel ##cancelappointment')}
        </Button>
      </Grid>
    </Grid>
  );
}
