import React from 'react';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useMicIsWorking from '../../../hooks/useMicIsWorking/useMicIsWorking';
import NextButton from '../NextButton/NextButton';
import AudioInputList from '../../MenuBar/DeviceSelector/AudioInputList/AudioInputList';
import { FormHelperText } from '@material-ui/core';
import {
  delayMicIsWorkingFailureByExtraNSec,
  delayMicIsWorkingSuccessByNSec,
} from '../../../utils/settings';
import tr from '../../../utils/tr';

export default function TestAudioIn({ onNext }: { onNext: () => void }) {
  const { audioInDisabled, devices } = useVideoContext();
  const micIsWorking = useMicIsWorking(
    delayMicIsWorkingSuccessByNSec,
    delayMicIsWorkingFailureByExtraNSec
  );
  const deviceMissing = devices.audioIn.length === 0;
  const buttonDisabled = deviceMissing || audioInDisabled || !micIsWorking;

  return (
    <>
      <br />
      {deviceMissing ? (
        <FormHelperText data-testid="audioInHelperText" error>
          {tr('No microphone detected. Please connect a device. ##media')}
        </FormHelperText>
      ) : (
        <AudioInputList />
      )}
      <NextButton
        onNext={onNext}
        disabled={buttonDisabled}
        text={tr('My Mic is Working ##media button')}
        disabledText={
          deviceMissing
            ? tr('Looking for Devices ##media button')
            : tr('Listening for sound... ##media button')
        }
      />
    </>
  );
}
