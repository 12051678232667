import React from 'react';
import Participant from '../Participant/Participant';
import { Participant as ParticipantType } from 'twilio-video';
import useFilteredParticipants from '../../hooks/useFilteredParticipants/useFilteredParticipants';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useDominantSpeaker from '../../hooks/useDominantSpeaker/useDominantSpeaker';
import useParticipantStyle from '../../hooks/useParticipantStyle/useParticipantStyle';
import clsx from 'clsx';
import WaitingBackdrop from '../WaitingBackdrop/WaitingBackdrop';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import PlaceholderParticipant from '../PlaceholderParticipant/PlaceholderParticipant';
import { isNonPlaceholder } from '../../hooks/useParticipants/participantAssertions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflow: 'hidden',
      display: 'grid',
      gridGap: '4px',
      height: '100%',
      gridTemplateColumns: 'repeat(12, calc(8.33333% - 3.75px))',
      gridTemplateRows: 'repeat(2, calc(50% - 2px))',
      [theme.breakpoints.down('xs')]: {
        gridGap: 0,
        gridTemplateColumns: 'repeat(12, 8.33333%)',
        gridTemplateRows: 'repeat(2, 50%)',
      },
    },
  })
);

export default function ParticipantGrid({
  isMinimized = false,
}: {
  isMinimized?: boolean;
}) {
  const classes = useStyles();
  const dominantSpeaker = useDominantSpeaker();
  const [, setSelectedParticipant] = useSelectedParticipant();

  const {
    areOtherParticipants,
    filteredParticipants,
    everHadParticipants,
  } = useFilteredParticipants(isMinimized);

  const whichParticipantStyle = useParticipantStyle(
    isMinimized,
    filteredParticipants
  );

  const handleParticipantClick = (which: ParticipantType | null) => {
    setSelectedParticipant(which);
  };
  return (
    <div
      className={clsx(classes.container, whichParticipantStyle)}
      onClick={
        isMinimized && areOtherParticipants
          ? () => handleParticipantClick(null)
          : () => {
              // default empty on click implementation
            }
      }
    >
      <WaitingBackdrop showing={!everHadParticipants} styles={{ zIndex: 1 }} />
      <>
        {filteredParticipants.map(({ participant, isLocal, showing, i }) => {
          if (!isNonPlaceholder(participant)) {
            return (
              <PlaceholderParticipant
                style={{
                  gridArea: showing ? `videoItem${i}` : undefined,
                  pointerEvents:
                    // Disable pointer events if is minimized, OR if there is only the one local stream
                    isMinimized || (isLocal && !areOtherParticipants)
                      ? 'none'
                      : 'inherit',
                }}
                key={participant.key}
                isMain={false}
              />
            );
          }

          return (
            <Participant
              style={{
                gridArea: showing ? `videoItem${i}` : undefined,
                pointerEvents:
                  // Disable pointer events if is minimized, OR if there is only the one local stream
                  isMinimized || (isLocal && !areOtherParticipants)
                    ? 'none'
                    : 'inherit',
              }}
              key={participant.sid}
              participant={participant}
              isDominant={participant === dominantSpeaker}
              hideOverlay={isMinimized}
              onClick={() => handleParticipantClick(participant)}
              disableVideo={!showing}
              disableAudio={isLocal}
            />
          );
        })}
      </>
    </div>
  );
}
