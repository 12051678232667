import React from 'react';
import { makeStyles } from '@material-ui/styles';
import ChatInput from './ChatInput';
import ChatList from './ChatList';
import { Container } from '@material-ui/core';
import theme from '../../theme';
import NoChatMessages from './NoChatMessages';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import tr from '../../utils/tr';
import useSocketContext from '../../hooks/useSocketContext/useSocketContext';
import { ChatRecipientBkgColorsType } from '../../hooks/useChatContext/chatTypes';
import useCurrentParticipantId from '../../hooks/usePresentParticipantIds/usePresentParticipantIds';

const paddingTop = theme.spacing(2);
const paddingBottom = theme.spacing(1);

const useStyles = makeStyles({
  chatWrap: {
    display: 'flex',
    flexDirection: 'column',
    height: '90%',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  chatInputWrap: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: paddingTop,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    marginTop: paddingTop,
    marginBottom: paddingBottom,
  },
});

export default function Chat() {
  const classes = useStyles();
  const { messages, sendMessage } = useChatContext();
  const { name } = useSocketContext();
  const { localDatatrackStatus } = useVideoContext();
  const [sendError, setSendError] = React.useState('');
  const [sending, setSending] = React.useState(false);
  const [messagesBkgColors, setMessagesBkgColors] = React.useState<
    (string | null)[]
  >([]);

  const {
    currentParticipantId,
    presentParticipants,
  } = useCurrentParticipantId();

  React.useEffect(() => {
    let recipientBkgColors = {
      blue: null,
      green: null,
      orange: null,
    };
    const currentMessagesBkgColors: (string | null)[] = [];
    messages.forEach((message) => {
      let bkgColor: string | null = null;
      const { sentById } = message;
      if (sentById !== currentParticipantId) {
        for (const color of Object.keys(recipientBkgColors)) {
          if (
            recipientBkgColors[color as keyof ChatRecipientBkgColorsType] ===
            sentById
          ) {
            bkgColor = color;
            break;
          }
        }
        if (!bkgColor) {
          for (const color of Object.keys(recipientBkgColors)) {
            if (
              !recipientBkgColors[color as keyof ChatRecipientBkgColorsType]
            ) {
              bkgColor = color;
              recipientBkgColors = {
                ...recipientBkgColors,
                [color]: sentById,
              };
              break;
            }
          }
        }
        if (!bkgColor) {
          for (const color of Object.keys(recipientBkgColors)) {
            if (
              !presentParticipants.includes(
                recipientBkgColors[color as keyof ChatRecipientBkgColorsType] ||
                  ''
              )
            ) {
              bkgColor = color;
              recipientBkgColors = {
                ...recipientBkgColors,
                [color]: sentById,
              };
              break;
            }
          }
        }
      }
      currentMessagesBkgColors.push(bkgColor);
    });
    if (
      currentMessagesBkgColors.length !== messagesBkgColors.length ||
      !currentMessagesBkgColors.every(
        (val, idx) => val === messagesBkgColors[idx]
      )
    ) {
      setMessagesBkgColors([...currentMessagesBkgColors]);
    }
  }, [currentParticipantId, messages, presentParticipants, messagesBkgColors]);

  const onSubmit = React.useCallback(
    (text: string) => {
      setSending(true);
      setSendError('');
      if (
        sendMessage({
          sentBy: name,
          message: text,
        })
      ) {
        setSending(false);
        return true;
      }
      setSendError(tr('Unable to send message, server error ##chat error'));
      setSending(false);
      return false;
    },
    [setSending, name, sendMessage]
  );

  const onChange = React.useCallback(() => {
    setSendError('');
  }, [setSendError]);

  const isOffline = localDatatrackStatus !== 'connected';
  return (
    <div className={classes.container}>
      <Container className={classes.chatWrap}>
        {messages.length > 0 ? (
          <ChatList messages={messages} messagesBkgColors={messagesBkgColors} />
        ) : (
          <NoChatMessages />
        )}
      </Container>
      <Container className={classes.chatInputWrap}>
        <ChatInput
          disabled={sending || isOffline}
          onSend={onSubmit}
          error={
            isOffline
              ? tr(
                  'Unable to connect. Check your internet connection. ##chat warning'
                )
              : sendError
          }
          onChange={onChange}
        />
      </Container>
    </div>
  );
}
