import React from 'react';
import { Typography, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { borderRadius, colors } from '../../theme';
import tr from '../../utils/tr';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    div: {
      backgroundColor: colors.grayscale.gray11,
      padding: theme.custom.boxPaperPadding,
      borderRadius: borderRadius[1],
      marginBottom: '1.6em',
    },
    error: {
      fontWeight: 500,
      fontSize: '14px',
    },
  })
);

export default function RoomFullError() {
  const styled = useStyles();
  return (
    <div className={styled.div}>
      <Typography classes={{ root: styled.error }}>
        {tr(
          'Room is full. Any additional participants will not be able to join until others leave the call. ##error message'
        )}
      </Typography>
    </div>
  );
}
