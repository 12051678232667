import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  wrap: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: ({ hasLeft }: { hasLeft: boolean }) =>
      hasLeft ? 'max-content auto' : 'auto',
  },
  left: {
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '30px',
  },
  right: {
    display: 'grid',
    gridTemplateColumns: '1fr',
  },
});

export default function DeviceRow({
  left,
  children,
  bottom,
}: {
  left?: React.ReactNode;
  children: React.ReactNode;
  bottom?: React.ReactNode;
}) {
  const classes = useStyles({ hasLeft: !!left });

  return (
    <div className={classes.wrap}>
      {left && <div className={classes.left}>{left}</div>}
      <div className={classes.right}>
        {children}
        {bottom ? bottom : null}
      </div>
    </div>
  );
}
