import getTokenApptDetails from '../fetch/getTokenApptDetails';
import { VideoErrorType } from '../../../components/ErrorDialog/VideoErrorType';
import React from 'react';
import getTokenErrorFromFetchError, {
  getTokenErrorByStatus,
} from './getTokenErrorFromFetchError';
import { reportError, reportInfo } from '@ascension/report-event';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../../AnalyticsConfiguration/analyticsConstant';
import {
  ApptFetchErrorAboutEnum,
  ApptFetchErrorTypeEnum,
} from '../apptHookTypes';
import {
  ApptActionEnum,
  ApptActionsType,
} from '../../../state/appt/ApptActionObjectType';
import tr from '../../../utils/tr';
import {
  TokenApptDetailsType,
  CallEndpointNameEnum,
} from '../../../state/appt/apptTypes';
import dayjs from 'dayjs';
import { MIN_OPEN_BEFORE_START } from '../../../constants';

export interface UseGetTokenApptDetailsInterface {
  participantToken: string;
  identity?: string;
  endPointName?: CallEndpointNameEnum;
}

export default function useGetTokenApptDetails({
  setTokenError,
  dispatch,
}: {
  setTokenError: React.Dispatch<
    React.SetStateAction<VideoErrorType | null | undefined>
  >;
  dispatch: React.Dispatch<ApptActionsType>;
}) {
  return React.useCallback(
    async ({
      participantToken,
      identity = '',
      endPointName = CallEndpointNameEnum.JOIN,
    }: UseGetTokenApptDetailsInterface): Promise<TokenApptDetailsType | void> => {
      const { error, content } = await getTokenApptDetails({
        participantToken,
        identity,
        endPointName,
      });
      if (error) {
        reportError(`Error obtaining twilio token`, error.message);
        analyticEvent({
          label: 'Twilio token error',
          action: ACTION_CALL_TO_ACTION,
        });
        // If server/invalid error, trigger whole-page token-error
        // Otherwise, show normal fetch-error
        if (
          error?.type &&
          [ApptFetchErrorTypeEnum.INVALID_REQUEST].includes(error.type)
        ) {
          const res = getTokenErrorFromFetchError(error);
          setTokenError(res);
        } else {
          setTokenError(null);
          dispatch({ type: ApptActionEnum.ERROR_ACTION, error });
        }
        return;
      }
      // Check if the appointment expired
      if (endPointName === CallEndpointNameEnum.INFO) {
        const currentTime = dayjs.utc();
        const expirationTime = dayjs.utc(content?.expiration);
        const isExpired = expirationTime.diff(currentTime) < 0;
        if (isExpired) {
          const res = getTokenErrorByStatus(410);
          setTokenError(res);
          return;
        }

        // Check if the appointment not open
        const openingTime = dayjs
          .utc(content?.startTime)
          .subtract(MIN_OPEN_BEFORE_START, 'minutes');
        const beforeOpening =
          openingTime.diff(currentTime) > MIN_OPEN_BEFORE_START;
        if (beforeOpening) {
          const res = getTokenErrorByStatus(422);
          setTokenError(res);
          return;
        }
      }
      setTokenError(null);
      // If no error but token is missing, show an error for schedule/join
      // and no need to check for token for schedule/info
      if (endPointName !== CallEndpointNameEnum.INFO && !content?.twilioToken) {
        reportError(`Twilio token missing`);
        analyticEvent({
          label: 'Twilio token error',
          action: ACTION_CALL_TO_ACTION,
        });
        dispatch({
          type: ApptActionEnum.ERROR_ACTION,
          error: {
            about: ApptFetchErrorAboutEnum.GET_TOKEN_APPOINTMENT_DETAILS,
            message: tr(
              'Unable to retrieve appointment information. ##token error'
            ),
          },
        });
        return;
      }
      reportInfo(`Twilio token successfully retrieved`);
      return content;
    },
    [setTokenError, dispatch]
  );
}
