import { fetchWithTimeout, getDefaultHeaders } from '../../../utils';
import {
  ApptFetchErrorTypeEnum,
  ApptFetchErrorAboutEnum,
  ApptFetchResponseType,
} from '../apptHookTypes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { TIME_FORMAT_FOR_API_POST } from '../../../constants';
import {
  ApptType,
  IncomingApptType,
  SelectedClinicianADIDType,
} from '../../../state/appt/apptTypes';
import { convertIncomingApptType } from './fetchUtils';

dayjs.extend(utc);

export default function getNewApptId({
  baseAccessToken,
  selectedClinicianADID,
}: {
  baseAccessToken: string;
  selectedClinicianADID: SelectedClinicianADIDType;
}): Promise<ApptFetchResponseType<ApptType>> {
  return new Promise((resolve) => {
    const headers = getDefaultHeaders();
    headers.set('Authorization', `Bearer ${baseAccessToken}`);
    const { SCHEDULING_SERVICE } = window.config;
    const domain = window.location.origin;

    headers.set('Content-Type', 'application/json');
    fetchWithTimeout(`${SCHEDULING_SERVICE}/appointment/`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        startTime: dayjs.utc().format(TIME_FORMAT_FOR_API_POST),
        domain,
        targetAdid: selectedClinicianADID,
      }),
    })
      .then(
        (res): Promise<IncomingApptType> => {
          if (!res.ok) {
            let responseType = ApptFetchErrorTypeEnum.ACCESS_DENIED;
            if (res.status >= 500) {
              responseType = ApptFetchErrorTypeEnum.SERVER_ERROR;
            } else if (res.status >= 400) {
              responseType = ApptFetchErrorTypeEnum.INVALID_REQUEST;
            }
            resolve({
              error: {
                status: res.status,
                message: 'No valid response when creating appointment',
                type: responseType,
                about: ApptFetchErrorAboutEnum.ADD_APPOINTMENT,
              },
            });
          }
          return res.json();
        }
      )
      .then((appt) => {
        const convertedAppt = convertIncomingApptType([appt])[0];
        resolve({
          content: convertedAppt,
        });
      })
      .catch((err) => {
        resolve({
          error: {
            message: 'Error in requesting appointment id: ' + err,
            about: ApptFetchErrorAboutEnum.ADD_APPOINTMENT,
          },
        });
      });
  });
}
