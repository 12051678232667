import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppointmentToolbarGeneric from './AppointmentToolbarGeneric';
import { createStyles, Theme, Typography } from '@material-ui/core';
import useAuthBaseContext from '../../../hooks/useAuthBaseContext/useAuthBaseContext';
import AppointmentClinicianSelector from './AppointmentClinicianSelector';
import { useApptStateContext } from '../../../state/appt/ApptStateProvider';

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftGrid: {
      display: 'grid',
      width: 'calc(33.3% - 64px)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    title: {
      marginBlockStart: 0,
    },
    displayName: {},
  })
);

export default function AppointmentToolbar2() {
  const classes = useStyles();
  const { givenName, familyName } = useAuthBaseContext();
  const {
    clinicians,
    selectedClinicianADID,
    setSelectedClinicianADID,
    setChangeClinician,
  } = useApptStateContext();

  const names = [givenName, familyName].filter((name) => name);

  return (
    <AppointmentToolbarGeneric
      left={
        <div className={classes.leftGrid}>
          <Typography className={classes.title} color="primary" variant="h3">
            Video Chat
          </Typography>
          {!clinicians || clinicians.length === 0 ? (
            <Typography
              className={classes.displayName}
              color="textPrimary"
              variant="h4"
            >
              {names.join(' ')}
            </Typography>
          ) : (
            <AppointmentClinicianSelector
              cliniciansList={clinicians}
              selectedClinicianADID={selectedClinicianADID}
              setSelectedClinicianADID={setSelectedClinicianADID}
              setChangeClinician={setChangeClinician}
            />
          )}
        </div>
      }
    />
  );
}
