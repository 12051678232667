import React from 'react';
import { Container } from '@material-ui/core';
import ErrorWrapper from '../ErrorWrapper/ErrorWrapper';
import NotificationPage from '../NotificationPage/NotificationPage';

interface ErrorPageI {
  title: string;
  subtitle?: React.ReactNode | string;
  icon?: string;
}

export default function ErrorPage({ title, subtitle, icon }: ErrorPageI) {
  return (
    <NotificationPage>
      <Container id="errorWrap">
        <ErrorWrapper title={title} subtitle={subtitle} icon={icon} />
      </Container>
    </NotificationPage>
  );
}
