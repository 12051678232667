import React from 'react';
import { LocalDataTrack } from 'twilio-video';
import { retryChatMessagesTimes } from '../../../utils/settings';

// For use only by useRoom()
export default function useDatatrackRef() {
  return React.useRef(
    new LocalDataTrack({
      ordered: true,
      maxRetransmits: retryChatMessagesTimes,
      logLevel: 'error',
    })
  );
}
