import React from 'react';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import useShowInRightPanel from '../../hooks/useShowInRightPanel/useShowInRightPanel';
import { RightPanelKeyEnum } from '../../interfaces/RightPanelContentTypes';
import useToastContext from '../../hooks/useToastContext/useToastContext';
import tr from '../../utils/tr';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';

export default function ChatToast() {
  const { messages, markAllAsRead } = useChatContext();
  const { showingWhichQuery, toggleWhich } = useShowInRightPanel();
  const { add } = useToastContext();
  const [, setAlreadyAlertedIDs] = React.useState<string[]>([]);

  React.useEffect(() => {
    // If chat panel is open, mark as read
    if (showingWhichQuery === RightPanelKeyEnum.CHAT) {
      markAllAsRead();
    } else {
      setAlreadyAlertedIDs((alreadyIDs) => {
        // If chat panel not open, get list of unread messages and set alerts on them
        const unreadMessages = messages.filter(
          (message) =>
            !message.isRead &&
            !message.sentByMe &&
            !alreadyIDs.includes(message.id)
        );
        unreadMessages.forEach((message) => {
          // Wait until next tick to avoid change during render
          setTimeout(() => {
            add({
              message:
                (message.sentBy ? `${message.sentBy}: ` : '') + message.message,
              buttonTitle: tr('View ##view button'),

              buttonAction: () => {
                analyticEvent({
                  label: 'Open Chat',
                  action: ACTION_CALL_TO_ACTION,
                });
                toggleWhich(RightPanelKeyEnum.CHAT);
              },
            });
          });
        }, 0);
        // Flag already alerted
        return [
          ...alreadyIDs,
          ...unreadMessages.map((unreadMessage) => unreadMessage.id),
        ];
      });
    }
  }, [add, toggleWhich, showingWhichQuery, markAllAsRead, messages]);

  return null;
}
