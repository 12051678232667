import { Room } from 'twilio-video';
import { getShortRoomID } from '..';
import AsyncIntervalTimer from '../asyncIntervalTimer';
import { abbreviate } from './abbreviate';
import { reportInfo, reportError } from '@ascension/report-event';
interface Kvp {
  [key: string]: unknown;
}

interface KvpArray {
  [key: string]: unknown[];
}

interface SampleValue {
  timestamp: number;
  values: KvpArray;
}

/**
 * The collation of all the metrics corresponding to a room along with all the
 * participants, their perceived network quality
 */
export interface RoomData {
  roomId: string;
  userAgent: string;
  collectionStartTime: number;
  sampleValues: SampleValue[];
}

interface SamplerCallBack<T extends Kvp = Kvp> {
  (): T | Promise<T>;
}

interface SamplerType {
  sampler: SamplerCallBack;
  everyNumberOfIntervals: number;
}

interface SamplerCallBacks {
  [key: string]: SamplerType[];
}

/**
 * The code responsible for generating the Room Snapshots at different instances of time.
 * Can be set to polling for data per configurable interval, and invoked imperatively to
 * create snapshots at precise points in time.
 */
export default class Telemetry {
  private static _instance: Telemetry;
  private _roomData: RoomData | null = null;
  private _samplers: SamplerCallBacks = {};
  private readonly _intervalTimer: AsyncIntervalTimer;
  private _existingRoomData: string | null = null;

  private constructor(
    private _pollInterval: number,
    private _cacheSize: number,
    private _enableSendReport: boolean,
    room: Room | null
  ) {
    this.room = room;
    this._intervalTimer = new AsyncIntervalTimer(this._acquireCurrent, {
      waitToStart: true,
    });
  }

  private _pushDataToServer(reportToPush?: RoomData | null) {
    const resetRoomData = !reportToPush && !!this._roomData;
    if (!reportToPush) {
      reportToPush = this._roomData;
    }
    if (this._enableSendReport) {
      reportInfo(
        JSON.stringify(
          abbreviate((reportToPush as unknown) as Record<string, unknown>)
        )
      );
    }
    // Should reset roomData
    if (resetRoomData) {
      // Remove from this._existingRoomData so doesn't try to push on reload with same or empty data
      this.setExistingRoomData(null);
      // Create from copy to avoid resetting other possible copies
      this._roomData = {
        ...(this._roomData as RoomData),
        sampleValues: [],
        collectionStartTime: Date.now(),
      };
    }
  }

  public start() {
    const existingRoomData = this._existingRoomData;
    if (existingRoomData != null) {
      if (this._enableSendReport) {
        // wrap in JSON.parse to remove extra quotes when restored as string from this._existingRoomData
        let data;
        try {
          data = JSON.parse(existingRoomData);
        } catch (error) {
          reportError(
            `Unable to push room data to server ${
              error instanceof Error ? error.message : ''
            }`
          );
        }
        if (data) this._pushDataToServer(data);
      }
      this.setExistingRoomData(null);
    }

    this._intervalTimer.start({ intervalTimeMs: this._pollInterval });
  }

  public stop() {
    this._intervalTimer.stop();
    this.room = null; // Setting room triggers push to server
  }

  public set room(r: Room | null) {
    // Send old room data first
    if (this._roomData) {
      this._pushDataToServer();
    }

    if (!r) {
      this._roomData = null;
      return;
    }

    const roomId = getShortRoomID(r.sid);
    this._roomData = {
      roomId,
      userAgent: navigator.userAgent,
      collectionStartTime: Date.now(),
      sampleValues: [],
    };
  }

  /**
   * Each call to acquireCurrent creates a snapshot in time (against a time stamp)
   * with all the data points from different parts of the app.
   * If there have been more than cacheSize sampleValues, they are dispatched back
   * into the error reporting end point.
   */
  public _acquireCurrent = async (intervalI: number) => {
    // Save a copy to be used in the async function below in case it's removed before async completes;
    const currentRoomData = { ...this._roomData } as RoomData;

    if (this._roomData === null) {
      return;
    }

    const timestamp = Date.now();

    const samplerPromisesByName = Object.keys(this._samplers).map(
      (samplerName) => {
        let individualSamplerPromises = this._samplers[samplerName].map(
          ({ sampler, everyNumberOfIntervals }): Promise<Kvp> | null => {
            if (intervalI % everyNumberOfIntervals !== 0) return null;
            const sampleResult: Promise<Kvp> | Kvp = sampler();
            if (typeof sampleResult.then === 'function') {
              return sampleResult as Promise<Kvp>;
            }
            return Promise.resolve(sampleResult);
          }
        );

        // Filter out nulls from above
        individualSamplerPromises = individualSamplerPromises.filter(
          (thisPromise) => thisPromise !== null
        );

        return Promise.all(individualSamplerPromises).then(
          (samplerResults) => ({
            [samplerName]: samplerResults,
          })
        );
      }
    );

    const samplerArrays = await Promise.all(samplerPromisesByName);
    const combinedSample = samplerArrays.reduce(
      (sampleValues, samplerResultsPerName) => {
        return {
          ...sampleValues,
          values: {
            ...sampleValues.values,
            [Object.keys(samplerResultsPerName)[0]]: Object.values(
              samplerResultsPerName
            )[0],
          },
        };
      },
      {
        timestamp,
        values: {},
      }
    );

    // Something's out of sync - fallback to copy made at top of method and send direct to report()
    if (
      !this._roomData ||
      this._roomData.collectionStartTime !== currentRoomData.collectionStartTime
    ) {
      this._pushDataToServer({
        ...currentRoomData,
        sampleValues: [...currentRoomData.sampleValues, combinedSample],
      });
      return;
    }

    this._roomData.sampleValues.push(combinedSample);

    if (this._roomData.sampleValues.length >= this._cacheSize) {
      this._pushDataToServer();
    } else {
      // Only set if not pushing to server
      this.setExistingRoomData(JSON.stringify(this._roomData));
    }
  };

  public acquireCurrent() {
    this._intervalTimer.runImmediately();
  }

  // SamplerCallBack creates a JSON of datapoints to be sent to backend.
  public register<ValuesType extends Kvp>(
    samplerName: string,
    sampler: SamplerCallBack<ValuesType>,
    everyNumberOfIntervals = 1
  ) {
    if (!(samplerName in this._samplers)) {
      this._samplers[samplerName] = [];
    }
    this._samplers[samplerName].push({ sampler, everyNumberOfIntervals });
  }

  /*****
   * static initialization methods
   */

  public static get instance(): Telemetry {
    return this._instance;
  }

  public static initializeInstance(
    pollInterval: number,
    cacheSize: number,
    enableSendReport: boolean,
    room: Room | null
  ): Telemetry {
    this._instance = new Telemetry(
      pollInterval,
      cacheSize,
      enableSendReport,
      room
    );
    return this._instance;
  }

  public setExistingRoomData(roomData: string | null) {
    this._existingRoomData = roomData;
  }

  /****
   * static alias methods
   */

  public static set room(r: Room | null) {
    this.instance.room = r;
  }

  public static start() {
    return this.instance.start();
  }
  public static stop() {
    return this.instance.stop();
  }
  public static acquireCurrent() {
    return this.instance.acquireCurrent();
  }
  public static register<ValuesType extends Kvp>(
    samplerName: string,
    samplerCallback: SamplerCallBack<ValuesType>,
    everyNumberOfIntervals = 1
  ) {
    return this.instance.register<ValuesType>(
      samplerName,
      samplerCallback,
      everyNumberOfIntervals
    );
  }
}
