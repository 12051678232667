import React from 'react';
import tr from '../../../utils/tr';
import { createStyles, Typography, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AppointmentEditCancel from '../AppointmentEditCancel/AppointmentEditCancel';
import { getMinutesTillAppt } from '../../../state/appt/apptStateUtils';
import useApptStatusForDisplay from '../../../hooks/appt/useApptStatusForDisplay/useApptStatusForDisplay';
import { colors } from '../../../theme';
import { ApptType, ApptTypeEnum } from '../../../state/appt/apptTypes';
import { AppointmentDetailStatusEnum } from '../../../hooks/appt/apptHookTypes';
import useApptCancel from '../../../hooks/appt/useApptCancel/useApptCancel';

export const appointmentHeaderTextMap: {
  [key in AppointmentDetailStatusEnum]: string;
} = {
  [AppointmentDetailStatusEnum.NOW]: tr('Now ##appt header'),
  [AppointmentDetailStatusEnum.SCHEDULED]: tr('Scheduled ##appt header'),
  [AppointmentDetailStatusEnum.CANCELLED]: tr('Cancelled ##appt header'),
  [AppointmentDetailStatusEnum.COMPLETED]: tr('Completed ##appt header'),
  [AppointmentDetailStatusEnum.WAITING]: tr('Waiting ##appt header'),
  [AppointmentDetailStatusEnum.IN_CALL]: tr('In Call ##appt header'),
  [AppointmentDetailStatusEnum.INVITE_ERROR]: tr('Invite Error ##appt header'),
};

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(1.5),
    },
    appointmentTitle: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '32px',
      padding: theme.spacing(1.5),
    },
    appointmentWait: {
      paddingLeft: '10px',
    },
    editAndCancel: {
      display: 'flex',
      flexDirection: 'row',
    },
    link: {
      display: 'flex',
      fontSize: '0.9em',
      flexDirection: 'row',
      alignItems: 'center',
    },
    cancelledStyle: {
      color: colors.grayscale.disabled2,
    },
  })
);

export default function AppointmentPageHeader({
  appointment,
}: {
  appointment: ApptType;
}) {
  const classes = useStyles();
  const apptDisplayStatus = useApptStatusForDisplay(appointment);
  const waitDuration = getMinutesTillAppt(appointment.startTime);

  const handleCancel = useApptCancel({ appointment });

  return (
    <div className={classes.container}>
      <Typography variant="h2" className={classes.appointmentTitle}>
        {appointmentHeaderTextMap[apptDisplayStatus]}
        {apptDisplayStatus === AppointmentDetailStatusEnum.WAITING && (
          <span className={classes.appointmentWait}>
            {' ' + waitDuration}
            {tr('m ##appt-header suffix-minute')}
          </span>
        )}
      </Typography>
      <AppointmentEditCancel
        appointmentId={appointment.id}
        appointmentCancelHandler={handleCancel}
        disabled={apptDisplayStatus === AppointmentDetailStatusEnum.CANCELLED}
        isAthenaAppt={appointment.type === ApptTypeEnum.ATHENA}
      />
    </div>
  );
}
