import { ReactElement } from 'react';

export interface VideoErrorType {
  message: string;
  icon?: ReactElement;
  title?: string;
  code?: number;
  onClose?: () => void;
  buttonText?: string;
  altButtonText?: string;
  onAltButton?: () => void;
  tokenStatus?: tokenStatusEnum;
}

export enum tokenStatusEnum {
  UNKNOWN = 'unknown',
  VALID = 'valid',
  INVALID = 'invalid',
  EXPIRED = 'expired',
  FUTURE = 'future',
}
