import React from 'react';
import { devicePresent } from './utils';
import { getAudioTrack, getVideoTrack } from './getTrack';
import { LocalAudioTrack, LocalVideoTrack } from 'twilio-video';
import { DeviceType, OnErrorType, TrackType } from './UseMediaTypes';
import { isSafari } from 'react-device-detect';

interface UseDeviceIdInterface {
  which: 'video' | 'audio';
  deviceIdDesired?: string | null;
  devices: DeviceType[];
  refreshDevices: () => void;
  onError: OnErrorType;
  retryKey: object;
  disableDesired?: boolean;
}

type UseDeviceIdReturnType = {
  track: TrackType;
  setTrack: React.Dispatch<React.SetStateAction<TrackType>>;
  trackDeviceId: string | undefined;
  deviceId: string | null;
};

// Only for use in useAudioTrack() or useVideoTrack()
export default function useDevice({
  which,
  deviceIdDesired,
  devices,
  refreshDevices,
  onError,
  retryKey,
  disableDesired,
}: UseDeviceIdInterface): UseDeviceIdReturnType {
  const [track, _setTrack] = React.useState<TrackType>({ track: null });
  const trackRefLock = React.useRef(false);
  const trackDeviceId = track.track?.mediaStreamTrack.getSettings().deviceId;
  const trackRef = React.useRef<LocalVideoTrack | LocalAudioTrack | null>(null);

  const setTrack = React.useCallback(
    (track: LocalVideoTrack | LocalAudioTrack | null) => {
      _setTrack(() => {
        trackRef.current = track;
        return { track };
      });
    },
    []
  );

  const changeLock = React.useCallback(
    (value: boolean) => {
      trackRefLock.current = value;

      // If locking on Safari, also null out the track (to avoid Safari's race condition)
      if (isSafari && value) {
        setTrack(null);
      }
    },
    [setTrack]
  );

  // If a stream fails, then have it retry
  React.useEffect(() => {
    const interval = setInterval(() => {
      const currentTrack = track.track;
      if (currentTrack?.isStopped && !trackRefLock.current) {
        changeLock(true);
        currentTrack.restart().finally(() => {
          setTrack(currentTrack);
          changeLock(false);
        });
      }
    }, 1000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [track, setTrack, changeLock]);

  React.useEffect(() => {
    function acquireNew(deviceIdPicked?: string | null) {
      // Request per the appropriate function (video vs audio)
      return (which === 'video' ? getVideoTrack : getAudioTrack)({
        deviceId: deviceIdPicked || undefined,
      })
        .then((newTrack: LocalVideoTrack | LocalAudioTrack) => {
          setTrack(newTrack);
        })
        .catch((err: Error) => {
          onError(`Error acquiring ${which} track: ${err.toString()}`);
        })
        .finally(() => {
          refreshDevices();
          changeLock(false); // To guarantee lock can't get stuck
        });
    }

    // If already requesting, do nothing
    if (trackRefLock.current) {
      return;
    }

    changeLock(true);

    const deviceIdPick = deviceIdDesired && devicePresent(deviceIdDesired, devices)
      ? deviceIdDesired
      : isSafari
      ? devices[0]?.id
      : undefined;
    if (trackRef.current) {
      const restartingTrack = trackRef.current;
      const constraints = deviceIdPick
        ? ({ deviceId: { exact: deviceIdPick } } as MediaTrackConstraints)
        : {};
      restartingTrack
        .restart(constraints)
        .then(() => {
          setTrack(restartingTrack || null);
          refreshDevices();
        })
        .catch((err) => {
          // Console error here because this will be a common (and so probably not reportable) occurance,
          // but it may be useful in debugging to have that info in the console
          console.error('Error on restarting ' + which + ' track: ', err);
          changeLock(true);
          return acquireNew(deviceIdPick);
        })
        .finally(() => {
          changeLock(false); // To guarantee lock can't get stuck
        });
      return;
    }
    acquireNew(deviceIdPick);
  }, [
    changeLock,
    refreshDevices,
    onError,
    which,
    setTrack,
    deviceIdDesired,
    devices,
    // retry key is an object that is mutated when an on-demand retry is desired
    retryKey,
    // disableDesired is the desire to disable video track
    // Including it on the dependents is for enabling a disabled video with blurred background
    disableDesired,
  ]);

  return {
    track,
    setTrack: _setTrack,
    trackDeviceId,
    deviceId: track?.track?.mediaStreamTrack.getSettings().deviceId || null,
  };
}
