import React from 'react';
import useSendReliably from '../useSendReliably';

type ClearReservationProps = {
  claimReservation: (tag: string) => void;
};

export default ({ claimReservation }: ClearReservationProps) => {
  const { sendMessage: doClearReservation } = useSendReliably<{
    tag: string;
  }>({
    messageType: 'reservation-clear',
    receiveMessage: (newReservation) => claimReservation(newReservation.tag),
    autoResend: true,
  });

  return React.useCallback(
    (tag: string) => {
      claimReservation(tag);
      doClearReservation({ tag });
    },
    [claimReservation, doClearReservation]
  );
};
