import React from 'react';
import { StatsReport } from 'twilio-video';
import useStats from '../useStats/useStats';
import {
  VideoTrackStats,
  AudioTrackStats,
} from '../../../interfaces/VideoTrackStats';

export default function useDisplayStats(
  shouldDisplay?: boolean
): [Map<string, VideoTrackStats[]>, Map<string, AudioTrackStats>] {
  const [videoStats, setVideoStats] = React.useState<
    Map<string, VideoTrackStats[]>
  >(new Map());
  const [audioStats, setAudioStats] = React.useState<
    Map<string, AudioTrackStats>
  >(new Map());
  const { acquireLatest } = useStats();

  React.useEffect(() => {
    if (!shouldDisplay) {
      return;
    }
    const setDisplayStats = (updatedStats: StatsReport) => {
      const newVideoStats = new Map<string, VideoTrackStats[]>();
      const newAudioStats = new Map<string, AudioTrackStats>();
      updatedStats.localVideoTrackStats.forEach((track) => {
        const trackArray = newVideoStats.get(track.trackId) || [];
        trackArray.push(track);
        newVideoStats.set(track.trackId, trackArray);
      });
      updatedStats.remoteVideoTrackStats.forEach((track) => {
        const trackArray = newVideoStats.get(track.trackId) || [];
        trackArray.push(track);
        newVideoStats.set(track.trackId, trackArray);
      });
      updatedStats.localAudioTrackStats.forEach((track) => {
        newAudioStats.set(track.trackId, track);
      });
      updatedStats.remoteAudioTrackStats.forEach((track) => {
        newAudioStats.set(track.trackId, track);
      });
      setVideoStats(newVideoStats);
      setAudioStats(newAudioStats);
    };

    const intervalId = setInterval(() => {
      acquireLatest().then(setDisplayStats);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [acquireLatest, shouldDisplay]);

  return [videoStats, audioStats];
}
