/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect } from 'react';
import { IVideoTrack } from '../../types';
import { styled } from '@material-ui/core/styles';
import { Track } from 'twilio-video';
import { AscensionSpinner } from '../AscensionSpinner/AscensionSpinner';
import { makeStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/core';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      textAlign: 'center',
      objectFit: 'cover',
      height: '100%',
      width: '100%',
    },
    icon: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  })
);

const Video = styled('video')({
  objectFit: 'cover',
  height: '100%',
  width: '100%',
  backgroundColor: 'white',
});

type SizingString = 'contain' | 'cover';

interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
  isMain?: boolean;
  maxHeight?: string;
  sizing?: SizingString;
  isScreenShare?: boolean;
  position?: 'relative' | 'absolute';
}

export default function VideoTrack({
  track,
  isLocal,
  priority,
  sizing = 'cover',
  isScreenShare = false,
  position = 'relative',
}: VideoTrackProps) {
  const { isLoadingBlurBG } = useVideoContext();
  const styles = useStyles();
  const ref = useRef<HTMLVideoElement>(null!);

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    if (track.track.setPriority && priority) {
      track.track.setPriority(priority);
    }
    track.track.attach(el);
    return () => {
      track.track.detach(el);
      if (track.track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.track.setPriority(null);
      }
    };
  }, [track, priority]);

  // The local video track is mirrored.
  const isFrontFacing =
    track.track.mediaStreamTrack.getSettings().facingMode !== 'environment';
  const defaultStyle: { objectFit: SizingString } = {
    objectFit: sizing === 'cover' ? 'cover' : 'contain',
  };
  const style: {
    transform?: string;
    maxHeight?: string;
    display?: 'none' | 'block';
    objectFit: SizingString;
  } =
    // Only local, facing-camera streams
    isLocal && !isScreenShare && isFrontFacing
      ? {
          transform: 'rotateY(180deg)',
          ...defaultStyle,
        }
      : defaultStyle;
  // Hide if disabled (to allow user-icon to show-through)
  if (!track.track.isEnabled) style.display = 'none';

  return (
    <div className={styles.container} style={{ position }}>
      <Video
        ref={ref}
        style={style}
        data-trackid={'id' in track.track ? track.track.id : ''}
      />
      {isLoadingBlurBG && isLocal && (
        <AscensionSpinner className={styles.icon} />
      )}
    </div>
  );
}
