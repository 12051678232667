// Polyfills for IE
import './polyfills';
import 'raf/polyfill';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/string';
import 'core-js/web/url-search-params';
import 'core-js/features/promise';
import 'whatwg-fetch';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { pageChangeListener } from './AnalyticsConfiguration/AnalyticsConfiguration';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { Router } from 'react-router-dom';
import theme from './theme';
import './types';
import { ConfigurationOptions } from './state/configInterface';
import { createBrowserHistory } from 'history';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
// This injects dtrum types onto window.dtrum
import './interfaces/DynatraceTypes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  TranslationLanguageType,
  TranslationType,
} from './interfaces/TranslationType';
import EventEmitter from 'events';
import { redirectIfWrongHost } from './utils';
import setAnalyticListeners from './utils/setAnalyticListeners';
import setReportListeners from './utils/setReportListeners';
import Routes from './Routes';
import useSetupAnalytics from './hooks/useSetupAnalytics/useSetupAnalytics';

setAnalyticListeners();
setReportListeners();
dayjs.extend(utc);

interface EventEmitterWithStatusInterface extends EventEmitter {
  isPresent?: boolean;
}

declare global {
  interface Window {
    // Add Configuration Options to the global window.config - this is filled by script tab in index.html
    config: ConfigurationOptions;
    tr?: TranslationType;
    trLanguage?: TranslationLanguageType;
    // Add window.webkitAudioContext as same type as window.AudioContext (for iOS Safari)
    webkitAudioContext: {
      new (contextOptions?: AudioContextOptions | undefined): AudioContext;
      prototype: AudioContext;
    };
    warningBanner?: EventEmitterWithStatusInterface;
  }
}

redirectIfWrongHost();

const history = createBrowserHistory();
history.listen(pageChangeListener);

export const PrimaryApp = () => {
  useSetupAnalytics();

  return (
    <Router history={history}>
      <Routes />
    </Router>
  );
};

ReactDOM.render(
  <ErrorBoundary>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <PrimaryApp />
    </MuiThemeProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);
