import { Button } from '@material-ui/core';
import tr from '../../../utils/tr';
import React from 'react';
import SpinnerButton from '../../SpinnerButton/SpinnerButton';
import useIsMountedRef from '../../../hooks/appt/useIsMountedRef/useIsMountedRef';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../../AnalyticsConfiguration/analyticsConstant';

export default function SaveAppointmentButton({
  onClick,
  disabled,
}: {
  onClick: () => Promise<void> | undefined;
  disabled: boolean;
}) {
  const [saving, setSaving] = React.useState(false);
  const isMountedRef = useIsMountedRef();

  const handleClick = React.useCallback(async () => {
    analyticEvent({
      label: 'Update Appointment',
      action: ACTION_CALL_TO_ACTION,
    });
    setSaving(true);
    await onClick();
    if (isMountedRef.current) setSaving(false);
  }, [isMountedRef, onClick]);

  return (
    <SpinnerButton isWaiting={saving}>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={handleClick}
        disabled={disabled || saving}
        data-testid="updateAppointment"
      >
        {saving ? '' : tr('Update Appointment ##appt button')}
      </Button>
    </SpinnerButton>
  );
}
