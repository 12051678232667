import React from 'react';
import { Popover } from '@material-ui/core';
import DayJsUtils from '@date-io/dayjs';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import dayjs, { Dayjs } from 'dayjs';

export default function AppointmentDateSelectPicker({
  pickerAnchorEl,
  setPickerAnchorEl,
  appointmentsListDate,
  setAppointmentsListDate,
}: {
  pickerAnchorEl: HTMLElement | null;
  setPickerAnchorEl: Function;
  appointmentsListDate: Dayjs;
  setAppointmentsListDate: Function;
}) {
  return (
    <Popover
      open={Boolean(pickerAnchorEl)}
      anchorEl={pickerAnchorEl}
      onClose={() => {
        setPickerAnchorEl(null);
      }}
    >
      <MuiPickersUtilsProvider utils={DayJsUtils}>
        <DatePicker
          autoOk
          variant="static"
          openTo="date"
          value={appointmentsListDate.toDate()}
          onChange={(date) => {
            setAppointmentsListDate(dayjs.utc(date));
            setPickerAnchorEl(null);
          }}
        />
      </MuiPickersUtilsProvider>
    </Popover>
  );
}
