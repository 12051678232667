import React from 'react';
import { Participant, Track } from 'twilio-video';
import Publication from '../Publication/Publication';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useFilteredPublications from '../../hooks/useFilteredPublications/useFilteredPublications';

interface ParticipantTracksProps {
  participant: Participant;
  disableAudio?: boolean;
  disableVideo?: boolean;
  enableScreenShare?: boolean;
  videoPriority?: Track.Priority | null;
  isMain?: boolean;
}

/*
 *  The object model for the Room object (found here: https://www.twilio.com/docs/video/migrating-1x-2x#object-model) shows
 *  that Participant objects have TrackPublications, and TrackPublication objects have Tracks.
 *
 *  The React components in this application follow the same pattern. This ParticipantTracks component renders Publications,
 *  and the Publication component renders Tracks.
 */

export default function ParticipantTracks({
  participant,
  disableAudio,
  disableVideo,
  enableScreenShare,
  videoPriority,
  isMain,
}: ParticipantTracksProps) {
  const { room } = useVideoContext();
  const filteredPublications = useFilteredPublications(
    participant,
    enableScreenShare
  );
  const isLocal = participant === room.localParticipant;

  return (
    <>
      {filteredPublications.map((publication) => (
        <Publication
          key={publication.kind}
          publication={publication}
          isLocal={isLocal}
          disableAudio={disableAudio}
          disableVideo={disableVideo}
          videoPriority={videoPriority}
          isMain={isMain}
        />
      ))}
    </>
  );
}
