import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { MAIN_TOOLBAR_HEIGHT_UNITS } from '../../../constants';
import { colors } from '../../../theme';

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.background.default,
      height: theme.spacing(MAIN_TOOLBAR_HEIGHT_UNITS),
      display: 'grid',
      '&.MuiPaper-root.MuiAppBar-root': {
        boxShadow: 'none',
        borderBottom: `2px ${colors.grayscale.gray11} solid`,
      },
    },
    toolbar: {
      position: 'relative',
      padding: theme.spacing(0, 6),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 3),
      },
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      color: theme.palette.text.primary,
    },
  })
);

interface AppointmentToolbarGenericInterface {
  left: React.ReactNode;
  right?: React.ReactNode;
}

export default function AppointmentToolbarGeneric({
  left,
  right,
}: AppointmentToolbarGenericInterface) {
  const classes = useStyles();

  return (
    <AppBar className={classes.container} position="static">
      <Toolbar className={classes.toolbar}>
        {left}
        {right && (
          <div className={classes.rightButtonContainer} color="primary">
            {right}
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
