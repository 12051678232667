import React, { MutableRefObject } from 'react';

export default function useIsMountedRef(): MutableRefObject<boolean> {
  const isMountedRef = React.useRef(true);

  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return isMountedRef;
}
