import React from 'react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import ResponsiveDialog from '../ResponsiveDialog/ResponsiveDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Container, Button } from '@material-ui/core';
import theme from '../../theme';
import ScreenShareErrorContent from '../ScreenShareErrorContent/ScreenShareErrorContent';
import ScreenShare from '@material-ui/icons/ScreenShare';
import tr from '../../utils/tr';
import RefreshButton from '../RefreshButton/RefreshButton';
import { isIOS, isSafari } from 'react-device-detect';

const useStyles = makeStyles(
  createStyles({
    title: {
      textAlign: 'center',
      padding: '0px',
    },
    iconWrap: {
      textAlign: 'center',
      lineHeight: '0px',
    },
    icon: {
      width: '50px',
      height: '50px',
      color: 'rgba(0,0,0,0.6)',
    },
    button: {
      width: '100%',
    },
  })
);

export default function ScreenShareError() {
  const { screenShareError, setScreenShareError } = useVideoContext();
  const classes = useStyles();
  const dismissError = () => setScreenShareError(null);

  if (!screenShareError) return null;
  return (
    <ResponsiveDialog open={true} onDismiss={dismissError}>
      <Container
        style={{
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
        }}
      >
        <div className={classes.iconWrap}>
          <ScreenShare className={classes.icon} />
        </div>
        <DialogTitle className={classes.title}>
          {tr('Allow screenshare. ##warning title')}
        </DialogTitle>
        <ScreenShareErrorContent errorType={screenShareError.type || 'other'} />
        {isSafari && !isIOS ? (
          <RefreshButton className={classes.button} />
        ) : (
          <Button
            onClick={dismissError}
            color="primary"
            variant="contained"
            autoFocus
            className={classes.button}
          >
            {tr('OKAY ##Okay button')}
          </Button>
        )}
      </Container>
    </ResponsiveDialog>
  );
}
