import React from 'react';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../../AnalyticsConfiguration/analyticsConstant';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import tr from '../../../utils/tr';
import { isMobile } from '../../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flipCamera: {
      padding: `${theme.spacing(0)}px ${theme.spacing(2)}px `,
      textTransform: 'uppercase',
      fontSize: `${theme.spacing(1.75)}px `,
      lineHeight: `${theme.spacing(2)}px `,
      [theme.breakpoints.down('xs')]: {
        padding: `${theme.spacing(0)}px ${theme.spacing(1)}px `,
        minWidth: 'auto',
      },
    },
  })
);

export default function FlipCameraButton() {
  const classes = useStyles();
  const {
    devices,
    setVideoInDeviceIdDesired,
    videoInFacing,
    videoInDeviceId,
  } = useVideoContext();

  // If the device doesn't support flipping the camera, don't show the button
  const otherFacingMode = videoInFacing === 'user' ? 'environment' : 'user';
  const otherFacingDevice = devices.videoIn.find(
    // Make sure other facing mode is a separate device, because sometimes
    // Twilio's package assigns user-facing to a device without any clear reason
    (device) =>
      device.facing === otherFacingMode && device.id !== videoInDeviceId
  );
  if (!otherFacingDevice) {
    return null;
  }

  const handleToggle = () => {
    analyticEvent({ label: 'Flip Camera', action: ACTION_CALL_TO_ACTION });
    if (otherFacingDevice) {
      setVideoInDeviceIdDesired(otherFacingDevice.id);
    }
  };

  return (
    <div aria-label="filp camera">
      <Button
        color="primary"
        className={classes.flipCamera}
        onClick={handleToggle}
        endIcon={<FlipCameraIosIcon />}
      >
        {isMobile ? '' : tr('Switch Camera ##switchcamera label')}
      </Button>
    </div>
  );
}
