import { isFirefox } from 'react-device-detect';
import {
  LocalAudioTrackType,
  LocalVideoTrackType,
} from '../VideoProviderTypes';
import useMedia from '../useMedia/useMedia';
import useVisibilityDisable from '../useVisibilityDisable/useVisibilityDisable';
import useBlur from '../useMedia/useBlur/useBlur';
import useRememberDevice from '../useMedia/useRememberDevice';

export type MediaErrorType = 'permission' | 'systemPermission' | 'other';

export default function useLocalTracks() {
  const {
    devices,
    videoTrack,
    videoInFacing,
    videoInDeviceId,
    videoInDeviceIdDesired,
    setVideoInDeviceIdDesired,
    videoInDisabled,
    setVideoInDisableDesired,
    retryVideoAcquire,
    audioTrack,
    audioInDeviceId,
    audioInDeviceIdDesired,
    setAudioInDeviceIdDesired,
    audioInDisabled,
    setAudioInDisableDesired,
    audioOutDeviceId,
    audioOutDeviceIdDesired,
    setAudioOutDeviceIdDesired,
    retryAudioAcquire,
    otherError,
    clearOtherError,
    videoError,
    clearVideoError,
    audioError,
    clearAudioError,
  } = useMedia(isFirefox);

  const { blurDesired, setBlurDesired, blurred, isLoadingBlurBG } = useBlur(
    videoTrack
  );
  useRememberDevice({
    devices: devices.audioIn,
    currentDeviceId: audioInDeviceId,
    deviceIdDesired: audioInDeviceIdDesired,
    setDeviceIdDesired: setAudioInDeviceIdDesired,
    deviceType: 'audio-in',
  });

  useRememberDevice({
    devices: devices.audioOut,
    currentDeviceId: audioOutDeviceId,
    deviceIdDesired: audioOutDeviceIdDesired,
    setDeviceIdDesired: setAudioOutDeviceIdDesired,
    deviceType: 'audio-out',
  });

  useRememberDevice({
    devices: devices.videoIn,
    currentDeviceId: videoInDeviceId,
    deviceIdDesired: videoInDeviceIdDesired,
    setDeviceIdDesired: setVideoInDeviceIdDesired,
    deviceType: 'video-in',
  });

  // Temporarily disable video when window not visible
  useVisibilityDisable(videoTrack, videoInDisabled);

  const localTracks = [audioTrack, videoTrack].filter(
    (track) => track.track
  ) as (LocalVideoTrackType | LocalAudioTrackType)[];

  return {
    devices,
    localTracks,
    videoInFacing,
    videoInDeviceId,
    setVideoInDeviceIdDesired,
    videoInDisabled,
    setVideoInDisableDesired,
    retryVideoAcquire,
    blurDesired,
    setBlurDesired,
    blurred,
    isLoadingBlurBG,
    audioInDeviceId,
    setAudioInDeviceIdDesired,
    audioInDisabled,
    setAudioInDisableDesired,
    audioOutDeviceId,
    setAudioOutDeviceIdDesired,
    retryAudioAcquire,
    otherError,
    clearOtherError,
    videoError,
    clearVideoError,
    audioError,
    clearAudioError,
  };
}
