import { Dayjs } from 'dayjs';

export type ChangeTypeOfKeys<
  T extends object,
  Keys extends keyof T,
  NewType
> = {
  [key in keyof T]: key extends Keys ? NewType : T[key];
};

export enum ApptParticipantStatusNameEnum {
  INVITE_CREATED = 'INVITE_CREATED',
  INVITE_SENT = 'INVITE_SENT',
  INVITE_FAILED = 'INVITE_FAILED',
  INVITE_DELIVERED = 'INVITE_DELIVERED',
  PARTICIPANT_CONNECTED = 'PARTICIPANT_CONNECTED',
  PARTICIPANT_JOINED = 'PARTICIPANT_JOINED',
}

export enum ApptContentEnum {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  COPIED = 'COPIED',
  SELF = 'SELF',
  INTERPRETER = 'INTERPRETER',
  NONE = '',
}

export enum ApptTypeEnum {
  ATHENA = 'ATHENA',
  VC = 'VC',
}

export type ApptParticipantStatusType = {
  statusType: ApptParticipantStatusNameEnum;
  timeStamp: Dayjs;
};
export type IncomingApptParticipantStatusType = ChangeTypeOfKeys<
  ApptParticipantStatusType,
  'timeStamp',
  string | Dayjs
>;
export type ApptParticipantType = {
  id: number;
  name?: string | null;
  content?: string | null;
  type: ApptContentEnum;
  statuses: ApptParticipantStatusType[];
  token?: string;
  cancelled?: boolean;
  appointmentType?: ApptTypeEnum;
};
export type IncomingApptParticipantType = ChangeTypeOfKeys<
  ApptParticipantType,
  'statuses',
  IncomingApptParticipantStatusType[]
>;
export type ApptRootType = {
  id?: string;
  startTime: Dayjs;
};
export type ApptType = ApptRootType & {
  endTime: Dayjs;
  participants: ApptParticipantType[];
  inviteMessage?: string;
  appointmentCancelled?: boolean;
  type?: ApptTypeEnum;
  ownedBy: string | null;
  ministry: string;
};
export type IncomingApptType = ChangeTypeOfKeys<
  ChangeTypeOfKeys<ApptType, 'startTime' | 'endTime', string | Dayjs>,
  'participants',
  IncomingApptParticipantType[]
>;

type SharedApptDetailsType = {
  appointmentId?: string | null;
  startTime: Dayjs;
  expiration: Dayjs;
};

export type TokenApptDetailsType = SharedApptDetailsType & {
  twilioToken?: string;
};

export type IncomingApptDetailsType = ChangeTypeOfKeys<
  SharedApptDetailsType,
  'startTime' | 'expiration',
  string | Dayjs
> & {
  id: string;
};

export type ClinicianType = {
  name: string;
  adid: string;
  selected: boolean;
};

/*
 * This type can have three states:
 * Empty String       Initial Value (Waiting for server response.)
 * Null               Current user is selected.
 * Non-Empty String   Clinician is selected.
 */
export type SelectedClinicianADIDType = string | null;

export enum CallEndpointNameEnum {
  INFO = 'info',
  JOIN = 'join',
}

export type InterpreterLanguageType = {
  LanguageId: number;
  LanguageName: string;
};
