import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import VideoTrack from '../../../VideoTrack/VideoTrack';
import VideoStandin from '../VideoStandin/VideoStandin';
import { LocalVideoTrackType } from '../../../VideoProvider/VideoProviderTypes';

const useStyles = makeStyles({
  preview: {
    width: '150px',
    margin: '0.5em 0',
  },
  nonList: {
    marginTop: '1em',
  },
});

export default function VideoInputThumbnail(
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) {
  const classes = useStyles();
  const { localTracks, videoInDisabled } = useVideoContext();

  const localVideoTrack = localTracks.find(
    (track) => track.track.kind === 'video'
  ) as LocalVideoTrackType;

  return (
    <>
      {localVideoTrack && !videoInDisabled ? (
        <div
          className={classes.preview}
          style={{ height: '150px', width: '150px' }}
          {...props}
        >
          <VideoTrack isLocal track={localVideoTrack} />
        </div>
      ) : (
        <VideoStandin {...props} />
      )}
    </>
  );
}
