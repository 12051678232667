import React from 'react';

export default function DotsIcon({
  size,
  className = '',
  color = 'rgb(27,66,151)',
}: {
  size: number;
  className: string;
  color?: string;
}) {
  const stylesBall = {
    display: 'inline-block',
    position: 'relative',
    top: 0,
    marginRight: '0.9em',
    width: '2em',
    height: '2em',
    backgroundColor: color,
    borderRadius: '1em',
    animation: 'oscillate 1.5s infinite',
    animationTimingFunction: 'ease-in-out',
  } as React.CSSProperties;

  return (
    <div className={className} style={{ fontSize: size }}>
      <div style={stylesBall} />
      <div style={{ ...stylesBall, animationDelay: '0.1s' }} />
      <div style={{ ...stylesBall, animationDelay: '0.20s' }} />
    </div>
  );
}
