import useParticipants from '../useParticipants/useParticipants';
import useStripId from '../../components/VideoProvider/useStripId/useStripId';
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { isNonPlaceholder } from '../useParticipants/participantAssertions';
import { RemoteParticipant } from 'twilio-video';

type ParticipantTime = {
  participantSid: string;
  name: string;
  start: Dayjs;
  stop: Dayjs | null;
};

// For use only in useCallTimer()
export default function useParticipantTimes() {
  const participants = useParticipants();
  const stripId = useStripId();
  const [participantTimes, setParticipantTimes] = React.useState<
    ParticipantTime[]
  >([]);

  React.useEffect(() => {
    setParticipantTimes((currTimes) => {
      const filteredParticipants = participants.filter((p): p is RemoteParticipant => isNonPlaceholder(p));
      filteredParticipants.forEach((participant) => {
        // If participant is not in time-list, add it and clone the list
        if (
          !currTimes.find(
            (currTime) => currTime.participantSid === participant.sid
          )
        ) {
          const niceIdentity = stripId(participant.identity);
          currTimes.push({
            participantSid: participant.sid,
            start: dayjs(),
            stop: null,
            name: niceIdentity,
          });
        }
      });
      currTimes = currTimes.map((currTime) => {
        // If no stop time, and not in participant-list, clone it (to prevent mutation) and set stop-time
        if (
          !currTime.stop &&
          !filteredParticipants.find(
            (participant) => participant.sid === currTime.participantSid
          )
        ) {
          return {
            start: currTime.start,
            stop: dayjs(),
            participantSid: currTime.participantSid,
            name: currTime.name,
          };
        }
        return currTime;
      });
      return [...currTimes];
    });
  }, [stripId, participants, setParticipantTimes]);
  return participantTimes;
}
