import React from 'react';

import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

import useFullScreenToggle from '../../../hooks/useFullScreenToggle/useFullScreenToggle';
import tr from '../../../utils/tr';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { isMobile } from '../../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullScreen: {
      padding: `${theme.spacing(0)}px ${theme.spacing(2)}px `,
      textTransform: 'uppercase',
      fontSize: `${theme.spacing(1.75)}px `,
      lineHeight: `${theme.spacing(2)}px `,
      [theme.breakpoints.down('xs')]: {
        padding: `${theme.spacing(0)}px ${theme.spacing(1)}px `,
        minWidth: 'auto',
      },
    },
  })
);

export default function ToggleFullscreenButton() {
  const classes = useStyles();
  const [isFullScreen, toggleFullScreen] = useFullScreenToggle();

  return (
    <div aria-label="full screen">
      <Button
        color="primary"
        className={classes.fullScreen}
        onClick={toggleFullScreen}
        endIcon={isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      >
        {!isMobile ? tr('Full screen ##settings label') : ''}
      </Button>
    </div>
  );
}
