import React, { ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ResponsiveDialog from '../ResponsiveDialog/ResponsiveDialog';
import { useStylesForErrorDialog } from '../ErrorDialog/ErrorDialog';

interface ErrorDialogInterface {
  onCancelClick: () => void;
  onOkayClick: () => void;
  message: string;
  icon: ReactNode;
  cancelButtonLabel: string;
  okayButtonLabel: string;
}

export default function InterpreterConfirmDialog({
  icon,
  onCancelClick,
  onOkayClick,
  message,
  cancelButtonLabel,
  okayButtonLabel,
}: ErrorDialogInterface) {
  const classes = useStylesForErrorDialog();
  const theme = useTheme();
  const screenIsSmall = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ResponsiveDialog
      open={true}
      onDismiss={onCancelClick}
      isForInterpreter={!screenIsSmall}
    >
      <Container
        style={{
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
        }}
      >
        <div id="errorWrap" className={classes.wrap}>
          {icon}
          <Typography
            variant="h3"
            style={{ fontSize: '18px', lineHeight: '24px' }}
          >
            {message}
          </Typography>
          <Grid container spacing={3} className={classes.buttonWrap}>
            <Grid item xs={12} style={{ padding: '4px 12px' }}>
              <Button
                onClick={onOkayClick}
                color="primary"
                variant="contained"
                autoFocus
              >
                {okayButtonLabel}
              </Button>
            </Grid>
            <Grid item xs={12} style={{ padding: '4px 12px' }}>
              <Button
                onClick={onCancelClick}
                color="primary"
                variant="outlined"
                autoFocus
              >
                {cancelButtonLabel}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    </ResponsiveDialog>
  );
}
