import React from 'react';
import { checkApptsEveryNSeconds } from '../../../utils/settings';
import getApptList from '../fetch/getApptList';
import useAuthBaseContext from '../../useAuthBaseContext/useAuthBaseContext';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  ApptActionEnum,
  ApptActionsType,
} from '../../../state/appt/ApptActionObjectType';
import {
  ApptFetchErrorTypeEnum,
  ApptFetchErrorAboutEnum,
} from '../apptHookTypes';
import { SelectedClinicianADIDType } from '../../../state/appt/apptTypes';
import { getTimeIsMisaligned } from '../apptHookUtils';
import useTabIsHidden from '../../useTabIsHidden/useTabIsHidden';

dayjs.extend(utc);

// To be used only in ApptStateProvider
export default function useWatchApptList({
  dispatch,
  from,
  to,
  forceGet,
  selectedClinicianADID,
  changeClinician,
}: {
  dispatch: React.Dispatch<ApptActionsType>;
  from: Dayjs;
  to: Dayjs;
  forceGet: object;
  selectedClinicianADID: SelectedClinicianADIDType;
  changeClinician: boolean;
}) {
  const { baseAccessToken } = useAuthBaseContext();

  const isTabHidden = useTabIsHidden();

  React.useEffect(() => {
    const run = async () => {
      if (baseAccessToken) {
        const res = await getApptList({
          baseAccessToken,
          from,
          to,
          selectedClinicianADID,
        });
        if (res.error) {
          dispatch({
            type: ApptActionEnum.ERROR_ACTION,
            error: res.error,
          });
          return;
        }
        if (!res.content) {
          dispatch({
            type: ApptActionEnum.ERROR_ACTION,
            error: {
              type: ApptFetchErrorTypeEnum.SERVER_ERROR,
              message: 'Appointment list request provided no response',
              about: ApptFetchErrorAboutEnum.GET_APPOINTMENT_LIST,
            },
          });
          return;
        }
        if (res.content.timestamp) {
          dispatch({
            type: ApptActionEnum.TIME_IS_MISMATCHED,
            timeIsMismatched: getTimeIsMisaligned(res.content.timestamp),
          });
        }
        dispatch({
          type: ApptActionEnum.SET_APPOINTMENTS,
          appointments: res.content.appointments,
          changeClinician: changeClinician,
        });
      }
    };

    let interval: NodeJS.Timeout;

    // If selectedClinicianADID is not default value (empty string) then we have gotten the selected clinician.
    // gotSelectedClinician will be false in non-appointment page, like call page and feedback page
    const gotSelectedClinician = selectedClinicianADID !== '';

    if (!isTabHidden && gotSelectedClinician) {
      interval = setInterval(() => {
        run();
      }, checkApptsEveryNSeconds * 1000);
      run();
    }

    return () => {
      if (!interval) return interval;
      clearInterval(interval);
    };
  }, [
    from,
    to,
    baseAccessToken,
    dispatch,
    forceGet,
    isTabHidden,
    selectedClinicianADID,
    changeClinician,
  ]);
}
