import React from 'react';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { MicOff } from '@material-ui/icons';
import { LocalAudioTrackType } from '../../VideoProvider/VideoProviderTypes';
import { colors } from '../../../theme';
import LocalAudioLevelIndicator from '../../AudioLevelIndicator/LocalAudioLevelIndicator';

const nullTrack = { track: null };

export default function TestAudioInIcon() {
  const { audioInDisabled, localTracks } = useVideoContext();
  const localAudioTrack = (localTracks.find(
    (track) => track.track.kind === 'audio'
  ) || nullTrack) as LocalAudioTrackType;

  return !localAudioTrack.track || audioInDisabled ? (
    <MicOff style={{ color: colors.grayscale.gray3 }} />
  ) : (
    <LocalAudioLevelIndicator
      background={colors.grayscale.gray3}
      foreground={colors.primaryPalette.brandBlue}
      size={48}
    />
  );
}
