import * as amplitude from '@amplitude/analytics-browser';
import { LocationListener, LocationState, Location } from 'history';

const pathPartSelector = /(\/[^/]+)/g;

export const pageChangeListener: LocationListener<LocationState> = (
  location: Location
) => {
  // Avoid recording if API key not defined
  if (!window.config.ANALYTICS_ID) {
    return;
  }

  // Replace sub route values to remove IDs. /call/RM232d3 becomes /call/REMOVED. This makes aggregation easier.
  const pathMatch = location.pathname.match(pathPartSelector);
  const cleanedPagePath = pathMatch
    ? pathMatch
        .map((pathPart, index) => (!index ? pathPart : 'REMOVED'))
        .join('/')
    : location.pathname;
  amplitude.track('Page View', {
    host: window.location.hostname,
    environment: window.config.QVS_ENVIRONMENT,
    page: cleanedPagePath,
  });
};
