import { Participant } from 'twilio-video';
import usePublications from '../usePublications/usePublications';

export default function useFilteredPublications(
  participant: Participant,
  enableScreenShare?: boolean
) {
  const publications = usePublications(participant);

  if (
    enableScreenShare &&
    publications.some((p) => p.trackName.includes('screen'))
  ) {
    return publications.filter((p) => {
      return p.kind !== 'video' || p.trackName.includes('screen');
    });
  }
  return publications.filter((p) => !p.trackName.includes('screen'));
}
