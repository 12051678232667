import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {
  FLOATING_BUTTON_POSITION,
  LOGO_HEIGHT,
  MAIN_TOOLBAR_HEIGHT_UNITS,
} from '../../constants';
import ApplicationTitle from './ApplicationTitle/ApplicationTitle';
import { Button, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import { isIE, isIOS, isSafari, osVersion } from 'react-device-detect';
import useAuthBaseContext from '../../hooks/useAuthBaseContext/useAuthBaseContext';
import RightUpperButtons from '../RightUpperButtons/RightUpperButtons';
import { useRouteMatch } from 'react-router-dom';
import tr from '../../utils/tr';
import { analyticEvent } from '@ascension/analytic-event';
import { RouteEnum } from '../../RouteEnum';
import styleTheme, { colors, whitneyFontAndFallbacks } from '../../theme';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.background.default,
      height: theme.spacing(MAIN_TOOLBAR_HEIGHT_UNITS),
      display: 'grid',
    },
    toolbar: {
      position: 'relative',
      padding: theme.spacing(0, 3),
    },
    floatingButtons: {
      position: 'absolute',
      bottom: FLOATING_BUTTON_POSITION + 'px',
    },
    rightUpperButtons: {
      position: 'absolute',
      bottom: FLOATING_BUTTON_POSITION + -14 + 'px',
      paddingRight: 'inherit',
      right: 0,
    },
    applicationTitleIE: {
      bottom: '-60px',
      left: '47px',
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      color: theme.palette.text.primary,
    },
    signOutButton: {
      // Offset button pad, since the pad is invisible
      marginRight: '-8px',
    },
    loginButton: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
      color: colors.grayscale.white,
      background: colors.primaryPalette.brandBlue,
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: 600,
      fontFamily: whitneyFontAndFallbacks,
      lineHeight: '16px',
      height: '48px',
      letterSpacing: '1.25px',
      '&:hover': {
        background: colors.primaryPalette.brandBlueMed,
      },
    },
  })
);

export interface MenuBarProps {
  displaySignOut?: boolean;
  displayLoginButton?: boolean;
  showingRightUpperButtons?: boolean;
}

export default function MenuBar({
  displaySignOut,
  displayLoginButton,
  showingRightUpperButtons = false,
}: MenuBarProps) {
  const screenIsSmall = useMediaQuery(styleTheme.breakpoints.down('xs'));
  const { isLoggedIn, interactiveLogout } = useAuthBaseContext();
  const classes = useStyles();
  const showSignOut = displaySignOut && isLoggedIn;
  const routeMatch = useRouteMatch();

  const handleNewVisit = () => {
    if (routeMatch && routeMatch.url && routeMatch.path.includes('/feedback')) {
      analyticEvent({
        action: ACTION_CALL_TO_ACTION,
        label: 'New Invite Button in Feedback Page',
      });
    }
  };

  return (
    <AppBar className={classes.container} position="static">
      <Toolbar className={classes.toolbar}>
        <img
          src="/static/ascension-logo.png"
          alt="Ascension"
          height={LOGO_HEIGHT + 'px'}
        />
        <ApplicationTitle
          className={clsx(
            classes.floatingButtons,
            // Is IE or old iOS Safari
            isIE || (isSafari && isIOS && parseInt(osVersion) < 11)
              ? classes.applicationTitleIE
              : null
          )}
        />
        <RightUpperButtons
          showing={showingRightUpperButtons}
          handleNewVisit={handleNewVisit}
          className={clsx(classes.floatingButtons, classes.rightUpperButtons)}
        />
        <div className={classes.rightButtonContainer} color="primary">
          {showSignOut && (
            <Button
              color="primary"
              onClick={interactiveLogout}
              className={classes.signOutButton}
            >
              {tr('Sign Out ##menu button')}
            </Button>
          )}
          {displayLoginButton && (
            <Button
              href={RouteEnum.APPOINTMENT}
              variant="contained"
              className={classes.loginButton}
            >
              {screenIsSmall
                ? tr('Login ##login button')
                : tr('Clinician login ##login button')}
            </Button>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}
