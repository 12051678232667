import React from 'react';
import { transitionSpeed } from '../../theme';

export default function useTransitionIsDone() {
  const [transitionIsDone, setTransitionIsDone] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setTransitionIsDone(true);
    }, transitionSpeed);

    return () => clearTimeout(timeout);
  }, []);

  return transitionIsDone;
}
