export enum ApptFetchErrorTypeEnum {
  ACCESS_DENIED = 'access_denied',
  SERVER_ERROR = 'server_error',
  INVALID_REQUEST = 'invalid_request',
  GENERAL = 'general',
}

export enum ApptFetchErrorAboutEnum {
  ADD_APPOINTMENT = 'add-appointment',
  UPDATE_APPOINTMENT = 'update-appointment',
  CANCEL_APPOINTMENT = 'cancel-appointment',
  RESEND_INVITE = 'resend-invite',
  GET_APPOINTMENT_LIST = 'get-appointment',
  GET_TOKEN_APPOINTMENT_DETAILS = 'get-token-appointment-details',
  GET_CLINICIAN = 'get-clinician',
  GET_APPOINTMENT_DETAILS = 'get-appointment-details',
  GET_INTERPRETER_LANGUAGES = 'get-interpreter-languages',
  GET_INTERPRETER = 'get-interpreter',
}

export type ApptFetchErrorType = {
  message: string;
  about: ApptFetchErrorAboutEnum;
  status?: number;
  type?: ApptFetchErrorTypeEnum;
};

export type ApptFetchResponseType<T> = {
  content?: T;
  error?: ApptFetchErrorType;
};

export enum AppointmentDetailStatusEnum {
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  WAITING = 'waiting',
  SCHEDULED = 'scheduled',
  NOW = 'now',
  IN_CALL = 'in call',
  INVITE_ERROR = 'invite error',
}
