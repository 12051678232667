import React from 'react';
import getDevices from './getDevices';
import { DevicesType, DeviceType, OnErrorType } from './UseMediaTypes';
import { deviceListHasChanged } from './utils';

interface UseDevicesInterface {
  refresh: () => void;
  devices: DevicesType;
}

export default function useDevices(onError: OnErrorType): UseDevicesInterface {
  const [videoIn, setVideoIn] = React.useState<DeviceType[]>([]);
  const [audioIn, setAudioIn] = React.useState<DeviceType[]>([]);
  const [audioOut, setAudioOut] = React.useState<DeviceType[]>([]);
  const refreshing = React.useRef(false);

  const refresh = React.useCallback(() => {
    if (refreshing.current) {
      return;
    }
    refreshing.current = true;

    getDevices()
      .then((newDevices) => {
        // Check for changes separately to keep from state of one triggering the other
        setVideoIn((curr) => {
          return deviceListHasChanged(curr, newDevices.videoIn)
            ? newDevices.videoIn
            : curr;
        });
        setAudioIn((curr) => {
          return deviceListHasChanged(curr, newDevices.audioIn)
            ? newDevices.audioIn
            : curr;
        });
        setAudioOut((curr) => {
          return deviceListHasChanged(curr, newDevices.audioOut)
            ? newDevices.audioOut
            : curr;
        });
      })
      .catch((err) => {
        onError(`${err} (useDevices)`);
      })
      .finally(() => {
        refreshing.current = false;
      });
  }, [onError]);

  React.useEffect(() => {
    refresh();
    navigator.mediaDevices.addEventListener('devicechange', refresh);

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', refresh);
    };
  }, [refresh, onError]);

  return {
    refresh,
    devices: {
      videoIn,
      audioIn,
      audioOut,
    },
  };
}
