import React from 'react';
import { createStyles, Theme, Divider, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuBar from '../MenuBar/MenuBar';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import tr from '../../utils/tr';
import {
  colors,
  whitneyFontAndFallbacks,
  chronicleFontAndFallbacks,
} from '../../theme';
import clsx from 'clsx';
import {
  getTextDirectionOriginIsRight,
  isMobile,
  formatInviteTarget,
} from '../../utils';
import { RouteEnum } from '../../RouteEnum';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';

export const emailAddress = 'ais.servicedesk@ascension.org';
export const phoneNumber = '8776350545';

type ItemType = { imgURL: string; title: string; description: string };

const outerContainerGrid = {
  display: 'grid',
  gridTemplateColumns: 'repeat(20, 1fr)',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    parent: {
      textAlign: 'center',
    },
    divider: {
      background: '#fcfcfc',
    },
    imgSection: {
      ...outerContainerGrid,
      gridTemplateRows: 'repeat(1, 1fr)',
      [theme.breakpoints.down('xs')]: {
        gridTemplateRows: 'repeat(3, 1fr)',
      },
    },
    imgContainer: {
      height: '430px',
      gridColumn: '1 / -1',
      gridRow: '1 / -1',
      zIndex: 1,
      backgroundImage: 'url("/static/landing_background.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right top 50%',
      backgroundSize: 'cover',
      [theme.breakpoints.down('sm')]: {
        height: '400px',
      },
      [theme.breakpoints.down('xs')]: {
        height: '113px',
        gridRow: '1/1',
      },
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    textOuterContainer: {
      gridRow: '1/-1',
      gridColumn: '3 / 11',
      zIndex: 100,
      alignSelf: 'center',
      [theme.breakpoints.down('lg')]: {
        gridColumn: '2 / 11',
      },
      '@media (max-width: 899px)': {
        gridColumn: getTextDirectionOriginIsRight() ? '1 / 10' : '1/14',
        padding: `0px ${theme.spacing(2)}px`,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'grid',
        gridTemplateRows: 'Repeat(3, 1fr)',
        alignSelf: 'auto',
        gridColumn: '1 / -1',
        padding: `0px ${theme.spacing(2)}px`,
      },
    },
    textTitleWrap: {
      gridRow: '1',
      alignItems: 'start',
      justifyContent: 'end',
      direction: getTextDirectionOriginIsRight() ? 'rtl' : 'ltr',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
        width: getTextDirectionOriginIsRight() ? '60%' : 'auto',
      },
    },
    textContentWrap: {
      gridRow: '2/-1',
      direction: getTextDirectionOriginIsRight() ? 'rtl' : 'ltr',
    },
    shortLine: {
      width: '40px',
      height: '6px',
      marginLeft: '0px',
      backgroundColor: colors.secondary.violet,
      marginRight: getTextDirectionOriginIsRight() ? '0px' : 'auto',
    },
    imageTitle: {
      fontFamily: chronicleFontAndFallbacks,
      fontSize: '75px',
      lineHeight: '87px',
      textAlign: getTextDirectionOriginIsRight() ? 'right' : 'left',
      marginBottom: '0px',
      fontWeight: 300,
      letterSpacing: '-1.5px',
      [theme.breakpoints.down('md')]: {
        fontSize: '56px',
        lineHeight: '65px',
        letterSpacing: '-1.5px',
      },
      '@media(max-width: 899px)': {
        fontSize: '48px',
        lineHeight: '56px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '36px',
        lineHeight: '42px',
      },
    },
    imageBigText: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(3),
      textAlign: 'start',
      fontFamily: whitneyFontAndFallbacks,
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '32px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: '28px',
      },
    },
    imageSmallText: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      textAlign: 'start',
      fontFamily: whitneyFontAndFallbacks,
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '24px',
      '@media (max-width: 1439px)': {
        fontSize: '16px',
      },
    },
    introSubTitle: {
      fontSize: '12px',
      fontWeight: 600,
      fontFamily: whitneyFontAndFallbacks,
      lineHeight: '14px',
      letterSpacing: '1.75px',
      ...(!getTextDirectionOriginIsRight()
        ? {
            textAlign: 'left',
          }
        : {}),
      marginBottom: theme.spacing(1),
    },
    contactSection: {
      ...outerContainerGrid,
      backgroundColor: colors.primaryPalette.brandPurple,
      height: '200px',
      gridTemplateRows: 'repeat(1, 1fr)',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        height: '250px',
      },
      '@media (max-width: 299px)': {
        height: 'fit-content',
      },
    },
    contactContainer: {
      gridColumn: '3/-3',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      height: '60%',
      direction: getTextDirectionOriginIsRight() ? 'rtl' : 'ltr',
      [theme.breakpoints.down('lg')]: {
        gridColumn: '2/-2',
      },
      paddingBottom: theme.spacing(1),
      '@media (max-width: 899px)': {
        height: '80%',
        gridColumn: '1 / -1',
        padding: `0px ${theme.spacing(2)}px`,
      },
    },
    contactText: {
      lineHeight: '37px',
      letterSpacing: '0px',
      color: colors.grayscale.white,
      fontFamily: chronicleFontAndFallbacks,
      fontSize: '32px',
      fontWeight: 300,
      textAlign: 'start',
      marginBottom: theme.spacing(3),
      '@media (max-width: 899px)': {
        fontSize: '24px',
        lineHeight: '28px',
        marginBottom: '0px',
      },
    },
    contactDetailWrap: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    contactIcon: {
      width: '18px',
      height: '18px',
      marginLeft: getTextDirectionOriginIsRight() ? theme.spacing(1) : '0px',
      marginRight: getTextDirectionOriginIsRight() ? '0px' : theme.spacing(1),
    },
    contactDetail: {
      color: colors.grayscale.white,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      fontWeight: 600,
      fontSize: '14px',
      fontFamily: whitneyFontAndFallbacks,
      wordBreak: 'break-all',
      lineHeight: '16px',
      letterSpacing: '0.5px',
      textDecoration: 'none',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
      cursor: 'auto',
    },
    contactDetailBorder: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '4px',
      padding: '14px',
      cursor: 'pointer',
    },
    leftMargin: {
      marginLeft: getTextDirectionOriginIsRight() ? 'auto' : theme.spacing(4),
      marginRight: getTextDirectionOriginIsRight() ? theme.spacing(4) : 'auto',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '0px',
        marginRight: '0px',
      },
    },
    leftCallMargin: {
      marginLeft: getTextDirectionOriginIsRight() ? theme.spacing(0.5) : '0px',
      marginRight: getTextDirectionOriginIsRight() ? '0px' : theme.spacing(0.5),
    },
    topMargin: {
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2),
      },
    },
    introSection: {
      ...outerContainerGrid,
      height: '400px',
      alignItems: 'center',
      alignSelf: 'center',
      gridTemplateRows: 'repeat(1, 1fr)',
      '@media (max-width: 899px)': {
        height: '450px',
      },
    },
    [theme.breakpoints.down('lg')]: {
      gridColumn: '2 / -3',
    },
    introContainer: {
      height: '100%',
      gridColumn: '3/-3',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      textAlign: getTextDirectionOriginIsRight() ? 'right' : 'left',
      [theme.breakpoints.down('lg')]: {
        gridColumn: '2 / -2',
      },
      '@media (max-width: 899px)': {
        gridColumn: '1 / -1',
        padding: `0px ${theme.spacing(2)}px`,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    introTextWrapper: {
      height: '70%',
      display: 'flex',
      flexDirection: 'column',
      width: '45%',
      justifyContent: 'start',
      direction: getTextDirectionOriginIsRight() ? 'rtl' : 'ltr',
      '@media (max-width: 899px)': {
        width: '100%',
        height: 'fit-content',
        paddingBottom: theme.spacing(3),
      },
    },
    introImage: {
      height: '70%',
      width: '45%',
      backgroundImage: 'url("/static/video_chat_notification.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top',
      backgroundSize: 'cover',
      '@media (max-width: 899px)': {
        width: '100%',
        height: '48%',
      },
      [theme.breakpoints.down('xs')]: {
        height: '42%',
      },
    },
    introTitle: {
      fontFamily: chronicleFontAndFallbacks,
      fontSize: '32px',
      fontWeight: 300,
      lineHeight: '37px',
      letterSpacing: '0px',
      marginBottom: theme.spacing(2),
      textAlign: getTextDirectionOriginIsRight() ? 'right' : 'left',
      '@media (max-width: 899px)': {
        fontSize: '24px',
        lineHeight: '28px',
      },
    },
    introText: {
      fontFamily: whitneyFontAndFallbacks,
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      ...(!getTextDirectionOriginIsRight()
        ? {
            textAlign: 'left',
          }
        : {}),
      marginTop: theme.spacing(2),
      '@media (max-width: 1439px)': {
        fontSize: '16px',
      },
    },
    featureSection: {
      ...outerContainerGrid,
      alignItems: 'center',
      alignSelf: 'center',
      backgroundColor: '#F5F5F5',
      gridTemplateRows: 'repeat(1, 1fr)',
      height: 'fit-content',
    },
    featureContainer: {
      display: 'flex',
      flexDirection: 'column',
      gridColumn: '3/-3',
      justifyContent: 'start',
      alignItems: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down('lg')]: {
        gridColumn: '2 / -2',
      },
      '@media (max-width: 899px)': {
        gridColumn: '1 / -1',
        padding: `0px ${theme.spacing(2)}px`,
      },
      [theme.breakpoints.down('xs')]: {},
    },
    featureTitleWrap: {
      fontFamily: whitneyFontAndFallbacks,
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0px',
      width: '100%',
      marginBottom: theme.spacing(3),
      textAlign: getTextDirectionOriginIsRight() ? 'right' : 'left',
      '@media (max-width: 899px)': {
        marginBottom: '0px',
      },
    },
    featureListWrap: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      textAlign: getTextDirectionOriginIsRight() ? 'right' : 'left',
      '@media (max-width: 899px)': {
        flexDirection: 'column',
      },
      [theme.breakpoints.down('xs')]: {
        height: '100%',
      },
    },
    featureItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      width: '30%',
      height: '100%',
      justifyContent: 'start',
      borderRadius: '8px',
      boxShadow:
        '0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
      backgroundColor: 'white',
      '@media (max-width: 899px)': {
        flexDirection: 'row',
        width: '100%',
        height: '30%',
        marginTop: theme.spacing(3),
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    featureTextWrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontWeight: 500,
      fontFamily: whitneyFontAndFallbacks,
      fontStyle: 'normal',
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '0.25px',
      color: 'rgba(0, 0, 0, 0.6)',
      width: '100%',
      alignSelf: 'center',
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      height: '70px',
      [theme.breakpoints.down('lg')]: {
        height: '80px',
      },
      '@media (max-width: 1570px)': {
        height: '95px',
      },
      '@media (max-width: 1439px)': {
        fontSize: '16px',
      },
      [theme.breakpoints.down('md')]: {
        height: '115px',
      },
      '@media (max-width: 1060px)': {
        height: '140px',
      },
      '@media (max-width: 899px)': {
        minHeight: 'unset',
        maxHeight: 'unset',
        width: '50%',
        height: '100%',
        justifyContent: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: theme.spacing(2),
        height: '25%',
        justifyContent: 'start',
      },
      '@media (max-width: 359px)': {
        height: 'fit-content',
      },
      direction: getTextDirectionOriginIsRight() ? 'rtl' : 'ltr',
    },
    featureImage: {
      height: '250px',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '8px 8px 0px 0px',
      '@media (max-width: 899px)': {
        height: '140px',
        width: '50%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '180px',
      },
    },
    footerSection: {
      color: '#FFFFFF',
      backgroundColor: '#092A79',
      ...outerContainerGrid,
      height: '270px',
      alignItems: 'center',
      alignSelf: 'center',
      gridTemplateRows: 'repeat(1, 1fr)',
      '@media (max-width: 899px)': {
        height: '300px',
      },
    },
    footerContainer: {
      height: '60%',
      display: 'flex',
      gridColumn: '3/-3',
      justifyContent: 'start',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        gridColumn: '2 / -2',
      },
      '@media (max-width: 899px)': {
        gridColumn: '1 / -1',
        padding: `0px ${theme.spacing(2)}px`,
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        height: '80%',
      },
      direction: getTextDirectionOriginIsRight() ? 'rtl' : 'ltr',
    },
    footerLogo: {
      width: '175px',
      alignSelf: 'start',
    },
    footerContactInfo: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      wordBreak: 'break-word',
      marginLeft: getTextDirectionOriginIsRight() ? 'auto' : theme.spacing(4),
      marginRight: getTextDirectionOriginIsRight() ? theme.spacing(4) : 'auto',
      textAlign: getTextDirectionOriginIsRight() ? 'right' : 'left',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        marginLeft: getTextDirectionOriginIsRight() ? 'auto' : theme.spacing(0),
        marginRight: getTextDirectionOriginIsRight()
          ? theme.spacing(0)
          : 'auto',
        width: '100%',
        marginTop: theme.spacing(6),
      },
    },
    footerContactSubtitle: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: whitneyFontAndFallbacks,
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '14px',
      letterSpacing: '1.75px',
    },
    footerContactDetail: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: whitneyFontAndFallbacks,
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '0.5px',
    },
    footContactLogo: {
      alignSelf: 'start',
      fontFamily: whitneyFontAndFallbacks,
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0em',
    },
  })
);

const items = [
  {
    imgURL: 'static/no_downloads_required.jpg',
    title: tr('No downloads required ##splash feature'),
    description: tr(
      'Join from any major browser, no app download required. ##splash featureText'
    ),
  },
  {
    imgURL: 'static/chat.jpg',
    title: tr('Chat ##splash feature'),
    description: tr(
      'Group chat with all the participants. ##splash featureText'
    ),
  },
  {
    imgURL: 'static/screen_share.jpg',
    title: tr('Screen share ##splash feature'),
    description: tr(
      'Share your screen for additional context or show real-time diagnostics. ##splash featureText'
    ),
  },
] as ItemType[];

export default function LandingTwo() {
  const classes = useStyles();

  return (
    <>
      <MenuBar displayLoginButton={true} />
      <Divider className={classes.divider} />
      <div className={classes.parent}>
        <div className={classes.imgSection}>
          <div className={classes.textOuterContainer}>
            <div className={clsx(classes.textContainer, classes.textTitleWrap)}>
              <span className={classes.imageTitle}>
                {tr('Video Chat ##landing title')}
              </span>
              <hr className={classes.shortLine} />
            </div>
            <div
              className={clsx(classes.textContainer, classes.textContentWrap)}
            >
              <span className={classes.imageBigText}>
                {tr(
                  'A simple and secure way to deliver personalized care to patients from wherever they are. ##landing text'
                )}
              </span>
              <span
                className={classes.imageSmallText}
                style={{
                  color: colors.primaryPalette.brandPurple,
                  fontWeight: 700,
                }}
              >
                {tr('Login NOT required for patients. ##landing text')}
              </span>
              <span className={classes.imageSmallText}>
                {tr(
                  'A link will be sent to all participants via text or email to join Video Chat prior to the visit. ##landing text'
                )}
              </span>
            </div>
          </div>
          <div className={classes.imgContainer} />
        </div>

        <div className={classes.contactSection}>
          <div className={classes.contactContainer}>
            <div className={classes.contactText}>
              {tr(
                'Get clinician login support from the Ascension Service Desk ##contact text'
              )}
            </div>
            <div className={classes.contactDetailWrap}>
              <a
                href={`mailto: ${emailAddress}`}
                className={clsx(
                  classes.contactDetail,
                  classes.contactDetailBorder,
                  classes.topMargin
                )}
                onClick={() => {
                  analyticEvent({
                    label: 'Call ServiceDesk Email',
                    action: ACTION_CALL_TO_ACTION,
                  });
                }}
              >
                <EmailIcon className={classes.contactIcon} />
                <span>{tr('Email: ##contact email') + ' ' + emailAddress}</span>
              </a>
              <a
                href={isMobile ? `tel: ${phoneNumber}` : undefined}
                className={clsx(
                  classes.contactDetail,
                  classes.topMargin,
                  isMobile && classes.contactDetailBorder,
                  classes.leftMargin
                )}
                onClick={() => {
                  if (isMobile) {
                    analyticEvent({
                      label: 'Call ServiceDesk Phone',
                      action: ACTION_CALL_TO_ACTION,
                    });
                  }
                }}
              >
                <CallIcon className={classes.contactIcon} />
                <span className={classes.leftCallMargin}>
                  {tr('Call:##contact phone')}
                </span>
                <span>{formatInviteTarget(phoneNumber)}</span>
              </a>
            </div>
          </div>
        </div>
        <div className={classes.introSection}>
          <div className={classes.introContainer}>
            <div className={classes.introTextWrapper}>
              <div className={classes.introSubTitle}>
                {tr('GET STARTED ##splash title')}
              </div>
              <span className={classes.introTitle}>
                {tr('Visits on demand ##splash')}
              </span>
              <div className={classes.introText}>
                {tr(
                  'Easily invite up to four participants to a secure, HIPAA compliant video call. Invite participants by phone number or email to join a call through any major web browser on any laptop, desktop, smartphone, or tablet. ##splash text'
                )}
              </div>
            </div>
            <div className={classes.introImage} />
          </div>
        </div>
        <div className={classes.featureSection}>
          <div className={classes.featureContainer}>
            <div className={classes.featureTitleWrap}>
              {tr('Feature Highlights ##landing featureTitle')}
            </div>
            <div className={classes.featureListWrap}>
              {items.map((item) => (
                <div className={classes.featureItem} key={item.title}>
                  <div
                    className={classes.featureImage}
                    style={{ backgroundImage: `url("${item.imgURL}")` }}
                  />
                  <div className={classes.featureTextWrap}>
                    <p style={{ margin: '0px' }}>
                      <span style={{ fontWeight: 700 }}>{item.title}</span>
                      {' - '}
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.footerSection}>
          <div className={classes.footerContainer}>
            <div className={classes.footContactLogo}>
              <span style={{ fontWeight: 400 }}>Ascension</span>
              <span style={{ fontWeight: 700 }}>Connect</span>
            </div>
            <div className={classes.footerContactInfo}>
              <div className={classes.footerContactSubtitle}>
                {tr('CONTACT INFORMATION ##contact title')}
              </div>
              <div className={classes.footerContactDetail}>
                {tr(
                  'Get login support from the Ascension Service Desk. ##contact text'
                )}
              </div>
              <div className={classes.footerContactDetail}>
                <CallIcon className={classes.contactIcon} />
                {formatInviteTarget(phoneNumber)}
              </div>
              <div className={classes.footerContactDetail}>
                <EmailIcon className={classes.contactIcon} />
                {emailAddress}
              </div>
              <div className={classes.footerContactDetail}>
                <Link
                  href={RouteEnum.TERMS}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'underline', color: 'white' }}
                >
                  {tr('Terms of Use ##Terms link')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
