import React from 'react';
import { TimeSensitiveMessage } from './messageTypes';
import { useMessageConversions } from './useMessageConversions';
import useVideoContext from '../useVideoContext/useVideoContext';

interface UseChatUtilsInterface<T> {
  messageType: string;
  setMessages: React.Dispatch<React.SetStateAction<TimeSensitiveMessage<T>[]>>;
  onError: (err: string) => void;
}

type AddMessagePayload<T> = Omit<
  Omit<TimeSensitiveMessage<T>, 'receivedByCount'>,
  'pastConfirmedDeadline'
>;

// useCurrentParticipantId
export function useCurrentParticipants() {
  const {
    room: {
      localParticipant,
      participants: rawParticipants,
    },
  } = useVideoContext();
  const participants = Array.from(rawParticipants?.values() || []);

  return { currentParticipantId: localParticipant?.identity, participants };
}

export function useMessageUtils<T>({
  messageType,
  setMessages,
  onError,
}: UseChatUtilsInterface<T>) {
  const { localDatatrack } = useVideoContext();
  const localDataTrackRef = React.useRef(localDatatrack);
  const { convertAck } = useMessageConversions(messageType);

  React.useEffect(() => {
    localDataTrackRef.current = localDatatrack;
  }, [localDatatrack, localDataTrackRef]);

  const addMessage = React.useCallback(
    (newMessage: AddMessagePayload<T>) => {
      setMessages((currMessages) => {
        return [
          ...currMessages,
          { ...newMessage, receivedByCount: 0, pastConfirmedDeadline: false },
        ];
      });
    },
    [setMessages]
  );

  const confirmMessageReceived = React.useCallback(
    (id: string) => {
      setMessages((currMessages) =>
        currMessages.map((currMessage) => {
          // If the desired message, clone it and increment the received-by count (if not already received by all)
          if (currMessage.sentByMe && currMessage.id === id) {
            currMessage = { ...currMessage };
            currMessage.receivedByCount +=
              currMessage.receivedByCount >= currMessage.sentToCount ? 0 : 1;
          }
          return currMessage;
        })
      );
    },
    [setMessages]
  );

  const sendConfirmation = React.useCallback(
    (messageId: string): boolean => {
      if (!localDataTrackRef.current) {
        onError('Unable to send confirmation; no local data track');
        return false;
      }
      const datatrackMessage = convertAck(messageId);
      try {
        localDataTrackRef.current.send(JSON.stringify(datatrackMessage));
      } catch (e) {
        onError('There was an error sending confirmation: ' + e);
        return false;
      }
      return true;
    },
    [convertAck, onError]
  );

  return {
    addMessage,
    confirmMessageReceived,
    sendConfirmation,
  };
}
