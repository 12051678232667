import React from 'react';
import { isAndroid, isIE, isIOS, isMacOs } from 'react-device-detect';
import BrowserIcon from '../BrowserIcon/BrowserIcon';
import { makeStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/core';
import clsx from 'clsx';

type BrowserItemType = {
  icon: string;
  name: string;
  url: string | null;
};

type BrowserType = {
  firefox: BrowserItemType;
  chrome: BrowserItemType;
  edge: BrowserItemType;
  safari: BrowserItemType;
  mobileSafari: BrowserItemType;
  mobileFirefox: BrowserItemType;
  mobileChrome: BrowserItemType;
  iosChrome: BrowserItemType;
};

const useStyles = makeStyles(
  createStyles({
    wrap: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    rowList: {
      display: 'grid',
      padding: 0,
      width: '100%',
      maxWidth: '700px',
      gridGap: '10px',
      gridTemplateColumns: 'repeat(auto-fit, minmax(60px, auto))',
    },
    // Switch to flex for IE (which doesn't support grid well)
    listIE: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      '& > li': {
        margin: '0 10px',
        maxWidth: '105px',
      },
    },
  })
);

const browser = {
  firefox: {
    icon: '/static/firefox-icon.png',
    name: 'Firefox',
    url: 'https://www.mozilla.org/en-US/firefox/new/',
  },
  chrome: {
    icon: '/static/chrome-icon.png',
    name: 'Google Chrome',
    url: 'https://www.google.com/chrome/',
  },
  iosChrome: {
    icon: '/static/chrome-icon.png',
    name: 'Google Chrome',
    url: 'https://apps.apple.com/us/app/google-chrome/id535886823',
  },
  edge: {
    icon: '/static/edge-icon.png',
    name: 'Microsoft Edge',
    url: 'https://www.microsoft.com/en-us/edge',
  },
  safari: {
    icon: '/static/safari-icon.png',
    name: 'Safari',
    url: 'https://support.apple.com/downloads/safari',
  },
  mobileSafari: {
    icon: '/static/safari-icon.png',
    name: 'Safari',
    url: null,
  },
  mobileFirefox: {
    icon: '/static/firefox-icon.png',
    name: 'Firefox',
    url: 'https://play.google.com/store/apps/details?id=org.mozilla.firefox',
  },
  mobileChrome: {
    icon: '/static/chrome-icon.png',
    name: 'Google Chrome',
    url: 'https://play.google.com/store/apps/details?id=com.android.chrome',
  },
} as BrowserType;

export default function PreferredBrowserIcons({
  isIOSWithWebRTC,
}: {
  isIOSWithWebRTC: boolean;
}) {
  const classes = useStyles();
  // Default to windows
  let browserPicks = [browser.firefox, browser.chrome, browser.edge];
  // Adjust set of browsers for other OSs
  if (isMacOs) browserPicks = [browser.firefox, browser.chrome, browser.safari];
  else if (isIOS && isIOSWithWebRTC)
    browserPicks = [browser.mobileSafari, browser.iosChrome];
  else if (isIOS && !isIOSWithWebRTC) browserPicks = [browser.mobileSafari];
  else if (isAndroid)
    browserPicks = [browser.mobileFirefox, browser.mobileChrome];

  return (
    <div className={classes.wrap}>
      <ul className={clsx(classes.rowList, isIE ? classes.listIE : null)}>
        {browserPicks.map(({ icon, name, url }) => (
          <BrowserIcon icon={icon} name={name} url={url} key={name} />
        ))}
      </ul>
    </div>
  );
}
