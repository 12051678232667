import { useApptStateContext } from '../../../state/appt/ApptStateProvider';
import React from 'react';
import tr from '../../../utils/tr';

export default function WatchTimeMismatch() {
  const {
    state: { timeIsMismatched },
    setError,
  } = useApptStateContext();

  React.useEffect(() => {
    if (timeIsMismatched) {
      setError({
        title: tr(
          'Warning: Your device time is set up incorrectly. ##error message'
        ),
        message: tr(
          "Please update it in your device's date/time preferences. ##error message"
        ),
      });
    }
  }, [timeIsMismatched, setError]);

  return <></>;
}
