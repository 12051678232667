import { FeatureGroupEnum, FeatureEnum } from './features';
import { FeatureGroupsType, FeaturesType } from './featureTypes';
import React from 'react';
import {
  rememberFeatureGroupIsDismissed,
  rememberFeatureIsDismissed,
} from './featureUtils';

export default function useFeatureGroupSelectDismiss({
  visibleGroupId,
  setFeatures,
  setFeatureGroups,
}: {
  visibleGroupId: FeatureGroupEnum | null;
  setFeatures: React.Dispatch<React.SetStateAction<FeaturesType>>;
  setFeatureGroups: React.Dispatch<React.SetStateAction<FeatureGroupsType>>;
}) {
  const selectGroup = React.useCallback(() => {
    if (!visibleGroupId) return;
    // First clear-out any feature/group dismissals
    rememberFeatureGroupIsDismissed(visibleGroupId, false);
    setFeatures((currFeatures) => {
      return Object.keys(currFeatures).reduce((cummFeatures, currFeatureId) => {
        const currFeature = currFeatures[currFeatureId as FeatureEnum];
        if (currFeature.groupId === visibleGroupId) {
          currFeature.isDismissed = false;
          rememberFeatureIsDismissed(currFeatureId, false);
        }
        return { ...cummFeatures, [currFeatureId]: currFeature };
      }, {} as FeaturesType);
    });
    setFeatureGroups((currFeatureGroups) => {
      currFeatureGroups = { ...currFeatureGroups };
      currFeatureGroups[visibleGroupId] = {
        ...currFeatureGroups[visibleGroupId],
        isSelected: true,
      };
      return currFeatureGroups;
    });
  }, [setFeatures, setFeatureGroups, visibleGroupId]);

  const dismissGroup = React.useCallback(() => {
    if (!visibleGroupId) return;
    rememberFeatureGroupIsDismissed(visibleGroupId);
    setFeatures((currFeatures) => {
      return Object.keys(currFeatures).reduce((cummFeatures, currFeatureId) => {
        const currFeature = currFeatures[currFeatureId as FeatureEnum];
        if (currFeature.groupId === visibleGroupId)
          currFeature.isDismissed = true;
        return { ...cummFeatures, [currFeatureId]: currFeature };
      }, {} as FeaturesType);
    });
  }, [setFeatures, visibleGroupId]);

  return { selectGroup, dismissGroup };
}
