import React from 'react';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Container, Grid, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {
  ApptFetchErrorType,
  ApptFetchErrorTypeEnum,
} from '../../../hooks/appt/apptHookTypes';
import theme, { transitionSpeed } from '../../../theme';
import useHeldState from '../../../hooks/useHeldState/useHeldState';
import ResponsiveDialog from '../../ResponsiveDialog/ResponsiveDialog';
import tr from '../../../utils/tr';
import { useStylesForErrorDialog } from '../../ErrorDialog/ErrorDialog';
import apptErrors, { ApptDisplayErrorType } from './apptErrors';

interface ErrorDialogInterface {
  dismissError: () => void;
  buttonText?: string;
  onButtonClick?: (() => void) | null;
  error: ApptFetchErrorType | null;
}

export default function ApptErrorDialog({
  dismissError,
  buttonText,
  onButtonClick,
  error,
}: ErrorDialogInterface) {
  const heldError = useHeldState<ApptFetchErrorType | null>({
    expectedState: error,
    holdForMs: transitionSpeed + 200,
  });
  const heldButtonText = useHeldState<string | undefined>({
    expectedState: buttonText,
    holdForMs: transitionSpeed + 200,
  });
  const classes = useStylesForErrorDialog();
  let apptError: ApptDisplayErrorType = {
    message: tr('System error. Please try again. ##appt-error message'),
  };
  if (heldError) {
    apptError =
      apptErrors[heldError.about][
        heldError.type || ApptFetchErrorTypeEnum.GENERAL
      ];
  }

  return (
    <ResponsiveDialog open={error !== null} onDismiss={dismissError}>
      <Container
        style={{
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
        }}
      >
        <div id="errorWrap" className={classes.wrap}>
          <ErrorOutlineIcon
            width="64px"
            height="64px"
            className={classes.icon}
          />
          <Typography variant="h3" className={classes.title}>
            {apptError.title
              ? apptError.title
              : tr('Error. ##appt-error title')}
          </Typography>
          <DialogContentText>{apptError.message}</DialogContentText>
          {heldError && 'status' in heldError && (
            <Typography variant="subtitle1" className={classes.subtitle}>
              {`${tr('HTTP ERROR ##appt-error')} ${heldError.status}`}
            </Typography>
          )}
          <Grid container spacing={3} className={classes.buttonWrap}>
            <Grid item xs={12}>
              <Button
                onClick={() => {
                  if (onButtonClick) onButtonClick();
                  else dismissError();
                }}
                color="primary"
                variant="contained"
                autoFocus
              >
                {heldButtonText || tr('OKAY ##appt-error button')}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    </ResponsiveDialog>
  );
}
