import { Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import tr from '../../../utils/tr';
import AppointmentParticipantList from '../AppointmentParticipantList/AppointmentParticipantList';
import React from 'react';
import SaveAppointmentButton from '../SaveAppointmentButton/SaveAppointmentButton';
import InviteMessage from '../InviteMessage/InviteMessage';
import useApptUpdate from '../../../hooks/appt/useApptUpdate/useApptUpdate';
import { getApptIsSame } from '../../../state/appt/apptStateUtils';
import {
  ApptParticipantType,
  ApptType,
  ApptTypeEnum,
} from '../../../state/appt/apptTypes';
import useToastContext from '../../../hooks/useToastContext/useToastContext';
import { filterParticipantListForDisplay } from '../utils';
import useAppointmentUpdateForm from '../../../hooks/appt/useAppointmentUpdateForm/useAppointmentUpdateForm';

export enum ParticipantTempStatusEnum {
  ADDED = 'added',
  REMOVED = 'removed',
}

export type ApptParticipantWithTempStatusType = ApptParticipantType & {
  tempStatus?: ParticipantTempStatusEnum;
};

export default function AppointmentUpdateForm({
  appointment,
  hideName = false,
  refetchAppointment,
  onUpdated,
}: {
  appointment: ApptType;
  hideName?: boolean;
  refetchAppointment: () => void;
  onUpdated: () => void;
}) {
  const {
    participants,
    onRemoveParticipant,
    onAddParticipant,
    inviteMessage,
    setInviteMessage,
    EditParticipant,
    editParticipantProps,
  } = useAppointmentUpdateForm({ appointment });
  const domain = window.location.origin;
  const apptUpdate = useApptUpdate({
    participants,
    appointment,
    content: editParticipantProps.content,
    name: editParticipantProps.name,
    inviteMessage,
  });
  const { add: addToast } = useToastContext();
  const apptIsSame =
    getApptIsSame(appointment, {
      participants,
      inviteMessage,
    }) && !editParticipantProps.content;
  const apptIsSameRef = React.useRef(apptIsSame);
  const updateButtonClicked = React.useRef(false);

  const showToast = React.useCallback(() => {
    if (!apptIsSameRef.current && !updateButtonClicked.current) {
      addToast({
        message: tr('Your changes were not saved. ##toast'),
        isForApptDirty: true,
      });
    }
  }, [addToast]);

  apptIsSameRef.current = apptIsSame;

  React.useEffect(() => {
    return showToast;
  }, [showToast]);

  const filteredParticipantsForDisplay = filterParticipantListForDisplay(
    participants
  );
  const { pathname } = useLocation();
  const isInCallPage = pathname.includes('/call');
  return (
    <>
      <Typography variant="h3">
        {tr('Participants ##add-appt title')}
      </Typography>
      <EditParticipant
        {...editParticipantProps}
        hideName={hideName}
        onSubmit={onAddParticipant}
        isInCallPage={isInCallPage}
      />
      <AppointmentParticipantList
        participants={filteredParticipantsForDisplay}
        onRemove={onRemoveParticipant}
      />
      <br />
      <div>
        <InviteMessage
          domain={domain}
          roomId="..."
          inviteMessage={inviteMessage}
          setInviteMessage={setInviteMessage}
          appointmentType={appointment.type}
          appointmentStartTime={appointment.startTime}
        />
      </div>

      <br />
      <SaveAppointmentButton
        onClick={async () => {
          const isAnyParticipantRemoved = participants.find(
            (participant) =>
              participant?.appointmentType === ApptTypeEnum.ATHENA &&
              participant?.tempStatus === ParticipantTempStatusEnum.REMOVED
          );
          if (
            (editParticipantProps.content === '' &&
              editParticipantProps.name === '') ||
            editParticipantProps.checkIsValid()
          ) {
            updateButtonClicked.current = true;
            const wasUpdated = await apptUpdate();
            if (wasUpdated) {
              refetchAppointment();
              if (isAnyParticipantRemoved) {
                addToast({
                  message: tr(
                    'Removing participant here does not remove it from the EHR record. ##participantRemoveToast'
                  ),
                  hold: true,
                  isForApptDirty: true,
                });
              }
              onUpdated();
            }
          }
        }}
        disabled={apptIsSameRef.current}
      />
    </>
  );
}
