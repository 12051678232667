import React from 'react';
import {
  ApptActionEnum,
  ApptActionsType,
} from '../../../state/appt/ApptActionObjectType';
import cancelAppt from '../fetch/cancelAppt';
import { useApptStateContext } from '../../../state/appt/ApptStateProvider';
import useAuthBaseContext from '../../useAuthBaseContext/useAuthBaseContext';
import { ApptType } from '../../../state/appt/apptTypes';
import {
  ApptFetchErrorTypeEnum,
  ApptFetchErrorAboutEnum,
} from '../apptHookTypes';

export default function useApptCancel({
  appointment,
}: {
  appointment: ApptType;
}) {
  const { dispatch, getAppointments } = useApptStateContext();
  const { baseAccessToken } = useAuthBaseContext();

  return React.useCallback(async () => {
    if (!baseAccessToken) {
      dispatch({
        type: ApptActionEnum.ERROR_ACTION,
        error: {
          message: 'Unable to cancel appointment due to missing access token',
          type: ApptFetchErrorTypeEnum.ACCESS_DENIED,
          about: ApptFetchErrorAboutEnum.CANCEL_APPOINTMENT,
        },
      });
      return;
    }
    if (!appointment?.id) {
      dispatch({
        type: ApptActionEnum.ERROR_ACTION,
        error: {
          message: 'Unable to cancel appointment due to no appointment id',
          type: ApptFetchErrorTypeEnum.INVALID_REQUEST,
          about: ApptFetchErrorAboutEnum.CANCEL_APPOINTMENT,
        },
      });
      return;
    }

    const res = await cancelAppt({
      appointmentId: appointment.id,
      baseAccessToken,
      targetAdid: appointment.ownedBy,
    });
    if (res.error) {
      dispatch({
        type: ApptActionEnum.ERROR_ACTION,
        error: res.error,
      } as ApptActionsType);
      return;
    }
    getAppointments();
  }, [baseAccessToken, appointment, dispatch, getAppointments]);
}
