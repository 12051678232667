import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import tr from '../../../utils/tr';
import { colors } from '../../../theme';
import ResponsiveDialog from '../../ResponsiveDialog/ResponsiveDialog';
import CancelAppointment from '../CancelAppointment/CancelAppointment';
import { useHistory } from 'react-router-dom';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../../AnalyticsConfiguration/analyticsConstant';
import { getTextDirectionOriginIsRight } from '../../../utils';

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      '&.Mui-disabled': {
        color: colors.grayscale.gray3,
      },
    },
    athenaAppt: {
      fontSize: '0.8rem',
      lineHeight: '48px !important',
      paddingLeft: theme.spacing(2),
    },
    athenaApptText: {
      paddingRight: '10px',
    },
    dotSeparator: {
      height: '5px',
      width: '5px',
      borderRadius: '50%',
      display: 'inline-block',
      paddingRight: '5px',
      position: 'relative',
      top: '-2px',
      backgroundColor: theme.palette.grey[500],
    },
  })
);

export default function AppointmentEditCancel({
  appointmentId,
  appointmentCancelHandler,
  disabled,
  isAthenaAppt = false,
}: {
  appointmentId?: string;
  appointmentCancelHandler: () => void;
  disabled: boolean;
  isAthenaAppt?: boolean;
}) {
  const classes = useStyles();
  const [showCancelDialog, setShowCancelDialog] = React.useState(false);
  const history = useHistory();

  return (
    <>
      <Grid
        container
        direction="row"
        justify={getTextDirectionOriginIsRight() ? 'flex-end' : 'flex-start'}
      >
        {isAthenaAppt && (
          <Typography align="center" className={classes.athenaAppt}>
            <span className={classes.athenaApptText}>
              {tr('Scheduled from Athena ##athenaappointment')}
            </span>
            <span className={classes.dotSeparator} />
          </Typography>
        )}
        <Button
          variant="text"
          color="primary"
          disabled={disabled}
          className={classes.button}
          data-testid="editAppointment"
          onClick={() => {
            analyticEvent({
              label: 'Edit Appointment',
              action: ACTION_CALL_TO_ACTION,
            });
            return (
              appointmentId &&
              history.push(`/appointment/${appointmentId}/edit`)
            );
          }}
        >
          {tr('Edit ##appointmenteditcancel')}
        </Button>
        {!isAthenaAppt && (
          <Button
            variant="text"
            color="primary"
            disabled={disabled}
            className={classes.button}
            data-testid="cancelAppointment"
            onClick={() => {
              analyticEvent({
                label: 'Cancel Appointment',
                action: ACTION_CALL_TO_ACTION,
              });
              return setShowCancelDialog(true);
            }}
          >
            {tr('Cancel ##appointmenteditcancel')}
          </Button>
        )}
      </Grid>
      <ResponsiveDialog
        open={showCancelDialog}
        onDismiss={() => setShowCancelDialog(false)}
        fullWidth={false}
      >
        <CancelAppointment
          onKeep={() => setShowCancelDialog(false)}
          onCancel={() => {
            setShowCancelDialog(false);
            appointmentCancelHandler();
          }}
        />
      </ResponsiveDialog>
    </>
  );
}
