import { useEffect, useState } from 'react';
import { transitionSpeed } from '../../theme';

export default function useDebounced<T>({
  value,
  debounceByMs,
}: {
  value: T;
  debounceByMs: number;
}) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, transitionSpeed);
    return () => clearTimeout(timeout);
  }, [value, debounceByMs]);

  return debouncedValue;
}
