import React, { Dispatch, SetStateAction, useContext } from 'react';
import useSelectedParticipant from '../../components/VideoProvider/useSelectedParticipant/useSelectedParticipant';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { hideToolbarDelayNSeconds } from '../../utils/settings';
import useToastContext from '../useToastContext/useToastContext';
import useCallTimer from './useCallTimer';
import { TokenApptDetailsType, ApptType } from '../../state/appt/apptTypes';
import useCallCurrentAppt from '../appt/useCallCurrentAppt/useCallCurrentAppt';

interface VideoRoomStateInterface {
  showingToolbar: boolean;
  setShowingToolbar: (newShowingToolbar: boolean) => void;
  callDurationInSeconds: number;
  callParticipants: number;
  tokenApptDetails: TokenApptDetailsType | null;
  roomCurrentAppt: ApptType | null;
  getAppointmentDetail: () => void;
  interpreterLanguage: string;
  setInterpreterLanguage: Dispatch<SetStateAction<string>>;
}

const defaultState: VideoRoomStateInterface = {
  showingToolbar: true,
  setShowingToolbar: () => {
    // default empty setShowingToolbar implementation
  },
  callDurationInSeconds: 0,
  callParticipants: 0,
  tokenApptDetails: null,
  roomCurrentAppt: null,
  getAppointmentDetail: () => {
    // empty implementation
  },
  interpreterLanguage: '',
  setInterpreterLanguage: () => {
    // empty implementation
  },
};

const VideoRoomStateContext = React.createContext(defaultState);

export function useVideoRoomState() {
  const videoRoomState = useContext(VideoRoomStateContext);
  if (!videoRoomState)
    throw new Error(
      'useVideoRoomState must be used withing VideoRoomStateContextProvider'
    );
  return videoRoomState;
}

export default function VideoRoomStateProvider({
  children,
  tokenApptDetails,
}: {
  children: React.ReactNode;
  tokenApptDetails: TokenApptDetailsType | null;
}) {
  const [showingToolbar, setShowingToolbar] = React.useState(true);
  const [interpreterLanguage, setInterpreterLanguage] = React.useState('');
  const [selectedParticipant] = useSelectedParticipant();
  const {
    totalSeconds: callDurationInSeconds,
    totalParticipants: callParticipants,
  } = useCallTimer();
  const { add } = useToastContext();
  const theme = useTheme();
  const screenIsSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    getAppointmentDetail,
    apptDetails: roomCurrentAppt,
  } = useCallCurrentAppt(tokenApptDetails?.appointmentId || null);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (screenIsSmall && selectedParticipant && showingToolbar) {
      timeout = setTimeout(() => {
        setShowingToolbar(false);
        add({ message: 'Tap screen to view Toolbar', type: 'video' });
      }, hideToolbarDelayNSeconds * 1000);
    } else if (!selectedParticipant && !showingToolbar) {
      setShowingToolbar(true);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [
    add,
    screenIsSmall,
    showingToolbar,
    selectedParticipant,
    setShowingToolbar,
  ]);

  return (
    <VideoRoomStateContext.Provider
      value={{
        showingToolbar,
        setShowingToolbar,
        callDurationInSeconds,
        callParticipants,
        tokenApptDetails,
        roomCurrentAppt,
        getAppointmentDetail,
        interpreterLanguage,
        setInterpreterLanguage,
      }}
    >
      {children}
    </VideoRoomStateContext.Provider>
  );
}
