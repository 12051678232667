import React from 'react';
import { fetchWithTimeout, getDefaultHeaders } from '../../utils';
import { RatingType } from '../../components/FeedbackForm/FeedbackForm';
import useAuthBaseContext from '../useAuthBaseContext/useAuthBaseContext';
import { reportError } from '@ascension/report-event';

interface SendFeedbackInterface {
  sessionID: string;
  comment?: string;
  rating: RatingType;
  appointmentId?: string;
  tags?: Array<string>;
}

export default function useSendFeedback() {
  const { baseAccessToken, isLoggedIn } = useAuthBaseContext();
  const [error, setError] = React.useState('');
  const [status, setStatus] = React.useState<'sending' | 'sent' | null>(null);
  const headers = getDefaultHeaders();
  headers.set('Content-Type', 'application/json');

  if (isLoggedIn && baseAccessToken) {
    headers.set('Authorization', `Bearer ${baseAccessToken}`);
    headers.set('Content-Type', 'application/json');
  }

  const send = async ({
    sessionID,
    comment,
    rating,
    tags,
    appointmentId,
  }: SendFeedbackInterface) => {
    const body = JSON.stringify({
      sessionId: sessionID,
      appointmentId,
      comment,
      callRating: rating,
      tags,
    });
    const { API_BASE } = window.config;
    setStatus('sending');
    return fetchWithTimeout(`${API_BASE}feedback/callqualitysurvey`, {
      method: 'POST',
      headers,
      body,
    })
      .then((res) => {
        if (!res.ok) {
          reportError(`Error while sending feedback: ${res.status}`);
          setStatus(null);
          return setError('There was an error while sending feedback');
        }
        setStatus('sent');
      })
      .catch((err) => {
        reportError(`Error sending feedback: ${err}`);
        setStatus(null);
        setError('Error while sending feedback');
      });
  };
  return { error, status, send };
}
