import React from 'react';
import { getTotalDuration } from '../../utils';
import useParticipantTimes from './useParticipantTimes';

// For use only in VideoRoomStateProvider
export default function useCallTimer() {
  const participantTimes = useParticipantTimes();
  const [totalSeconds, setTotalSeconds] = React.useState(0);

  React.useEffect(() => {
    const interval = window.setInterval(() => {
      // Get overlapped, total duration (ie, superimpose durations on each other and only
      // stop the clock when no one is present)
      const totalDuration = getTotalDuration(participantTimes);
      setTotalSeconds(totalDuration);
    }, 1000);

    return () => {
      window.clearInterval(interval);
    };
  }, [setTotalSeconds, participantTimes]);

  return {
    participantTimes,
    totalSeconds,
    totalParticipants: participantTimes.length,
  };
}
