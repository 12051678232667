import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import tr from '../../../../utils/tr';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) => ({
  audioLevelWrap: {
    position: 'relative',
    top: '4px',
    marginRight: '9px',
  },
  mutedWrap: {
    marginTop: '1em',
    width: '100%',
    verticalAlign: 'middle',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mutedLeft: {
    flex: 1,
  },
  [theme.breakpoints.between('sm', 'md')]: {
    mutedLeft: {
      display: 'inline-block',
      marginRight: '1em',
    },
  },
}));

export default function InputMutedNotice({
  onUnmute,
  text,
}: {
  onUnmute?: () => void;
  text: string;
}) {
  const classes = useStyles();
  const {
    audioError,
    videoError,
    retryVideoAcquire,
    retryAudioAcquire,
  } = useVideoContext();

  const enableDevice = React.useCallback(() => {
    if (onUnmute) onUnmute();
    if (audioError || videoError) {
      retryVideoAcquire();
      retryAudioAcquire();
    }
  }, [onUnmute, audioError, videoError, retryAudioAcquire, retryVideoAcquire]);

  return (
    <div className={classes.mutedWrap}>
      <div className={classes.mutedLeft}>{text}</div>
      <Button onClick={enableDevice} color="primary" variant="outlined">
        {tr('Enable ##input button')}
      </Button>
    </div>
  );
}
