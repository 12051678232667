import React from 'react';
import { makeStyles } from '@material-ui/styles';
import theme, { borderRadius, colors } from '../../theme';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import {
  ChatRecipientBkgColorsType,
  ChatMessageType,
} from '../../hooks/useChatContext/chatTypes';
import tr from '../../utils/tr';

const useStyles = makeStyles({
  wrap: {
    flex: 1,
    padding: 0,
    margin: 0,
    overflowY: 'auto',
  },
  item: {
    position: 'relative',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    fontWeight: 400,
    listStyleType: 'none',
  },
  upper: {
    width: 'max-content',
    minWidth: '100px',
    borderRadius: borderRadius[1],
    maxWidth: '80%',
    backgroundColor: colors.secondary.violetDark,
  },
  upperFromMe: {
    backgroundColor: colors.grayscale.gray11,
    marginLeft: 'auto',
  },
  upperHasError: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  axillary: {
    fontSize: '0.9em',
  },
  name: {
    position: 'absolute',
    top: '-1.7em',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '200px',
    textOverflow: 'ellipsis',
  },
  text: {
    padding: theme.spacing(2),
    color: colors.grayscale.black,
    overflowWrap: 'break-word',
  },
  alert: {
    margin: '0 0.2em',
  },
  alertButton: {
    '&.MuiButton-root': {
      height: 'fit-content',
    },
  },
  nameUpperFromMe: {
    right: '0',
  },
});

export default function ChatMessage({
  message: { sentByMe, sentBy, message, pastConfirmedDeadline },
  bkgColor,
}: {
  message: ChatMessageType;
  bkgColor: string | null;
}) {
  const classes = useStyles();
  const { sendMessage } = useChatContext();

  const handleResend = React.useCallback(() => {
    sendMessage({
      message,
      sentBy,
    });
  }, [message, sendMessage, sentBy]);

  let extraStyling = null;
  if (pastConfirmedDeadline) {
    extraStyling = classes.upperHasError;
  } else if (sentByMe) {
    extraStyling = classes.upperFromMe;
  }

  let nameExtraStyling = null;
  if (sentByMe) {
    nameExtraStyling = classes.nameUpperFromMe;
  }

  return (
    <li className={classes.item}>
      <div
        className={clsx(classes.upper, extraStyling)}
        style={{
          backgroundColor:
            colors.chatRecipientBkg[
              bkgColor as keyof ChatRecipientBkgColorsType
            ],
        }}
      >
        <div
          className={clsx(classes.axillary, classes.name, nameExtraStyling)}
          data-testid="sentBy"
        >
          {sentByMe ? tr('Me ##chat sentByMe') : sentBy}
        </div>
        <div className={classes.text} data-testid="message">
          {message}
        </div>
      </div>
      {pastConfirmedDeadline && (
        <div
          className={clsx(classes.axillary, classes.alert)}
          data-testid="pastConfirmedDeadline"
        >
          {tr('Not delivered to all participants. ##error chat')}
          <Button
            onClick={handleResend}
            color="primary"
            size="small"
            className={classes.alertButton}
          >
            {tr('Send Again ##error chat')}
          </Button>
        </div>
      )}
    </li>
  );
}
