export default function tr(
  phrase?: string,
  showIfPosition: 'left' | 'right' | 'both' = 'both'
) {
  if (window.tr) {
    return window.tr(phrase, showIfPosition);
  }
  return phrase && ['both', 'left'].includes(showIfPosition)
    ? phrase.split('##')[0].trim()
    : '';
}
