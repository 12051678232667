import React from 'react';
import { MediaErrorType } from '../VideoProvider/useLocalTracks/useLocalTracks';
import {
  isMacOs,
  isWindows,
  isAndroid,
  isIOS,
  isSafari,
  isChrome,
  isFirefox,
} from 'react-device-detect';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import RefreshButton from '../RefreshButton/RefreshButton';
import DialogContentText from '@material-ui/core/DialogContentText';
import tr from '../../utils/tr';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      '& ol': {
        paddingInlineStart: theme.spacing(3) + 'px',
      },
      '& li': {
        marginBottom: '1em',
        paddingInlineStart: theme.spacing(2) + 'px',
      },
      '& li:first-child': {
        marginBottom: '0px !important',
      },
    },
    button: {
      width: '100%',
    },
    screenshot: {
      marginLeft: `-${theme.spacing(2)}px`,
      paddingRight: theme.spacing(1) + 'px',
    },
    icon: {
      height: '1.4em',
      marginLeft: '0.4em',
      marginRight: '0.4em',
      marginBottom: '-0.2em',
    },
    threedotsIcon: {
      marginLeft: '0.4em',
      marginRight: '0.4em',
      marginBottom: '0.2em',
    },
  })
);

export default function VideoAudioErrorContent({
  errorType,
}: {
  errorType: MediaErrorType;
}) {
  const classes = useStyles();
  // Other
  if (errorType === 'other') {
    return (
      <div className={classes.wrap}>
        <DialogContentText>
          {tr(
            'An error occurred that prevented video chat from accessing your camera or microphone. Ensure that you have a working Camera and Microphone, that you are using a compatible browser, and that your browser has Camera and Microphone access. ##warning'
          )}
        </DialogContentText>
        <RefreshButton className={classes.button} />
      </div>
    );
  }

  // System Permissions
  if (errorType === 'systemPermission') {
    return (
      <div className={classes.wrap}>
        {tr(
          'Video Chat needs to access your camera and microphone to proceed. ##warning'
        ) +
          ' ' +
          tr(
            'Please follow these steps to enable those devices in your System Settings. ##warning'
          )}
        {isMacOs && (
          <ol>
            <li>
              {tr('To do this, click the ##warning') + ' '}
              <img
                src="/static/screen-osx-search.png"
                alt={tr('Spotlight Search button ##warning icon')}
                className={classes.icon}
              />{' '}
              {' ' +
                tr(
                  'Spotlight Search icon at the top of your screen and search for ##warning mac'
                ) +
                ' '}
              <b>{tr('"Security & Privacy" ##warning mac')}</b>.
            </li>
            <li>
              {tr('Select ##warning mac') + ' '}
              <b>{tr('"Camera" ##warning mac')}</b>
              {' ' +
                tr('and place a check next to your browser. ##warning mac')}
            </li>{' '}
            <li>
              {tr('Repeat for ##warning mac') + ' '}
              <b>{tr('"Microphone" ##warning mac')}</b>.
            </li>
            <li>
              {tr('Restart your browser and load this page. ##warning mac')}
            </li>
          </ol>
        )}
        {isWindows && (
          <ol>
            <li>
              {tr('To do this, click the ##warning windows') + ' '}
              <img
                src="/static/screen-windows10-search.png"
                alt={tr('Windows Start button ##warning widowsicon')}
                className={classes.icon}
              />
              {' ' +
                tr(
                  'Windows Start button on your toolbar and search for ##warning windows'
                ) +
                ' '}
              <b>{tr('"Privacy Settings" ##warning windows')}</b>.
            </li>
            <li>
              {tr('Select ##warning windows') + ' '}
              <b>{tr('"Camera" ##warning windows')}</b>
              {' ' +
                tr('and ensure the switch is enabled ##warning windows') +
                ' '}
              {tr('for ##warning windows')}
              <b>
                {tr('"Allow apps to access your microphone" ##warning windows')}
              </b>
              {' ' + tr('and for your browser. ##warning windows')}
            </li>
            <li>
              {tr('Repeat for ##warning windows') + ' '}
              <b>{tr('"Microphone" ##warning windows')}</b>.
            </li>
            <li>
              {tr('Restart your browser and load this page. ##warning windows')}
            </li>
          </ol>
        )}{' '}
        {!isWindows && !isMacOs && (
          <ol>
            <li>
              {tr(
                'Open your System Preferences and ensure that camera and microphone access is enabled for your browser. ##warning other'
              )}
            </li>
            <li>
              {tr('Restart your browser and load this page. ##warning other')}
            </li>
          </ol>
        )}
      </div>
    );
  }
  // Site permissions: iOS Safari
  if (isSafari && isIOS) {
    return (
      <div className={classes.wrap}>
        {tr(
          'Video Chat needs to access your camera and microphone to proceed. ##warning isafari'
        ) + ' '}
        {tr(
          'Please follow these steps to enable those devices in your Site Settings. ##warning isafari'
        )}
        <ol>
          <li>
            {tr('To do this, click the ##warning isafari') + ' '}
            <img
              src="/static/screen-ios-safari-aa.svg"
              alt={tr('Safari site accessibility button ##warning isafariicon')}
              className={classes.icon}
            />{' '}
            {tr(
              'accessibility icon in the address bar of your browser. ##warning isafari'
            )}
            <img
              src="/static/screen-ios-safari-sitesettings.svg"
              alt={tr(
                'site-settings location on iOS Safari ##warning isafariimage'
              )}
              width="100%"
              className={classes.screenshot}
            />
          </li>
          <li>
            {tr('Look for ##warning isafari') + ' '}
            <b>{tr('"Website Settings" ##warning isafari')}</b>
            {tr(', and select ##warning isafari') + ' '}
            <b>{tr('"Allow" ##warning isafari')}</b>
            {' ' + tr('for Camera and Microphone. ##warning isafari')}
          </li>
          <li>
            {tr(
              'Refresh this page after you complete those steps to join the Video Chat. ##warning safari'
            )}
          </li>
        </ol>
        <RefreshButton className={classes.button} />
      </div>
    );
  }

  // Site permissions: Desktop Safari
  if (isSafari && !isIOS) {
    return (
      <div className={classes.wrap}>
        {tr(
          'Video Chat needs to access your camera and microphone to proceed. ##warning dsafari'
        ) +
          ' ' +
          tr(
            'Please follow these steps to enable those devices in your Site Settings. ##warning dsafari'
          )}
        <ol>
          <li>
            {tr('To do this, ##warning dsafari') + ' '}
            <b>{tr('right-click ##warning dsafari')}</b>
            {' ' + tr('the') + ' '}
            <img
              src="/static/screen-desktop-safari-lock.svg"
              alt={tr('desktop Safari lock button ##warning dsafariicon')}
              className={classes.icon}
            />
            {' ' +
              tr(
                'lock icon in the address bar of your browser. ##warning dsafari'
              ) +
              ' '}
            <img
              src="/static/screen-desktop-safari-sitesettings.svg"
              alt={tr(
                'site-settings location on desktop Safari ##warning dsafariimage'
              )}
              width="100%"
              className={classes.screenshot}
            />
          </li>
          <li>
            {tr('Look for ##warning dsafari') + ' '}
            <b>{tr('"Settings For This Website" ##warning dsafari')}</b>
            {tr(', and select ##warning dsafari') + ' '}
            <b>{tr('"Allow" ##warning dsafari')}</b>
            {' ' + tr('for Camera and Microphone. ##warning dsafari')}
          </li>
          <li>
            {tr(
              'Refresh this page after you complete those steps to join the Video Chat. ##warning dsafari'
            )}
          </li>
        </ol>
        <RefreshButton className={classes.button} />
      </div>
    );
  }

  // Site permissions: Android Firefox
  if (isFirefox && isAndroid) {
    return (
      <div className={classes.wrap}>
        {tr(
          'Video Chat needs to access your camera and microphone to proceed. ##warning afirefox'
        ) +
          ' ' +
          tr(
            'Please follow these steps to enable those devices in your Site Settings. ##warning afirefox'
          )}
        <ol>
          <li>
            {tr('To do this, click the ##warning afirefox') + ' '}
            <img
              src="/static/screen-android-firefox-threedots.svg"
              alt={tr(
                'android Firefox threedots button ##warning afirefoxicon'
              )}
              className={classes.threedotsIcon}
            />
            {' ' +
              tr(
                'menu in the address bar of your browser. ##warning afirefox'
              ) +
              ' '}
            <img
              src="/static/screen-android-firefox-menu.svg"
              alt={tr(
                'site-settings location on Android Firefox ##warning afirefoximage'
              )}
              width="100%"
              className={classes.screenshot}
            />
          </li>
          <li>
            {tr('Select ##warning afirefox') + ' '}
            <b>{tr('"Settings" ##warning afirefox')}</b>
            {' ' + tr('then ##warning afirefox') + ' '}
            <b>{tr('"Site Permissions" ##warning afirefox')}</b>.
            {' ' +
              tr(
                'Make sure that your Camera and Microphone are not blocked by Android. ##warning afirefox'
              )}
          </li>
          <li>
            {tr('Select ##warning afirefox') + ' '}
            <b>{tr('"Exceptions" ##warning afirefox')}</b>
            {' ' + tr('and look for ##warning afirefox') + ' '}
            <b>&quot;{window.location.host}&quot;</b>
            {' ' + tr('and select it. ##warning afirefox') + ' '}
            {' ' + tr('Press ##warning afirefox') + ' '}
            <b>{tr('"Clear Permissions" ##warning afirefox')}</b>.
          </li>
          <li>
            {tr(
              'Refresh this page after you complete those steps to join the Video Chat. ##warning afirefox'
            )}
          </li>
        </ol>
        <RefreshButton className={classes.button} />
      </div>
    );
  }
  // Site permissions: Desktop Firefox
  if (isFirefox && !isAndroid) {
    return (
      <div className={classes.wrap}>
        {tr(
          'Video Chat needs to access your camera and microphone to proceed. ##warning dfirefox'
        ) +
          ' ' +
          tr(
            'Please follow these steps to enable those devices in your Site Settings. ##warning dfirefox'
          )}
        <ol>
          <li>
            {tr('To do this, click the ##warning dfirefox') + ' '}
            <img
              src="/static/screen-desktop-firefox-settingicon.svg"
              alt={tr(
                'desktop Firefox settingicon button ##warning dfirefoxicon'
              )}
              className={classes.icon}
            />
            {' ' +
              tr(
                'permissions icon in the address bar of your browser. ##warning dfirefox'
              ) +
              ' '}
            <img
              src="/static/screen-desktop-firefox-sitesettings.svg"
              alt={tr(
                'site-settings location on Desktop Firefox ##warning dfirefoximage'
              )}
              width="100%"
              className={classes.screenshot}
            />
          </li>
          <li>
            {tr('Click the ##warning dfirefox') + ' '}
            <b>&quot;x&quot;</b>
            {' ' +
              tr(
                'to remove the block on Camera and Microphone. ##warning dfirefox'
              )}
          </li>
          <li>
            {tr(
              'Refresh this page after you complete those steps to join the Video Chat. ##warning dfirefox'
            )}
          </li>
        </ol>
        <RefreshButton className={classes.button} />
      </div>
    );
  }

  // Site permissions: Android Chrome
  if (isChrome && isAndroid) {
    return (
      <div className={classes.wrap}>
        {tr(
          'Video Chat needs to access your camera and microphone to proceed. ##warning achrome'
        ) +
          ' ' +
          tr(
            'Please follow these steps to enable those devices in your Site Settings. ##warning achrome'
          )}
        <ol>
          <li>
            {tr('Click the ##warning achrome') + ' '}
            <img
              src="/static/screen-android-chrome-threedots.svg"
              alt={tr('android Chrome threedots button ##warning achromeicon')}
              className={classes.threedotsIcon}
            />
            {' ' +
              tr('icon in the address bar of your browser. ##warning achrome') +
              ' '}
            <img
              src="/static/screen-android-chrome-sitesettings.svg"
              alt={tr(
                'site-settings location on Android Chrome ##warning achromeimage'
              )}
              width="100%"
              className={classes.screenshot}
            />
          </li>
          <li>
            {tr('Select ##warning achrome') + ' '}
            <b>{tr('"Settings" ##warning achrome')}</b>
            {' ' + tr('then ##warning achrome') + ' '}
            <b>{tr('"Site Settings" ##warning achrome')}</b>
            {' ' + tr('then ##warning achrome')}
            <b>{' ' + tr('"Camera/Microphone" ##warning achrome')}</b>.
          </li>
          <li>
            {tr('Remove the ##warning achrome') + ' '}
            <b>&quot;{window.location.host}&quot;</b>
            {' ' +
              tr('from the list of blocked sites. ##warning achrome') +
              ' ' +
              tr(
                'Make sure to turn on your Camera/Microphone in ##warning achrome'
              ) +
              ' '}
            <b>{tr('"Android Settings" ##warning achrome')}</b>.
          </li>
          <li>
            {tr(
              'Refresh this page after you complete those steps to join the Video Chat. ##warning achrome'
            )}
          </li>
        </ol>
        <RefreshButton className={classes.button} />
      </div>
    );
  }

  // Site permissions/fallback: Desktop Chrome
  return (
    <div className={classes.wrap}>
      {tr(
        'Video Chat needs to access your camera and microphone to proceed. ##dchrome'
      ) +
        ' ' +
        tr(
          'Please follow these steps to enable those devices in your Site Settings. ##dchrome'
        )}
      <ol>
        <li>
          {tr('Click the ##warning dchrome') + ' '}
          <img
            src="/static/screen-desktop-chrome-lock.svg"
            alt={tr('desktop Chrome lock button ##warning dchromeicon')}
            className={classes.icon}
          />
          {' ' +
            tr('in the address bar of your browser. ##warning dchrome') +
            ' '}
          <img
            src="/static/screen-desktop-chrome-sitesettings.svg"
            alt={tr(
              'site-settings location on Desktop Chrome ##warning dchromeimage'
            )}
            width="100%"
            className={classes.screenshot}
          />
        </li>
        <li>
          {tr('Select ##warning dchrome') + ' '}
          <b>{tr('"Allow" ##warning dchrome')}</b>
          {' ' + tr('for Camera and Microphone. ##warning dchrome')}
        </li>
        <li>
          {tr(
            'Refresh this page after you complete those steps to join the Video Chat. ##warning dchrome'
          )}
        </li>
      </ol>
      <RefreshButton className={classes.button} />
    </div>
  );
}
