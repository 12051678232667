import React from 'react';

export type UseEffectOnceCallbackType = (
  values: unknown[]
) => void | (() => void);

export default function useEffectOnce(
  callback: UseEffectOnceCallbackType,
  values: unknown[]
) {
  const hasRunRef = React.useRef(false);
  const valueRef = React.useRef(values);
  const hasValue = !!values.find((value: unknown) => value);

  React.useEffect(() => {
    valueRef.current = values;
    // Must suppress this as it is not known what values will be sent-in
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...values]);

  React.useEffect(() => {
    if (!hasRunRef.current && hasValue) {
      hasRunRef.current = true;
      const tearDown = callback(valueRef.current);
      if (tearDown) return tearDown;
    }
  }, [hasValue, callback]);
}
