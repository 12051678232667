import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Button, Badge } from '@material-ui/core';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import tr from '../../../utils/tr';
import { colors } from '../../../theme';

/* istanbul ignore next */
const useStyles = makeStyles(() =>
  createStyles({
    badge: {
      '& .MuiBadge-badge': {
        transform: 'scale(1) translate(-10%, -40%)',
      },
      '& .MuiBadge-dot': {
        height: '14px',
        minWidth: '14px',
        borderRadius: '10px',
      },
    },
    noBadge: {
      '& .MuiBadge-badge': {
        visibility: 'hidden',
      },
    },
    button: {
      backgroundColor: colors.primaryPalette.brandPurple,
      color: 'white',
      boxShadow:
        '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
      borderRadius: '64px',
      margin: '0px',
      '&:hover': {
        background: '#82086d',
      },
      '&.Mui-disabled': {
        color: 'white',
        backgroundColor: colors.grayscale.gray3,
      },
    },
  })
);

export default function ChatButtonOnAppt({
  hasMessage = false,
}: {
  hasMessage?: boolean;
}) {
  const [showBadge, setShowBadge] = React.useState<boolean>(hasMessage);
  const classes = useStyles();
  return (
    <div>
      <Badge
        color="error"
        variant="dot"
        overlap="circle"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className={showBadge ? classes.badge : classes.noBadge}
      >
        <Button
          disabled={!hasMessage}
          variant="contained"
          className={classes.button}
          onClick={() => {
            setShowBadge(false);
          }}
        >
          <ChatOutlinedIcon color="inherit" />
          <span style={{ paddingLeft: '10px' }}>
            {tr('Chat ##new-appt chat-button')}
          </span>
        </Button>
      </Badge>
    </div>
  );
}
