import React from 'react';
import MenuBar from '../MenuBar/MenuBar';
import { Container, createStyles, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import useBrowserIsOk from '../../hooks/useBrowserIsOk/useBrowserIsOk';
import ErrorWrapper from '../ErrorWrapper/ErrorWrapper';
import PreferredBrowserIcons from '../PreferredBrowserIcons/PreferredBrowserIcons';
import CopyField from '../CopyField/CopyField';
import useReportAuthErrors from '../../hooks/useReportAuthErrors/useReportAuthErrors';
import tr from '../../utils/tr';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';
import { reportError } from '@ascension/report-event';

const useStyles = makeStyles(
  createStyles({
    wrapper: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    centerWrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      // Avoid the "Video Chat Beta title
      margin: '50px 0',
    },
    message: {
      fontSize: '1.2em',
    },
    center: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    screenshot: {
      width: '300px',
      maxWidth: '100%',
    },
  })
);

export default function BrowserIsOk({
  children,
}: {
  children: React.ReactNode;
}) {
  const classes = useStyles();
  const { isOk, features } = useBrowserIsOk();
  const [forceSkip, setForceSkip] = React.useState(false);
  const currLink = window.location.href;
  // Include auth reporting here since this component wraps all the others
  useReportAuthErrors();

  React.useEffect(() => {
    // Report specific feature-error to server
    if (!isOk) {
      analyticEvent({
        label: 'Browser incompatible event',
        action: ACTION_CALL_TO_ACTION,
      });
      reportError(`Unsupported browser: ${JSON.stringify(features)}`);
    }
  }, [isOk, features]);

  if (isOk || forceSkip) return <>{children}</>;
  return (
    <div className={classes.wrapper}>
      <MenuBar />
      <div className={classes.centerWrapper}>
        <Container id="browserMessage" className={classes.message}>
          {features.isBadSafari14Dot2 && (
            <ErrorWrapper
              title={tr(
                'You are using a version of Safari that has an audio problem ##browser warning'
              )}
              belowTitle={
                <img
                  className={classes.screenshot}
                  src="/static/ios-update.png"
                  alt={tr('iOS update button in settings ##warning image')}
                />
              }
              subtitle={tr(
                'Open your device settings and select "Software Update" to update to the latest version of your operating system. Then click on the link again. ##warning'
              )}
            >
              <Button
                onClick={() => setForceSkip(true)}
                variant="contained"
                color="primary"
              >
                {tr('Try it anyway ##warning button')}
              </Button>
            </ErrorWrapper>
          )}
          {features.isOldEdge && (
            <ErrorWrapper
              title={tr(
                'You are using a web browser we don’t support. ##warning title'
              )}
              belowTitle={<CopyField str={currLink} />}
              subtitle={tr(
                'You are using a version of Edge that is no longer supported. Please download and install the most current version then try again. ##warning'
              )}
            >
              <PreferredBrowserIcons
                isIOSWithWebRTC={features.isIOSWithWebRTC}
              />
            </ErrorWrapper>
          )}
          {!features.isOldEdge && !features.isBadSafari14Dot2 && (
            <ErrorWrapper
              title={tr(
                'You are using a web browser we don’t support. ##warning title'
              )}
              belowTitle={<CopyField str={currLink} />}
              subtitle={tr(
                'Please copy the link to the Video Chat and paste it in the latest version of one of the following browsers for a better experience: ##warning'
              )}
            >
              <PreferredBrowserIcons
                isIOSWithWebRTC={features.isIOSWithWebRTC}
              />
            </ErrorWrapper>
          )}
        </Container>
      </div>
    </div>
  );
}
