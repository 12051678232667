import { Button, FormControl, TextField } from '@material-ui/core';
import React, { FormEvent } from 'react';
import { makeStyles } from '@material-ui/styles';
import { maxChatInput } from '../../utils/settings';
import clsx from 'clsx';
import theme, { colors } from '../../theme';
import tr from '../../utils/tr';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';
interface ChatInputInterface {
  disabled?: boolean;
  onSend:
    | ((sendText: string) => boolean)
    | ((sendText: string) => Promise<boolean>);
  error?: string;
  onChange?: (text: string) => void;
}

const useStyles = makeStyles({
  wrap: {
    flex: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  input: {
    '@media (max-width: 360px)': {
      width: '70%',
    },
    width: '80%',
    '& .MuiInputBase-input': {
      overflowX: 'unset',
      height: '48px',
      margin: `0 ${theme.spacing(1)} + 'px'`,
    },
    '& .MuiInputBase-root': {
      marginTop: 0,
      padding: 0,
      borderRadius: '4px',
    },
  },
  inputFlush: {
    marginBottom: 0,
  },
  sendButton: {
    backgroundColor: colors.primaryPalette.brandBlue,
    color: 'white',
    marginLeft: theme.spacing(1.5),
  },
});

const defaultOnChange = () => {
  // default empty on change implementation
};

export default function ChatInput({
  disabled = false,
  onSend,
  error = '',
  onChange = defaultOnChange,
}: ChatInputInterface) {
  const classes = useStyles();
  const [text, setText] = React.useState('');
  const inputRef = React.useRef<null | HTMLInputElement>(null);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.preventDefault();
      const val = e.target.value;
      const chopped = val.substring(0, maxChatInput).replace('\n', '');
      setText(chopped);
      onChange(chopped);
    },
    [onChange, setText]
  );

  const onSubmit = React.useCallback(
    async (e?: FormEvent<HTMLElement>) => {
      if (e) {
        e.preventDefault();
      }
      // Don't sent if disabled or if no text
      if (disabled || !text) {
        return;
      }
      let sentSuccessfully = false;
      try {
        sentSuccessfully = await onSend(text);
      } catch (err) {
        // pass
      }
      if (sentSuccessfully) {
        analyticEvent({ label: 'Send Message', action: ACTION_CALL_TO_ACTION });
        setText('');
      }
    },
    [disabled, text, onSend, setText]
  );

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <FormControl disabled={disabled} className={classes.wrap} error={!!error}>
        <TextField
          value={text}
          multiline
          rowsMax={2}
          inputRef={inputRef}
          className={clsx(classes.input, !error && classes.inputFlush)}
          onKeyUp={(e) => e.key.toLowerCase() === 'enter' && onSubmit()}
          onChange={handleChange}
          data-testid="chatField"
          error={!!error}
          helperText={error}
        />
        <Button
          disabled={!text}
          fullWidth
          type="submit"
          variant="outlined"
          className={classes.sendButton}
          color={'primary'}
          style={{
            color: 'white',
            backgroundColor: colors.primaryPalette.brandBlue,
            width: '80px',
          }}
          data-testid="sendButton"
        >
          {tr('Send ##chat button')}
        </Button>
      </FormControl>
    </form>
  );
}
