import useTwilioPreflight, {
  preflightStepOrder,
} from '../../hooks/useTwilioPreflight/useTwilioPreflight';
import React from 'react';
import { Typography } from '@material-ui/core';
import tr from '../../utils/tr';
import CheckIcon from '@material-ui/icons/Check';

export default function TwilioPreflightDebug() {
  const {
    step: preflightStep,
    message: preflightMessage,
  } = useTwilioPreflight();
  const stepFraction =
    (preflightStep?.stepNumber || 0) / preflightStepOrder.length;
  const effectiveStepFraction =
    stepFraction <= 1 && !isNaN(stepFraction) ? stepFraction : 0;

  return (
    <>
      {preflightMessage ? (
        <>
          <Typography>
            <CheckIcon style={{ position: 'relative', top: '5px' }} />
            {tr('Preflight result: ##preroom status')} <br />
          </Typography>
          <pre>{preflightMessage}</pre>
        </>
      ) : (
        <Typography>
          {tr('Checking your connection: ##preroom status')}{' '}
          {Math.round(effectiveStepFraction * 100)}%
        </Typography>
      )}
    </>
  );
}
