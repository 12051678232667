import { JoinedParticipantType } from './socketTypes';
import React from 'react';
import { EventEmitter } from 'events';
import debounce from 'debounce';
import { debounceSocketParticipantsNSeconds } from '../../utils/settings';

// Put here to avoid issues with re-creating the lambda each render, and thus making React freak out
const removeSet = (
  set: JoinedParticipantType[],
  toRemove: JoinedParticipantType[]
) =>
  set.filter(
    (participant) =>
      !toRemove.find((candidate) => candidate.id === participant.id)
  );

export const SocketParticipantEvents = {
  added: 'participant_added',
  dropped: 'participant_dropped',
};

/**
 * This hook is only for internal use for `useSocketContext`. Using it outside of this context
 * will not result in you getting everyone connected to the socket, it'll generate a new, empty
 * list. If you want to subscribe for changes on the socket list, use `useSocketContext`'s
 * subscription methods.
 */
export function useSocketParticipants() {
  const [socketParticipants, setSocketParticipants] = React.useState<
    JoinedParticipantType[] | null
  >(null);
  const emitter = React.useMemo(() => new EventEmitter(), []);

  const updateSocketParticipants = React.useCallback(
    debounce((newParticipants: JoinedParticipantType[]) => {
      setSocketParticipants((oldParticipants) => {
        if (!oldParticipants) {
          return newParticipants;
        }

        // Determine who was added and who was dropped, and exit if no update
        const added = removeSet(newParticipants, oldParticipants);
        const dropped = removeSet(oldParticipants, newParticipants);
        if (added.length === 0 && dropped.length === 0) {
          return oldParticipants;
        }

        // Run callbacks and update value
        added.forEach((addedParticipant) =>
          emitter.emit(SocketParticipantEvents.added, addedParticipant)
        );
        dropped.forEach((droppedParticipant) =>
          emitter.emit(SocketParticipantEvents.dropped, droppedParticipant)
        );
        return newParticipants;
      });
    }, debounceSocketParticipantsNSeconds * 1000),
    [emitter]
  );

  return {
    socketParticipants,
    updateSocketParticipants,
    socketParticipantListener: emitter,
  };
}
