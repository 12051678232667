import React from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/styles';
import tr from '../../utils/tr';

const useStyles = makeStyles(
  createStyles({
    button: {
      width: '100%',
      marginLeft: '0 !important',
      marginRight: '0 !important',
    },
  })
);

export default function RefreshButton({
  className = '',
}: {
  className?: string;
}) {
  const classes = useStyles();
  const handleReload = () => {
    window.location && window.location.reload();
  };
  return (
    <Button
      variant="contained"
      color="primary"
      className={clsx(classes.button, className)}
      onClick={handleReload}
    >
      {tr('Refresh ##warning button')}
    </Button>
  );
}
