import { ISubscription, ReactiveSocket } from 'rsocket-types';
import {
  ConnectionType,
  JoinedParticipantType,
  RoomInfoRequestType,
  SocketLogLevelType,
  WaitingRoomInformationType,
} from './socketTypes';
import React from 'react';
import rSocketWrapper from 'ascension-rsocket-wrapper';
import { ChannelEventsInterface } from 'ascension-rsocket-wrapper/dist/client/types';
import {
  SOCKET_IN_ROOM_ROUTE,
  SOCKET_ROOM_INFO_ROUTE,
} from './socketConstants';
import { useSocketParticipants } from './useSocketParticipants';
import { EventEmitter } from 'events';

type ContextType = {
  participants: JoinedParticipantType[] | null;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  connectionStatus: ConnectionType;
  appointmentId: string | null;
  setAppointmentId: React.Dispatch<React.SetStateAction<string | null>>;
  connectDesired: boolean;
  setConnectDesired: React.Dispatch<React.SetStateAction<boolean>>;
  inRoom: boolean;
  setInRoomDesired: React.Dispatch<React.SetStateAction<boolean>>;
  socketParticipantListener: EventEmitter;
};

const SocketContext = React.createContext<ContextType | null>(null);

interface SocketProviderInterface {
  baseURL: string;
  maxN: number;
  retryAtSeconds: number[];
  fallbackRetryAtSeconds: number;
  identifier: string;
  children: React.ReactNode;
  onLog?: (err: Error | string, logLevel?: SocketLogLevelType) => void;
  logLevel?: SocketLogLevelType;
}

const defaultOnError = () => {
  // default empty defaultOnError implementation
};

/** Cleans the display name of the user before registering it in the socket-service */ 
const cleanName = (name: string) => {
  return name.split("::TAG::")[0]
};

export function SocketProvider({
  baseURL,
  maxN,
  retryAtSeconds,
  fallbackRetryAtSeconds,
  identifier,
  children,
  onLog = defaultOnError,
  logLevel = 'error',
}: SocketProviderInterface) {
  const [name, setName] = React.useState('');
  const [connectionStatus, setConnectionStatus] = React.useState<
    ConnectionType
  >('disconnected');
  const [connectDesired, setConnectDesired] = React.useState(false);
  const [appointmentId, setAppointmentId] = React.useState<string | null>(null);
  const [inRoomDesired, setInRoomDesired] = React.useState(false);
  const cancelSubRef = React.useRef<null | (() => void)>(null);
  const closeSocketRef = React.useRef<null | (() => void)>(null);

  const log = React.useCallback(
    (err: Error | string, level: SocketLogLevelType = 'debug') => {
      if (level === 'error' || level === logLevel) {
        onLog(err, level);
      }
    },
    [logLevel, onLog]
  );

  const {
    socketParticipants: participants,
    updateSocketParticipants,
    socketParticipantListener,
  } = useSocketParticipants();

  const connect = React.useCallback(
    (id: string, thisAppointmentId: string, thisName: string) => {
      const cleanedName = cleanName(thisName);
      const channelState = {
        onSocketConnect: (socket: ReactiveSocket<Buffer, Buffer>) => {
          log('Socket connected');
          closeSocketRef.current = () => socket.close();
        },
        onChannelEstablished: (
          channelSend: (msg: Record<string, unknown>) => void
        ) => {
          log('Channel established');
          const message: RoomInfoRequestType = {
            appointmentId: thisAppointmentId,
            participantName: cleanedName,
            identifier: id,
          };
          channelSend((message as unknown) as Record<string, string>);
        },
        onMessage: (message: Record<string, unknown>) => {
          log(`Message incoming: ${JSON.stringify(message)}`);
          const {
            participants: newParticipants,
          } = message as WaitingRoomInformationType;
          updateSocketParticipants(newParticipants);
        },
        onComplete: () => {
          log('Channel complete');
        },
        onSubscribe: (sub: ISubscription) => {
          log('Subscribed');
          setConnectionStatus('connected');
          cancelSubRef.current = sub.cancel;
        },
      } as ChannelEventsInterface;

      setConnectionStatus('connecting');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      rSocketWrapper.getPersistentChannel({
        baseURL,
        channelState,
        maxN,
        route: inRoomDesired ? SOCKET_IN_ROOM_ROUTE : SOCKET_ROOM_INFO_ROUTE,
        retryAtSeconds,
        fallbackRetryAtSeconds,
        onError: (err, isDisconnect) => {
          if (isDisconnect) {
            setConnectionStatus('disconnected');
          }
          log(
            `${
              isDisconnect ? 'Disconnect error in socket' : 'Error in socket'
            } ${err.toString()}`,
            'error'
          );
        },
      });
    },
    [
      baseURL,
      maxN,
      inRoomDesired,
      retryAtSeconds,
      fallbackRetryAtSeconds,
      log,
      updateSocketParticipants,
    ]
  );

  React.useEffect(() => {
    if (logLevel === 'debug') log(`Appointment id: ${appointmentId || 'null'}`);
    if (identifier && appointmentId && connectDesired) {
      if (logLevel === 'debug') log('Connecting');
      connect(identifier, appointmentId, name);
    }

    return () => {
      if (logLevel === 'debug') log('Disconnecting');
      if (cancelSubRef.current) {
        cancelSubRef.current();
      }
      if (closeSocketRef.current) {
        closeSocketRef.current();
      }
      cancelSubRef.current = null;
      closeSocketRef.current = null;
      setConnectionStatus('disconnected');
    };
  }, [logLevel, name, log, connectDesired, connect, identifier, appointmentId]);

  return (
    <SocketContext.Provider
      value={{
        name,
        setName,
        participants,
        connectionStatus,
        appointmentId,
        setAppointmentId,
        connectDesired,
        setConnectDesired,
        inRoom: !!(
          participants &&
          participants.find((participant) => participant.id === identifier)
        ),
        setInRoomDesired,
        socketParticipantListener,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}

export default function useSocketContext() {
  const socket = React.useContext(SocketContext);
  if (!socket)
    throw new Error('UseSocket can only be called inside SocketProvider');
  return socket;
}
