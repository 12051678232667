import React from 'react';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import usePlaySound from '../../../hooks/usePlaySound/usePlaySound';
import { Button } from '@material-ui/core';
import { playTestSoundForNSeconds } from '../../../utils/settings';
import tr from '../../../utils/tr';

interface SoundButtonInterface {
  style?: React.CSSProperties;
  delayByNSeconds?: number;
}

export default function SoundButton({
  style = {},
  delayByNSeconds = 0,
}: SoundButtonInterface) {
  const [playing, setPlaying] = React.useState(false);
  const [everPlayed, setEverPlayed] = React.useState(false);
  const { audioOutDeviceId } = useVideoContext();
  usePlaySound(playing, audioOutDeviceId);

  const handleToggle = () => {
    setPlaying(!playing);
  };

  React.useEffect(() => {
    if (playing) setEverPlayed(true);

    const timeout = setTimeout(() => {
      if (playing) {
        setPlaying(false);
      }
    }, playTestSoundForNSeconds * 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [playing]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setPlaying(true);
    }, delayByNSeconds * 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [delayByNSeconds]);

  let soundButtonValue = '';
  if (playing) {
    soundButtonValue = tr('Sound is Playing ##media button');
  } else if (everPlayed) {
    soundButtonValue = tr('Replay Sound ##media button');
  } else {
    soundButtonValue = tr('Play Sound ##media button');
  }

  return (
    <Button
      onClick={handleToggle}
      color="primary"
      fullWidth
      size="small"
      style={style}
    >
      {soundButtonValue}
    </Button>
  );
}
