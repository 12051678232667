import React from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AscensionSpinner } from '../AscensionSpinner/AscensionSpinner';
import tr from '../../utils/tr';
import { getTextDirectionOriginIsRight } from '../../utils';

const useStyles = makeStyles({
  waitWrap: {
    fontSize: '1.2em',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    direction: getTextDirectionOriginIsRight() ? 'rtl' : 'ltr',
  },
});

export default function LoadingAnimation() {
  const classes = useStyles();

  return (
    <Container className={classes.waitWrap}>
      <AscensionSpinner />
      <p>{tr('Connecting in just a moment... ##page notice')}</p>
    </Container>
  );
}
