import React from 'react';
import clsx from 'clsx';
import { useMediaQuery, useTheme, Typography, Theme } from '@material-ui/core';
import { createStyles, CSSProperties, makeStyles } from '@material-ui/styles';
import tr from '../../../utils/tr';
import { colors, whitneyFontAndFallbacks } from '../../../theme';
import InvitedParticipant from '../InvitedParticipant/InvitedParticipant';
import { InvitedParticipantType } from '../AppointmentPageTypes';
import { filterParticipantListForDisplay } from '../utils';

export const noListItem = {
  listStyle: 'none',
  margin: 0,
  padding: 0,
};

export const cell: CSSProperties = {
  ...noListItem,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontWeight: 500,
  overflowX: 'hidden',
};

export const overflow: CSSProperties = {
  overflowX: 'hidden',
  wordWrap: 'break-word',
};

export const grayWord: CSSProperties = {
  color: colors.grayscale.gray2,
};

export const smallGrayWord: CSSProperties = {
  ...grayWord,
  fontSize: '0.8em',
};

export const rowList: CSSProperties = {
  ...noListItem,
  display: 'grid',
  gridTemplateColumns: '3fr 3fr 2fr',
  borderBottom: `0.5px ${colors.grayscale.gray12} solid`,
  gridGap: '8px',
  paddingTop: '5px',
  paddingBottom: '5px',
};

export const rowListMDScreen: CSSProperties = {
  gridTemplateColumns: '2fr 4fr 1fr',
};

export const rowListTwoColumns: CSSProperties = {
  gridTemplateColumns: '2fr 1fr',
};

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    invitedParticipantTitle: {
      color: 'black',
      fontSize: '25px',
      fontWeight: 500,
      paddingLeft: theme.spacing(1.5),
      marginBottom: '12px',
      fontFamily: whitneyFontAndFallbacks,
    },
    noList: {
      listStyleType: 'none',
      margin: 0,
      padding: '0 0 0 12px',
    },
    titleRow: {
      ...rowList,
      [theme.breakpoints.down('md')]: {
        ...rowListMDScreen,
      },
    },
    rowListTwoColumns,
    noListItem,
    cell,
    overflow,
    grayWord,
    smallGrayWord,
    noParticipantText: {
      fontSize: '1em',
      fontWeight: 500,
      margin: '10px auto',
      fontFamily: whitneyFontAndFallbacks,
      display: 'flex',
      textAlign: 'center',
      lineHeight: '1.2em',
      ...grayWord,
      [theme.breakpoints.down('sm')]: {
        width: '80%',
        fontSize: '2em',
        margin: '50px auto',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.2em',
        margin: '30px auto',
      },
    },
  })
);

export default function InvitedParticipantList({
  invitedParticipants,
  disabled,
}: {
  invitedParticipants: InvitedParticipantType[];
  disabled: boolean;
}) {
  const classes = useStyles();
  const theme = useTheme();
  const screenIsSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const participantListForDisplay = filterParticipantListForDisplay(
    invitedParticipants
  );
  const hasName = participantListForDisplay.some(
    (participant) =>
      participant.name ||
      (!participant.name && participant.type === 'INTERPRETER')
  );

  return (
    <div>
      <Typography variant="h2" className={classes.invitedParticipantTitle}>
        {tr('Participants invited ##new-appt invite-title')}
      </Typography>
      <ul className={classes.noList} data-testid="invitedParticipantList">
        <li className={classes.noListItem}>
          <ul
            className={clsx(
              classes.titleRow,
              !hasName && classes.rowListTwoColumns
            )}
          >
            {hasName && (
              <li className={classes.cell}>
                <div className={clsx(classes.overflow, classes.smallGrayWord)}>
                  {tr('Name ##new-appt name-title')}
                </div>
              </li>
            )}
            {screenIsSmall && hasName ? (
              ''
            ) : (
              <li className={classes.cell}>
                <div className={clsx(classes.overflow, classes.smallGrayWord)}>
                  {tr('Phone number / email ##new-appt contact-title')}
                </div>
              </li>
            )}
          </ul>
        </li>

        {participantListForDisplay.length > 0 ? (
          participantListForDisplay.map((invitedParticipant) => (
            <InvitedParticipant
              key={invitedParticipant.id}
              invitedParticipant={invitedParticipant}
              showName={hasName}
              screenIsSmall={screenIsSmall}
              disabled={disabled}
            />
          ))
        ) : (
          <Typography
            variant="h2"
            className={classes.noParticipantText}
            data-testid="noParticipantText"
          >
            {tr(
              'No participants invited by phone number or email ##new-appt noInvite-text'
            )}
          </Typography>
        )}
      </ul>
    </div>
  );
}
