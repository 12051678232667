/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { createContext, ReactNode } from 'react';
import { ConnectOptions, LocalDataTrack, Room } from 'twilio-video';
import { Callback, ErrorCallback } from '../../types';
import { SelectedParticipantProvider } from './useSelectedParticipant/useSelectedParticipant';
import useHandleRoomDisconnectionErrors from './useHandleRoomDisconnectionErrors/useHandleRoomDisconnectionErrors';
import useHandleOnDisconnect from './useHandleOnDisconnect/useHandleOnDisconnect';
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed/useHandleTrackPublicationFailed';
import useLocalTracks from './useLocalTracks/useLocalTracks';
import useRoom from './useRoom/useRoom';
import { VideoErrorType } from '../ErrorDialog/VideoErrorType';
import { LocalDataTrackStatusEnum } from './useRoom/DatatrackTypes';
import { LocalAudioTrackType, LocalVideoTrackType } from './VideoProviderTypes';
import {
  DevicesType,
  ErrorType,
  FacingModeType,
} from './useMedia/UseMediaTypes';

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface VideoContextProperties {
  room: Room;
  localTracks: (LocalAudioTrackType | LocalVideoTrackType)[];
  isConnecting: boolean;
  connect: (token: string) => Promise<void>;
  onError: ErrorCallback;
  onDisconnect: Callback;
  mainVideoHeight: string | undefined;
  setMainVideoHeight: (h: string | undefined) => void;
  mainVideoWidth: string | undefined;
  setMainVideoWidth: (w: string | undefined) => void;
  mainVideoRatio: number;
  setMainVideoRatio: (r: number) => void;
  otherError: ErrorType | null;
  clearOtherError: () => void;
  videoError: ErrorType | null;
  clearVideoError: () => void;
  audioError: ErrorType | null;
  clearAudioError: () => void;
  screenShareError: ErrorType | null;
  setScreenShareError: React.Dispatch<React.SetStateAction<ErrorType | null>>;
  localDatatrack: LocalDataTrack | null;
  localDatatrackStatus: LocalDataTrackStatusEnum;
  devices: DevicesType;
  videoInDeviceId: string | null;
  setVideoInDeviceIdDesired: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  videoInDisabled: boolean;
  setVideoInDisableDesired: React.Dispatch<React.SetStateAction<boolean>>;
  videoInFacing: FacingModeType;
  retryVideoAcquire: () => void;
  blurDesired: boolean;
  setBlurDesired: React.Dispatch<React.SetStateAction<boolean>>;
  blurred: boolean;
  isLoadingBlurBG: boolean;
  setAudioInDeviceIdDesired: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  audioInDisabled: boolean;
  setAudioInDisableDesired: React.Dispatch<React.SetStateAction<boolean>>;
  audioInDeviceId: string | null;
  setAudioOutDeviceIdDesired: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  audioOutDeviceId: string | null;
  retryAudioAcquire: () => void;
}

export const VideoContext = createContext<VideoContextProperties>(null!);

interface VideoProviderProps {
  options?: ConnectOptions;
  onError: ErrorCallback;
  onDisconnect?: Callback;
  children: ReactNode;
}

export function VideoProvider({
  options,
  children,
  onError = () => undefined,
  onDisconnect = () => undefined,
}: VideoProviderProps) {
  const onErrorCallback: ErrorCallback = (error: VideoErrorType) => {
    onError(error);
  };
  const {
    devices,
    localTracks,
    videoInDeviceId,
    setVideoInDeviceIdDesired,
    videoInDisabled,
    setVideoInDisableDesired,
    videoInFacing,
    retryVideoAcquire,
    blurDesired,
    setBlurDesired,
    blurred,
    isLoadingBlurBG,
    audioInDeviceId,
    setAudioInDeviceIdDesired,
    audioInDisabled,
    setAudioInDisableDesired,
    audioOutDeviceId,
    setAudioOutDeviceIdDesired,
    retryAudioAcquire,
    otherError,
    clearOtherError,
    videoError,
    clearVideoError,
    audioError,
    clearAudioError,
  } = useLocalTracks();
  const {
    room,
    isConnecting,
    connect,
    localDatatrack,
    localDatatrackStatus,
  } = useRoom(localTracks, onErrorCallback, options);
  const [mainVideoHeight, setMainVideoHeight] = React.useState<
    string | undefined
  >(undefined);
  const [mainVideoWidth, setMainVideoWidth] = React.useState<
    string | undefined
  >(undefined);
  const [mainVideoRatio, setMainVideoRatio] = React.useState<number>(0.5625);
  const [
    screenShareError,
    setScreenShareError,
  ] = React.useState<ErrorType | null>(null);

  // Register onError and onDisconnect callback functions.
  useHandleRoomDisconnectionErrors(room, onError);
  useHandleTrackPublicationFailed(room, onError);
  useHandleOnDisconnect(room, onDisconnect);

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        onDisconnect,
        connect,
        mainVideoHeight,
        setMainVideoHeight,
        mainVideoWidth,
        setMainVideoWidth,
        mainVideoRatio,
        setMainVideoRatio,
        otherError,
        clearOtherError,
        videoError,
        clearVideoError,
        audioError,
        clearAudioError,
        screenShareError,
        setScreenShareError,
        localDatatrack,
        localDatatrackStatus,
        devices,
        videoInDeviceId,
        setVideoInDeviceIdDesired,
        videoInDisabled,
        setVideoInDisableDesired,
        videoInFacing,
        retryVideoAcquire,
        blurDesired,
        setBlurDesired,
        blurred,
        isLoadingBlurBG,
        audioInDeviceId,
        setAudioInDeviceIdDesired,
        audioInDisabled,
        setAudioInDisableDesired,
        setAudioOutDeviceIdDesired,
        audioOutDeviceId,
        retryAudioAcquire,
      }}
    >
      <SelectedParticipantProvider room={room}>
        {children}
      </SelectedParticipantProvider>
    </VideoContext.Provider>
  );
}
