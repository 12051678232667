import React from 'react';
import { useLocalVolumeContext } from '../../hooks/useLocalVolumeContext/useLocalVolumeContext';
import AudioLevelIcon from './AudioLevelIcon';

export default function LocalAudioLevelIndicator({
  size,
  background,
  foreground,
}: {
  size?: number;
  background?: string;
  foreground?: string;
}) {
  const volume = useLocalVolumeContext();

  return (
    <AudioLevelIcon
      volume={volume}
      size={size}
      background={background}
      foreground={foreground}
    />
  );
}
