import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import {
  createStyles,
  ListSubheader,
  makeStyles,
  Select,
} from '@material-ui/core';
import { whitneyFontAndFallbacks } from '../../../theme';
import {
  ClinicianType,
  SelectedClinicianADIDType,
} from '../../../state/appt/apptTypes';
import { ME } from '../../../constants';
import { useHistory } from 'react-router-dom';

/* istanbul ignore next */
const useStyles = makeStyles(() =>
  /* istanbul ignore next */
  createStyles({
    clinicianSelect: {
      margin: '0px',
      minHeight: 'unset',
      '& .MuiSelect-select': {
        margin: '0px',
        padding: '8px 0px 8px 16px',
      },
    },
    categoryHeader: {
      fontWeight: 600,
      fontSize: '10px',
      fontFamily: whitneyFontAndFallbacks,
      fontStyle: 'normal',
      lineHeight: '16px',
      paddingTop: '12px',
      paddingBottom: '12px',
      textTransform: 'uppercase',
    },
    menuItem: {
      fontWeight: 500,
      fontSize: '16px',
      fontFamily: whitneyFontAndFallbacks,
      fontStyle: 'normal',
      lineHeight: '24px',
      paddingTop: '12px',
      paddingBottom: '12px',
    },
    menu: {
      maxHeight: '47%',
      '& .MuiListSubheader-sticky': {
        position: 'static',
      },
      '& .MuiListItem-root.Mui-selected': {
        backgroundColor: '#E8F5FE',
      },
    },
  })
);

export default function AppointmentClinicianSelector({
  cliniciansList,
  selectedClinicianADID,
  setSelectedClinicianADID,
  setChangeClinician,
}: {
  cliniciansList: ClinicianType[];
  selectedClinicianADID: SelectedClinicianADIDType;
  setSelectedClinicianADID(
    selectedClinicianADID: SelectedClinicianADIDType
  ): void;
  setChangeClinician: (changeClinician: boolean) => void;
}) {
  const classes = useStyles();
  const history = useHistory();

  const clinicianItems = cliniciansList.map((clinician: ClinicianType) => {
    return (
      <MenuItem
        key={clinician.adid}
        value={clinician.adid}
        className={classes.menuItem}
      >
        {clinician.name}
      </MenuItem>
    );
  });

  return (
    <Select
      value={selectedClinicianADID || ME}
      onChange={(e) => {
        const value = e.target.value as string;
        const newAdid = value !== ME ? value : null;
        setChangeClinician(selectedClinicianADID !== newAdid);
        setSelectedClinicianADID(newAdid);
        history.push('/appointment');
      }}
      defaultValue=""
      data-testid="cliniciansList"
      className={classes.clinicianSelect}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
        classes: { paper: classes.menu },
      }}
    >
      {[
        <MenuItem key={ME} value={ME} className={classes.menuItem}>
          {ME}
        </MenuItem>,
        <ListSubheader
          key="clinicians"
          onClickCapture={(e) => e.stopPropagation()}
          className={classes.categoryHeader}
        >
          clinicians
        </ListSubheader>,
        clinicianItems,
      ]}
    </Select>
  );
}
