import React from 'react';
import { RemoteParticipant, RemoteTrackPublication } from 'twilio-video';
import useTrackListener from './useTrackListener';
import { TimeSensitiveMessage } from './messageTypes';
import { SentMetadata } from './useSendMessage';

interface UseParticipantsListenerInterface<T> {
  participants: RemoteParticipant[];
  messageType: string;
  setMessages: React.Dispatch<React.SetStateAction<TimeSensitiveMessage<T>[]>>;
  acceptMessage: (message: T & SentMetadata) => void;
  onError: (err: string) => void;
}

export default function useParticipantsListener<T>({
  participants,
  messageType,
  setMessages,
  acceptMessage,
  onError,
}: UseParticipantsListenerInterface<T>) {
  const trackListener = useTrackListener({ messageType, acceptMessage, setMessages, onError });

  React.useEffect(() => {
    const onTrackAddedListener = (track: RemoteTrackPublication) => {
      if (track.kind === 'data') {
        track.on('message', trackListener);
      }
    };
    const onTrackRemovedListener = (track: RemoteTrackPublication) => {
      if (track.kind === 'data') {
        track.off('message', trackListener);
      }
    };
    participants.forEach((participant) => {
      participant.on('trackSubscribed', onTrackAddedListener);
      participant.on('trackUnsubscribed', onTrackRemovedListener);
      participant.tracks.forEach((track) => onTrackAddedListener(track));
    });
    return () => {
      participants.forEach((participant) => {
        participant.off('trackSubscribed', onTrackAddedListener);
        participant.off('trackUnsubscribed', onTrackRemovedListener);
        participant.tracks.forEach((track) => onTrackRemovedListener(track));
      });
    };
  }, [trackListener, participants]);
}
