import useSocketContext from '../useSocketContext/useSocketContext';
import React from 'react';
import { socketIsFailedIfNotConnectedByNSeconds } from '../../utils/settings';
import { analyticEvent } from '@ascension/analytic-event';
import { reportError, reportInfo } from '@ascension/report-event';

export default function useReportSocketStatus() {
  const { connectionStatus, connectDesired } = useSocketContext();

  React.useEffect(() => {
    const timeout = window.setTimeout(() => {
      if (connectDesired && connectionStatus !== 'connected') {
        reportError(
          `Socket connection not established within ${socketIsFailedIfNotConnectedByNSeconds} seconds`
        );
        analyticEvent(
          {
            label: 'Failed to Connect',
            action: 'Socket Connection',
          },
          'error'
        );
      }
    }, socketIsFailedIfNotConnectedByNSeconds * 1000);

    return () => window.clearTimeout(timeout);
  }, [connectionStatus, connectDesired]);

  React.useEffect(() => {
    if (connectionStatus === 'connected') {
      reportInfo(`Socket connected`);
      analyticEvent({
        label: 'Connected',
        action: 'Socket Connection',
      });
    }
  }, [connectionStatus]);
}
