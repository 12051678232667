import ConsoleGrid from '../ConsoleGrid/ConsoleGrid';
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import ApptRoutes from './ApptRoutes';
import AppointmentsList from '../AppointmentsList/AppointmentsList';
import ApptError from '../ApptError/ApptError';
import useListenerForChildMessages from '../../../hooks/useListenerForChildMessages/useListenerForChildMessages';
import { useApptStateContext } from '../../../state/appt/ApptStateProvider';
import LoadingAnimation from '../../LoadingAnimation/LoadingAnimation';
import NotificationPage from '../../NotificationPage/NotificationPage';

export default function AppointmentPage() {
  const { athenaUser } = useApptStateContext();
  const { pathname } = useLocation();
  const appointmentId = matchPath<{ appointmentId: string }>(pathname, {
    path: '/appointment/:appointmentId',
  });
  useListenerForChildMessages();

  if (athenaUser === null)
    return (
      <NotificationPage>
        <LoadingAnimation />
      </NotificationPage>
    );

  return (
    <>
      <ConsoleGrid
        left={
          <AppointmentsList
            appointmentId={appointmentId?.params.appointmentId}
          />
        }
        right={<ApptRoutes />}
        focusOn={appointmentId ? 'right' : 'left'}
      />
      <ApptError />
    </>
  );
}
