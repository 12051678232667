import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const animationAttributes = '1.5s infinite ease-in-out';

const useStyles = makeStyles(() =>
  createStyles({
    wrap: {
      display: 'block',
    },
    teal: {
      animation: `spinner-blink-teal ${animationAttributes}`,
    },
    blue: {
      animation: `spinner-blink-blue ${animationAttributes}`,
    },
    red: {
      animation: `spinner-blink-red ${animationAttributes}`,
    },
  })
);

export const AscensionSpinner = ({ className }: { className?: string }) => {
  const styles = useStyles();
  return (
    <svg
      width="100px"
      height="100px"
      viewBox="0 0 77 70"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(styles.wrap, className)}
      data-testid="spinnerSVG"
    >
      <title>ascension-logo</title>
      <desc>Ascension Loading image</desc>
      <g
        id="ascension-logo"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="asce_mark_fc"
          transform="translate(0.500000, 0.000000)"
          fillRule="nonzero"
        >
          <path
            d="M36.2,58.1 C36.2,58.1 37.3840607,57.1147786 38.5,55.9 C38.5,55.9 40.9,58 40.9,58 C39.4644067,59.4585019 38.5050244,60.2926974 38.5,60.288301 C31.8,65.888301 23.3,69.2 13.9,69.2 C9.2,69.2 4.6,68.4 0.4,66.8 L4.1,64.7 C7.2,65.6 10.5,66.1 13.9,66.1 C22.3,66 30.1,63 36.2,58.1 Z"
            id="Path"
            className={styles.teal}
          ></path>
          <path
            d="M38.5,0.8 C47,7.9 52.5,18.7 52.5,30.6 C52.5,32.7 52.3,34.9 51.9,36.9 C51.3,40.6 50.1,44.2 48.5,47.5 C46.6,51.4 44,55 40.9,58 C40.1,57.4 39.3,56.6 38.5,55.9 C42,52.5 44.8,48.3 46.7,43.7 C47.9,40.8 48.7,37.7 49.1,34.4 C49.2,33.2 49.3,31.9 49.3,30.6 C49.3,20.7785714 45.3451212,11.825287 38.8564328,5.4454298 L38.5,5.1 L38.5,0.8 Z"
            id="Path"
            className={styles.teal}
          ></path>
          <path
            d="M51.9456928,36.9 L51.95,36.918481 L52.3486551,37.0700721 C65.0222688,41.9631287 74.4267442,53.1604651 76.7,66.8 L73,64.6 C70.2,53.5 62.3,44.5 51.9,40.3 L51.2926169,40.0713639 L50.2122367,39.7395062 C47.2040789,38.8242798 45.1666667,38.2777778 44.1,38.1 C42.3,37.8 40.4,37.6 38.5,37.6 C35.6,37.6 32.9,37.9 30.2,38.6 L25.6,40.1 C15,44.2 6.9,53.4 4.1,64.6 L0.4,66.7 C2.67325581,53.0604651 12.1754191,41.8631287 24.6582005,37.0666242 C26.0194002,36.5555414 26.9666667,36.2333333 27.5,36.1 C36.6347653,34.4742175 44.7833296,34.7408842 51.9456928,36.9 Z"
            id="Path"
            className={styles.blue}
          ></path>
          <path
            d="M24.7428879,30.6 C24.7428879,18.7000264 30.1,7.9 38.6,0.8 L38.6,5.1 C31.9,11.5 27.8,20.6 27.8,30.6 C27.8,40.7120622 34.3457627,51.9423729 38.5,56 L40.9,58.1 C47,63 54.8,66 63.2,66 C66.3733333,66 69.4595556,65.5644444 72.377363,64.774637 L73,64.6 L76.7,66.8 C72.5,68.4 67.9,69.2 63.2,69.2 C53.8,69.2 45.2,65.9 38.5,60.3 C37.7,59.6 36.9,58.9 36.1,58.1 C32.376,54.474 28.6509688,47.6232909 25.661374,38.2701354 C25.04905,35.5195385 24.7428879,32.9628267 24.7428879,30.6 Z"
            id="Path"
            className={styles.red}
          ></path>
        </g>
      </g>
    </svg>
  );
};
