import React from 'react';
import SoundButton from './SoundButton';
import NextButton from '../NextButton/NextButton';
import AudioOutputList from '../../MenuBar/DeviceSelector/AudioOutputList/AudioOutputList';
import { delayTestSoundForNSeconds } from '../../../utils/settings';
import getAudioContext from '../../../utils/getAudioContext/getAudioContext';
import { FormHelperText } from '@material-ui/core';
import tr from '../../../utils/tr';

export default function TestAudioOut({ onNext }: { onNext: () => void }) {
  const soundPlaySupported = !!getAudioContext();

  return (
    <>
      {soundPlaySupported ? (
        <SoundButton
          style={{ marginBottom: '1em' }}
          delayByNSeconds={delayTestSoundForNSeconds}
        />
      ) : (
        <>
          <FormHelperText id="audioOutWarningText" error>
            {tr('Your browser does not support playing a sound test ##media')}
          </FormHelperText>
          <br />
        </>
      )}
      <AudioOutputList />
      <NextButton
        onNext={onNext}
        text={
          soundPlaySupported
            ? tr('I Hear the Sound ##media button')
            : tr('Continue ##media-audio button')
        }
      />
    </>
  );
}
