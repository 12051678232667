import React from 'react';
import { MediaErrorType } from '../VideoProvider/useLocalTracks/useLocalTracks';
import {
  isMacOs,
  isAndroid,
  isIOS,
  isSafari,
  isFirefox,
} from 'react-device-detect';
import { createStyles, makeStyles } from '@material-ui/styles';
import tr from '../../utils/tr';

const useStyles = makeStyles(
  createStyles({
    itemsWrap: {
      '& ol': {
        paddingInlineStart: '30px',
      },
      '& li': {
        marginBottom: '1em',
        paddingInlineStart: '10px',
      },
    },
    subtitle: {
      textAlign: 'center',
      color: 'rgba(0,0,0,0.6)',
      padding: '8px',
    },
    content: {
      backgroundColor: '#F5F5F5',
      paddingTop: '10px',
      paddingBottom: '10px',
      marginBottom: '10px',
      borderRadius: '4px',
    },
    contentTitle: {
      paddingInlineStart: '10px',
      fontWeight: 500,
    },
    imgWrap: {
      textAlign: 'center',
    },
    img: {
      width: '222px',
      height: '190px',
    },
  })
);

export default function ScreenShareErrorContent({
  errorType,
}: {
  errorType: MediaErrorType;
}) {
  const classes = useStyles();
  const sitePermissionSubtitle = tr(
    'Ascension needs screenshare access via your browser. Access your browser’s settings to allow screenshare. ##screenshare error'
  );
  const systemPermissionSubtitle = tr(
    'Ascension needs screenshare access via your operating system. ##screenshare error'
  );

  // System Permissions
  if (errorType === 'systemPermission') {
    return (
      <div>
        <div className={classes.subtitle}>{systemPermissionSubtitle}</div>
        <div className={classes.content}>
          {isMacOs && (
            <div>
              <div className={classes.contentTitle} data-testid="contentTitle">
                {tr('Steps for MacOS Catalina and higher: ##screenshare error')}
              </div>
              <div className={classes.itemsWrap}>
                <ol>
                  <li>
                    {tr(
                      'On your Mac, choose Apple menu > System Preferences, then click Security & Privacy. ##screenshare error'
                    )}
                  </li>
                  <li>
                    {tr(
                      'On the Privacy tab, select Screen Recording and locate the name of your browser in the apps list. ##screenshare error mac'
                    )}
                  </li>
                  <li>
                    {tr(
                      'Select the checkbox for your browser. (May require you to close and reopen your browser) ##screenshare error mac'
                    )}
                  </li>
                </ol>
                <div className={classes.imgWrap}>
                  <img
                    src="/static/sharescreen_system_level_MacOS.png"
                    alt={tr(
                      'Instruction for Screenshare on MacOS ##screenshare error'
                    )}
                    className={classes.img}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  // Site permissions: Desktop Safari
  if (isSafari && !isIOS) {
    return (
      <div>
        <div className={classes.subtitle}>{sitePermissionSubtitle}</div>
        <div className={classes.content}>
          <div className={classes.contentTitle} data-testid="contentTitle">
            {tr('Steps for Safari: ##screenshare error dsafari')}
          </div>
          <div className={classes.itemsWrap}>
            <ol>
              <li>
                {tr(
                  'Right click on the URL in the address bar and click “Settings for this website”. ##screenshare error dsafari'
                )}
              </li>
              <li>
                {tr(
                  'Change the screenshare setting from Deny to Ask. ##screenshare error dsafari'
                )}
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  }

  // Site permissions: Desktop Firefox
  if (isFirefox && !isAndroid) {
    return (
      <div>
        <div className={classes.subtitle}>{sitePermissionSubtitle}</div>
        <div className={classes.content}>
          <div className={classes.contentTitle} data-testid="contentTitle">
            {tr('Steps for Firefox: ##screenshare error dfirefox')}
          </div>
          <div className={classes.itemsWrap}>
            <ol>
              <li>
                {tr(
                  'Select the screenshare icon left of the address bar to show permissions. ##screenshare error dfirefox'
                )}
              </li>
              <li>
                {tr(
                  'Clear the “Blocked Temporarily” permission for screensharing. ##screenshare error dfirefox'
                )}
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  }

  // Site permissions: fallback
  return (
    <div>
      <div className={classes.subtitle} data-testid="subTitle">
        {sitePermissionSubtitle}
      </div>
    </div>
  );
}
