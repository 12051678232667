import TestAudioInIcon from './TestAudioInIcon/TestAudioInIcon';
import TestAudioIn from './TestAudioIn/TestAudioIn';
import { Videocam, VolumeUp } from '@material-ui/icons';
import React from 'react';
import TestAudioOut from './TestAudioOut/TestAudioOut';
import TestVideoIn from './TestVideoIn/TestVideoIn';
import tr from '../../utils/tr';

const audioVideoSteps = [
  {
    device: 'microphone',
    title: tr('Check microphone ##media title'),
    instruction: tr('Speak into your microphone ##media subtitle'),
    icon: <TestAudioInIcon />,
    component: TestAudioIn,
  },
  {
    device: 'speaker',
    title: tr('Check speaker ##media title'),
    instruction: tr('Listen for the sound playing ##media subtitle'),
    icon: <VolumeUp />,
    component: TestAudioOut,
  },
  {
    device: 'video',
    title: tr('Check video ##media title'),
    instruction: tr('Select your preferred camera below ##media subtitle'),
    icon: <Videocam />,
    component: TestVideoIn,
  },
];

export default audioVideoSteps;
