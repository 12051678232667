import React, { FormEvent } from 'react';
import { maxFeedbackTextLength } from '../../utils/settings';
import {
  Button,
  createStyles,
  FormControl,
  InputLabel,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import StarIcon from '@material-ui/icons/Star';
import ErrorDialog from '../ErrorDialog/ErrorDialog';
import { useHistory } from 'react-router';
import useSendFeedback from '../../hooks/useSendFeedback/useSendFeedback';
import { userIdentifier } from '../../utils/userIdentifier';
import OverlayTextbox from '../OverlayTextbox/OverlayTextbox';
import useAuthBaseContext from '../../hooks/useAuthBaseContext/useAuthBaseContext';
import {
  ACTION_CALL_FEEDBACK,
  ACTION_PROPERTY_APPOINTMENT_ID,
  ACTION_PROPERTY_STAR_RATING,
} from '../../dynatraceConstants';
import { shortRoomID, getTextDirectionOriginIsRight } from '../../utils';
import tr from '../../utils/tr';
import RatingChips from './RatingChips';
import SpinnerButton from '../SpinnerButton/SpinnerButton';
import useToastContext from '../../hooks/useToastContext/useToastContext';
import useURLRoomToken from '../../hooks/useURLRoomToken/useURLRoomToken';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';
import { colors } from '../../theme';
import useTokenAppointmentDetails from '../../hooks/appt/useTokenAppointmentDetails/useTokenAppointmentDetails';
import { isFirefox } from 'react-device-detect';
import { RouteEnum } from '../../RouteEnum';
import clsx from 'clsx';

export type RatingType = null | number;

const StyledRating = withStyles({
  iconFilled: {
    color: '#FFA83F',
  },
  iconHover: {
    color: colors.grayscale.white,
    '& path': {
      stroke: '#FFA83F',
      strokeWidth: '2',
    },
  },
  label: {
    padding: '8px 8px',
  },
})(Rating);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submitButton: {
      '&.MuiButton-root.MuiButton-contained': {
        width: '50%',
        marginLeft: 'unset',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
    },
    formControl: {
      width: '100%',
      marginTop: '10px',
    },
    commentLabel: {
      position: 'unset',
    },
    title: {
      fontWeight: 300,
    },
    buttonWrap: {
      marginBottom: '20px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    callStatementLabel: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '28px',
      marginTop: `${theme.spacing(2)}px`,
    },
    rightDirection: {
      direction: 'rtl',
    },
    callStatement: {
      fontSize: '14px',
      lineHeight: '1.6em',
      marginBottom: `${theme.spacing(2)}px`,
    },
    starSelect: {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    commentsFooter: {
      fontSize: '10px',
      position: 'relative',
      top: '-5px',
      display: 'flex',
    },
    commentsFrom: {
      lineHeight: 'normal',
    },
    commentsCharsLeft: {
      position: 'relative',
      top: '-5px',
      marginLeft: 'auto',
    },
    paper: {
      backgroundColor: colors.grayscale.gray11,
      padding: theme.custom.boxPaperPadding,
      display: 'flex',
      margin: `${theme.spacing(1)}px ${theme.spacing(0)}px ${theme.spacing(
        2.5
      )}px`,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    rating: {
      margin: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },
    ratingText: {
      marginLeft: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(0),
        marginTop: theme.spacing(1),
      },
    },
    spinnerButtonProgress: {
      width: '50%',
    },
  })
);

export const ratingChips = [
  {
    id: 'audio_problem',
    message: tr('Sound/Audio issues ##feedback chip'),
    from: 0,
    to: 0.8,
  },
  {
    id: 'camera_problem',
    message: tr('Camera issues ##feedback chip'),
    from: 0,
    to: 0.8,
  },
  {
    id: 'link_problem',
    message: tr('Link issues ##feedback chip'),
    from: 0,
    to: 0.8,
  },
  {
    id: 'connectivity_problem',
    message: tr('Connectivity issues ##feedback chip'),
    from: 0,
    to: 0.8,
  },
  {
    id: 'chat_problem',
    message: tr('Chat issues ##feedback chip'),
    from: 0,
    to: 0.8,
  },
];
const toastMessage = tr(
  'Your feedback was sent successfully. ##feedback toast'
);

export default function FeedbackForm({
  feedbackWasSent = false,
}: {
  feedbackWasSent?: boolean;
}) {
  const { isLoggedIn, email } = useAuthBaseContext();
  const URLRoomToken = useURLRoomToken();
  const [rating, setRating] = React.useState<RatingType>(null);
  const [comment, setComment] = React.useState('');
  const [from, setFrom] = React.useState('');
  const [selectedRatingChipIds, setselectedRatingChipIds] = React.useState<
    Array<string>
  >([]);
  const { replace, push } = useHistory();
  const {
    send: sendFeedback,
    status: statusFeedback,
    error: errorFeedback,
  } = useSendFeedback();
  const [showErrorDialog, setShowErrorDialog] = React.useState(true);
  const classes = useStyles();
  const { add: addToast } = useToastContext();
  const appointmentDetails = useTokenAppointmentDetails();

  const feedbackErrorMessage = {
    title: tr('Feedback not sent. ##feedback title'),
    message: tr(
      'Video chat could not connect to the internet. Your feedback was not sent. Please try again and ensure that your connection to the internet is active. ##feedback'
    ),
  };

  const toggleRatingChipSelection = (chipId: string) => {
    const index = selectedRatingChipIds.indexOf(chipId);
    if (index > -1) {
      setselectedRatingChipIds(
        selectedRatingChipIds.filter(
          (selectedRatingChip) => selectedRatingChip !== chipId
        )
      );
    } else {
      setselectedRatingChipIds([...selectedRatingChipIds, chipId]);
    }
  };

  const handleChangeComment = (e: FormEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const textbox = e.target as HTMLTextAreaElement;
    setComment(textbox.value.substring(0, maxFeedbackTextLength));
  };

  const handleRating = (ratingVal: number | null) => {
    setRating(ratingVal);
  };

  const showTags = (ratingVal: number) => {
    return ratingChips.filter(
      (ratingChip) => ratingVal >= ratingChip.from && ratingVal <= ratingChip.to
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    analyticEvent({
      label: 'Feedback sent event',
      action: ACTION_CALL_TO_ACTION,
    });
    switch (rating) {
      case null:
        analyticEvent({
          label: 'No star rating',
          action: ACTION_CALL_TO_ACTION,
        });
        break;
      case 1:
        analyticEvent({
          label: 'Five star rating',
          action: ACTION_CALL_TO_ACTION,
        });
        break;
      default:
        analyticEvent({
          label: 'Below Five star rating',
          action: ACTION_CALL_TO_ACTION,
        });
    }
    try {
      await sendFeedback({
        sessionID: userIdentifier,
        comment,
        rating,
        tags: selectedRatingChipIds,
        appointmentId: appointmentDetails?.appointmentId ?? undefined,
      });
      setShowErrorDialog(true);
    } catch (error) {
      // Do nothing - error already sent
    }
    if (window.dtrum) {
      const actionID = window.dtrum.enterAction(ACTION_CALL_FEEDBACK, 'Custom');
      window.dtrum.addActionProperties(actionID, undefined, undefined, {
        [ACTION_PROPERTY_STAR_RATING]: `${rating}`,
        [ACTION_PROPERTY_APPOINTMENT_ID]: shortRoomID(URLRoomToken || ''),
      });
      window.dtrum.leaveAction(actionID);
    }
  };

  React.useEffect(() => {
    if (isLoggedIn && email) {
      if (email) {
        setFrom(email);
      }
    }
  }, [isLoggedIn, email]);

  React.useEffect(() => {
    if (rating === null || rating === 1) {
      setselectedRatingChipIds([]);
    }
  }, [rating]);

  React.useEffect(() => {
    if (statusFeedback === 'sent') {
      addToast({ message: toastMessage });
      replace(`${RouteEnum.COMPLETE}${URLRoomToken}`);
      if (isLoggedIn) {
        if (window.opener?.name) {
          window.open(
            isFirefox ? window.opener.location : '',
            window.opener.name
          );
          window.opener.postMessage({
            message: toastMessage,
            type: 'toastMessage',
          });
          window.close();
          return;
        }
        push(RouteEnum.APPOINTMENT);
      }
    }
  }, [statusFeedback, addToast, isLoggedIn, replace, URLRoomToken, push]);

  const ratingValue = rating ? rating * 5 : 0;
  const commentsFromValue = from
    ? `${tr('From ##feedback input')}: ${from}`
    : '';
  const commentsCharsLeftValue =
    maxFeedbackTextLength - comment.length < 0
      ? 0
      : maxFeedbackTextLength - comment.length;
  const statusFeedbackButtonLabel =
    statusFeedback === 'sending' ? '' : tr('Send Feedback ##feedback button');

  return (
    <>
      <form action="#" onSubmit={handleSubmit}>
        <div>
          {feedbackWasSent ? (
            <Typography
              data-testid="callStatement"
              className={classes.callStatement}
            >
              {tr(
                'Thank you for using Video Chat today. You may close this browser. ##feedback title'
              )}
            </Typography>
          ) : (
            <>
              <Typography
                data-testid="callStatement-label"
                className={clsx(
                  classes.callStatementLabel,
                  getTextDirectionOriginIsRight() && classes.rightDirection
                )}
              >
                {tr(
                  'How would you rate your call quality? ##callquality title'
                )}
              </Typography>
              <Typography
                className={clsx(
                  classes.starSelect,
                  getTextDirectionOriginIsRight() ? classes.rightDirection : {}
                )}
              >
                <span>{tr('Select a star to rate. ##rating description')}</span>
              </Typography>
              <Paper className={classes.paper}>
                <StyledRating
                  data-testid="ratingFeedback"
                  name="customized-empty"
                  value={ratingValue}
                  size="medium"
                  onChange={(_, value) =>
                    handleRating(value ? value / 5 : value)
                  }
                  emptyIcon={<StarIcon fontSize="inherit" />}
                  className={classes.rating}
                />
                <div className={classes.ratingText}>
                  <Typography
                    data-testid="rating-value"
                    className={clsx(
                      getTextDirectionOriginIsRight()
                        ? classes.rightDirection
                        : {}
                    )}
                  >
                    <span>
                      {ratingValue
                        ? `${ratingValue} ${tr('out of ##rating value')} 5`
                        : tr('No Rating ##rating number')}
                    </span>
                  </Typography>
                </div>
              </Paper>
              {rating && rating <= 0.8 && (
                <RatingChips
                  data-testid="ratingChipsFeedback"
                  title={tr(
                    'Did you experience any of the following issues? ##feedback chips'
                  )}
                  chips={showTags(rating || 0)}
                  selectedChipIds={selectedRatingChipIds}
                  onChipClick={toggleRatingChipSelection}
                />
              )}
              <Typography
                data-testid="callStatement-label"
                className={clsx(
                  classes.callStatementLabel,
                  getTextDirectionOriginIsRight() && classes.rightDirection
                )}
              >
                {tr('Any additional comments or feedback? ##feedback title')}
              </Typography>
              <Typography
                data-testid="callStatement"
                className={clsx(
                  classes.callStatement,
                  getTextDirectionOriginIsRight() && classes.rightDirection
                )}
              >
                <span>
                  {tr(
                    'These details will not be shared with your care clinician, so please contact your clinician with questions regarding your care. ##feedback title'
                  )}
                </span>
              </Typography>
              <FormControl className={classes.formControl}>
                <InputLabel className={classes.commentLabel}>
                  {tr('Comments and Feedback ##feedback label')}
                </InputLabel>
                <OverlayTextbox
                  onChange={handleChangeComment}
                  textValue={comment}
                  onClick={() => {
                    analyticEvent({
                      label: 'Click Custom Feedback Message',
                      action: ACTION_CALL_TO_ACTION,
                    });
                  }}
                />
                <div className={classes.commentsFooter}>
                  <div id="linkOverlay" className={classes.commentsFrom}>
                    {commentsFromValue}
                  </div>
                  <div id="charsLeft" className={classes.commentsCharsLeft}>
                    {commentsCharsLeftValue}
                  </div>
                </div>
              </FormControl>
              <SpinnerButton
                progressStyleClass={classes.spinnerButtonProgress}
                isWaiting={statusFeedback === 'sending'}
              >
                <Button
                  id="callFeedbackSubmitButton"
                  className={classes.submitButton}
                  variant="contained"
                  color="primary"
                  disabled={statusFeedback === 'sending'}
                  onClick={handleSubmit}
                >
                  {statusFeedbackButtonLabel}
                </Button>
              </SpinnerButton>
              {errorFeedback && showErrorDialog && (
                <ErrorDialog
                  dismissError={() => setShowErrorDialog(false)}
                  error={feedbackErrorMessage}
                />
              )}
            </>
          )}
        </div>
      </form>
    </>
  );
}
