import { ApptFetchErrorType, ApptFetchErrorTypeEnum } from '../apptHookTypes';
import {
  VideoErrorType,
  tokenStatusEnum,
} from '../../../components/ErrorDialog/VideoErrorType';
import tr from '../../../utils/tr';
import apptErrors from '../../../components/appt/ApptError/apptErrors';
import { MIN_OPEN_BEFORE_START } from '../../../constants';

const statusMap: { [key: number]: VideoErrorType } = {
  404: {
    title: tr('Invalid link. ##error title'),
    message: tr('Ensure accuracy of link. Contact your Admin. ##error message'),
    tokenStatus: tokenStatusEnum.INVALID,
  },

  409: {
    title: tr('Sorry, this link has expired. ##error title'),
    message: tr('This appointment has been cancelled or is no longer available. If you need to reschedule an appointment, please contact your care provider. ##error message'),
    tokenStatus: tokenStatusEnum.INVALID,
  },

  400: {
    title: tr('Invalid link. ##error title'),
    message: tr(
      'Invalid link. Please try again or contact care provider. ##error message'
    ),
    tokenStatus: tokenStatusEnum.INVALID,
  },

  410: {
    title: tr('Link expired. ##error title'),
    message: tr('Invalid link. Contact care provider. ##error message'),
    tokenStatus: tokenStatusEnum.EXPIRED,
  },

  422: {
    title: tr("Sorry, it's too early to join this appointment. ##error not open title"),
    message: tr(`You can join the appointment up to ${MIN_OPEN_BEFORE_START} minutes before it starts.\nPlease check your appointment start time. ##error not open message`),
    tokenStatus: tokenStatusEnum.FUTURE,
  },

  403: {
    title: tr('Error ##error title'),
    message: tr(
      'This video call link is no longer valid. Contact your care team for another link. ##error message'
    ),
    tokenStatus: tokenStatusEnum.INVALID,
  },
};

export const getTokenErrorByStatus = (code: keyof typeof statusMap) => ({ ...statusMap[code], code });

export default function getTokenErrorFromFetchError(
  fetchError: ApptFetchErrorType
): VideoErrorType {
  // If a 400 error, get those specific messages. Otherwise, use the defaults.
  if (fetchError.status && fetchError.status in statusMap) {
    return getTokenErrorByStatus(fetchError.status);
  }
  const apptError =
    apptErrors[fetchError.about][
      fetchError.type || ApptFetchErrorTypeEnum.GENERAL
    ];
  return {
    message: apptError.message,
    title: apptError.title,
    code: fetchError.status,
    tokenStatus: tokenStatusEnum.INVALID,
  };
}
