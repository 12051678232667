import React, { useCallback, useEffect, useRef, useState } from 'react';
import EventEmitter from 'events';
import { isMobile } from '../../../utils';
import Video, { ConnectOptions, Room } from 'twilio-video';
import { VideoErrorType } from '../../ErrorDialog/VideoErrorType';
import VideocamOff from '@material-ui/icons/VideocamOff';
import { maxRoomListeners } from '../../../utils/settings';
import useLocalDatatrack from './useLocalDatatrack';
import useDatatrackRef from './useDatatrackRef';
import { LocalDataTrackStatusEnum } from './DatatrackTypes';
import {
  LocalAudioTrackType,
  LocalVideoTrackType,
} from '../VideoProviderTypes';
import { analyticEvent } from '@ascension/analytic-event';
import { VideoRoomMonitor } from '@twilio/video-room-monitor';

type WindowTwilio = Window &
  typeof globalThis & { TwilioVideo: {}; twilioRoom: {} };
(window as WindowTwilio).TwilioVideo = Video;

export default function useRoom(
  localTracks: (LocalVideoTrackType | LocalAudioTrackType)[],
  onError: (err: VideoErrorType) => void,
  options?: ConnectOptions
) {
  const [room, setRoom] = useState<Room>(new EventEmitter() as Room);
  const [isConnecting, setIsConnecting] = useState(false);
  const localTracksRef = useRef<(LocalVideoTrackType | LocalAudioTrackType)[]>(
    []
  );
  const datatrackRef = useDatatrackRef();
  const shouldEnable = window?.location?.search?.includes(
    'enableRoomMonitor=true'
  );
  const setRoomMonitor = useCallback(
    (monitoringCandidate: Room) => {
      if (shouldEnable) {
        VideoRoomMonitor.registerVideoRoom(monitoringCandidate);
        VideoRoomMonitor.openMonitor();
      }
    },
    [shouldEnable]
  );

  const {
    localDatatrack,
    localDatatrackStatus,
    setLocalDatatrackStatus,
  } = useLocalDatatrack({ room, datatrackRef });

  useEffect(() => {
    // It can take a moment for Video.connect to connect to a room. During this time, the user may have enabled or disabled their
    // local audio or video tracks. If this happens, we store the localTracks in this ref, so that they are correctly published
    // once the user is connected to the room.
    localTracksRef.current = localTracks;
  }, [localTracks]);

  const connect = useCallback(
    (token) => {
      setIsConnecting(true);
      setLocalDatatrackStatus(LocalDataTrackStatusEnum.CONNECTING);
      return Video.connect(token, {
        ...options,
        tracks: [datatrackRef.current],
      }).then(
        (newRoom: Room) => {
          setRoom(newRoom);
          setRoomMonitor(newRoom);
          const disconnect = () => newRoom.disconnect();
          // Allow more listeners
          newRoom.setMaxListeners(maxRoomListeners);
          newRoom.once('disconnected', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(new EventEmitter() as Room));
            window.removeEventListener('beforeunload', disconnect);

            if (isMobile) {
              window.removeEventListener('pagehide', disconnect);
            }
          });

          (window as WindowTwilio).twilioRoom = newRoom;

          localTracksRef.current.forEach((track) => {
            // Tracks can be supplied as arguments to the Video.connect() function and they will automatically be published.
            // However, tracks must be published manually in order to set the priority on them.
            // All video tracks are published with 'low' priority. This works because the video
            // track that is displayed in the 'ParticipantMaximized' component will have it's priority
            // set to 'high' via track.setPriority()
            newRoom.localParticipant.publishTrack(track.track, {
              priority: track.track.kind === 'video' ? 'low' : 'standard',
            });
          });

          setIsConnecting(false);

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', disconnect);
          }
        },
        (error: VideoErrorType) => {
          let err: VideoErrorType = error;
          if (err.code === 53105) {
            err = {
              ...err,
              title: '',
              message:
                'Looks like the room is full. You will not be able to join the call at the moment.',
              icon: <VideocamOff />,
            };
            analyticEvent(
              { label: 'Room Full', action: 'Joined Call', value: err.code },
              'error'
            );
          } else if (err.code === 53106) {
            analyticEvent(
              {
                label: 'Room Not Found',
                action: 'Joined Call',
                value: err.code,
              },
              'error'
            );
          }
          onError(err);
          setIsConnecting(false);
        }
      );
    },
    [datatrackRef, setLocalDatatrackStatus, options, onError, setRoomMonitor]
  );

  return { room, isConnecting, connect, localDatatrack, localDatatrackStatus };
}
