import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import CallEnd from '@material-ui/icons/CallEnd';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { Popover } from '@material-ui/core';
import { useHistory } from 'react-router';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BarButton from '../BarButton/BarButton';
import tr from '../../../utils/tr';
import { useVideoRoomState } from '../../../hooks/useVideoRoomState/VideoRoomStateProvider';
import {
  QUERY_CALL_DURATION,
  QUERY_CALL_PARTICIPANTS,
} from '../../../constants';
import useURLRoomToken from '../../../hooks/useURLRoomToken/useURLRoomToken';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../../AnalyticsConfiguration/analyticsConstant';
import { RouteEnum } from '../../../RouteEnum';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
      },
    },
    popover: {
      '& .MuiPopover-paper': {
        transform: 'translateY(-8px) !important',
        overflow: 'visible',
        '@media (max-width: 480px)': {
          left: '0px !important',
          bottom: '-8px',
          right: '0px',
          top: 'unset !important',
          maxWidth: 'none',
        },
      },
    },
    popoverGrid: {
      width: '290px',
      margin: '10px',
      '& .MuiButton-root': {
        height: '40px',
        marginTop: '8px',
        marginBottom: '8px',
        '&.MuiButton-contained.MuiButton-containedSecondary': {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        },
      },
      '& .MuiTypography-h1': {
        fontSize: '18px',
        lineHeight: '21px',
        marginBlockEnd: '8px',
        marginBlockStart: '8px',
      },
      '& .MuiTypography-body1': {
        fontSize: '13px',
        lineHeight: '21px',
        padding: theme.spacing(1),
      },
      '@media (max-width: 480px)': {
        width: 'unset',
      },
    },
  })
);

export default function EndCallButton() {
  const classes = useStyles();
  const {
    room,
    setVideoInDisableDesired,
    setAudioInDisableDesired,
  } = useVideoContext();
  const { push } = useHistory();
  const URLRoomToken = useURLRoomToken();
  const { callDurationInSeconds, callParticipants } = useVideoRoomState();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const openConfirmation = !!anchorEl;
  const handlePopoverClose = () => {
    analyticEvent({ label: 'Leave Cancel', action: ACTION_CALL_TO_ACTION });
    setAnchorEl(null);
  };

  const handleLeaveConfirmationClick = () => {
    analyticEvent({ label: 'Leave Call', action: ACTION_CALL_TO_ACTION });
    setAnchorEl(null);
    setVideoInDisableDesired(true);
    setAudioInDisableDesired(true);
    // Allow camera disconnect before shutting
    setTimeout(() => {
      room.disconnect();
      push(
        `${RouteEnum.FEEDBACK}${URLRoomToken}?${QUERY_CALL_DURATION}=${callDurationInSeconds}&${QUERY_CALL_PARTICIPANTS}=${callParticipants}`
      );
    }, 0);
  };

  const handlePopoverOpen = (ev: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(ev.currentTarget);
  };

  return (
    <>
      <BarButton
        onClick={handlePopoverOpen}
        text={tr('End Call ##video button')}
        selected
      >
        <CallEnd />
      </BarButton>
      <Popover
        open={openConfirmation}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className={classes.popover}
      >
        <Grid container className={classes.popoverGrid} direction="column">
          <Typography variant="h1" align="center">
            {tr('End Call ##video button')}
          </Typography>
          <Typography align="center">
            {tr('Are you sure you want to leave this call? ##endcall')}
          </Typography>
          <Grid container direction="column" justify="space-between">
            <Button
              variant="outlined"
              color="default"
              onClick={handlePopoverClose}
            >
              {tr('Cancel ##endcall')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleLeaveConfirmationClick}
            >
              {tr('Leave ##endcall')}
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
