import { createStyles, Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  /* istanbul ignore next */
  createStyles({
    wrap: {
      padding: `${theme.spacing(4)}px ${theme.spacing(6)}px `,
      [theme.breakpoints.down('xs')]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px `,
      },
    },
  })
);

export default function AppointmentPageWrap({
  children,
  className,
  innerClassName,
}: {
  children: React.ReactNode;
  className?: string;
  innerClassName?: string;
}) {
  const classes = useStyles();

  return (
    <Grid container className={clsx(classes.wrap, className)}>
      <Grid item xs={12} className={innerClassName}>
        {children}
      </Grid>
    </Grid>
  );
}
