import React from 'react';
import useAuthBaseContext from '../useAuthBaseContext/useAuthBaseContext';
import {
  ApptActionEnum,
  ApptActionsType,
} from '../../state/appt/ApptActionObjectType';
import { ApptFetchErrorTypeEnum } from '../appt/apptHookTypes';
import {
  ClinicianType,
  SelectedClinicianADIDType,
} from '../../state/appt/apptTypes';
import getCliniciansList from './fetch/getCliniciansList';

// To be used only in ApptStateProvider
// TODO - refactor into Clinician state
export default function useCliniciansList({
  dispatch,
  onApptPage,
}: {
  dispatch: React.Dispatch<ApptActionsType>;
  onApptPage: boolean;
}) {
  const { baseAccessToken } = useAuthBaseContext();
  const [selectedClinicianADID, setSelectedClinicianADID] = React.useState<
    SelectedClinicianADIDType
  >('');
  const [clinicians, setClinicians] = React.useState<ClinicianType[] | null>(
    null
  );
  const [athenaUser, setAthenaUser] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    const run = async () => {
      if (baseAccessToken && !onApptPage) {
        const res = await getCliniciansList(baseAccessToken);
        if (
          res.error &&
          res.error.type !== ApptFetchErrorTypeEnum.SERVER_ERROR
        ) {
          dispatch({
            type: ApptActionEnum.ERROR_ACTION,
            error: res.error,
          });
          return;
        }
        if (
          (res.error &&
            res.error.type === ApptFetchErrorTypeEnum.SERVER_ERROR) ||
          !res.content
        ) {
          res.content = {
            clinicians: [],
            athenaUser: false,
            selfSelected: false,
          };
          delete res.error;
        }
        const sortedCliniciansList: ClinicianType[] = [
          ...res.content.clinicians,
        ].sort((clinician1, clinician2) =>
          clinician1.name.localeCompare(clinician2.name)
        );
        const savedClinicianADID: SelectedClinicianADIDType =
          res.content.selfSelected || res.content.clinicians.length === 0
            ? null
            : sortedCliniciansList.find((clinician) => clinician.selected)
                ?.adid || sortedCliniciansList[0].adid;
        setClinicians(sortedCliniciansList);
        setSelectedClinicianADID(savedClinicianADID);
        setAthenaUser(res.content.athenaUser);
      }
    };
    run();
  }, [
    baseAccessToken,
    dispatch,
    setSelectedClinicianADID,
    setClinicians,
    onApptPage,
  ]);

  return {
    athenaUser,
    clinicians,
    selectedClinicianADID,
    setSelectedClinicianADID,
  };
}
