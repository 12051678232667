import React from 'react';
import { Button, Container } from '@material-ui/core';
import { waitForSignIn } from '../../utils/settings';
import { makeStyles } from '@material-ui/styles';
import ErrorWrapper from '../ErrorWrapper/ErrorWrapper';
import NotificationPage from '../NotificationPage/NotificationPage';
import tr from '../../utils/tr';
import { reportError } from '@ascension/report-event';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';

const useStyles = makeStyles({
  message: {
    fontSize: '1.2em',
  },
  errorButton: {
    width: '50%',
  },
});

export default function SSOLoadingScreen({
  timeout = waitForSignIn,
}: {
  timeout?: number;
}) {
  const classes = useStyles();
  // Go ahead and trigger now if timeout is zero
  const [waitedTooLong, setWaitedTooLong] = React.useState(timeout === 0);

  const handleReload = () => {
    window.location.reload();
  };

  React.useEffect(() => {
    const thisTimeout = setTimeout(() => {
      setWaitedTooLong(true);
      reportError('SSO reached timeout limit');
    }, timeout);
    return () => clearTimeout(thisTimeout);
  }, [timeout]);

  return (
    <NotificationPage>
      {waitedTooLong ? (
        <Container id="waitingMessage" className={classes.message}>
          <ErrorWrapper
            title={tr('Something went wrong. ##page warning')}
            subtitle={tr(
              'An error occurred while loading this page. Please reload the page and try again. ##page warning'
            )}
          >
            <Button
              className={classes.errorButton}
              variant="contained"
              color="primary"
              onClick={handleReload}
            >
              {tr('Reload ##page warning')}
            </Button>
          </ErrorWrapper>
        </Container>
      ) : (
        <LoadingAnimation />
      )}
    </NotificationPage>
  );
}
