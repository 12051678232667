import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { considerApptLateAfterNMinutes } from '../../utils/settings';
import { ApptStateType } from './apptInitialState';
import { ApptParticipantType, ApptType, ApptTypeEnum } from './apptTypes';
import { AppointmentDetailStatusEnum } from '../../hooks/appt/apptHookTypes';

dayjs.extend(utc);

export function getApptDisplayStatus({
  startTime,
  endTime,
  appointmentCancelled = false,
  type = ApptTypeEnum.VC,
}: {
  startTime: Dayjs;
  endTime: Dayjs;
  appointmentCancelled?: boolean;
  type?: ApptTypeEnum;
}): AppointmentDetailStatusEnum {
  const now = dayjs.utc();
  const isAthenaAppt = type === 'ATHENA';
  const waitTime = isAthenaAppt
    ? endTime.diff(startTime, 'minute')
    : considerApptLateAfterNMinutes;
  const littleAgo = now.subtract(waitTime, 'minute');

  if (appointmentCancelled) return AppointmentDetailStatusEnum.CANCELLED;
  if (startTime.isBefore(now) && startTime.isAfter(littleAgo))
    return AppointmentDetailStatusEnum.NOW;
  return AppointmentDetailStatusEnum.SCHEDULED;
}

export function getMinutesTillAppt(time: Dayjs): number {
  const now = dayjs.utc();
  return time.diff(now, 'minute');
}

export function updateApptInState(
  state: ApptStateType,
  appointmentId: string,
  cb: (appt: ApptType) => ApptType
): ApptStateType {
  const thisAppt =
    state.appointments &&
    state.appointments.find((appt) => appt.id === appointmentId);
  if (!thisAppt) return state;
  return {
    ...state,
    appointments: state.appointments && [
      ...state.appointments.filter((appt) => appt.id !== appointmentId),
      cb(thisAppt),
    ],
  };
}

interface ApptIsSameInputInterface {
  participants: ApptParticipantType[];
  inviteMessage?: string;
}

export function getApptIsSame(
  {
    participants: oldParticipants,
    inviteMessage: oldInviteMessage = '',
  }: ApptType | ApptIsSameInputInterface,
  {
    participants: newParticipants,
    inviteMessage: newInviteMessage = '',
  }: ApptIsSameInputInterface
) {
  oldInviteMessage = oldInviteMessage ?? '';
  newInviteMessage = newInviteMessage ?? '';
  return (
    JSON.stringify(oldParticipants) + oldInviteMessage ===
    JSON.stringify(newParticipants) + newInviteMessage
  );
}
