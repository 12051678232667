import React from 'react';
import { Button, createStyles, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { rememberTouchForNSeconds } from '../../../utils/settings';
import CopyIcon from './CopyIcon';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../../AnalyticsConfiguration/analyticsConstant';

interface CopyButtonInterface {
  strToBeCopied: string;
  onCopy?: () => void;
  tooltipText?: string | false;
  textCopy: string;
  textCopied: string;
  disabled?: boolean;
}

const useStyles = makeStyles(
  createStyles({
    input: {
      padding: 0,
      margin: 0,
      border: 0,
      pointerEvents: 'none',
      position: 'absolute',
      top: '-10000px',
    },
    button: {
      height: '40px',
    },
    tooltip: {
      '&.MuiTooltip-tooltip': {
        textAlign: 'center',
        backgroundColor: 'red',
      },
    },
    wrap: {
      display: 'inline-block',
    },
  })
);

export default function CopyButton({
  strToBeCopied,
  onCopy,
  tooltipText,
  textCopy,
  textCopied,
  disabled = false,
}: CopyButtonInterface) {
  const inputRef = React.useRef<null | HTMLInputElement>(null);
  const [isCopied, setIsCopied] = React.useState<boolean>(false);
  const [touched, setTouched] = React.useState(false);
  const classes = useStyles();

  const handleCopyLink = () => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.focus();
    inputRef.current.select();
    inputRef.current.setSelectionRange(0, 99999);
    document.execCommand('copy');
    if (onCopy) onCopy();
    setIsCopied(true);
  };

  const handleTouch = () => {
    setTouched(true);
    setTimeout(() => {
      setTouched(false);
    }, rememberTouchForNSeconds * 1000);
  };

  return (
    <>
      <input
        ref={inputRef}
        className={classes.input}
        value={strToBeCopied}
        readOnly
      />
      <Tooltip
        title={tooltipText || ''}
        open={touched}
        className={classes.tooltip}
      >
        <div
          className={classes.wrap}
          onTouchStart={handleTouch}
          onMouseEnter={() => setTouched(true)}
          onMouseLeave={() => setTouched(false)}
        >
          <Button
            onClick={() => {
              if (!isCopied) {
                analyticEvent({
                  label: 'Copy Appointment Link',
                  action: ACTION_CALL_TO_ACTION,
                });
              }
              handleCopyLink();
            }}
            startIcon={<CopyIcon disabled={disabled} />}
            variant="outlined"
            className={classes.button}
            color="primary"
            disabled={disabled}
          >
            {isCopied ? textCopied : textCopy}
          </Button>
        </div>
      </Tooltip>
    </>
  );
}
