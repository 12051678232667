import React from 'react';
import NotificationPage from '../NotificationPage/NotificationPage';
import { Button, Container } from '@material-ui/core';
import { logOutWaitNSecondsBeforeForwarding } from '../../utils/settings';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import tr from '../../utils/tr';

const useStyles = makeStyles({
  message: {
    fontSize: '1.2em',
  },
});

export default function Logout() {
  const history = useHistory();
  const classes = useStyles();

  React.useEffect(() => {
    let timeout = null as null | NodeJS.Timeout;
    const finishLogout = () => {
      if (window?.dtrum) {
        window.dtrum.endSession();
      }
      timeout = setTimeout(() => {
        history.replace('/');
      }, logOutWaitNSecondsBeforeForwarding * 1000);
    };
    finishLogout();
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [history]);

  return (
    <NotificationPage>
      <Container data-testid="logoutMesssage">
        <Container>
          <p className={classes.message}>
            {tr('You have logged out. ##logout label')}
          </p>
          <Button href="/" variant="contained" color="primary">
            {tr('Return to Log In ##login button')}
          </Button>
        </Container>
      </Container>
    </NotificationPage>
  );
}
