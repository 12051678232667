import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import tr from '../../../utils/tr';
import dayjs from 'dayjs';
import AppointmentDateMenuItem from './AppointmentDateMenuItem';

export default function AppointmentDateSelectMenu({
  menuAnchorEl,
  setPickerAnchorEl,
  setMenuAnchorEl,
  setAppointmentsListDate,
  apptDateSelectVariantIndex,
}: {
  menuAnchorEl: HTMLElement | null;
  setPickerAnchorEl: Function;
  setMenuAnchorEl: Function;
  setAppointmentsListDate: Function;
  apptDateSelectVariantIndex: number;
}) {
  return (
    <Menu
      id="appts-date-selector-menu"
      anchorEl={menuAnchorEl}
      keepMounted
      open={Boolean(menuAnchorEl)}
      onClose={() => setMenuAnchorEl(null)}
    >
      <MenuItem
        onClick={() => {
          setMenuAnchorEl(null);
          setAppointmentsListDate(dayjs.utc());
        }}
      >
        <AppointmentDateMenuItem
          dateVariant={tr('Today ##ApptDateSelector')}
          isSelected={apptDateSelectVariantIndex === 0}
        />
      </MenuItem>
      <MenuItem
        onClick={() => {
          setMenuAnchorEl(null);
          setAppointmentsListDate(dayjs.utc().add(+1, 'day'));
        }}
      >
        <AppointmentDateMenuItem
          dateVariant={tr('Tomorrow ##ApptDateSelector')}
          isSelected={apptDateSelectVariantIndex === 1}
        />
      </MenuItem>
      <MenuItem
        onClick={() => {
          setMenuAnchorEl(null);
          setAppointmentsListDate(dayjs.utc().add(-1, 'day'));
        }}
      >
        <AppointmentDateMenuItem
          dateVariant={tr('Yesterday ##ApptDateSelector')}
          isSelected={apptDateSelectVariantIndex === 2}
        />
      </MenuItem>
      <MenuItem
        onClick={(ev) => {
          setMenuAnchorEl(null);
          setPickerAnchorEl(ev.currentTarget);
        }}
      >
        <AppointmentDateMenuItem
          dateVariant={tr('Custom Date ##ApptDateSelector')}
          isSelected={apptDateSelectVariantIndex === 3}
        />
      </MenuItem>
    </Menu>
  );
}
