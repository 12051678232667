import React from 'react';
import { createStyles, Theme, Typography, Container } from '@material-ui/core';
import LocalVideoPreview from '../LocalVideoPreview/LocalVideoPreview';
import { JoinForm } from '../JoinForm/JoinForm';
import { makeStyles } from '@material-ui/styles';
import tr from '../../utils/tr';
import clsx from 'clsx';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_PAGE_LOAD } from '../../AnalyticsConfiguration/analyticsConstant';
import RightUpperButtons from '../RightUpperButtons/RightUpperButtons';
import useAuthBaseContext from '../../hooks/useAuthBaseContext/useAuthBaseContext';
import { useRouteMatch } from 'react-router-dom';
import { isIOS } from 'react-device-detect';

const mobileGridTemplateRows = !isIOS
  ? {
      gridTemplateRows: 'auto auto auto auto',
    }
  : {
      gridTemplateRows: 'auto auto auto',
    };

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      height: '100%',
    },
    gridWrap: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    grid: {
      display: 'grid',
      gridTemplateRows: 'auto auto',
      gridGap: '10px',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridTemplateAreas: '"apptLink apptLink" "title title" "form video"',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      maxWidth: '1208px',
      position: 'relative',
      margin: '8px',
      [theme.breakpoints.down('xs')]: {
        gridTemplateRows: 'auto auto auto',
        gridTemplateAreas:
          '"apptLink apptLink" "title title" "form form" "video video"',
      },
    },
    standardPadding: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    form: {
      gridArea: 'form',
      alignSelf: 'flex-start',
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    titleWrap: {
      gridArea: 'title',
      alignSelf: 'flex-end',
      maxWidth: 'none',
    },
    title: {
      [theme.breakpoints.down('xs')]: {
        '& .MuiTypography-h1': {
          fontSize: '32px',
        },
      },
      marginBlockEnd: '0px !important',
      marginBlockStart: '0px !important',
      fontWeight: 325,
    },
    video: {
      gridArea: 'video',
      display: 'grid',
      gridTemplateRows: 'auto auto auto auto',
      height: '340px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
        ...mobileGridTemplateRows,
      },
    },
    appointmentButtonWrap: {
      gridArea: 'apptLink',
      display: 'flex',
      marginTop: theme.spacing(2),
    },
    appointmentButton: {
      paddingLeft: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
      },
    },
  })
);

export const PreRoomPage = () => {
  const classes = useStyles();

  const { isLoggedIn } = useAuthBaseContext();
  const routeMatch = useRouteMatch();

  React.useEffect(() => {
    analyticEvent({ label: 'Join Call', action: ACTION_PAGE_LOAD });
  }, []);

  const handleNewVisit = () => {
    if (routeMatch && routeMatch.url && routeMatch.path.includes('/call')) {
      analyticEvent({
        action: 'Call to Action',
        label: 'Appointments Button in PreRoom Page',
      });
    }
  };

  return (
    <>
      <div className={classes.gridWrap}>
        <div className={classes.grid}>
          <div className={classes.appointmentButtonWrap}>
            <RightUpperButtons
              showing={isLoggedIn}
              handleNewVisit={handleNewVisit}
              showArrowIcon={true}
              className={classes.appointmentButton}
            />
          </div>
          <Container
            className={clsx(classes.standardPadding, classes.titleWrap)}
          >
            <Typography variant="h1" className={classes.title}>
              {tr('Ready to join? ##preroom title')}
            </Typography>
          </Container>
          <JoinForm className={clsx(classes.standardPadding, classes.form)} />
          <LocalVideoPreview className={classes.video} />
        </div>
      </div>
    </>
  );
};
