import { DeviceType, FacingModeType, MediaErrorType } from './UseMediaTypes';

export function stopTracks(stream: MediaStream) {
  const tracks = stream.getTracks();
  tracks.forEach((track) => track.stop());
}

export function devicePresent(deviceId: string | null, devices: DeviceType[]) {
  if (!deviceId) return false;
  return !!devices.find(({ id }) => id === deviceId);
}

export function getErrorType(
  isFirefox: boolean,
  error = 'Unknown Error'
): MediaErrorType {
  const errorLower = error.toLowerCase();
  // If not-allowed and "system", it's a system permission
  // Or, if not-found and firefox, it's the Firefox version of system permission
  if (
    (errorLower.includes('notallowed') && errorLower.includes('system')) ||
    (isFirefox && errorLower.includes('notfound'))
  ) {
    return 'systemPermission';
  }
  return errorLower.includes('notallowed')
    ? // If not system, it must be generic "permission"
      'permission'
    : // Fallback to "other" error
      'other';
}

export function getFacingFlexed(facingMode?: string | null) {
  if (!facingMode) return new Set([]);
  return facingMode === 'user'
    ? new Set(['user', 'front'])
    : new Set(['environment', 'back']);
}

export function getStandardFacing(str?: string | null): FacingModeType {
  if (!str) return null;
  const facingModeArr = str.toLowerCase().split(' ');
  return facingModeArr.find((strWord) => getFacingFlexed('user').has(strWord))
    ? 'user'
    : 'environment';
}

export function deviceListHasChanged(
  oldDevices: DeviceType[],
  newDevices: DeviceType[]
) {
  const added = newDevices.filter(
    (newDevice) =>
      !oldDevices.find((oldDevice) => oldDevice.id === newDevice.id)
  );
  const removed = oldDevices.filter(
    (oldDevice) =>
      !newDevices.find((newDevice) => newDevice.id === oldDevice.id)
  );
  // Get a list of name/facing changes
  const changed = newDevices.filter((newDevice) =>
    oldDevices.find(
      (oldDevice) =>
        oldDevice.id === newDevice.id &&
        ((oldDevice.name !== newDevice.name && newDevice.name) ||
          (oldDevice.facing !== newDevice.facing && newDevice.facing))
    )
  );
  return added.length !== 0 || removed.length !== 0 || changed.length !== 0;
}
