import React from 'react';
import { ToastContext } from '../../components/ToastProvider/ToastProvider';

export default function useToastContext() {
  const context = React.useContext(ToastContext);
  if (!context) {
    throw new Error('useToastContext must be used within ToastContextProvider');
  }
  return context;
}
