import React from 'react';
import { FeatureEnum } from './features';

export default function useFeatureTooltipRegister({
  setRegisteredFeatureIds,
}: {
  setRegisteredFeatureIds: React.Dispatch<React.SetStateAction<FeatureEnum[]>>;
}) {
  const register = React.useCallback(
    (featureId: FeatureEnum) => {
      setRegisteredFeatureIds((currFeatureIds) => {
        return [...currFeatureIds, featureId];
      });
    },
    [setRegisteredFeatureIds]
  );

  const deregister = React.useCallback(
    (featureId: FeatureEnum) => {
      setRegisteredFeatureIds((currFeatureIds) =>
        currFeatureIds.filter((currFeatureId) => currFeatureId !== featureId)
      );
    },
    [setRegisteredFeatureIds]
  );

  return {
    register,
    deregister,
  };
}
