import { FeaturesType } from './featureTypes';
import React from 'react';
import { FeatureEnum } from './features';
import { rememberFeatureIsDismissed } from './featureUtils';

export default function useFeatureDismissFeature({
  setFeatures,
}: {
  setFeatures: React.Dispatch<React.SetStateAction<FeaturesType>>;
}) {
  return React.useCallback(
    (featureId: FeatureEnum) => {
      rememberFeatureIsDismissed(featureId);
      setFeatures((currFeatures) => {
        currFeatures = { ...currFeatures };
        currFeatures[featureId] = {
          ...currFeatures[featureId],
          isDismissed: true,
        };
        return currFeatures;
      });
    },
    [setFeatures]
  );
}
