import { Button } from '@material-ui/core';
import React from 'react';

export default function NextButton({
  onNext,
  disabled = false,
  text,
  disabledText,
}: {
  onNext: () => void;
  disabled?: boolean;
  text: string;
  disabledText?: string;
}) {
  return (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      disabled={disabled}
      onClick={
        disabled
          ? () => {
              // default empty on click implementation
            }
          : onNext
      }
      style={{ marginLeft: 0, marginRight: 0 }}
    >
      {disabled && disabledText ? disabledText : text}
    </Button>
  );
}
