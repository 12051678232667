import React from 'react';
import { Snackbar, Button, Theme, createStyles } from '@material-ui/core';
import { toastLastsNSeconds } from '../../utils/settings';
import { getRandomString, getTextDirectionOriginIsRight } from '../../utils';
import { makeStyles } from '@material-ui/styles';
import { colors } from '../../theme';
import tr from '../../utils/tr';
import clsx from 'clsx';

type ToastMessageTypeType = 'default' | 'video';

export type ToastMessageType = {
  id: string;
  message: string;
  buttonTitle: string;
  buttonAction: (() => void) | null;
  type: ToastMessageTypeType;
  hold?: boolean;
  isForApptDirty?: boolean;
};

type ToastMessageContextType = {
  toastMessages: ToastMessageType[];
  add: ({
    message,
    buttonTitle,
    buttonAction,
  }: {
    message: string;
    buttonTitle?: string;
    buttonAction?: () => void;
    type?: ToastMessageTypeType;
    hold?: boolean;
    isForApptDirty?: boolean;
  }) => void;
  remove: (id: string) => void;
};

interface ToastProviderInterface {
  children: React.ReactNode;
}

const defaultToastContext = {
  toastMessages: [] as ToastMessageType[],
  add: () => {
    // default empty add implementation
  },
  remove: () => {
    // default empty remove implementation
  },
} as ToastMessageContextType;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: { color: colors.secondary.blueLight },
    toastOrigin: {
      '& .MuiSnackbar-anchorOriginBottomLeft': {
        bottom: '75px',
      },
    },
    videoToast: {
      '& .MuiSnackbar-root': {
        bottom: '50px',
        color: 'white',
      },
      '& .MuiPaper-root': {
        border: '1px solid rgba(255, 255, 255, 0.5)',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        paddingTop: 0,
        height: '38px',
        fontWeight: '400px',
      },
      '& .MuiSnackbarContent-message': {
        width: '100%',
        textAlign: 'center',
      },
    },
    toastSize: {
      [theme.breakpoints.up('sm')]: {
        '& .MuiSnackbar-anchorOriginBottomLeft': {
          maxWidth: '30%',
        },
      },
    },
    snackbarContent: {
      [theme.breakpoints.up('sm')]: {
        '& .MuiSnackbarContent-root': {
          minWidth: '30%',
        },
      },
    },
    rightDirectionContent: {
      '& .MuiSnackbarContent-message': {
        direction: 'rtl',
      },
    },
  })
);

export const ToastContext = React.createContext(defaultToastContext);

export default function ToastProvider({ children }: ToastProviderInterface) {
  const [toastMessages, setToastMessages] = React.useState<ToastMessageType[]>(
    defaultToastContext.toastMessages
  );
  const [latestToast, setLatestToast] = React.useState<ToastMessageType | null>(
    null
  );
  const classes = useStyles();

  const remove = React.useCallback(
    (id: string) => {
      setToastMessages((currMessages) => {
        return [...currMessages.filter(({ id: thisId }) => thisId !== id)];
      });
    },
    [setToastMessages]
  );

  const add = React.useCallback(
    ({
      message,
      buttonTitle = tr('Dismiss ##dismiss button'),
      buttonAction = null,
      type = 'default',
      hold = false,
      isForApptDirty = false,
    }) => {
      setToastMessages((currMessages) => [
        ...currMessages,
        {
          id: getRandomString(20),
          message,
          buttonTitle,
          buttonAction,
          type,
          hold,
          isForApptDirty,
        },
      ]);
    },
    [setToastMessages]
  );

  React.useEffect(() => {
    const lastToast = toastMessages[0] || null;
    let timeout: NodeJS.Timeout | null = null;
    // Null-out between each to make Snackbar realize this is a new toast
    if (lastToast) {
      timeout = setTimeout(() => {
        setLatestToast(lastToast);
      }, 0);
    }
    setLatestToast(null);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [toastMessages]);
  const handleClose = latestToast
    ? () => remove(latestToast.id)
    : () => {
        // default empty close implementation
      };
  const handleButtonClose = () => {
    handleClose();
    if (latestToast?.buttonAction) {
      latestToast.buttonAction();
    }
  };
  return (
    <ToastContext.Provider
      value={{
        toastMessages,
        add,
        remove,
      }}
    >
      {children}
      <div
        className={clsx(
          latestToast && latestToast.isForApptDirty ? classes.toastOrigin : '',
          latestToast && latestToast.type === 'video' ? classes.videoToast : '',
          classes.toastSize
        )}
      >
        <Snackbar
          className={clsx(
            classes.snackbarContent,
            getTextDirectionOriginIsRight() ? classes.rightDirectionContent : ''
          )}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          data-testid="toastAlert"
          open={!!latestToast}
          autoHideDuration={
            latestToast && latestToast.hold ? null : toastLastsNSeconds * 1000
          }
          onClose={handleClose}
          message={latestToast ? latestToast.message : ''}
          action={
            latestToast &&
            latestToast.type !== 'video' && (
              <Button
                size="small"
                className={classes.closeButton}
                onClick={handleButtonClose}
              >
                {latestToast ? latestToast.buttonTitle : ''}
              </Button>
            )
          }
        />
      </div>
    </ToastContext.Provider>
  );
}
