import { useCallback, useState, useEffect } from 'react';
import fscreen from 'fscreen';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';
export default function useFullScreenToggle() {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(
    !!fscreen.fullscreenElement
  );

  useEffect(() => {
    const onFullScreenChange = () =>
      setIsFullScreen(!!fscreen.fullscreenElement);
    fscreen.addEventListener('fullscreenchange', onFullScreenChange);
    return () => {
      fscreen.removeEventListener('fullscreenchange', onFullScreenChange);
    };
  }, []);

  const toggleFullScreen = useCallback(() => {
    isFullScreen
      ? fscreen.exitFullscreen()
      : fscreen.requestFullscreen(document.documentElement);
    if (!isFullScreen) {
      analyticEvent({
        label: 'Maximize screen',
        action: ACTION_CALL_TO_ACTION,
      });
    }
  }, [isFullScreen]);

  return [isFullScreen, toggleFullScreen] as const;
}
