import { ClinicianType } from '../../../state/appt/apptTypes';
import { fetchWithTimeout, getDefaultHeaders } from '../../../utils';
import {
  ApptFetchErrorAboutEnum,
  ApptFetchErrorTypeEnum,
  ApptFetchResponseType,
} from '../../appt/apptHookTypes';

export default function getCliniciansList(
  baseAccessToken: string
): Promise<
  ApptFetchResponseType<{
    selfSelected: boolean;
    athenaUser: boolean;
    clinicians: ClinicianType[];
  }>
> {
  return new Promise((resolve) => {
    const headers = getDefaultHeaders();
    headers.set('Authorization', `Bearer ${baseAccessToken}`);
    const { SCHEDULING_SERVICE } = window.config;

    fetchWithTimeout(`${SCHEDULING_SERVICE}/clinician`, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (!res.ok) {
          let responseType = ApptFetchErrorTypeEnum.ACCESS_DENIED;
          if (res.status >= 500) {
            responseType = ApptFetchErrorTypeEnum.SERVER_ERROR;
          } else if (res.status >= 400) {
            responseType = ApptFetchErrorTypeEnum.INVALID_REQUEST;
          }
          resolve({
            error: {
              status: res.status,
              message: 'No valid response when requesting clinicians list',
              type: responseType,
              about: ApptFetchErrorAboutEnum.GET_CLINICIAN,
            },
          });
        }
        return {
          response: res.json(),
        };
      })
      .then(({ response }) => {
        response.then((res) => {
          resolve({
            content: {
              clinicians: res.clinicians,
              athenaUser: res.athenaUser,
              selfSelected: res.selfSelected,
            },
          });
        });
      })
      .catch((err) => {
        resolve({
          error: {
            message: 'Failure in requesting clinicians list: ' + err,
            about: ApptFetchErrorAboutEnum.GET_CLINICIAN,
          },
        });
      });
  });
}
