import { createMuiTheme } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import '@ascension/ui-library/dist/fonts/WhitneySSm.css';
import '@ascension/ui-library/dist/fonts/ChronicleSSm.css';
import { theme } from '@ascension/ui-library';
import tr from './utils/tr';
const breakpoints = createBreakpoints({});
export const otherSpacing = [16, 40, 14];
export const transitionSpeed = 200;

export const borderRadius = [3, 4, 6];
export const tooltipZIndex = 1200;
export const barZIndex = tooltipZIndex + 1;
export const overBarZIndex = tooltipZIndex + 2;
export const dialogZIndex = tooltipZIndex + 3;

export const colors = {
  primaryPalette: {
    brandBlue: '#1E69D2',
    brandBlueMed: '#4769b2',
    brandBlueLight: '#a4b2cf',
    brandPurple: '#9A0A81',
  },
  secondary: {
    violet: '#b40f87',
    violetMed: '#c33f9f',
    violetLight: '#c57bbc',
    violetDark: '#810778',
    blueMed: '#4b87db',
    blueLight: '#78a5e4',
    blueLight2: '#E8F5FE',
    green: '#00a791',
    greenMed: '#33b9a7',
    greenLight: '#66cabd',
    greenAccessible: '#0b8670',
    gold: '#ffb400',
    goldMed: '#ffc333',
    goldLight: '#ffd266',
    redLight: '#FDE6EE',
  },
  grayscale: {
    gray1: '#222222',
    gray2: '#6d6d6e',
    gray3: '#999999',
    gray4: '#cbcbcb',
    gray5: '#ebebeb',
    gray6: '#f9f9f9',
    gray7: '#C5C5C5',
    gray8: '#979797',
    gray9: '#D8D8D8',
    gray10: '#5E5E5E',
    gray11: '#F5F5F5',
    gray12: '#e0e0e0',
    gray13: '#E5E5E5',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabled2: 'rgba(0, 0, 0, 0.6)',
    white: '#ffffff',
    black: '#000000',
  },
  notifications: {
    success: '#67ab0e',
    warning: '#f2de00',
    warning2: '#ff7f00',
    error: '#d9021c',
  },
  tertiary: {
    blue: '#E7EBFC',
    blue2: '#3e63d8',
    blue3: '#276FD3',
    blue4: '#3538A1',
    gray: '#999999',
  },
  icon: {
    lightgray: '#626262',
  },
  chatRecipientBkg: {
    blue: '#E8F5FE',
    green: '#E9FBE7',
    orange: '#FFF7E6',
  },
};

export const panelShadow = '0 2px 6px 0 rgba(0,0,0,.2)';

export const chronicleFontAndFallbacks = 'HCo Chronicle SSm';
export const whitneyFontAndFallbacks = 'HCo Whitney SSm';

export default createMuiTheme({
  palette: {
    primary: {
      main: colors.primaryPalette.brandBlue,
    },
    secondary: {
      main: colors.secondary.green,
    },
    success: {
      main: colors.notifications.success,
    },
    warning: {
      main: colors.notifications.warning,
    },
    error: {
      main: colors.notifications.error,
    },
    background: {
      default: colors.grayscale.white,
    },
  },
  overrides: {
    MuiFab: {
      root: {
        backgroundColor: colors.grayscale.white,
        color: colors.tertiary.blue3,
        '&:hover': {
          backgroundColor: colors.grayscale.white,
          color: colors.tertiary.blue3,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: 'rgba(0, 0, 0, 0.87)',
        },
        '&.MuiInputLabel-formControl': {
          transform: 'translate(0, 1.5px) scale(0.75)',
          'transform-origin': 'top left',
          letterSpacing: '0.75px',
          // Adjust width back to original
          width: '133%',
        },
        '& $asterisk': {
          visibility: 'hidden',
          width: 0,
          'padding-left': '4px',
          '&:before': {
            content: `"(${tr('Required ##input label')})"`,
            visibility: 'visible',
          },
        },
        color: colors.grayscale.gray2,
        fontFamily: whitneyFontAndFallbacks,
        fontWeight: 600,
      },
    },
    MuiInput: {
      root: {
        '&$underline::before': {
          content: 'unset',
        },
        '&$underline::after': {
          content: 'unset',
        },
        '& input': {
          letterSpacing: '0.5px',
        },
        '& + .MuiFormHelperText-root': {
          marginTop: '-12px',
          marginBottom: '16px',
          opacity: 0.7,
          fontSize: '15px',
          lineHeight: '20px',
          fontWeight: 300,
        },
        border: '1px solid ' + colors.grayscale.gray2,
        margin: '1em 0',
        borderRadius: `${borderRadius[0]}px`,
        minHeight: '46px',
        boxSizing: 'content-box',
        backgroundColor: colors.grayscale.white,
      },
      input: {
        marginLeft: '16px',
        marginRight: '16px',
      },
    },
    MuiButton: {
      root: {
        '&$contained': {
          margin: '8px',
          boxShadow: 'unset',
          '&:first-child': {
            marginLeft: '0',
          },
          '&:last-child': {
            marginRight: '0',
            marginLeft: '0',
          },
          '&:hover': {
            boxShadow: 'unset',
          },
        },
        '&$outlined': {
          backgroundColor: colors.grayscale.white,
        },
        '& $label': {
          position: 'relative',
        },
        fontFamily: whitneyFontAndFallbacks,
        fontWeight: 600,
        fontSize: '14px',
        letterSpacing: '0.75px',
        height: '48px',
      },
    },
    MuiInputBase: {
      root: {
        '&$adornedEnd': {
          paddingRight: '0',
        },
        '& + .MuiFormHelperText-root': {
          fontFamily: whitneyFontAndFallbacks,
        },
        fontFamily: whitneyFontAndFallbacks,
      },
    },
    MuiInputAdornment: {
      root: {
        height: '48px',
        marginTop: '-4px',
        marginBottom: '-4px',
        maxHeight: 'unset',
        '& .MuiButton-root': {
          height: '100%',
          borderRadius: `0 ${borderRadius[0]}px ${borderRadius[0]}px 0`,
        },
      },
    },
    MuiSnackbar: {
      root: {
        '&$anchorOriginTopCenter': {
          transform: 'translate(-50%, 80px)',
        },
      },
    },
    MuiPaper: {
      root: {
        boxShadow: 'unset',
        '&$elevation1': {
          boxShadow: 'unset',
        },
        '&$elevation4': {
          boxShadow: panelShadow,
        },
      },
    },
    MuiChip: {
      root: {
        '&$colorPrimary': {
          backgroundColor: colors.primaryPalette.brandBlue,
          '&.MuiChip-outlined': {
            backgroundColor: colors.grayscale.gray6,
            borderColor: colors.tertiary.blue2,
          },
        },
        '& $deleteIcon': {
          width: '16px',
        },
        '& $deleteIconColorSecondary, & $deleteIconColorPrimary': {
          color: colors.grayscale.white,
        },
        borderRadius: `${borderRadius[1]}px`,
        marginRight: '5px',
        marginBottom: '5px',
        height: '34px',
      },
    },
    MuiDialog: {
      root: {
        [breakpoints.down('xs')]: {
          '& .MuiDialog-container': {
            alignItems: 'flex-end',
            transition: `all ${transitionSpeed}ms ease-in-out !important`,
            transform: 'translate(0, 0)',
          },
          '&.outOfView .MuiDialog-container': {
            alignItems: 'flex-end',
            transform: 'translate(0, 300px)',
          },
          '& .MuiPaper-root': {
            margin: 0,
            borderRadius: `${borderRadius[2]}px ${borderRadius[2]}px 0 0`,
            width: '100%',
            maxWidth: 'unset',
          },
        },
      },
    },
    MuiTooltip: {
      popper: {
        zIndex: tooltipZIndex,
      },
    },
  },
  typography: {
    h1: {
      fontFamily: chronicleFontAndFallbacks,
      fontSize: '32px',
      fontWeight: 'bold',
      lineHeight: '36px',
      letterSpacing: '0',
      marginBlockStart: '16px',
      marginBlockEnd: '24px',
    },
    h2: {
      fontFamily: chronicleFontAndFallbacks,
      fontSize: '21px',
      lineHeight: '28px',
      color: colors.tertiary.blue4,
    },
    h3: {
      fontFamily: whitneyFontAndFallbacks,
      fontSize: '18px',
      lineHeight: '32px',
      fontWeight: 'bold',
      marginBlockStart: '8px',
    },
    h4: {
      fontFamily: whitneyFontAndFallbacks,
      fontSize: '17px',
      lineHeight: '20px',
      fontWeight: 500,
    },
    h5: {
      fontFamily: whitneyFontAndFallbacks,
      fontSize: '17px',
      lineHeight: '20px',
      fontWeight: 300,
    },
    h6: {
      fontFamily: whitneyFontAndFallbacks,
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    body2: {
      fontFamily: whitneyFontAndFallbacks,
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 300,
    },
    body1: {
      fontFamily: whitneyFontAndFallbacks,
    },
  },
  custom: {
    videoPanelTransitionSpeed: 250,
    boxPaperPadding: theme.spacing(2),
    subscriptStyle: {
      fontSize: '0.9em',
      color: colors.grayscale.gray10,
    },
  },
});
