import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ApptType, IncomingApptType } from '../../../state/appt/apptTypes';
import { TIME_FORMAT_FOR_API_POST } from '../../../constants';

dayjs.extend(utc);

export function convertIncomingApptType(appts: IncomingApptType[]) {
  return appts.map((appt) => {
    appt.startTime = dayjs.utc(appt.startTime);
    appt.endTime = dayjs.utc(appt.endTime);
    appt.participants = appt.participants.map((part) => {
      return {
        ...part,
        statuses: part.statuses.map((status) => ({
          ...status,
          timeStamp: dayjs.utc(status.timeStamp),
        })),
      };
    });
    return appt as ApptType;
  });
}

export function convertToOutgoingApptType(appts: ApptType[]) {
  return appts.map((appt) => {
    return {
      ...appt,
      startTime: appt.startTime.format(TIME_FORMAT_FOR_API_POST),
      endTime: appt.endTime.format(TIME_FORMAT_FOR_API_POST),
      participants: appt.participants.map((part) => {
        return {
          ...part,
          statuses: part.statuses.map((status) => ({
            ...status,
            timeStamp: status.timeStamp.format(TIME_FORMAT_FOR_API_POST),
          })),
        };
      }),
    } as IncomingApptType;
  });
}
