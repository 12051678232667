import {
  ApptFetchResponseType,
  ApptFetchErrorAboutEnum,
  ApptFetchErrorTypeEnum,
} from '../../appt/apptHookTypes';
import { fetchWithTimeout, getDefaultHeaders } from '../../../utils';

export type GetInterpreterProps = {
  baseAccessToken: string;
  meetingId: string;
  languageId: number;
  participantToken: string;
  ministry: string;
};

export default function getInterpreter({
  baseAccessToken,
  meetingId,
  languageId,
  participantToken,
  ministry,
}: GetInterpreterProps): Promise<ApptFetchResponseType<string>> {
  return new Promise((resolve) => {
    const { INTERPRETER_SERVICE, INTERPRETER_API_KEY } = window.config;
    const headers = getDefaultHeaders();

    headers.set('Authorization', `Bearer ${baseAccessToken}`);
    headers.set('apiKey', INTERPRETER_API_KEY);
    headers.set('Content-Type', 'application/json');

    fetchWithTimeout(`${INTERPRETER_SERVICE}/interpreter`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        interpreterLink: participantToken,
        meetingId,
        languageId,
        ministry,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          let responseType = ApptFetchErrorTypeEnum.ACCESS_DENIED;
          if (res.status >= 500) {
            responseType = ApptFetchErrorTypeEnum.SERVER_ERROR;
          } else if (res.status >= 400) {
            responseType = ApptFetchErrorTypeEnum.INVALID_REQUEST;
          }
          resolve({
            error: {
              status: res.status,
              message: 'No valid response when requesting interpreter',
              type: responseType,
              about: ApptFetchErrorAboutEnum.GET_INTERPRETER,
            },
          });
        }
        return res.json();
      })
      .then((requestId) => {
        resolve({
          content: requestId,
        });
      })
      .catch((err) => {
        resolve({
          error: {
            message: 'Failure in requesting interpreter: ' + err,
            about: ApptFetchErrorAboutEnum.GET_INTERPRETER,
          },
        });
      });
  });
}
