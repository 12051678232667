import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { colors } from '../../theme';
import tr from '../../utils/tr';

const useStyles = makeStyles({
  wrap: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  icon: {
    width: '72px',
  },
  title: {
    fontSize: '20px',
  },
  subTitle: {
    marginTop: '8px',
    opacity: 0.5,
  },
});

export default function NoChatMessages() {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      <div>
        <svg
          width="73"
          height="72"
          viewBox="0 0 73 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.6 12H58.4C61.205 12 63.5 14.295 63.5 17.1V47.7C63.5 50.505 61.205 52.8 58.4 52.8H22.7L12.5 63L12.5255 17.1C12.5255 14.295 14.795 12 17.6 12ZM53.3 29.85H22.7V32.4H53.3V29.85ZM43.1 40.05H22.7V37.5H43.1V40.05ZM22.7 24.75H53.3V22.2H22.7V24.75Z"
            fill="black"
          />
          <mask
            id="mask0"
            maskUnits="userSpaceOnUse"
            x="12"
            y="12"
            width="52"
            height="51"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.6 12H58.4C61.205 12 63.5 14.295 63.5 17.1V47.7C63.5 50.505 61.205 52.8 58.4 52.8H22.7L12.5 63L12.5255 17.1C12.5255 14.295 14.795 12 17.6 12ZM53.3 29.85H22.7V32.4H53.3V29.85ZM43.1 40.05H22.7V37.5H43.1V40.05ZM22.7 24.75H53.3V22.2H22.7V24.75Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0)">
            <rect
              x="0.5"
              width="72"
              height="72"
              fill={colors.primaryPalette.brandBlue}
            />
          </g>
        </svg>
        <div className={classes.title}>{tr('No Messages Yet. ##chat')}</div>
        <div className={classes.subTitle}>
          {tr('Start a conversation ##chat')}
        </div>
      </div>
    </div>
  );
}
