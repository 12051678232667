import React, { ReactNode } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { colors } from '../../../theme';

type StyleType = { disabled: boolean };

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      // Remove button styling
      background: 'none',
      border: 'none',
      padding: 0,
      font: 'inherit',
      cursor: ({ disabled }: StyleType) => (disabled ? 'initial' : 'pointer'),
      pointerEvents: ({ disabled }: StyleType) => (disabled ? 'none' : 'auto'),
      outline: 'inherit',
      // Style
      width: '100%',
      height: '100%',
      paddingTop: '6px',
      paddingBottom: '4px',
      textAlign: 'center',
      color: theme.palette.primary.main,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderBottom: `1px transparent solid`,
      '& svg': {
        width: '100%',
        fontSize: '2em',
      },
      '&:hover': {
        backgroundColor: colors.grayscale.gray11,
      },
    },
    iconWrap: {
      flex: 0,
      display: 'flex',
      minHeight: '2em',
    },
    description: {
      flex: 0,
      width: '100%',
      textAlign: 'center',
      fontSize: '0.9em',
      fontWeight: 300,
    },
  })
);

interface BarButtonInterface {
  text?: string;
  disabled?: boolean;
  highlighted?: boolean;
  selected?: boolean;
  children: ReactNode;
  onClick?: (ev: React.MouseEvent<HTMLElement>) => void;
}

export default function BarButton({
  text = '',
  disabled = false,
  highlighted = false,
  selected = false,
  children,
  onClick = () => {
    // default empty on click implementation
  },
}: BarButtonInterface) {
  const styles = useStyles({ disabled });
  // Highlight over-rides normal color, and disabled over-rides both
  let pickedColor = colors.primaryPalette.brandBlue;
  if (selected) pickedColor = colors.notifications.error;
  if (disabled) pickedColor = colors.grayscale.gray1;
  return (
    <button
      className={clsx(styles.wrap)}
      style={{
        borderBottom: `1px ${highlighted ? pickedColor : 'transparent'} solid`,
        color: pickedColor,
      }}
      disabled={disabled}
      onClick={
        disabled
          ? () => {
              // default empty on click implementation
            }
          : onClick
      }
    >
      <div className={styles.iconWrap}>{children}</div>
      <div className={styles.description}>{text}</div>
    </button>
  );
}
