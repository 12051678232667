import isPlainObject from 'is-plain-object';
import {
  fetchTimeout,
  hostEmailAddressDomains,
  hostUserRoles,
  hostUserScopes,
  forceRedirects,
  twilioRoomIDRegex,
  blockedPhoneNumberPrefixes,
} from './settings';
import tr from './tr';
import { VideoErrorType } from '../components/ErrorDialog/VideoErrorType';
import dayjs, { Dayjs } from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { ConnectOptions } from 'twilio-video';
import { RouteEnum } from '../RouteEnum';
dayjs.extend(isBetweenPlugin);

export function getTextDirectionOriginIsRight() {
  return window.trLanguage?.directionOrigin === 'right';
}

export const isMobile = (() => {
  if (
    typeof navigator === 'undefined' ||
    typeof navigator.userAgent !== 'string'
  ) {
    return false;
  }
  return navigator.userAgent.includes('Mobile');
})();

export function getRandomNumber() {
  return Math.round(Math.random() * 9999999);
}

export function getRandomString(length: number) {
  let s = '';
  do {
    s += Math.random().toString(36).substring(2);
  } while (s.length < length);
  s = s.substring(0, length - 1);
  return s;
}

export function fetchWithTimeout(
  url: string,
  options: object,
  timeout = fetchTimeout
): Promise<Response> {
  return Promise.race([
    fetch(url, options),
    new Promise<Response>((_, reject) => {
      setTimeout(() => {
        reject(`Fetch timeout for ${url}`);
      }, timeout);
    }),
  ]);
}

// This function ensures that the user has granted the browser permission to use audio and video
// devices. If permission has not been granted, it will cause the browser to ask for permission
// for audio and video at the same time (as opposed to separate requests).
export function ensureMediaPermissions() {
  return navigator.mediaDevices
    .enumerateDevices()
    .then((devices) =>
      devices.every((device) => !(device.deviceId && device.label))
    )
    .then((shouldAskForMediaPermissions) => {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then((mediaStream) =>
            mediaStream.getTracks().forEach((track) => track.stop())
          );
      }
    });
}

// Recursively removes any object keys with a value of undefined
export function removeUndefineds(obj: {
  [name: string]: unknown;
}): { [name: string]: unknown } | unknown {
  if (!isPlainObject(obj)) {
    return obj;
  }

  const target: { [name: string]: unknown } = {};

  for (const key in obj) {
    const val = obj[key] as { [name: string]: unknown };
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val);
    }
  }

  return target;
}

// Get first 10 characters of room ID - for logging
export const getShortRoomID = (path?: string) => {
  path = path || window?.location?.pathname;
  if (!path) return '';
  const matches = twilioRoomIDRegex.exec(path);
  return (matches && matches[1]) || '';
};

export const getUserIdentifier = () =>
  '-U' + Math.round(Math.random() * 1000000);

export const getDefaultHeaders = () => {
  const headers = new Headers();
  // Only set the API Key if it's present (allows for disabling in localhost)
  if (window.config.APIGEE_API_KEY) {
    headers.set('apikey', window.config.APIGEE_API_KEY);
  }
  return headers;
};

export const isAllowedToBeHost = (
  email: string,
  userRoles: string[],
  userScopes: string[]
) => {
  const indexOfAt = email.indexOf('@');
  const domainAllowed = hostEmailAddressDomains.includes(
    email.substring(indexOfAt).toLowerCase()
  );
  const roleAllowed = !!hostUserRoles.find((allowRole) =>
    userRoles.includes(allowRole)
  );
  const scopeAllowed = !!hostUserScopes.find((allowScope) =>
    userScopes.includes(allowScope)
  );
  return domainAllowed || roleAllowed || scopeAllowed;
};

export type InviteTypeType = 'email' | 'phone' | '';

export type ValidateEmailOrPhoneType = {
  isValid: boolean;
  type: InviteTypeType;
  cleaned: string;
  formatted: string;
};

export const isEmail = (str: string) => str.includes('@');

export const cleanPhone = (str: string) => str.replace(/[-/\s.(),]/g, '');

export const isPhone = (str: string) =>
  !isEmail(str) && /^\d+$/.test(cleanPhone(str));

export const formatInviteTarget = (str: string) => {
  if (isPhone(str)) {
    return `${str.substring(0, 3)}-${str.substring(3, 6)}-${str.substring(6)}`;
  }
  return str;
};

export const checkIsBlockedNumber = (cleanedString: string) => {
  return blockedPhoneNumberPrefixes.includes(
    parseInt(cleanedString.substring(0, 3))
  );
};

export const addSpacesAfterPeriods = (str: string) => {
  const rtn = str
    .split('.')
    .map((sentence) => sentence.trim())
    .join('. ');

  // Keep the original ending (to make backspacing work)
  if (str.endsWith(' ') && !rtn.endsWith(' ')) return rtn + ' ';
  if (!str.endsWith(' ') && rtn.endsWith(' ')) return rtn.trim();

  return rtn;
};

interface MakeFullInvitationInterface {
  domain: string;
  token?: string;
  invitationText: string;
}

export interface MakeFullInvitationFunctionInterface {
  (props: MakeFullInvitationInterface): string;
}

export const redirectIfWrongHost = () => {
  const currentHref = window.location.href;
  const forceRedirect = forceRedirects.find(({ from }) =>
    currentHref.startsWith(from)
  );
  if (forceRedirect && !currentHref.startsWith(forceRedirect.to)) {
    window.location.href = currentHref.replace(
      forceRedirect.from,
      forceRedirect.to
    );
  }
};

export const makeInvitationLink = ({
  domain,
  token = 'token_not_available',
}: Omit<MakeFullInvitationInterface, 'invitationText'>) => {
  return `${domain}${RouteEnum.CALL}${token}`;
};

export const makeFullInvitation = ({
  domain,
  token = 'token_not_available',
  invitationText,
}: MakeFullInvitationInterface) => {
  return `${invitationText} ${makeInvitationLink({ domain, token })}`;
};
export const shortRoomID = (str: string) => str.substring(0, 8);

export const getLogIdentifier = ({
  email,
  givenName,
  familyName,
}: {
  email?: string | null;
  givenName?: string | null;
  familyName?: string | null;
}) => {
  if (!email && !familyName && !givenName) {
    return 'no name';
  }
  return email || `${familyName},${givenName}`;
};

export const breakTimeFromSeconds = (rawSeconds: number) => {
  const hours = Math.floor(rawSeconds / 3600);
  const minutes = Math.floor((rawSeconds - hours * 3600) / 60);
  const seconds = rawSeconds - (hours * 3600 + minutes * 60);
  return { hours, minutes, seconds };
};

export const getTimestampFromSeconds = (rawSeconds: number) => {
  const { hours, minutes, seconds } = breakTimeFromSeconds(rawSeconds);
  let rtn = `${hours ? hours + ':' : ''}${minutes}:${seconds}`;
  // Add missing zeros
  rtn = rtn
    .split(':')
    .map((num, i) => (i > 0 && parseInt(num) <= 9 ? '0' + num : num))
    .join(':');
  return rtn;
};

export const getLongTimestampFromSeconds = (rawSeconds: number) => {
  const { hours, minutes, seconds } = breakTimeFromSeconds(rawSeconds);
  return `${hours ? hours + tr('h ##hour label') + ' ' : ''}${
    minutes ? minutes + tr('m ##minute label') + ' ' : ''
  }${seconds + tr('s ##second label')}`;
};

type DurationType = { start: Dayjs; stop: Dayjs | null };
type DurationNoNullType = { start: Dayjs; stop: Dayjs };

export const getOverlappedDurations = (durations: DurationType[]) => {
  const now = dayjs();
  // Set all null stop times to now
  const noNullDurations = durations.map(({ start, stop }) => {
    if (!stop) {
      stop = now;
    }
    return {
      start,
      stop,
    };
  }) as DurationNoNullType[];

  return noNullDurations.reduce((cummDurations, currDuration) => {
    const overlappingI = cummDurations.findIndex((cummDuration) => {
      return (
        currDuration.start.isBetween(
          cummDuration.start,
          cummDuration.stop,
          null,
          '[]'
        ) ||
        currDuration.stop.isBetween(
          cummDuration.start,
          cummDuration.stop,
          null,
          '[]'
        )
      );
    });
    // If overlapping an existing duration, expand that duration to encompass both
    if (overlappingI > -1) {
      const overlapping = cummDurations[overlappingI];
      cummDurations[overlappingI].start = overlapping.start.isBefore(
        currDuration.start
      )
        ? overlapping.start
        : currDuration.start;
      cummDurations[overlappingI].stop = overlapping.stop.isAfter(
        currDuration.stop
      )
        ? overlapping.stop
        : currDuration.stop;
    } else {
      cummDurations.push(currDuration);
    }

    return cummDurations;
  }, [] as DurationNoNullType[]);
};

export const getTotalDuration = (durations: DurationType[]) => {
  const overlappedDurations = getOverlappedDurations(durations);
  return overlappedDurations.reduce((cumm, curr) => {
    const duration = curr.stop.diff(curr.start, 'second');
    return cumm + duration;
  }, 0);
};

export const windowIsHidden = () => {
  return document.visibilityState === 'hidden';
};

export const getDefaultConnectionOptions = () => {
  // See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
  // for available connection options.
  return {
    bandwidthProfile: {
      video: {
        mode: 'collaboration',
        dominantSpeakerPriority: 'standard',
        clientTrackSwitchOffControl: 'auto',
        contentPreferencesMode: 'auto',
      },
    },
    dominantSpeaker: true,
    networkQuality: { local: 3, remote: 3 },

    // The SDK will use VP8 simulcast, and will enable/disable simulcast layers dynamically
    preferredVideoCodecs: 'auto',
  } as ConnectOptions;
};

interface ErrorRequest {
  message?: string;
  type: string;
  stackTrace?: string;
  identifier?: string;
  userAgent?: string;
}

export const sendError = (url: string, req: ErrorRequest) => {
  if (!req.message) req.message = 'No message was provided for error';
  const headers = getDefaultHeaders();
  headers.set('Content-Type', 'application/json');
  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(req),
  });
};

export const isExpiredOrInvalidOrFutureTokenError = (
  tokenError: VideoErrorType
) => {
  return ['expired', 'invalid', 'future'].includes(
    tokenError?.tokenStatus || ''
  );
};

export const getLocalizationTimeString = (time: Dayjs) => {
  return time.toDate().toLocaleString(window.trLanguage?.prefix || 'en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
  });
};
