import React, { useRef } from 'react';
import useAuthBaseContext from '../useAuthBaseContext/useAuthBaseContext';
import { useVideoRoomState } from '../useVideoRoomState/VideoRoomStateProvider';
import useShowInRightPanel from '../useShowInRightPanel/useShowInRightPanel';
import getInterpreter from './fetch/getInterpreter';
import scheduleInterpreter from './fetch/scheduleInterpreter';
import {
  ApptActionEnum,
  ApptActionsType,
} from '../../state/appt/ApptActionObjectType';
import {
  ApptFetchErrorTypeEnum,
  ApptFetchErrorAboutEnum,
} from '../appt/apptHookTypes';
import { v4 as uuidv4 } from 'uuid';
import { RightPanelKeyEnum } from '../../interfaces/RightPanelContentTypes';

export type UseInterpreterProps = {
  dispatch: React.Dispatch<ApptActionsType>;
  language: string;
  languageId: number;
  setIsRequestInterpreterError: React.Dispatch<React.SetStateAction<boolean>>;
  createReservation: React.Dispatch<string>;
};

export default function useInterpreter() {
  const { baseAccessToken } = useAuthBaseContext();
  const {
    roomCurrentAppt,
    getAppointmentDetail,
    setInterpreterLanguage,
  } = useVideoRoomState();
  const { showWhich } = useShowInRightPanel();
  // TODO: use this participantToken and requestId in POST /schedule/interpreter
  const participantTokenRef = useRef<string>();
  const interpreterRequestId = useRef<string | undefined>();

  const inviteWithCurrentDetails = React.useCallback(
    async ({
      dispatch,
      language,
      languageId,
      setIsRequestInterpreterError,
      createReservation,
    }: UseInterpreterProps) => {
      // Verify the request can be sent
      if (!baseAccessToken) {
        dispatch({
          type: ApptActionEnum.ERROR_ACTION,
          error: {
            message: 'Unable to get interpreter due to missing access token',
            type: ApptFetchErrorTypeEnum.ACCESS_DENIED,
            about: ApptFetchErrorAboutEnum.GET_INTERPRETER,
          },
        });
        return;
      }
      if (!roomCurrentAppt?.id) {
        dispatch({
          type: ApptActionEnum.ERROR_ACTION,
          error: {
            message: 'Unable to get interpreter due to no appointment id',
            type: ApptFetchErrorTypeEnum.INVALID_REQUEST,
            about: ApptFetchErrorAboutEnum.GET_INTERPRETER,
          },
        });
        return;
      }

      // If token not (re)created, generate one
      if (!participantTokenRef.current) {
        participantTokenRef.current = uuidv4();
      }

      // Send request if no request sent yet
      if (interpreterRequestId.current === undefined) {
        const res = await getInterpreter({
          baseAccessToken,
          meetingId: roomCurrentAppt.id,
          languageId,
          participantToken: participantTokenRef.current,
          ministry: roomCurrentAppt.ministry,
        });
        if (res.error) {
          setIsRequestInterpreterError(true);
          return;
        }
        interpreterRequestId.current = res.content;
      }

      // Schedule interpreter
      const scheduleRes = await scheduleInterpreter({
        baseAccessToken,
        appointmentId: roomCurrentAppt.id,
        appointmentToken: participantTokenRef.current,
        requestId: interpreterRequestId.current,
        languageId,
      });
      if (scheduleRes.error) {
        setIsRequestInterpreterError(true);
        return;
      }
      setInterpreterLanguage(language);
      // Interpreter is scheduled sucessfully, redirect to invite
      showWhich(RightPanelKeyEnum.INVITE);
      getAppointmentDetail(); //update participants
      createReservation('interpreter');

      // Clear unneeded refs (if need to invite a new interpreter)
      participantTokenRef.current = undefined;
      interpreterRequestId.current = undefined;
    },
    [
      baseAccessToken,
      roomCurrentAppt,
      setInterpreterLanguage,
      getAppointmentDetail,
      showWhich,
    ]
  );
  const inviteInterpreter = React.useCallback(
    (props: UseInterpreterProps) => {
      participantTokenRef.current = undefined;
      interpreterRequestId.current = undefined;
      return inviteWithCurrentDetails(props);
    },
    [inviteWithCurrentDetails]
  );

  const interpretersCount = React.useMemo(() => {
    return (
      roomCurrentAppt?.participants?.filter(
        (participant) =>
          participant.type === 'INTERPRETER' && !participant.cancelled
      ).length || 0
    );
  }, [roomCurrentAppt]);

  return {
    inviteInterpreter,
    retryLastInvite: inviteWithCurrentDetails,
    interpretersCount,
  };
}
