import React from 'react';
import useOnInterval from '../useOnInterval/useOnInterval';
import { PropertyMap } from '../../interfaces/DynatraceTypes';
import { ACTION_TELEMETRY } from '../../dynatraceConstants';

export type DynatraceOnPoll = () => Promise<{} | PropertyMap<number>>;

export default function useDynatraceTelemetry(
  onPoll: DynatraceOnPoll,
  everyNSeconds?: number
) {
  const send = React.useCallback(async () => {
    if (window?.dtrum) {
      const dataToSend = await onPoll();
      // Ignore an empty object
      if (Object.keys(dataToSend).length > 0) {
        const actionID = window.dtrum.enterAction(ACTION_TELEMETRY, 'Custom');
        window.dtrum.addActionProperties(actionID, dataToSend);
        window.dtrum.leaveAction(actionID);
      }
    }
  }, [onPoll]);
  useOnInterval(send, true, everyNSeconds);
}
