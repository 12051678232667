import React from 'react';
import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import RefreshApptListButton from './RefreshApptListButton';
import { apptListPadding } from './AppointmentsList';
import tr from '../../../utils/tr';

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  /* istanbul ignore next */
  createStyles({
    apptHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: '10px',
      paddingBottom: '10px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      ...apptListPadding(theme),
    },
    apptsSettings: {
      marginLeft: 'auto',
    },
  })
);

export default function AppointmentsListHeader({
  appointmentsCount,
}: {
  appointmentsCount: number;
}) {
  const classes = useStyles();
  return (
    <div className={classes.apptHeader}>
      <div>
        {appointmentsCount}{' '}
        {appointmentsCount === 1
          ? tr('appointment ##appt-list appointment count')
          : tr('appointments ##appt-list appointment count')}{' '}
        {tr('today ##appt-list label')}
      </div>
      <RefreshApptListButton />
    </div>
  );
}
