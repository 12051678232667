import React from 'react';
import { ReservationType } from './reservationTypes';
import useAddReservation from './useAddReservations';
import useClaimReservation from './useClaimReservation';
import useCancelReservation from './useCancelReservation';
import useReceiveReservations from './useReceiveReservations';

export default () => {
  const [reservations, setReservations] = React.useState<ReservationType[]>([]);

  const createReservation = useAddReservation({
    reservations,
    setReservations,
  });
  const claimReservation = useClaimReservation({
    setReservations,
  });
  const setOwnReservation = useReceiveReservations({
    reservations,
    setReservations,
  });
  const cancelReservationForAll = useCancelReservation({ claimReservation });

  return {
    reservations,
    createReservation,
    claimReservation,
    cancelReservationForAll,
    setOwnReservation,
  };
};
