import React from 'react';
import ResponsiveDialog from '../ResponsiveDialog/ResponsiveDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles } from '@material-ui/styles';
import NotificationErrorContent from '../NotificationErrorContent/NotificationErrorContent';
import { Container } from '@material-ui/core';
import theme from '../../theme';
import tr from '../../utils/tr';

const useStyles = makeStyles(
  createStyles({
    title: {
      textAlign: 'center',
    },
  })
);

export default function NotificationError(props: { onDismiss: () => void }) {
  const classes = useStyles();

  return (
    <ResponsiveDialog open={true} onDismiss={props.onDismiss}>
      <Container
        style={{
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
        }}
      >
        <DialogTitle className={classes.title}>
          {tr('Access is required. ##warning title')}
        </DialogTitle>
        <NotificationErrorContent />
      </Container>
    </ResponsiveDialog>
  );
}
