import React from 'react';
import { DatatrackMessageSendType } from './messageTypes';
import { useCurrentParticipants } from './useMessageUtils';

const ACK_SUFFIX = '_ACK';

export const ackMessageType = (messageType: string) =>
  `${messageType}${ACK_SUFFIX}`;

export const isAck = (messageType: string) => messageType.endsWith(ACK_SUFFIX);

export const useMessageConversions = <T>(messageType: string) => {
  const { currentParticipantId } = useCurrentParticipants();
  const ackType = ackMessageType(messageType);

  const convertMessage = React.useCallback(
    (
      id: string,
      message: unknown,
      options: Record<string, unknown>
    ): DatatrackMessageSendType => ({
      id,
      type: messageType,
      sentById: currentParticipantId,
      content: JSON.stringify(message),
      ...options,
    }),
    [currentParticipantId, messageType]
  );

  const convertAck = React.useCallback(
    (id: string): DatatrackMessageSendType => ({
      id,
      type: ackType,
      sentById: currentParticipantId,
      content: '',
    }),
    [ackType, currentParticipantId]
  );

  return { convertMessage, convertAck };
};
