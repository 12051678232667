import React from 'react';
import useAuthRedirects from '../../hooks/useAuthBaseContext/useAuthRedirects';
import SSOLoadingScreen from '../SSOLoadingScreen/SSOLoadingScreen';
import SSONotHostEmail from '../SSONotHostEmail/SSONotHostEmail';
import { Route, RouteProps } from 'react-router-dom';
import useIsAllowedToBeHost from '../../hooks/useIsAllowedToBeHost/useIsAllowedToBeHost';
import useAuthBaseContext from '../../hooks/useAuthBaseContext/useAuthBaseContext';

export default function SecureRoute({ children, ...rest }: RouteProps) {
  useAuthRedirects();
  const { isLoggedIn, baseAccessToken } = useAuthBaseContext();
  const isAllowedToBeHost = useIsAllowedToBeHost();
  if (!isLoggedIn || !baseAccessToken) return <SSOLoadingScreen />;
  if (!isAllowedToBeHost) return <SSONotHostEmail />;
  return <Route {...rest} render={() => <>{children}</>} />;
}
