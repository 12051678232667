import {
  ApptFetchResponseType,
  ApptFetchErrorAboutEnum,
  ApptFetchErrorTypeEnum,
} from '../../appt/apptHookTypes';
import { fetchWithTimeout, getDefaultHeaders } from '../../../utils';

export default function scheduleInterpreter({
  baseAccessToken,
  appointmentId,
  appointmentToken,
  languageId,
  requestId,
}: {
  baseAccessToken: string;
  appointmentId: string;
  appointmentToken: string;
  languageId: number;
  requestId?: string;
}): Promise<ApptFetchResponseType<string>> {
  return new Promise((resolve) => {
    const headers = getDefaultHeaders();
    headers.set('Authorization', `Bearer ${baseAccessToken}`);
    headers.set('Content-Type', 'application/json');
    const { SCHEDULING_SERVICE } = window.config;
    fetchWithTimeout(`${SCHEDULING_SERVICE}/interpreter`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        appointmentId,
        appointmentToken,
        requestId,
        languageId,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          let responseType = ApptFetchErrorTypeEnum.ACCESS_DENIED;
          if (res.status >= 500) {
            responseType = ApptFetchErrorTypeEnum.SERVER_ERROR;
          } else if (res.status >= 400) {
            responseType = ApptFetchErrorTypeEnum.INVALID_REQUEST;
          }
          resolve({
            error: {
              status: res.status,
              message: 'No valid response when scheduling interpreter',
              type: responseType,
              about: ApptFetchErrorAboutEnum.GET_INTERPRETER,
            },
          });
        }
        return res.json();
      })
      .then((successful) => {
        resolve({
          content: successful,
        });
      })
      .catch((err) => {
        resolve({
          error: {
            message: 'Failure in scheduling interpreter: ' + err,
            about: ApptFetchErrorAboutEnum.GET_INTERPRETER,
          },
        });
      });
  });
}
