import React from 'react';
import { TimeSensitiveMessage } from './messageTypes';
import { useMessageUtils, useCurrentParticipants } from './useMessageUtils';
import { v4 as uuidv4 } from 'uuid';
import { useMessageConversions } from './useMessageConversions';
import useVideoContext from '../useVideoContext/useVideoContext';
import dayjs from 'dayjs';
import { RemoteParticipant } from 'twilio-video';

interface UseSendMessageInterface<T> {
  participants: RemoteParticipant[];
  messages: TimeSensitiveMessage<T>[];
  setMessages: React.Dispatch<React.SetStateAction<TimeSensitiveMessage<T>[]>>;
  messageType: string;
  onError: (err: string) => void;
}

type Options = {
  resendOf?: string;
  sentToId?: string;
};

export type SentMetadata = {
  id: string;
  sentByMe: boolean;
  sentById: string;
  pastConfirmedDeadline: boolean;
  sentToId?: string;
};

export default function useSendMessage<T>({
  participants,
  messages,
  setMessages,
  messageType,
  onError,
}: UseSendMessageInterface<T>) {
  const { addMessage } = useMessageUtils({
    messageType,
    setMessages,
    onError,
  });
  const { localDatatrack } = useVideoContext();
  const { currentParticipantId } = useCurrentParticipants();
  const { convertMessage } = useMessageConversions(messageType);

  // Handle actually sending a message over the channel
  const doSendMessage = React.useCallback(
    (message: T, options?: Options): (T & SentMetadata) | undefined => {
      if (!localDatatrack) {
        onError('Unable to send message; no local data track');
        return undefined;
      }

      // Send message
      const id = uuidv4();
      const sentAt = dayjs.utc().format();
      const datatrackMessage = JSON.stringify(
        convertMessage(id, message, options ?? {})
      );
      try {
        localDatatrack.send(datatrackMessage);
      } catch (e) {
        onError('There was an error sending message: ' + e);
        return undefined;
      }

      // Add to message list
      if (!options?.resendOf) {
        addMessage({
          id,
          message,
          sentByMe: true,
          sentById: currentParticipantId,
          sentAt,
          sentToCount: options?.sentToId ? 1 : participants.length,
          sentToId: options?.sentToId,
        });
      }
      return {
        ...message,
        id,
        sentByMe: true,
        sentById: currentParticipantId,
        pastConfirmedDeadline: false,
        ...options,
      };
    },
    [
      addMessage,
      convertMessage,
      currentParticipantId,
      localDatatrack,
      onError,
      participants.length,
    ]
  );

  // Resend an already-sent message by ID, identifying the packet as a duplicate
  const resendMessage = React.useCallback(
    (id: string) => {
      const messageToResend = messages.find((message) => message.id === id);
      if (messageToResend) {
        return doSendMessage(messageToResend.message, {
          resendOf: messageToResend.resendOf || id,
          sentToId: messageToResend.sentToId,
        });
      }
      return undefined;
    },
    [doSendMessage, messages]
  );

  // Send a new message
  const sendMessage = React.useCallback(
    (message: T, toId?: string): (T & SentMetadata) | undefined =>
      doSendMessage(message, { sentToId: toId }),
    [doSendMessage]
  );

  return { sendMessage, resendMessage };
}
