import EditParticipantComponent, {
  inviteErrorTypeMap,
  ParticipantInputInterface,
  SharedParticipantInputInterface,
  EditParticipantInterface,
} from './EditParticipant';
import React from 'react';
import { validateEmailOrPhone } from '../utils';
import { checkIsBlockedNumber } from '../../../utils';
import tr from '../../../utils/tr';

interface UseEditParticipantResponseInterface
  extends ParticipantInputInterface {
  EditParticipant: (props: EditParticipantInterface) => JSX.Element;
}

export default function useEditParticipant({
  id,
  hideName,
  allowNoName,
}: SharedParticipantInputInterface): UseEditParticipantResponseInterface {
  const [name, setName] = React.useState('');
  const [content, setContent] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const [contentError, setContentError] = React.useState('');
  const nameRef = React.useRef<HTMLInputElement>();
  const contentRef = React.useRef<HTMLInputElement>();

  const checkIsValid = React.useCallback(() => {
    const validation = validateEmailOrPhone(content);
    const isBlockedNumber = checkIsBlockedNumber(validation.cleaned);
    if (!allowNoName && !hideName && !name) {
      if (nameRef.current) nameRef.current.focus();
      setNameError(
        tr('A name is required for each participant ##invite warning')
      );
      return false;
    }
    if (!validation.isValid || isBlockedNumber || !validation.type) {
      if (contentRef.current) contentRef.current.focus();
      setContentError(inviteErrorTypeMap[validation.type]);
      return false;
    }
    return true;
  }, [content, name, allowNoName, hideName]);

  return {
    name,
    setName,
    nameError,
    setNameError,
    contentError,
    setContentError,
    nameRef,
    contentRef,
    content,
    setContent,
    checkIsValid,
    hideName,
    allowNoName,
    id,
    EditParticipant: EditParticipantComponent,
  };
}
