import React from 'react';
import useSendReliably from '../useSendReliably';
import {
  ReservationPayload,
  ReservationType,
  SetReservations,
} from './reservationTypes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const RESERVATION_CLEAR_TIME = 300;

type AddReservationProps = {
  reservations: ReservationType[];
  setReservations: SetReservations;
};

export default ({ reservations, setReservations }: AddReservationProps) => {
  // Hook to add a reservation to the list
  const addReservation = React.useCallback(
    (tag: string, sentAt: string, id: string) => {
      setReservations((oldReservations) => [
        ...oldReservations,
        { tag, id, sentAt: dayjs(sentAt) },
      ]);
    },
    [setReservations]
  );

  React.useEffect(() => {
    if (reservations.length > 0) {
      const timeout = setTimeout(() => {
        setReservations((oldReservations) => {
          const now = dayjs.utc();
          return oldReservations.filter(
            (reservation) =>
              now.diff(reservation.sentAt, 'seconds') < RESERVATION_CLEAR_TIME
          );
        });
      }, 5_000);
      return () => clearTimeout(timeout);
    }
  }, [reservations, setReservations]);

  // Creating reservations
  const { sendMessage: doCreateReservation } = useSendReliably<
    ReservationPayload
  >({
    messageType: 'reservation-created',
    receiveMessage: (newReservation) =>
      addReservation(
        newReservation.tag,
        newReservation.sentAt,
        newReservation.id
      ),
    autoResend: true,
  });
  
  return React.useCallback(
    (tag: string) => {
      const sentAt = dayjs.utc().toISOString();
      addReservation(tag, sentAt, `${Math.random()}`);
      doCreateReservation({ tag, sentAt });
    },
    [addReservation, doCreateReservation]
  );
};
