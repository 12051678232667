import React from 'react';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Link from '@material-ui/core/Link';
import tr from '../../utils/tr';
import { RouteEnum } from '../../RouteEnum';

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  text: {
    fontSize: '16px',
  },
  termsLink: {
    textDecoration: 'underline',
  },
}));

export default function TermsAndConditions() {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      <Typography className={classes.text}>
        {tr(
          'By clicking Join Call Now I acknowledge receipt of these ##preroom linkprefix'
        )}{' '}
        <Link
          className={classes.termsLink}
          href={RouteEnum.TERMS}
          target="_blank"
          rel="noopener noreferrer"
        >
          {tr('Terms of Use ##preroom link')}
        </Link>
      </Typography>
    </div>
  );
}
