import React from 'react';
import { VideoTrackType } from '../useMedia/UseMediaTypes';
import { isMobile } from 'react-device-detect';
import { windowIsHidden } from '../../../utils';

export default function useVisibilityDisable(
  videoTrack: VideoTrackType,
  isDisabled: boolean
) {
  const handleVisibilityChange = () => {
    if (!isMobile) return;
    if (windowIsHidden() && videoTrack.track && !isDisabled) {
      videoTrack.track.disable();
    } else if (videoTrack.track && !isDisabled) {
      videoTrack.track.enable();
    }
  };

  React.useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  });
}
