import React from 'react';
import ParticipantGrid from '../ParticipantGrid/ParticipantGrid';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import PlusParticipants from '../ParticipantGrid/PlusParticipants/PlusParticipants';
import ParticipantMaximized from '../ParticipantMaximized/ParticipantMaximized';
import useAutoselectScreenshareParticipant from '../../hooks/useAutoselectScreenshareParticipant/useAutoselectScreenshareParticipant';
import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { useVideoRoomState } from '../../hooks/useVideoRoomState/VideoRoomStateProvider';
import CallTimer from '../CallTimer/CallTimer';
import projTheme from '../../theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    videoWrap: {
      flex: 1,
      maxWidth: '100%',
      maxHeight: '100%',
      overflow: 'hidden',
      position: 'relative',
      margin: '8px',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
    },
    timerWrap: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    centerWrap: {
      position: 'absolute',
      right: 0,
      top: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      transition: `${theme.custom.videoPanelTransitionSpeed}ms ease-in-out`,
      width: '100%',
      height: '100%',
    },
    centerWrapMinimized: {
      width: '20%',
      height: '25%',
      minHeight: '150px',
      minWidth: '150px',
    },
    gridWrap: {
      width: '100%',
      height: '100%',
      transition: `${theme.custom.videoPanelTransitionSpeed}ms ease-in-out`,
      zIndex: 1,
      // Keep a ~1.3 aspect ratio between max-height and max-width
      '@media (min-aspect-ratio: 1/1)': {
        '@media (max-height: 600px)': {
          maxWidth: '780px',
        },
        '@media (min-height: 601px) and (max-height: 800px)': {
          maxWidth: '1040px',
        },
        '@media (min-height: 801px) and (max-height: 1200px)': {
          maxWidth: '1560px',
        },
        '@media (min-height: 1201px) and (max-height: 1600px)': {
          maxWidth: '2080px',
        },
        '@media (min-height: 1601px) and (max-height: 2130px)': {
          maxWidth: '2080px',
        },
      },
    },
    gridWrapMinimized: {
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      maxWidth: '2080px',
    },
    plusWrap: {
      position: 'absolute',
      bottom: '-22px',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      zIndex: 2,
    },
  })
);

export default function VideoView() {
  // Create a delayed version of isMinimized to retain the background video during the transition
  const { setShowingToolbar } = useVideoRoomState();
  const [delayedIsMinimized, setDelayedIsMinimized] = React.useState(false);
  const [selectedParticipant] = useSelectedParticipant();

  useAutoselectScreenshareParticipant();
  const isMinimized = !!selectedParticipant;
  const classes = useStyles();

  React.useEffect(() => {
    setTimeout(() => {
      setDelayedIsMinimized(isMinimized);
    }, projTheme.custom.videoPanelTransitionSpeed);
  }, [isMinimized]);

  React.useEffect(() => {
    setShowingToolbar(true);
  }, [setShowingToolbar]);

  return (
    <div className={classes.videoWrap}>
      <div className={classes.timerWrap}>{<CallTimer />}</div>
      <div
        className={clsx(
          classes.centerWrap,
          isMinimized ? classes.centerWrapMinimized : null
        )}
      >
        <div
          className={clsx(
            classes.gridWrap,
            isMinimized ? classes.gridWrapMinimized : null
          )}
        >
          <ParticipantGrid isMinimized={isMinimized} />
          {isMinimized && (
            <div className={classes.plusWrap}>
              <PlusParticipants />
            </div>
          )}
        </div>
      </div>
      {(delayedIsMinimized || isMinimized) && <ParticipantMaximized />}
    </div>
  );
}
