import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { colors } from '../../theme';

const useStyles = makeStyles({
  userIcon: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    backgroundColor: colors.grayscale.gray9,
    color: colors.grayscale.gray8,
  },
});

export default function PersonBackdrop({
  zIndex = 0,
  alt = 'User',
}: {
  zIndex: number;
  alt?: string;
}) {
  const classes = useStyles();
  return (
    <img
      src="/static/person.svg"
      alt={alt}
      className={classes.userIcon}
      style={{ zIndex }}
    />
  );
}
