import { getApptDisplayStatus } from '../../../state/appt/apptStateUtils';
import React from 'react';
import { updateRelativeApptTimesEveryNSeconds } from '../../../utils/settings';
import { ApptType } from '../../../state/appt/apptTypes';
import { AppointmentDetailStatusEnum } from '../apptHookTypes';

export default function useApptStatusForDisplay(
  appointment: ApptType
): AppointmentDetailStatusEnum {
  const [status, setStatus] = React.useState<AppointmentDetailStatusEnum>(
    getApptDisplayStatus(appointment)
  );

  React.useEffect(() => {
    const interval = setInterval(() => {
      setStatus(getApptDisplayStatus(appointment));
    }, updateRelativeApptTimesEveryNSeconds * 1000);
    // Run now
    setStatus(getApptDisplayStatus(appointment));

    return () => clearInterval(interval);
  }, [appointment]);

  return status;
}
