import { LocalParticipant, RemoteParticipant } from 'twilio-video';
import { PlaceholderParticipant } from './useParticipants';

export function isNonPlaceholder(
  participant: RemoteParticipant | PlaceholderParticipant
): participant is RemoteParticipant;

export function isNonPlaceholder(
  participant: LocalParticipant | PlaceholderParticipant
): participant is LocalParticipant;

export function isNonPlaceholder(
  participant: RemoteParticipant | LocalParticipant | PlaceholderParticipant
): participant is RemoteParticipant | LocalParticipant;

export function isNonPlaceholder(
  participant: LocalParticipant | RemoteParticipant | PlaceholderParticipant
): participant is RemoteParticipant | LocalParticipant {
  return !('isPlaceholder' in participant);
}
