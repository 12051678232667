import React from 'react';
import useAuthBaseContext from '../useAuthBaseContext/useAuthBaseContext';
import {
  ApptActionEnum,
  ApptActionsType,
} from '../../state/appt/ApptActionObjectType';
import { ApptFetchErrorTypeEnum } from '../appt/apptHookTypes';
import { InterpreterLanguageType } from '../../state/appt/apptTypes';
import getInterpreterLanguageList from './fetch/getInterpreterLanguageList';

export default function useInterpreterLanguagesList({
  dispatch,
}: {
  dispatch: React.Dispatch<ApptActionsType>;
}) {
  const { baseAccessToken } = useAuthBaseContext();
  const [interpreterLanguages, setInterpreterLanguages] = React.useState<
    InterpreterLanguageType[]
  >([]);
  const [
    isInterpreterLanguagesApiCallInFlight,
    setIsInterpreterLanguagesApiCallInFlight,
  ] = React.useState(false);

  React.useEffect(() => {
    const run = async () => {
      if (baseAccessToken) {
        setIsInterpreterLanguagesApiCallInFlight(true);
        const res = await getInterpreterLanguageList(baseAccessToken);
        if (
          res.error &&
          res.error.type !== ApptFetchErrorTypeEnum.SERVER_ERROR
        ) {
          dispatch({
            type: ApptActionEnum.ERROR_ACTION,
            error: res.error,
          });
          return;
        }
        if (
          (res.error &&
            res.error.type === ApptFetchErrorTypeEnum.SERVER_ERROR) ||
          !res.content
        ) {
          res.content = [];
          delete res.error;
        }
        const sortedInterpreterLanguagesList: InterpreterLanguageType[] = [
          ...res.content,
        ].sort((language1, language2) =>
          language1.LanguageName.localeCompare(language2.LanguageName)
        );
        setInterpreterLanguages(sortedInterpreterLanguagesList);
        setIsInterpreterLanguagesApiCallInFlight(false);
      }
    };
    run();
  }, [baseAccessToken, dispatch, setInterpreterLanguages]);

  return {
    interpreterLanguages,
    isInterpreterLanguagesApiCallInFlight,
  };
}
