import {
  FormControlLabel,
  RadioGroup,
  Radio,
  RadioProps,
} from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import tr from '../../../utils/tr';
import CopyButton from '../CopyButton/CopyButton';
import { makeStyles } from '@material-ui/styles';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export enum AppointmentOptionsEnum {
  NOW = 'now',
  LATER = 'later',
}

interface AppointmentOptionsInterface {
  onChange:
    | ((newValue: AppointmentOptionsEnum) => void)
    | React.Dispatch<React.SetStateAction<AppointmentOptionsEnum>>;
  value: AppointmentOptionsEnum;
  disableOption?: AppointmentOptionsEnum;
  strToBeCopied: string;
  appointmentExpiration?: Dayjs;
}

function RadioStyled({ ...props }: RadioProps) {
  return <Radio {...props} color="primary" />;
}

const useStyles = makeStyles({
  labelWrap: {
    display: 'flex',
    height: '50px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    marginRight: '20px',
  },
});

export default function AppointmentOptions({
  onChange,
  value,
  disableOption,
  strToBeCopied,
  appointmentExpiration,
}: AppointmentOptionsInterface) {
  const classes = useStyles();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    onChange(event.target.value as AppointmentOptionsEnum);
  };
  const disabled = !!(
    appointmentExpiration && appointmentExpiration.isBefore(dayjs.utc())
  );
  const tooltipText = disabled
    ? tr(
        'Link can only be copied within 12 hours of the time of visit ##tooltip'
      )
    : '';

  return (
    <RadioGroup
      aria-label="appointment type"
      name="appointment_type"
      value={value}
      onChange={handleChange}
    >
      <FormControlLabel
        control={<RadioStyled />}
        value={AppointmentOptionsEnum.NOW}
        label={
          <div className={classes.labelWrap}>
            <span className={classes.label}>
              {tr('Send invites now ##appointment radio')}
            </span>
            {value === AppointmentOptionsEnum.NOW && (
              <CopyButton
                strToBeCopied={strToBeCopied}
                textCopy={tr('Copy Link ##appt button')}
                textCopied={tr('Copied Link ##appt button')}
                tooltipText={tooltipText}
                disabled={disabled}
              />
            )}
          </div>
        }
        disabled={disableOption === AppointmentOptionsEnum.NOW}
      />
      <FormControlLabel
        control={<RadioStyled />}
        value={AppointmentOptionsEnum.LATER}
        label={
          <div className={classes.labelWrap}>
            <span className={classes.label}>
              {tr('Send invites later ##appointment radio')}
            </span>
            {value === AppointmentOptionsEnum.LATER && (
              <CopyButton
                strToBeCopied={strToBeCopied}
                textCopy={tr('Copy Link ##appt button')}
                textCopied={tr('Copied Link ##appt button')}
                tooltipText={tooltipText}
                disabled={disabled}
              />
            )}
          </div>
        }
        disabled={disableOption === AppointmentOptionsEnum.LATER}
      />
    </RadioGroup>
  );
}
