import React from 'react';
import { FormControl, MenuItem, Select, InputLabel } from '@material-ui/core';
import { ShowingMediaMessageType } from '../../../../interfaces/ShowingMediaMessageType';
import { makeStyles } from '@material-ui/core/styles';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import InputMutedNotice from '../InputMutedNotice/InputMutedNotice';
import MiscMediaMessage from '../MiscMediaMessage/MiscMediaMessage';
import DeviceRow from '../DeviceRow/DeviceRow';
import tr from '../../../../utils/tr';

const useStyles = makeStyles({
  audioLevelWrap: {
    display: 'inline-block',
  },
  mutedWrap: {
    marginTop: '1em',
    width: '100%',
    verticalAlign: 'middle',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mutedLeft: {
    flex: 1,
  },
  mutedRight: {
    flex: 0,
  },
  select: {
    overflow: 'hidden',
  },
});

export default function AudioInputList() {
  const classes = useStyles();
  const {
    devices: { audioIn: audioInDevices },
    localTracks,
    setAudioInDisableDesired,
    audioInDisabled,
    setAudioInDeviceIdDesired,
    audioInDeviceId,
  } = useVideoContext();

  const localAudioTrack = localTracks.find(
    (track) => track.track.kind === 'audio'
  );

  let showingMessage = 'muted' as ShowingMediaMessageType;
  if (!audioInDisabled) {
    if (audioInDevices.length === 0) {
      showingMessage = 'no_options';
    } else if (audioInDevices.length === 1) {
      showingMessage = 'one_option';
    } else {
      showingMessage = 'multiple_options';
    }
  }
  return (
    <FormControl fullWidth>
      <InputLabel id="select-audio-input">
        {tr('Microphone ##device label')}
      </InputLabel>
      <DeviceRow>
        {showingMessage === 'muted' && (
          <InputMutedNotice
            text={tr('No Local Audio ##device label')}
            onUnmute={() => setAudioInDisableDesired(!audioInDisabled)}
          />
        )}
        {showingMessage === 'multiple_options' && (
          <Select
            labelId="select-audio-input"
            className={classes.select}
            onChange={(e) =>
              setAudioInDeviceIdDesired(e.target.value as string)
            }
            value={audioInDeviceId || audioInDevices[0]?.id || ''}
          >
            {audioInDevices.map((device) => (
              <MenuItem value={device.id} key={device.id}>
                {device.name}
              </MenuItem>
            ))}
          </Select>
        )}
        {showingMessage === 'one_option' && (
          <MiscMediaMessage
            text={
              localAudioTrack?.track.mediaStreamTrack.label ||
              tr('Unknown Microphone ##device label')
            }
          />
        )}
        {showingMessage === 'no_options' && (
          <MiscMediaMessage
            text={tr('No Local Audio ##device label')}
            subtext={tr(
              'Check that your device has a microphone. ##device label'
            )}
          />
        )}
      </DeviceRow>
    </FormControl>
  );
}
