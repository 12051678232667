import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { styled } from '@material-ui/core/styles';
import { colors, whitneyFontAndFallbacks } from '../../theme';
import { useVideoRoomState } from '../../hooks/useVideoRoomState/VideoRoomStateProvider';
import ParticipantInfoContainer from '../ParticipantInfo/ParticipantInfoContainer';
import tr from '../../utils/tr';
const Container = styled('div')({
  height: '100%',
  gridArea: 'videoItem',
});

interface PlaceholderParticipantProps {
  style?: object;
  isMain: boolean;
}

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  inner: {
    position: 'relative',
  },
  dominantBorderOverlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    boxSizing: 'border-box',
    border: `solid 3px ${colors.secondary.green}`,
    zIndex: 2,
  },
  clickableArea: {
    height: '100%',
    width: '100%',
    pointerEvents: 'all',
  },
  background: {
    // Background formatting
    height: '100%',
    width: '100%',
    background: '#EEEEEE',

    // Text positioning
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    // Text formatting
    fontFamily: whitneyFontAndFallbacks,
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '28px',
  },
});

export default function PlaceholderParticipant({
  style = {},
  isMain = false,
}: PlaceholderParticipantProps) {
  const styles = useStyles();

  const { setShowingToolbar } = useVideoRoomState();

  const onScreenClick = React.useCallback(() => {
    if (isMain) {
      setShowingToolbar(true);
    }
  }, [isMain, setShowingToolbar]);

  return (
    <Container className={styles.inner} style={{ ...style }}>
      <div
        className={styles.clickableArea}
        onClick={onScreenClick}
        data-testid="videoClickArea"
      >
        <ParticipantInfoContainer identity={tr('Connecting... ##connecting short')} overlay={<div />}>
          <div className={styles.background}>
            {tr('Connecting with an interpreter... ##connecting interpreter long')}
          </div>
        </ParticipantInfoContainer>
      </div>
    </Container>
  );
}
