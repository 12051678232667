import React from 'react';
import type { ErrorInfo } from 'react';
import { reportError } from '@ascension/report-event';

// Could add getDerivedStateFromError() and add a hasError state to return error message in render()
export default class ErrorBoundary extends React.Component<{}> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    reportError(error.message, errorInfo.componentStack);
  }

  render() {
    return this.props.children;
  }
}
