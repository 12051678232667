import React from 'react';
import { useApptStateContext } from '../../../state/appt/ApptStateProvider';
import { ApptActionEnum } from '../../../state/appt/ApptActionObjectType';
import ApptErrorDialog from './ApptErrorDialog';
import useSendApptError from '../../../hooks/appt/useSendApptError/useSendApptError';
import {
  ApptFetchErrorAboutEnum,
  ApptFetchErrorTypeEnum,
} from '../../../hooks/appt/apptHookTypes';
import tr from '../../../utils/tr';
import { pauseNSecondsBeforeRetry } from '../../../utils/settings';

export default function ApptError({
  isShowing = true,
}: {
  isShowing?: boolean;
}) {
  const {
    state: { error },
    dispatch,
    getAppointments,
  } = useApptStateContext();
  useSendApptError(error);

  const dismissError = React.useCallback(() => {
    dispatch({ type: ApptActionEnum.ERROR_ACTION, error: null });
  }, [dispatch]);

  const retry = React.useCallback(() => {
    setTimeout(getAppointments, pauseNSecondsBeforeRetry * 1000);
    dismissError();
  }, [getAppointments, dismissError]);

  const triggerRetry =
    error &&
    error.about === ApptFetchErrorAboutEnum.GET_APPOINTMENT_LIST &&
    (!error.type || error.type === ApptFetchErrorTypeEnum.GENERAL);

  return (
    <ApptErrorDialog
      error={isShowing ? error : null}
      dismissError={dismissError}
      buttonText={triggerRetry ? tr('Retry ##appt-error button') : undefined}
      onButtonClick={triggerRetry ? retry : null}
    />
  );
}
