import { InterpreterLanguageType } from '../../../state/appt/apptTypes';
import { fetchWithTimeout, getDefaultHeaders } from '../../../utils';
import {
  ApptFetchErrorAboutEnum,
  ApptFetchErrorTypeEnum,
  ApptFetchResponseType,
} from '../../appt/apptHookTypes';

export default function getInterpreterLanguageList(
  baseAccessToken: string
): Promise<ApptFetchResponseType<InterpreterLanguageType[]>> {
  return new Promise((resolve) => {
    const { INTERPRETER_SERVICE, INTERPRETER_API_KEY } = window.config;
    const headers = getDefaultHeaders();

    headers.set('Authorization', `Bearer ${baseAccessToken}`);
    headers.set('apiKey', INTERPRETER_API_KEY);
    headers.set('Content-Type', 'application/json');

    fetchWithTimeout(`${INTERPRETER_SERVICE}/interpreter/language`, {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (!res.ok) {
          let responseType = ApptFetchErrorTypeEnum.ACCESS_DENIED;
          if (res.status >= 500) {
            responseType = ApptFetchErrorTypeEnum.SERVER_ERROR;
          } else if (res.status >= 400) {
            responseType = ApptFetchErrorTypeEnum.INVALID_REQUEST;
          }
          resolve({
            error: {
              status: res.status,
              message:
                'No valid response when requesting interpreter languages list',
              type: responseType,
              about: ApptFetchErrorAboutEnum.GET_INTERPRETER_LANGUAGES,
            },
          });
        }
        return {
          response: res.json(),
        };
      })
      .then(({ response }) => {
        response.then((res) => {
          resolve({
            content: res,
          });
        });
      })
      .catch((err) => {
        resolve({
          error: {
            message: 'Failure in requesting interpreter languages list: ' + err,
            about: ApptFetchErrorAboutEnum.GET_INTERPRETER_LANGUAGES,
          },
        });
      });
  });
}
