import { ApptActionEnum, ApptActionsType } from './ApptActionObjectType';
import { ApptStateType } from './apptInitialState';
import { updateApptInState } from './apptStateUtils';
import { ApptType } from './apptTypes';

export default function apptReducer(
  state: ApptStateType,
  action: ApptActionsType
) {
  let newState = { ...state };
  switch (action.type) {
    case ApptActionEnum.ERROR_ACTION:
      newState.error = action.error;
      break;
    case ApptActionEnum.ADD_APPOINTMENT:
      newState.appointments = [
        ...(newState.appointments || []).filter(
          ({ id }) => id !== action.appointment.id
        ),
        action.appointment,
      ];
      break;
    case ApptActionEnum.REMOVE_APPOINTMENT:
      newState = updateApptInState(
        newState,
        action.appointmentId,
        (appt: ApptType) => {
          return { ...appt, appointmentCancelled: true };
        }
      );
      break;
    case ApptActionEnum.TIME_IS_MISMATCHED:
      newState.timeIsMismatched = action.timeIsMismatched;
      break;
    case ApptActionEnum.SET_APPOINTMENTS:
      if (action.changeClinician) {
        newState.appointments = [...action.appointments];
      } else {
        newState.appointments = [
          ...(newState.appointments || []).filter(
            ({ id }) =>
              !action.appointments.find(({ id: newId }) => id === newId)
          ),
          ...action.appointments,
        ];
      }
      break;
  }
  return newState;
}
