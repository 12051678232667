import React from 'react';

// This hooks holds state for a specified period of time after it is becomes falsy
export default function useHeldState<S = undefined>({
  expectedState,
  holdForMs,
}: {
  expectedState: S;
  holdForMs: number;
}): S {
  const [held, setHeld] = React.useState<S>(expectedState);

  React.useEffect(() => {
    if (expectedState === null || typeof expectedState === 'undefined') {
      const timeout = setTimeout(() => setHeld(expectedState), holdForMs);
      return () => clearTimeout(timeout);
    } else {
      setHeld(expectedState);
    }
  }, [holdForMs, expectedState]);

  return held;
}
