import { ApptFetchErrorType } from '../../hooks/appt/apptHookTypes';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ApptType } from './apptTypes';

dayjs.extend(utc);

const apptFrom = dayjs().startOf('day').utc();
const apptTo = apptFrom.add(24, 'hour');

export type ApptStateType = {
  error: ApptFetchErrorType | null;
  appointments: ApptType[] | null;
  apptFrom: Dayjs;
  apptTo: Dayjs;
  timeIsMismatched: boolean;
};

const apptInitialState: ApptStateType = {
  error: null,
  appointments: null,
  apptFrom,
  apptTo,
  timeIsMismatched: false,
};

export default apptInitialState;
