import { LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';
import getAudioContext from '../../utils/getAudioContext/getAudioContext';

export default function getSoundAnalyser(
  audioTrack: LocalAudioTrack | RemoteAudioTrack
) {
  const audioContext = getAudioContext();

  const stream = new window.MediaStream([audioTrack.mediaStreamTrack]);
  let analyser: AnalyserNode | null = null;
  let audioSource: MediaStreamAudioSourceNode | null = null;

  if (audioContext) {
    audioSource = audioContext.createMediaStreamSource(stream);
    analyser = audioContext.createAnalyser();
    analyser.smoothingTimeConstant = 0.4;
    analyser.fftSize = 512;
    audioSource.connect(analyser);
  }

  // Provide a clean-up, otherwise browser seems to max-out and subsequent analysers fail
  const closeAnalyser = () => {
    if (audioContext) {
      audioContext.close();
    }
    if (analyser && audioSource) {
      audioSource.disconnect(analyser);
    }
  };

  return { analyser, closeAnalyser };
}
