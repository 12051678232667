import React from 'react';
import { FormHelperText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  wrap: {
    marginTop: '1.2em',
  },
});

export default function MiscMediaMessage({
  text,
  subtext = '',
}: {
  text: string;
  subtext?: string;
}) {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      <Typography>{text}</Typography>
      {subtext && <FormHelperText>{subtext}</FormHelperText>}
    </div>
  );
}
