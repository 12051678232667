import React from 'react';
import AudioInputList from '../MenuBar/DeviceSelector/AudioInputList/AudioInputList';
import VideoInputList from '../MenuBar/DeviceSelector/VideoInputList/VideoInputList';
import AudioOutputList from '../MenuBar/DeviceSelector/AudioOutputList/AudioOutputList';
import { styled } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import theme from '../../theme';
import VideoInputThumbnail from '../MenuBar/DeviceSelector/VideoInputThumbnail/VideoInputThumbnail';
import BlurSwitch from '../MenuBar/BlurSwitch/BlurSwitch';

const Item = styled('div')({
  margin: `${theme.spacing(1)}px 0px`,
});

export default function MediaSettings() {
  return (
    <Container
      style={{
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      }}
    >
      <Item>
        <AudioInputList />
      </Item>
      <Item>
        <AudioOutputList />
      </Item>
      <Item>
        <VideoInputList />
        <BlurSwitch />
        <VideoInputThumbnail style={{ height: '150px', width: '150px' }} />
      </Item>
    </Container>
  );
}
