import useDynatraceTelemetry from '../useDynatraceTelemetry/useDynatraceTelemetry';
import React from 'react';
import useStats from '../../components/VideoProvider/useStats/useStats';
import { dynatraceTelemetryEveryNSeconds } from '../../utils/settings';
import {
  ACTION_PROPERTY_MBPS_DOWNLINK,
  ACTION_PROPERTY_NETWORK_QUALITY,
} from '../../dynatraceConstants';
import useVideoContext from '../useVideoContext/useVideoContext';

export type DynatraceTelemetry = {
  [ACTION_PROPERTY_NETWORK_QUALITY]?: number;
  [ACTION_PROPERTY_MBPS_DOWNLINK]?: number;
};

export default function useDynatraceVideoTelemetry() {
  const {
    room: { localParticipant },
  } = useVideoContext();
  const { acquireLatest } = useStats();

  const onPoll = React.useCallback(async () => {
    const latest = await acquireLatest();
    const mbpsDownlink = latest.remoteVideoTrackStats.reduce((a, v) => {
      if (typeof v.mbps !== 'undefined' && v.mbps !== null) {
        return a + v.mbps;
      }
      return a;
    }, 0);
    const res = {} as DynatraceTelemetry;
    // Add network quality
    if (
      typeof localParticipant?.networkQualityLevel !== 'undefined' &&
      localParticipant?.networkQualityLevel !== null
    ) {
      res[ACTION_PROPERTY_NETWORK_QUALITY] =
        localParticipant.networkQualityLevel;
    }
    // Add Mbps
    if (mbpsDownlink) {
      res[ACTION_PROPERTY_MBPS_DOWNLINK] = mbpsDownlink;
    }
    return res;
  }, [localParticipant, acquireLatest]);

  useDynatraceTelemetry(onPoll, dynatraceTelemetryEveryNSeconds);
}
