import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

interface PinOverlayProps {
  onClick: () => void;
  children: (onClick: () => void) => React.ReactNode;
  preventHover?: boolean;
}

const useStyles = makeStyles({
  wrap: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 3,
    cursor: 'pointer',
  },
  overlay: {
    position: 'absolute',
    opacity: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    transition: 'opacity 250ms ease-in-out',
  },
  overlayHovering: {
    opacity: 0.5,
  },
  childWrap: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default function PinOverlay({ onClick, children }: PinOverlayProps) {
  const [hovering, setHovering] = React.useState(false);
  const [clickable, setClickable] = React.useState(false);
  const [touching, setTouching] = React.useState(false);
  const [hoverTimeout, setHoverTimeout] = React.useState<NodeJS.Timeout | null>(
    null
  );

  React.useEffect(() => {
    if (hovering) {
      setTimeout(() => {
        setClickable(true);
      }, 100);
    } else {
      setClickable(false);
    }
  }, [hovering]);
  const resetHoverTimeout = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
  };
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  const handleMouseOver = () => {
    if (!touching) {
      resetHoverTimeout();
      setHovering(true);
    }
  };
  const handleMouseOff = () => {
    if (!touching) {
      resetHoverTimeout();
      setHovering(false);
    }
  };
  const handleTouch = () => {
    resetHoverTimeout();
    setTouching(true);
    setHovering(true);
    setHoverTimeout(
      setTimeout(() => {
        setHovering(false);
        resetHoverTimeout();
      }, 2000)
    );
  };
  const styles = useStyles();
  return (
    <div
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOff}
      onTouchStart={handleTouch}
      onClick={
        clickable
          ? handleClick
          : () => {
              // default empty on click implementation
            }
      }
      className={clsx('pinOverlayWrap', styles.wrap)}
    >
      <div
        className={clsx(
          styles.overlay,
          hovering ? styles.overlayHovering : null
        )}
      />
      <div className={styles.childWrap}>
        {hovering
          ? children(() => {
              // default empty function implementation
            })
          : null}
      </div>
    </div>
  );
}
