import React, { useCallback } from 'react';
import useSelectedParticipant from '../../components/VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../useVideoContext/useVideoContext';
import usePrevious from '../usePrevious/usePrevious';

export default function useAutoselectScreenshareParticipant() {
  const [, setSelectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const prevScreenShareParticipant = usePrevious(screenShareParticipant);
  const {
    room: { localParticipant },
  } = useVideoContext();

  // For some reason setSelectedParticipant mutates - remove that
  const setSelectedParticipantCallback = useCallback(
    setSelectedParticipant,
    []
  );

  // If a new screen-share participant is set, pin automatically
  React.useEffect(() => {
    if (screenShareParticipant && screenShareParticipant !== localParticipant) {
      setSelectedParticipantCallback((currSelected) =>
        currSelected === screenShareParticipant
          ? currSelected
          : screenShareParticipant
      );
    }
    if (!screenShareParticipant) {
      setSelectedParticipantCallback((currSelectedPart) => {
        return currSelectedPart === prevScreenShareParticipant
          ? null
          : currSelectedPart;
      });
    }
  }, [
    prevScreenShareParticipant,
    screenShareParticipant,
    setSelectedParticipantCallback,
    localParticipant,
  ]);
}
