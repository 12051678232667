import React, { useCallback } from 'react';

export default function useCalculateOverTime(
  deletePrevOnNullUndefined?: boolean,
  minZero?: boolean
) {
  const storedValues = React.useRef<{
    [id: string]: { timestamp: number; value: number };
  }>({});

  return useCallback(
    (id: string, newTimestamp: number, newValue: number | null | undefined) => {
      if (newValue === null || newValue === undefined) {
        if (deletePrevOnNullUndefined) {
          delete storedValues.current[id];
        }
        return undefined;
      }

      const previousValues = storedValues.current[id];

      if (!previousValues) {
        storedValues.current[id] = {
          timestamp: newTimestamp,
          value: newValue,
        };
        return undefined;
      }

      const { timestamp, value } = previousValues;

      storedValues.current[id] = {
        timestamp: newTimestamp,
        value: newValue,
      };

      let valueOverTime = (newValue - value) / (newTimestamp - timestamp);

      if (minZero && valueOverTime <= 0) {
        valueOverTime = 0;
      }
      return valueOverTime;
    },
    [deletePrevOnNullUndefined, minZero]
  );
}
