import {
  Button,
  createStyles,
  makeStyles,
  Snackbar,
  SnackbarContent,
  Theme,
  Typography,
} from '@material-ui/core';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import tr from '../../../utils/tr';
import AppointmentParticipantList from '../AppointmentParticipantList/AppointmentParticipantList';
import { RightPanelKeyEnum } from '../../../interfaces/RightPanelContentTypes';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import InviteMessage from '../InviteMessage/InviteMessage';
import updateAppt, {
  OutgoingBodyAddedParticipantType,
  OutgoingBodyParticipantMap,
  OutgoingBodyRemovedParticipantType,
} from '../../../hooks/appt/fetch/updateAppt';
import { getApptIsSame } from '../../../state/appt/apptStateUtils';
import {
  ApptContentEnum,
  ApptParticipantType,
  ApptType,
  ApptTypeEnum,
} from '../../../state/appt/apptTypes';
import useToastContext from '../../../hooks/useToastContext/useToastContext';
import { filterParticipantListForDisplay } from '../utils';
import useAppointmentUpdateForm from '../../../hooks/appt/useAppointmentUpdateForm/useAppointmentUpdateForm';
import { useApptStateContext } from '../../../state/appt/ApptStateProvider';
import useAuthBaseContext from '../../../hooks/useAuthBaseContext/useAuthBaseContext';
import {
  ApptActionEnum,
  ApptActionsType,
} from '../../../state/appt/ApptActionObjectType';
import {
  ApptFetchErrorAboutEnum,
  ApptFetchErrorTypeEnum,
} from '../../../hooks/appt/apptHookTypes';
import useShowInRightPanel from '../../../hooks/useShowInRightPanel/useShowInRightPanel';

export enum ParticipantTempStatusEnum {
  ADDED = 'added',
  REMOVED = 'removed',
}

export type ApptParticipantWithTempStatusType = ApptParticipantType & {
  tempStatus?: ParticipantTempStatusEnum;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addInterpreter: {
      border: 'none',
    },
    line: {
      borderColor: 'rgba(0, 0, 0, 0.12)',
    },
    message: {
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.5px',
      fontWeight: 350,
    },
    icon: {
      marginRight: '0.8em',
      color: '#126E36',
    },
    apptSaveSnackbar: {
      border: '1px solid #126E36',
      borderLeftWidth: '4px',
      transform: 'translate(-50%, -11px) !important',
      '& .MuiSnackbarContent-root': {
        borderRadius: '4px',
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      [theme.breakpoints.down('xs')]: {
        transform: 'unset !important',
        top: '80px',
      },
    },
  })
);

export default function InviteAppointmentUpdateForm({
  appointment,
  hideName = false,
  refetchAppointment,
}: {
  appointment: ApptType;
  hideName?: boolean;
  refetchAppointment: () => void;
}) {
  const classes = useStyles();
  const { dispatch } = useApptStateContext();
  const { baseAccessToken } = useAuthBaseContext();
  const {
    participants,
    inviteMessage,
    setInviteMessage,
    EditParticipant,
    editParticipantProps,
  } = useAppointmentUpdateForm({ appointment });
  const domain = window.location.origin;
  const { add: addToast } = useToastContext();
  const [apptSaveSnackbarMsg, setApptSaveSnackbarMsg] = React.useState<string>(
    ''
  );

  const apptIsSame =
    getApptIsSame(appointment, {
      participants,
      inviteMessage,
    }) && !editParticipantProps.content;
  const apptIsSameRef = React.useRef(apptIsSame);
  const { showWhich } = useShowInRightPanel();

  const showToast = React.useCallback(() => {
    if (!apptIsSameRef.current) {
      addToast({
        message: tr('Your changes were not saved. ##toast'),
        isForApptDirty: true,
      });
    }
  }, [addToast]);

  apptIsSameRef.current = apptIsSame;

  React.useEffect(() => {
    return showToast;
  }, [showToast]);

  const filteredParticipantsForDisplay = filterParticipantListForDisplay(
    participants
  );

  // Used to track whether the invite message has been edited and needs updated
  const [initialInviteMessage] = React.useState(inviteMessage);

  const saveAppointment = async (
    participant: ApptParticipantType,
    isRemoved = false
  ) => {
    if (!baseAccessToken) {
      dispatch({
        type: ApptActionEnum.ERROR_ACTION,
        error: {
          message: 'Unable to update appointment due to missing access token',
          type: ApptFetchErrorTypeEnum.ACCESS_DENIED,
          about: ApptFetchErrorAboutEnum.UPDATE_APPOINTMENT,
        },
      });
      return false;
    }
    if (!appointment?.id) {
      dispatch({
        type: ApptActionEnum.ERROR_ACTION,
        error: {
          message: 'Unable to update appointment due to no appointment id',
          type: ApptFetchErrorTypeEnum.INVALID_REQUEST,
          about: ApptFetchErrorAboutEnum.UPDATE_APPOINTMENT,
        },
      });
      return false;
    }
    const addedParticipants = [];
    const removedParticipants = [];
    if (isRemoved) {
      setApptSaveSnackbarMsg(
        tr('Participant has been removed ##participant removed')
      );
      removedParticipants.push({
        id: participant.id,
      } as OutgoingBodyRemovedParticipantType);
    } else {
      setApptSaveSnackbarMsg(tr('Invite has been sent ##invite sent'));
      const { name, content, type } = participant;
      if (
        content &&
        (type === ApptContentEnum.EMAIL || type === ApptContentEnum.PHONE)
      ) {
        addedParticipants.push({
          name: name || '',
          content: content || '',
          type: OutgoingBodyParticipantMap[type],
        } as OutgoingBodyAddedParticipantType);
      }
    }
    editParticipantProps.setContent('');
    const res = await updateAppt({
      appointmentId: appointment.id,
      addedParticipants,
      removedParticipants,
      inviteMessage:
        inviteMessage === initialInviteMessage ? undefined : inviteMessage,
      baseAccessToken,
      targetAdid: appointment.ownedBy,
    });
    if (res.error) {
      dispatch({
        type: ApptActionEnum.ERROR_ACTION,
        error: res.error,
      } as ApptActionsType);
    } else {
      refetchAppointment();
      if (isRemoved && participant?.appointmentType === ApptTypeEnum.ATHENA) {
        addToast({
          message: tr(
            'Removing participant here does not remove it from the EHR record. ##participantRemoveToast'
          ),
          hold: true,
          isForApptDirty: true,
        });
      }
    }
  };

  return (
    <>
      <Typography variant="h4">
        {tr('Invite a Guest ##add-appt title')}
      </Typography>
      <EditParticipant
        {...editParticipantProps}
        hideName={hideName}
        onSubmit={(participant: ApptParticipantType) =>
          saveAppointment(participant)
        }
        isInCallPage={true}
      />
      <hr className={classes.line} />
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          showWhich(RightPanelKeyEnum.INTERPRETER);
        }}
        startIcon={<AddIcon />}
        className={classes.addInterpreter}
        data-testid="addInterpreter"
      >
        {tr('Add an Interpreter ##appt interpreter')}
      </Button>
      <hr className={classes.line} />
      <AppointmentParticipantList
        participants={filteredParticipantsForDisplay}
        onRemove={(participant: ApptParticipantType) =>
          saveAppointment(participant, true)
        }
        isInCallPage={true}
      />
      <div>
        <InviteMessage
          domain={domain}
          roomId="..."
          inviteMessage={inviteMessage}
          setInviteMessage={setInviteMessage}
          appointmentType={appointment.type}
          appointmentStartTime={appointment.startTime}
        />
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!apptSaveSnackbarMsg}
        autoHideDuration={5000}
        onClose={() => setApptSaveSnackbarMsg('')}
        className={classes.apptSaveSnackbar}
      >
        <SnackbarContent
          message={
            <span className={classes.message}>
              <SuccessIcon className={classes.icon} />
              {apptSaveSnackbarMsg}
            </span>
          }
        />
      </Snackbar>
    </>
  );
}
