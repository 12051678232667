import { Redirect, Route, Switch } from 'react-router-dom';
import AppointmentUpdate from '../AppointmentUpdate/AppointmentUpdate';
import AppointmentDetailEmpty from '../AppointmentDetailEmpty/AppointmentDetailEmpty';
import AppointmentDetail from '../AppointmentDetail/AppointmentDetail';
import React from 'react';
import { RouteEnum } from '../../../RouteEnum';

export default function ApptRoutes() {
  return (
    <Switch>
      <Route path={`${RouteEnum.APPOINTMENT}/:appointmentId/add`} exact>
        <AppointmentUpdate isAdd />
      </Route>
      <Route path={`${RouteEnum.APPOINTMENT}/:appointmentId/edit`} exact>
        <AppointmentUpdate />
      </Route>
      <Route path={`${RouteEnum.APPOINTMENT}/`} exact>
        <AppointmentDetailEmpty />
      </Route>
      <Route path={`${RouteEnum.APPOINTMENT}/:appointmentId`}>
        <AppointmentDetail />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
}
