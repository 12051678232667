import React from 'react';
import { DeviceType, TrackType } from './UseMediaTypes';
import useReleaseTrack from './useReleaseTrack';

interface UseClearTrackInterface {
  trackDeviceId?: string;
  devices: DeviceType[];
  setTrack: React.Dispatch<React.SetStateAction<TrackType>>;
}

export default function useClearTrack({
  devices,
  setTrack,
}: UseClearTrackInterface) {
  const releaseTrack = useReleaseTrack();
  // Clear-out track if its device disappears
  React.useEffect(() => {
    setTrack((currTrack) => {
      const track = currTrack.track;

      // Only kill the track if it is not in the device list
      if (track && !devices.find((device) => device.id === track.id)) {
        releaseTrack(currTrack);
        track.stop();
        track.disable();
        console.log(`useClearTracks deleted track`);
        return { track: null };
      }

      // Otherwise, return the same to indicate no state change
      return currTrack;
    });
  }, [releaseTrack, setTrack, devices]);
}
