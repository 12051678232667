import useFilteredParticipants from '../useFilteredParticipants/useFilteredParticipants';
import { isNonPlaceholder } from '../useParticipants/participantAssertions';

const isDefined = <T>(x: T | undefined): x is T => x !== undefined;

export default () => {
  const { filteredParticipants } = useFilteredParticipants(false);
  const presentParticipantIds = filteredParticipants
    .map((wrapped) => {
      return wrapped.participant && isNonPlaceholder(wrapped.participant)
        ? wrapped.participant.identity
        : undefined;
    })
    .filter(isDefined);
  const presentParticipants = presentParticipantIds.filter(
    (presentParticipantId) => presentParticipantId
  );
  return { currentParticipantId: presentParticipants[0], presentParticipants };
};
