import React from 'react';
import useShowInRightPanel from '../../hooks/useShowInRightPanel/useShowInRightPanel';
import ResponsiveDialog from '../ResponsiveDialog/ResponsiveDialog';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { transitionSpeed } from '../../theme';
import { RightPanelContentType } from '../../interfaces/RightPanelContentTypes';
import { DialogBar } from '../ResponsiveDialog/DialogBar/DialogBar';

const useStyles = makeStyles({
  rightPanel: {
    width: '400px',
    maxWidth: 0,
    transition: `max-width ${transitionSpeed}ms ease-in-out`,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflow: 'hidden',
    height: '100%',
  },
  rightPanelShowing: {
    maxWidth: '400px',
    position: 'relative',
  },
  rightPanelContents: {
    flex: 1,
    overflowY: 'auto',
  },
  noScroll: {
    display: 'flex',
    flexDirection: 'row',
  },
  invitePanel: {
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: 'calc(100% - 144px)',
    },
  },
});

export default function RightPanelRouter() {
  const [lastPick, setLastPick] = React.useState<null | RightPanelContentType>(
    null
  );
  const { showingWhich, hide, showWhich } = useShowInRightPanel();
  const theme = useTheme();
  const classes = useStyles();
  const timeoutRef = React.useRef<null | NodeJS.Timeout>(null);
  const showingSomething = !!showingWhich;
  const screenIsSmall = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    // Retain last route, to prevent it disappearing before transition-out
    if (showingSomething) {
      setLastPick(showingWhich);
    } else {
      timeoutRef.current = setTimeout(() => {
        setLastPick(null);
      }, transitionSpeed);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [setLastPick, timeoutRef, showingWhich, showingSomething]);

  const component = lastPick ? lastPick.component : '';
  const title = lastPick ? lastPick.title : '';

  return (
    <>
      <div
        className={clsx(
          classes.rightPanel,
          showingSomething && !screenIsSmall ? classes.rightPanelShowing : null
        )}
        data-testid="rightPanelRouterWrap"
        id="rightPanelRouterWrap"
      >
        <DialogBar
          onBackClick={
            lastPick && lastPick.back
              ? () => {
                  lastPick.back && showWhich(lastPick.back);
                }
              : undefined
          }
          title={title}
          onDismiss={hide}
        />
        <div
          className={clsx(classes.rightPanelContents, classes.noScroll)}
          data-testid="rightPanelRouterContents"
        >
          {component}
        </div>
      </div>
      <ResponsiveDialog
        open={showingSomething && screenIsSmall}
        className={clsx(title?.includes('Invite') ? classes.invitePanel : null)}
        onDismiss={hide}
        title={title}
        onBackClick={
          lastPick && lastPick.back
            ? () => {
                lastPick.back && showWhich(lastPick.back);
              }
            : undefined
        }
        scrollStyle={lastPick?.scroll === 'off' ? classes.noScroll : ''}
      >
        {component}
      </ResponsiveDialog>
    </>
  );
}
