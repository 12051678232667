import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import { colors } from '../../../theme';
import clsx from 'clsx';
import { Pin } from '@primer/octicons-react';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
    icon: {
      fontSize: '35px',
      color: colors.grayscale.black,
      position: 'relative',
      top: '6px',
      left: '6px',
    },
  })
);

export default function PinButton({
  disabled = false,
  onClick,
}: {
  disabled?: boolean;
  onClick?: () => void | undefined;
}) {
  const classes = useStyles();

  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <Tooltip
      title="Pin Participant"
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab
        className={classes.fab}
        onClick={handleClick}
        disabled={disabled}
        data-cy-audio-toggle
      >
        <SvgIcon className={clsx(classes.icon, 'pinButton')}>
          <Pin />
        </SvgIcon>
      </Fab>
    </Tooltip>
  );
}
