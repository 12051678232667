import React from 'react';
import { Container, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import tr from '../../utils/tr';
import { getTextDirectionOriginIsRight } from '../../utils';

const boldFontWeight = 500;

const useStyles = makeStyles({
  header: {
    fontWeight: boldFontWeight,
  },
  bold: {
    fontWeight: boldFontWeight,
  },
  italics: {
    fontStyle: 'italic',
  },
  termHeader: { lineHeight: '0.9' },
});

export default function TermsPage() {
  const classes = useStyles();
  return (
    <Container>
      <h1 className={classes.termHeader}>
        {tr(
          'AscensionConnect Video Chat End User License Agreement ##terms title'
        )}
      </h1>
      <h2>{tr('TERMS OF USE ##terms title')}</h2>
      <ul
        style={{
          ...(getTextDirectionOriginIsRight() ? { direction: 'rtl' } : {}),
        }}
      >
        <li>
          <div className={classes.header}>
            {tr('INTRODUCTION ##terms title')}
          </div>
          <p>
            {tr(
              'Welcome to AscensionConnect Video Chat platform provided by AscensionConnect, LLC ("AscensionConnect") where you can connect with your providers (if available). These Terms of Use ("Terms") govern your use of the Ascension Video Chat provided by a third-party technology vendor (“Vendor”), and any other website or online service that Ascension operates and that links to these Terms (collectively, the "Solution").'
            ) + ' '}
            <span className={classes.bold}>
              {tr(
                'Please review these Terms carefully before using the Solution. By using the Solution, you agree to be bound by these Terms.'
              )}
            </span>{' '}
            {tr(
              'If you do not agree to these Terms, you are not authorized to access or use the Solution.'
            )}
          </p>
          <p>
            {tr(
              'By using the Solution, you hereby acknowledge and agree that you consented to receive the Ascension Video Chat Link and these Terms via email or text. Text messaging rates may apply.'
            )}
          </p>
          <p>
            {tr(
              'AscensionConnect may revise and update this Agreement at any time without your consent. Your continued usage of the Solution means you have accepted those changes.'
            )}
          </p>
        </li>
        <li>
          <div className={classes.header}>
            {tr(
              'IMPORTANT INFORMATION ABOUT YOUR USE OF THE SOLUTION ##terms title'
            )}
          </div>
          <p>
            {tr(
              'DO NOT USE THE SOLUTION FOR EMERGENCY MEDICAL NEEDS. IF YOU EXPERIENCE A MEDICAL EMERGENCY, CALL 9-1-1 IMMEDIATELY.'
            )}
          </p>
          <p>
            {tr(
              'The Vendor utilized by AscensionConnect does not provide any physicians or other healthcare providers (collectively, "Providers") services itself. This Solution is a convenience tool and technology to provide expanded accessibility to health care professionals.'
            )}
          </p>
          <p>
            {tr(
              'Neither AscensionConnect nor any of its, Vendor, licensors or suppliers or any third parties who promote the Solution or provide you with a link to the Solution shall be liable for any professional advice you obtain from a Provider via the Solution nor for any information obtained from the Solution. You acknowledge your reliance on any Providers or information provided by the Solution is solely at your own risk and you assume full responsibility for all risk associated therewith.'
            )}
          </p>
          <p>
            {tr(
              'You hereby certify that you are physically located in the State where you receive professional services from the Provider. You acknowledge that your ability to access and use the Solution is conditioned upon the truthfulness of this certification and that the Providers you access through the Solution are relying upon this certification in order to interact with you. You are ultimately responsible for choosing your particular Provider on the Solution. All of the Providers services are independent of the Solution.'
            )}
          </p>
          <p>
            {tr(
              'In the event that your certification is inaccurate, you agree to indemnify AscensionConnect and the Providers you interact with from any resulting damages, costs, fines or claims from third parties or government entities.'
            )}
          </p>
        </li>
        <li>
          <div className={classes.header}>
            {tr('EXPECTATIONS OF ASCENSION VIDEO CHAT ##terms title')}
          </div>
          <p>
            {tr(
              'Ascension Video Chat involves the use of technology to enable health care Providers at sites remote from patients to provide medical consultative services (including telemedicine, telehealth, virtual care and/or digital care). Providers may include primary care practitioners, specialists, and/or subspecialists. The information may be used for diagnosis, therapy, follow-up and/or education, and may include live two-way audio and video and other materials (e.g., medical records, data from medical devices).'
            )}
          </p>
          <p>
            {tr(
              'The communications systems used will incorporate reasonable security protocols to protect the confidentiality of patient information and will include reasonable measures to safeguard the data and to ensure its integrity against intentional or unintentional corruption.'
            )}
          </p>
          <ul>
            <li>
              <div className={classes.italics}>
                {tr('Anticipated Benefits of Virtual Care ##terms title')}
              </div>
              <ul>
                <li>
                  {tr(
                    'Improved access to medical care by enabling a patient to consult with a clinician remotely. ##terms'
                  )}
                </li>
                <li>
                  {tr(
                    'More efficient medical evaluation and management. ##terms'
                  )}
                </li>
              </ul>
            </li>
            <li>
              <div className={classes.italics}>
                {tr('Possible Risks of Virtual Care ##terms')}
              </div>
              {tr(
                'As with any medical care, evaluation or procedure, there are potential risks associated with the use of virtual care. AscensionConnect believes that the likelihood of these risks materializing is very low. These risks may include, without limitation, the following:'
              )}
              <ol>
                <li>
                  {tr(
                    'Delays in medical evaluation and consultation or treatment may occur due to deficiencies or failures of the technology. ##terms'
                  )}
                </li>
                <li>
                  {tr(
                    'Security protocols could fail, causing a breach of privacy of personal medical information. ##terms'
                  )}
                </li>
                <li>
                  {tr(
                    'Lack of access to complete medical records may result in adverse drug interactions or allergic reactions or other negative outcomes. ##terms'
                  )}
                </li>
              </ol>
            </li>
          </ul>
          <p>
            {tr(
              'Use of the Solution may be affected by technical and equipment malfunctions, congestion on communications networks, availability of the Internet, available signal on any electronic device, or a combination thereof. You agree that alternative means of communications with the Provider may be more effective in some situations.'
            )}
          </p>
        </li>
        <li>
          <div className={classes.header}>
            {tr('DATA PRIVACY ##terms title')}
          </div>
          <p>
            {tr(
              'By using the Solution, you consent to AscensionConnect’s processing your information consistent with Ascension’s'
            ) + ' '}
            <Link
              href="https://ascension.org/PrivacyPolicy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {tr('Privacy Policy. ##terms inline')}
            </Link>{' '}
            {tr(
              'The Privacy Policy only applies to information collected from the Solution that is not protected health information (or “PHI”). PHI collected through your use of the Solution is protected by the Notice of Privacy Practices. AscensionConnect and its affiliates may use data on the Solution, including your PHI, for any legal purpose.'
            )}
          </p>
          <div className={classes.header}>
            {tr('PROHIBITED CONDUCT ##terms title')}
          </div>
          <p>
            {tr(
              'You may not access or use, or attempt to access or use, the Solution to take any action that could harm ACM or any third party, interfere with the operation of the Solution, or use the Solution in a manner that violates any laws. For example, and without limitation, you may not and you agree not to:'
            )}
          </p>
          <ul>
            <li>
              {tr(
                'record the interaction with the provider; ##terms list item'
              )}
            </li>
            <li>
              {tr(
                'transmit any message or information under a false name or otherwise misrepresent your affiliation or the origin of materials you transmit; ##terms list item'
              )}
            </li>
            <li>
              {tr(
                'provide information on the enrollment form that is untrue, inaccurate, not current, or incomplete; ##terms list item'
              )}
            </li>
            <li>
              {tr(
                'transmit any message or information that is unlawful, libelous, defamatory, obscene, fraudulent, predatory of minors, harassing, threatening, or hateful; ##terms list item'
              )}
            </li>
            <li>
              {tr(
                'reproduce, retransmit, distribute, disseminate, sell, publish, broadcast, or circulate content received through the Solution to anyone without prior express permission; ##terms list item'
              )}
            </li>
            <li>
              {tr(
                'take any action that imposes an unreasonable or disproportionately large load on AscensionConnect’s network or infrastructure; ##terms list item'
              )}
            </li>
            <li>
              {tr(
                'use any device, software, or routine to interfere or attempt to interfere with the proper working of the Solution by any method, including through the use of viruses, Trojan horses, worms, time bombs, denial of service attacks, flooding or spamming, or any activity conducted on the Solution or attempt to probe, scan, test the vulnerability of, or breach the security of any system or network; ##terms list item'
              )}
            </li>
            <li>
              {tr(
                'attempt to modify, translate, decipher, decompile, disassemble, reverse- engineer, or create derivative works of any of the software comprising or in any way making up a part of the Solution; or ##terms list item'
              )}
            </li>
            <li>
              {tr(
                'engage in any other conduct that restricts or inhibits any person from using or enjoying the Solution, or that, in AscensionConnect’s sole judgment, exposes AscensionConnect or any of AscensionConnect’s users, affiliates, or any other third party to any liability, damages, or detriment of any type.'
              )}
            </li>
          </ul>
          <p>
            {tr(
              'Violations of system or network security may result in civil or criminal liability. AscensionConnect may investigate and work with law enforcement authorities to prosecute users who violate the Terms. AscensionConnect may suspend or terminate your access to the Solution for any or no reason at any time without notice.'
            )}
          </p>
        </li>
        <li>
          <div className={classes.header}>
            {tr('INTELLECTUAL PROPERTY RIGHTS ##terms title')}
          </div>
          <p>
            {tr(
              'The Solution and all of the content available on or through the Solution are protected under the copyright laws of the United States and possibly other countries. All copyrights in the content of the Solution and the Solution are owned by AscensionConnect or AscensionConnect’s Vendor or third-party licensors to the full extent permitted under the United States Copyright Act and all international copyright laws. You may not publish, reproduce, distribute, display, perform, edit, adapt, modify, or otherwise exploit any part of the Solution without AscensionConnect’s written consent.'
            )}
          </p>
          <p>
            {tr(
              'All rights in the product names, company names, trade names, logos, service marks, trade dress, slogans, product packaging, and designs of the Solution, whether or not appearing in large print or with the trademark symbol, belong exclusively to AscensionConnect or its licensors and are protected from reproduction, imitation, dilution, or confusing or misleading uses under national and international trademark and copyright laws. The use or misuse of these trademarks or any materials, except as authorized herein, is expressly prohibited, and nothing stated or implied on the Solution confers on you any license or right under any patent or trademark of AscensionConnect, its affiliates, or any third party without the express written permission of AscensionConnect or such third-party that may own the trademark.'
            )}
          </p>
          <p>
            {tr(
              'Subject to these Terms, AscensionConnect through its Vendor, hereby grants you a limited, revocable, non-transferable and non-exclusive license to use the software, network facilities, content and documentation on and in the Solution to the extent, and only to the extent, necessary to access and use the Solution.'
            )}
          </p>
          <p>
            {tr(
              'If when using the Solution you communicate to AscensionConnect suggestions for improvements, ideas, enhancement requests or other feedback in connection with the Solution (“Feedback”), AscensionConnect shall own all right, title, and interest in and to the same, even if you have designated the Feedback as confidential, and AscensionConnect shall be entitled to use the Feedback without restriction.'
            )}
          </p>
        </li>
        <li>
          <div className={classes.header}>
            {tr('DISCLAIMERS ##terms title')}
          </div>
          <p>
            {tr(
              'YOUR USE OF THE SOLUTION IS AT YOUR OWN RISK. THE SOLUTION ARE PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON- INFRINGEMENT, OR OTHER VIOLATION OF RIGHTS. ASCENSIONCONNECT AND VENDOR DO NOT WARRANT THE ADEQUACY, CURRENCY, ACCURACY, LIKELY RESULTS, OR COMPLETENESS OF THE SOLUTION OR ANY THIRD-PARTY SITES LINKED TO OR FROM THE SOLUTION, OR THAT THE FUNCTIONS PROVIDED WILL BE UNINTERRUPTED, VIRUS-FREE, OR ERROR-FREE. ASCENSIONCONNECT AND VENDOR EXPRESSLY DISCLAIM ANY LIABILITY FOR ANY ERRORS OR OMISSIONS IN THE CONTENT INCLUDED IN THE SOLUTION OR ANY THIRD- PARTY SITES LINKED TO OR FROM THE SOLUTION. SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.'
            )}
          </p>
          <p>
            {tr(
              'YOU SHALL BE SOLELY AND FULLY RESPONSIBLE FOR ANY DAMAGE TO THE SOluTION OR ANY COMPUTER SYSTEM, ANY LOSS OF DATA, OR ANY IMPROPER USE OR DISCLOSURE OF INFORMATION ON THE SOLUTION CAUSED BY YOU OR ANY PERSON USING YOUR USERNAME OR PASSWORD. ASCENSIONCONNECT CANNOT AND DOES NOT ASSUME ANY RESPONSIBILITY FOR ANY LOSS, DAMAGES OR LIABILITIES ARISING FROM THE FAILURE OF ANY TELECOMMUNICATIONS INFRASTRUCTURE, OR THE INTERNET OR FOR YOUR MISUSE OF ANY PROTECTED HEALTH INFORMATION, ADVICE, IDEAS, INFORMATION, INSTRUCTIONS OR GUIDELINES ACCESSED THROUGH THE SOLUTION.'
            )}
          </p>
        </li>
        <li>
          <p>
            <div className={classes.header}>
              {tr('LIMITATION OF LIABILITY ##terms title')}
            </div>
            {tr(
              'IN THE EVENT OF ANY PROBLEM WITH THE SOLUTION OR ANY OF ITS CONTENT, YOU AGREE THAT YOUR SOLE REMEDY FOR ANY CLAIM ARISING OUT OF OR CONNECTED WITH THE SOLUTION WILL BE TO CEASE USING THE SOLUTION.'
            )}
          </p>
          <p>
            {tr(
              'IN NO EVENT WILL ASCENSIONCONNECT, OR ASCENSIONCONNECT’S PARENTS, SUBSIDIARIES, AFFILIATES, LICENSORS, SUPPLIERS AND THEIR DIRECTORS, OFFICERS, AFFILIATES, SUBCONTRACTORS, EMPLOYEES, AGENTS, AND ASSIGNS BE LIABLE FOR ANY DIRECT OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, LOST PROFITS, OR OTHER DAMAGES WHATSOEVER ARISING IN CONNECTION WITH THE USE OF THE SOLUTION OR ANY OF ITS CONTENT, ANY INTERRUPTION IN AVAILABILITY OF THE SOLUTION, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, LOSS OF DATA, OR USE, MISUSE, RELIANCE, REVIEW, MANIPULATION, OR OTHER UTILIZATION IN ANY MANNER WHATSOEVER OF THE SOLUTION OR THE DATA COLLECTED THROUGH THE SOLUTION, EVEN IF ONE OR MORE OF THEM HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSS.'
            )}
          </p>
          <p>
            {tr(
              'UNDER NO CIRCUMSTANCES SHALL ASCENSIONCONNECT, VENDOR OR SUPPLIERS OR ANY THIRD PARTY WHO PROMOTES THE Solution OR PROVIDES YOU WITH A LINK TO THE SOLUTION, BE LIABLE FOR ANY PUNITIVE, EXEMPLARY, CONSEQUENTIAL, INCIDENTAL, INDIRECT OR SPECIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, ANY PERSONAL INJURY, LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA ON YOUR COMPUTER OR OTHERWISE) ARISING FROM OR IN CONNECTION WITH YOUR USE OF THE SOLUTION, WHETHER UNDER A THEORY OF BREACH OF CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF ASCENSIONCONNECT OR YOU HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.'
            )}
          </p>
          <p>
            {tr(
              'YOU HEREBY RELEASE AND HOLD ASCENSIONCONNECT, VENDOR, SUPPLIERS AND PROVIDERS AND ANY THIRD PARTY WHO PROMOTES THE Solution OR PROVIDES YOU WITH A LINK TO THE SERVICE HARMLESS FROM ANY AND ALL CLAIMS, DEMANDS, AND DAMAGES OF EVERY KIND AND NATURE (INCLUDING, WITHOUT LIMITATION, ACTUAL, SPECIAL, INCIDENTAL AND CONSEQUENTIAL), KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR USE OF THE Solution. YOU WAIVE THE PROVISIONS OF ANY STATE OR LOCAL LAW LIMITING OR PROHIBITING A GENERAL RELEASE.'
            )}
          </p>
          <p>
            {tr(
              'YOU ACKNOWLEDGE AND AGREE THAT ASCENSIONCONNECT’S VENDOR IS NOT ENGAGED IN THE PRACTICE OF MEDICINE AND THAT IT IS NOT DETERMINING APPROPRIATE MEDICAL USE OF THE Solution. IT, ITS LICENSORS, SUPPLIER AND ALL THIRD PARTIES WHO PROMOTE THE Solution OR PROVIDE YOU WITH A LINK TO THE Solution EXPRESSLY DISCLAIMS ANY AND ALL LIABILITY RESULTING FROM THE DELIVERY OF HEALTHCARE VIA THE PLATFORM SERVICE, INCLUDING, BUT NOT LIMITED TO LIABILITY FOR MEDICAL MALPRACTICE. ASCENSIONCONNECT IS THE PARTY PROVIDING THE TELEMEDICINE SERVICE.'
            )}
          </p>
          <p>
            {tr(
              "BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, ASCENSIONCONNECT'S LIABILITY IN SUCH JURISDICTIONS SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW."
            )}
          </p>
        </li>
        <li>
          <div className={classes.header}>
            {tr('INDEMNIFICATION ##terms title')}
          </div>
          <p>
            {tr(
              "YOU AGREE TO INDEMNIFY, DEFEND AND HOLD ASCENSIONCONNECT AND ASCENSIONCONNECT’S PARENTS, SUBSIDIARIES, AFFILIATES, LICENSORS, SUPPLIERS AND THEIR DIRECTORS, OFFICERS, AFFILIATES, SUBCONTRACTORS, EMPLOYEES, AGENTS, AND ASSIGNS HARMLESS FROM AND AGAINST ANY AND ALL LOSS, COSTS, EXPENSES (INCLUDING REASONABLE ATTORNEYS' FEES AND EXPENSES), CLAIMS, DAMAGES AND LIABILITIES RELATED TO OR ASSOCIATED WITH YOUR USE OF THE SOLUTION, ANY ALLEGED VIOLATION BY YOU OF THESE TERMS AND ANY THIRD PARTY CLAIMS ARISING OUR OF YOUR FAILURE TO COMPLY WITH ANY APPLICABLE LAWS AND REGULATIONS. ASCENSIONCONNECT RESERVE THE RIGHT TO ASSUME THE EXCLUSIVE DEFENSE OF ANY CLAIM FOR WHICH ASCENSIONCONNECT ARE ENTITLED TO INDEMNIFICATION UNDER THIS SECTION. IN SUCH EVENT, YOU SHALL PROVIDE ASCENSIONCONNECT WITH SUCH COOPERATION AS ASCENSIONCONNECT REASONABLY REQUESTS."
            )}
          </p>
        </li>
        <li>
          <div className={classes.header}>
            {tr('NO INTERNATIONAL USE ##terms title')}
          </div>
          <p>
            {tr(
              'The Solution is intended for use by residents of the United States. AscensionConnect does not intend to intentionally provide access to the Solution to individuals located outside the United States. AscensionConnect makes no representation that the information and services provided on the Solution are applicable to, appropriate for, or available to users in locations outside the United States. Accessing the Solution from territories where the content is illegal is prohibited. If you choose to access the site from a location outside the U.S., you do so on your own initiative and you are responsible for compliance with local laws.'
            )}
          </p>
        </li>
        <li>
          <div className={classes.header}>
            {tr('TERMINATION ##terms title')}
          </div>
          <p>
            {tr(
              'AscensionConnect or Vendor, if directed by AscensionConnect, may suspend or terminate your access to the Solution at any time, for any reason or for no reason at all. AscensionConnect has the right (but not the obligation) to refuse to provide access to the Solution to any person, agency or organization at any time, for any reason or for no reason at all, in our sole discretion. AscensionConnect reserves the right to change, suspend, or discontinue all or part of the Solution, temporarily or permanently, without prior notice.'
            )}
          </p>
        </li>
        <li>
          <div className={classes.header}>
            {tr('OPERATION AND RECORD RETENTION ##terms title')}
          </div>
          <p>
            {tr(
              'AscensionConnect and Vendor reserve complete and sole discretion with respect to the operation of the Solution. AscensionConnect, through its Vendor, may, among other things withdraw, suspend or discontinue any functionality or feature of the Solution. Subject to applicable law, AscensionConnect reserves the right to maintain, delete or destroy all communications and materials posted or uploaded to the Solution pursuant to its internal record retention and/or destruction policies.'
            )}
          </p>
        </li>
        <li>
          <div className={classes.header}>
            {tr('MISCELLANEOUS ##terms title')}
          </div>
          <p>
            {tr(
              'You agree that your access to and use of the Solution will be governed by and will be construed in accordance with the laws of the State of Missouri without regard to principles of conflicts of laws. You agree that any claim or dispute against AscensionConnect arising out of or relating to the Solution must be resolved by a federal district court located in St. Louis County, Missouri, unless agreed upon by all parties.'
            )}
          </p>
          <p>
            {tr(
              'You acknowledge that the terms of agreement with your respective mobile network provider will continue to apply when using the Solution. As a result, You may be charged by the mobile provider for access to network connection services for the duration of the connection while accessing the Solution or any such third-party charges as may arise. You accept responsibility for any such charges, fees or taxes that arise or are incurred while using the Solution.'
            )}
          </p>
          <p>
            {tr(
              'These Terms constitute the entire agreement between you and AscensionConnect, superseding any prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and AscensionConnect. In the event any provision of these Terms is held unenforceable, it will not affect the validity or enforceability of the remaining provisions and will be replaced by an enforceable provision that comes closest to the intention underlying the unenforceable provision. You agree that no joint venture, partnership, employment, or agency relationship exists between you and AscensionConnect as a result of these Terms or your access to and use of the Solution. You may not assign or otherwise transfer this Agreement or any rights or obligations hereunder without AscensionConnect’s prior written consent.'
            )}
          </p>
          <p>
            {tr(
              'AscensionConnect’s failure to enforce any provisions of these Terms or respond to a violation by any party does not waive AscensionConnect’s right to subsequently enforce any terms or conditions of the Terms or respond to any violations. Nothing contained in these Terms is in derogation of AscensionConnect’s right to comply with governmental, court, and law enforcement requests or requirements relating to your use of the Solution or information provided to or gathered by AscensionConnect with respect to such use.'
            )}
          </p>
        </li>
      </ul>
    </Container>
  );
}
