import React from 'react';
import { colors } from '../../../theme';

export default function CopyIcon({
  color,
  disabled = false,
}: {
  color?: string;
  disabled: boolean;
}) {
  color =
    color ||
    (disabled ? colors.grayscale.disabled : colors.primaryPalette.brandBlue);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="copy_24px">
        <path
          id="icon/content/copy_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5 1H4.5C3.39999 1 2.5 1.89999 2.5 3V16C2.5 16.55 2.95001 17 3.5 17C4.04999 17 4.5 16.55 4.5 16V4C4.5 3.45001 4.95001 3 5.5 3H15.5C16.05 3 16.5 2.54999 16.5 2C16.5 1.45001 16.05 1 15.5 1ZM19.5 5H8.5C7.39999 5 6.5 5.89999 6.5 7V21C6.5 22.1 7.39999 23 8.5 23H19.5C20.6 23 21.5 22.1 21.5 21V7C21.5 5.89999 20.6 5 19.5 5ZM9.5 21H18.5C19.05 21 19.5 20.55 19.5 20V8C19.5 7.45001 19.05 7 18.5 7H9.5C8.95001 7 8.5 7.45001 8.5 8V20C8.5 20.55 8.95001 21 9.5 21Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
