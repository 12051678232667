import { useEffect, useState } from 'react';

export default function useHeight(
  adjustment = 0,
  asNumber = false
): string | number {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const onResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', onResize);
    const intervalCheck = setInterval(onResize, 1000);

    return () => {
      window.removeEventListener('resize', onResize);
      clearInterval(intervalCheck);
    };
  });

  const finalHeight = height + adjustment;

  return asNumber ? finalHeight : finalHeight + 'px';
}
