import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import {
  FormControlLabel,
  FormGroup,
  Switch,
  Divider,
} from '@material-ui/core';
import React from 'react';
import tr from '../../../utils/tr';
import {
  isChrome,
  isChromium,
  isMobile,
  isEdgeChromium,
} from 'react-device-detect';
import { makeStyles } from '@material-ui/styles';
import theme from '../../../theme';

const useStyles = makeStyles({
  group: {
    '& .MuiTypography-root': {
      fontSize: '0.9em',
    },
    '& .MuiFormControlLabel-root': {
      justifyContent: 'space-between',
      margin: `0px`,
    },
  },
  customizedSwitch: {
    '& .MuiSwitch-switchBase': {
      color: '#000000DE',
    },
    '& .Mui-checked': {
      color: '#1E69D2',
    },
    '& .MuiSwitch-track': {
      color: '#00000080',
    },
    '& .Mui-disabled': {
      color: '#BFBFBF',
    },
  },
});

export default function BlurSwitch() {
  const classes = useStyles();
  const {
    blurDesired,
    setBlurDesired,
    blurred,
    videoInDisabled,
    videoError,
  } = useVideoContext();
  if ((!isChrome && !isChromium && !isEdgeChromium) || isMobile) return null;

  return (
    <>
      <Divider
        style={{
          margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px 0px`,
        }}
      />
      <FormGroup className={classes.group}>
        <FormControlLabel
          label={tr('Blur my background ##device label')}
          labelPlacement="start"
          disabled={videoInDisabled || !!videoError}
          control={
            <Switch
              className={classes.customizedSwitch}
              color="primary"
              checked={blurDesired}
              disabled={blurDesired && !blurred}
              inputProps={{ 'aria-label': 'blur background' }}
              onChange={() => setBlurDesired(!blurDesired)}
            />
          }
        />
      </FormGroup>
    </>
  );
}
