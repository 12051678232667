import React from 'react';
import { Button, Toolbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import tr from '../../utils/tr';
import theme, { colors, overBarZIndex } from '../../theme';
import clsx from 'clsx';
import useFeatureContext from '../../hooks/useFeatureContext/useFeatureContext';
import { delayNSecondsBeforeAutoscroll } from '../../utils/settings';
import { getTextDirectionOriginIsRight } from '../../utils';

const useStyles = makeStyles({
  toolbar: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    justifyContent: 'center',
    backgroundColor: colors.secondary.blueLight2,
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    zIndex: overBarZIndex,
  },
  textAndButtonWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    margin: `0 ${theme.spacing(1)}px`,
  },
  text: {
    fontWeight: 500,
  },
  button: {
    height: '1.6em',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: 0,
  },
  body: {
    flex: 1,
  },
});

export default function FeatureBanner({
  children,
}: {
  children: React.ReactNode;
}) {
  const { visibleGroup, dismissGroup, selectGroup } = useFeatureContext();
  const classes = useStyles();
  const wrapRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (visibleGroup && wrapRef.current) {
        wrapRef.current.scrollIntoView();
      }
    }, delayNSecondsBeforeAutoscroll * 1000);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [visibleGroup]);

  const showBar = visibleGroup && !visibleGroup.isSelected;

  return (
    <div ref={wrapRef}>
      {showBar && (
        <Toolbar color="secondary" className={classes.toolbar}>
          <div
            className={classes.textAndButtonWrap}
            style={{
              flexDirection: getTextDirectionOriginIsRight()
                ? 'row-reverse'
                : 'row',
            }}
            data-testid="featureBannerTextAndButtonWrap"
          >
            <div style={{ display: 'inline-block' }}>
              <span className={classes.title} data-testid="feature-title">
                {visibleGroup?.barTitle || ''}
              </span>
              <span className={classes.text} data-testid="feature-text">
                {visibleGroup?.barText || ''}
              </span>
            </div>
            <Button
              onClick={selectGroup}
              className={classes.button}
              color="primary"
              data-testid="see-feature-button"
            >
              {tr('See Feature ##featurebanner button')}
            </Button>
          </div>
          <Button
            onClick={dismissGroup}
            className={classes.closeButton}
            data-testid="feature-dismiss-button"
          >
            <CloseIcon />
          </Button>
        </Toolbar>
      )}
      <div className={clsx(showBar && classes.body)}>{children}</div>
    </div>
  );
}
