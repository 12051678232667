import clsx from 'clsx';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { colors } from '../../../theme';
import {
  ApptParticipantStatusType,
  ApptContentEnum,
} from '../../../state/appt/apptTypes';
import getExpectedApptStatuses, {
  SuccessOrFailedApptStatusForDisplayEnum,
} from '../getExpectedApptStatusMap';

const useStyles = makeStyles({
  status: {
    display: 'block',
    fontSize: '0.9em',
    color: colors.primaryPalette.brandBlue,
    position: 'relative',
    top: '-0.1em',
  },
  error: {
    color: colors.notifications.error,
    backgroundColor: '#FFF7E6 !important',
  },
  statusChip: {
    backgroundColor: '#E8F5FE',
    padding: '0px 8px',
    textTransform: 'uppercase',
    fontSize: '10px',
    lineHeight: '16px',
    borderRadius: '10px',
  },
});

export default function AppointmentParticipantStatus({
  className,
  participantStatuses,
  participantType,
  isInCallPage = false,
}: {
  className: string;
  participantStatuses: ApptParticipantStatusType[];
  participantType: ApptContentEnum;
  isInCallPage?: boolean;
}) {
  const classes = useStyles();
  const expectedApptStatuses = getExpectedApptStatuses(
    participantStatuses,
    participantType
  );

  const lastStatus = expectedApptStatuses[expectedApptStatuses.length - 1];
  if (!lastStatus?.successOrFailed) return null;

  return (
    <div
      className={clsx(
        className,
        classes.status,
        lastStatus.successOrFailed ===
          SuccessOrFailedApptStatusForDisplayEnum.FAILED && classes.error,
        isInCallPage && classes.statusChip
      )}
    >
      {lastStatus.text}
    </div>
  );
}
