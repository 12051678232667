import ResponsiveDialog from '../ResponsiveDialog/ResponsiveDialog';
import TestAudioVideo from '../TestAudioVideo/TestAudioVideo';
import React from 'react';
import theme from '../../theme';
import { Container } from '@material-ui/core';
import useQuery from '../../hooks/useQuery/useQuery';
import { QUERY_TEST_AUDIO_VIDEO_STEP } from '../../constants';
import { useHistory } from 'react-router-dom';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';

export default function TestAudioVideoDialog() {
  const history = useHistory();
  const query = useQuery();

  const showStep = React.useCallback(
    (newStepIndex: number | null) => {
      if (newStepIndex === null) {
        analyticEvent({
          label: 'Test Audio/Video close',
          action: ACTION_CALL_TO_ACTION,
        });
        history.replace({
          search: ``,
        });
      } else {
        history.replace({
          search: `?${QUERY_TEST_AUDIO_VIDEO_STEP}=${newStepIndex}`,
        });
      }
    },
    [history]
  );

  const showingAudioVideo = query.get(QUERY_TEST_AUDIO_VIDEO_STEP) !== null;

  return (
    <ResponsiveDialog
      open={showingAudioVideo}
      onDismiss={() => showStep(null)}
      maxWidth="xs"
    >
      <Container
        style={{
          paddingTop: theme.spacing(5),
          paddingBottom: theme.spacing(2),
        }}
      >
        <TestAudioVideo showStep={showStep} />
      </Container>
    </ResponsiveDialog>
  );
}
