import { fetchWithTimeout, getDefaultHeaders } from '../../../utils';
import {
  ApptFetchErrorTypeEnum,
  ApptFetchErrorAboutEnum,
  ApptFetchResponseType,
} from '../apptHookTypes';
import { ApptContentEnum } from '../../../state/appt/apptTypes';

export enum OutgoingBodyParticipantTypeEnum {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export const OutgoingBodyParticipantMap = {
  [ApptContentEnum.EMAIL]: OutgoingBodyParticipantTypeEnum.EMAIL,
  [ApptContentEnum.PHONE]: OutgoingBodyParticipantTypeEnum.PHONE,
};

export type OutgoingBodyAddedParticipantType = {
  name?: string;
  content: string;
  type: OutgoingBodyParticipantTypeEnum;
};

export type OutgoingBodyRemovedParticipantType = {
  id: number;
};

type OutgoingBodyType = {
  appointmentId: string;
  inviteMessage?: string;
  addedParticipants?: OutgoingBodyAddedParticipantType[];
  removedParticipants?: OutgoingBodyRemovedParticipantType[];
  targetAdid?: string | null;
};

interface UpdateApptInterface {
  appointmentId: string;
  inviteMessage?: string;
  addedParticipants?: OutgoingBodyAddedParticipantType[];
  removedParticipants?: OutgoingBodyRemovedParticipantType[];
  baseAccessToken: string;
  targetAdid: string | null;
}

export default function updateAppt<T>({
  appointmentId,
  inviteMessage,
  addedParticipants,
  removedParticipants,
  baseAccessToken,
  targetAdid,
}: UpdateApptInterface): Promise<ApptFetchResponseType<undefined>> {
  return new Promise((resolve) => {
    const { SCHEDULING_SERVICE } = window.config;
    const headers = getDefaultHeaders();
    headers.set('Authorization', `Bearer ${baseAccessToken}`);
    headers.set('Content-Type', 'application/json');

    const body: OutgoingBodyType = {
      appointmentId,
      inviteMessage,
      addedParticipants,
      removedParticipants,
      targetAdid: targetAdid || null,
    };

    fetchWithTimeout(`${SCHEDULING_SERVICE}/appointment`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (!res) {
          return resolve({
            error: {
              message: 'Error updating appointment',
              type: ApptFetchErrorTypeEnum.SERVER_ERROR,
              about: ApptFetchErrorAboutEnum.UPDATE_APPOINTMENT,
            },
          });
        }
        if (res.status !== 200) {
          let responseType = ApptFetchErrorTypeEnum.ACCESS_DENIED;
          if (res.status >= 500) {
            responseType = ApptFetchErrorTypeEnum.SERVER_ERROR;
          } else if (res.status >= 400) {
            responseType = ApptFetchErrorTypeEnum.INVALID_REQUEST;
          }
          return resolve({
            error: {
              message: `Error updating appointment: ${res.status}`,
              type: responseType,
              status: res.status,
              about: ApptFetchErrorAboutEnum.UPDATE_APPOINTMENT,
            },
          });
        }
        resolve({});
      })
      .catch((err) => {
        resolve({
          error: {
            message: 'Error in updating appointment: ' + err,
            about: ApptFetchErrorAboutEnum.UPDATE_APPOINTMENT,
          },
        });
      });
  });
}
