import React from 'react';
import Participant from '../Participant/Participant';
import { styled } from '@material-ui/core/styles';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import { LocalParticipant, RemoteParticipant } from 'twilio-video';
import theme from '../../theme';
import { isNonPlaceholder } from '../../hooks/useParticipants/participantAssertions';

const Container = styled('div')({
  pointerEvents: 'none',
  overflow: 'hidden',
  display: 'grid',
  gridGap: '4px',
  height: '100%',
  gridTemplateColumns: 'repeat(2, calc(50% - 2px))',
  gridTemplateRows: 'repeat(2, calc(50% - 2px))',
  gridTemplateAreas: '"videoItem0 videoItem0" "videoItem0 videoItem0"',
});

export default function ParticipantMaximized() {
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const [selectedParticipant] = useSelectedParticipant();
  const [pickedParticipant, setPickedParticipant] = React.useState<
    RemoteParticipant | LocalParticipant | null
  >(null);
  React.useEffect(() => {
    const newRemotePart = participants.filter((p): p is RemoteParticipant => isNonPlaceholder(p)).find(
      (part) => part === selectedParticipant
    );
    // If the new selected participant is neither remote nor local (probably null),
    // null-out the displayed pickedParticipant (but delay to allow transition)
    let timeout: NodeJS.Timeout | null = null;
    if (!newRemotePart && selectedParticipant !== localParticipant) {
      // Record timeout to clear, if we need to reconsider selected participant
      timeout = setTimeout(
        () => setPickedParticipant(null),
        theme.custom.videoPanelTransitionSpeed
      );
    } else {
      // Use whatever participant is selected, otherwise set null (null shouldn't happen)
      setPickedParticipant(
        localParticipant === selectedParticipant
          ? localParticipant
          : newRemotePart || null
      );
    }

    // If the timeout was set to null out the picked participant, clear it if the effect is cleared (to prevent race conditions)
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [participants, localParticipant, selectedParticipant]);

  return (
    <Container>
      {pickedParticipant !== null && (
        <Participant
          style={{ gridArea: 'videoItem0' }}
          participant={pickedParticipant}
          enableScreenShare
          disableAudio
          priority="high"
          isMain
        />
      )}
    </Container>
  );
}
