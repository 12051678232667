import React from 'react';
import { OnErrorType, TrackType } from './UseMediaTypes';
import useReleaseTrack from './useReleaseTrack';

interface UseTrackDisabledInterface {
  track: TrackType;
  disableDesired: boolean;
  onError: OnErrorType;
}

export default function useTrackDisabled({
  track,
  disableDesired,
  onError,
}: UseTrackDisabledInterface) {
  const trackIsEnabled = track.track?.isEnabled || false;
  const [disabled, setDisabled] = React.useState(true);
  const releaseTrack = useReleaseTrack();

  React.useEffect(() => {
    setDisabled(!trackIsEnabled);
  }, [trackIsEnabled]);

  React.useEffect(() => {
    if (track.track) {
      if (trackIsEnabled && disableDesired) {
        releaseTrack(track);
        track.track.disable();
        track.track.stop();
        setDisabled(true);
      } else if (!trackIsEnabled && !disableDesired) {
        track.track
          .restart()
          .then(() => {
            if (track.track) {
              track.track.enable();
            }
            setDisabled(false);
          })
          .catch((err) => {
            onError('Error on restarting track during re-enable:' + err);
          });
      }
    }
  }, [
    releaseTrack,
    onError,
    setDisabled,
    trackIsEnabled,
    track,
    disableDesired,
  ]);

  return disabled;
}
