interface IntervalConfig {
  skipInitial?: boolean;
  signal?: AbortSignal;
  intervalTimeMs?: number;
}

interface AsyncIntervalTimerConfig extends IntervalConfig {
  waitToStart?: boolean;
}

/***
 * AsyncIntervalTimer
 *
 * Advanced Timing Control
 *   * pending callback can be invoked immediately which resets the timer
 *   * pending timer can be stopped and restarted at a later time as needed
 *   * Cancel using AbortController API by providing an AbortSignal to constructor or .start() method
 *   * Can also call .stop() to cancel timer
 *   * Update time by calling .start({ intervalTimeMs: #, skipInitial: true })
 *   	with updated time. Pending timer will be stopped and new timer will be set immediately
 */
export default class AsyncIntervalTimer {
  private _internalAbortController: AbortController = new AbortController();
  private _externalSignal: AbortSignal | undefined;
  private _pollInterval = 0;
  private _intervalI = 0;
  private readonly _cancelPendingInterval: () => void = () =>
    this._internalAbortController.abort();

  /***
   *
   * @param _callback - required - Function to call on each interval.
   * @param intervalTimeMsOrConfig - Interval time in milliseconds number OR config object with properties below
   * @param config - see options below - all optional
   * @param config.intervalTimeMs - Defaults to 0; time between intervals after callback resolves
   * @param config.signal - AbortSignal created with an AbortController
   * @param config.skipInitial- when falsy, callback called immediately on start before first timeout
   * @param config.waitToStart - when falsy, interval starts immediately by constructor
   *
   * @example
   * new AsyncIntervalTimer(() => Promise.resolve('Hi'), 5000)
   * @example
   * new AsyncIntervalTimer(() => 'You', { intervalTimeMs: 3000, signal: new AbortController().signal, skipInitial: true, waitToStart: true })
   */
  constructor(
    _callback: (intervalI: number) => Promise<unknown>,
    config: AsyncIntervalTimerConfig
  );
  constructor(_callback: () => Promise<unknown>);
  constructor(
    _callback: () => Promise<unknown>,
    intervalTimeMs: number
  );
  constructor(
    private _callback?: (intervalI: number) => Promise<unknown>,
    intervalTimeMsOrConfig: number | AsyncIntervalTimerConfig = {
      intervalTimeMs: 0,
    }
  ) {
    const config: AsyncIntervalTimerConfig = {
      // Safe to assume type since spread of number or null will result in an empty object
      ...(intervalTimeMsOrConfig as AsyncIntervalTimerConfig),
    };
    if (config.waitToStart) {
      return this;
    }

    if (typeof intervalTimeMsOrConfig === 'number') {
      config.intervalTimeMs = intervalTimeMsOrConfig;
    }
    this._externalSignal = config.signal;
    this._pollInterval = config.intervalTimeMs || 0;
    this.start(config);
  }

  private async _interval({
    skipInitial,
    signal,
    intervalTimeMs,
  }: IntervalConfig = {}) {
    this._externalSignal = signal || this._externalSignal;
    this._pollInterval =
      intervalTimeMs === undefined ? this._pollInterval : intervalTimeMs;

    if (!skipInitial && this._callback) {
      // Wait on the first run
      await this._callback(this._intervalI);
    }

    // Only  current when pollInterval is falsy (0, undefined, null)
    if (!this._pollInterval) {
      return;
    }

    let intervalId = 0;

    const cancelTimeout = () => {
      clearTimeout(intervalId);
      this._internalAbortController.signal.removeEventListener(
        'abort',
        cancelTimeout
      );
      if (this._externalSignal) {
        this._externalSignal.removeEventListener('abort', cancelTimeout);
      }
    };

    intervalId = (setInterval(() => {
      this._callback?.(this._intervalI);
      this._intervalI += 1;
    }, this._pollInterval) as unknown) as number;

    this._internalAbortController.signal.addEventListener(
      'abort',
      cancelTimeout
    );
    if (this._externalSignal) {
      this._externalSignal.addEventListener('abort', cancelTimeout);
    }
  }

  public stop() {
    this._cancelPendingInterval();
  }

  public start(config?: IntervalConfig) {
    this.stop();

    this._internalAbortController = new AbortController();
    this._interval(config);
  }

  public runImmediately() {
    this._intervalI += 1;
    this.start();
  }

  // Remove leftover references to prevent memory leak.
  public destroy() {
    this.stop();
    delete this._callback;
  }
}
