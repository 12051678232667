import NextButton from '../NextButton/NextButton';
import React from 'react';
import VideoInputList from '../../MenuBar/DeviceSelector/VideoInputList/VideoInputList';
import VideoInputThumbnail from '../../MenuBar/DeviceSelector/VideoInputThumbnail/VideoInputThumbnail';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { FormHelperText } from '@material-ui/core';
import tr from '../../../utils/tr';

export default function TestVideoIn({ onNext }: { onNext: () => void }) {
  const { devices, videoInDisabled } = useVideoContext();
  const noCameras = devices.videoIn.length === 0;
  const buttonDisabled = noCameras || videoInDisabled;

  return (
    <>
      <VideoInputThumbnail
        style={{
          marginLeft: 'calc(50% - 75px)',
          width: '150px',
          height: '150px',
        }}
      />
      <br />
      {noCameras ? (
        <FormHelperText data-testid="videoInHelperText" error>
          {tr('No camera detected. Please connect a device. ##media')}
        </FormHelperText>
      ) : (
        <VideoInputList />
      )}
      <NextButton
        onNext={
          buttonDisabled
            ? () => {
                // default empty on next implementation
              }
            : onNext
        }
        disabled={buttonDisabled}
        text={tr('My Camera is Working ##media button')}
        disabledText={tr('Looking for Devices ##media button')}
      />
    </>
  );
}
