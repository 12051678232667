import React from 'react';
import { Typography, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { borderRadius, colors } from '../../theme';
import tr from '../../utils/tr';
import useIsAllowedToBeHost from '../../hooks/useIsAllowedToBeHost/useIsAllowedToBeHost';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    div: {
      backgroundColor: colors.grayscale.gray11,
      padding: theme.custom.boxPaperPadding,
      borderRadius: borderRadius[1],
    },
    error: {
      fontWeight: 500,
      fontSize: '14px',
    },
  })
);
export default function LogoutError() {
  const styled = useStyles();
  const isAllowedToBeHost = useIsAllowedToBeHost();
  return (
    <div className={styled.div}>
      <Typography classes={{ root: styled.error }}>
        {isAllowedToBeHost
          ? tr(
              'You have been automatically logged out after 15 minutes. Invites can not be sent at this time. ##error message'
            )
          : tr('You are not authorized to send invites. ##error message')}
      </Typography>
    </div>
  );
}
