import React from 'react';
import useShowInRightPanel from '../../hooks/useShowInRightPanel/useShowInRightPanel';
import ChatButtonIcon from './ChatButtonIcon';
import { RightPanelKeyEnum } from '../../interfaces/RightPanelContentTypes';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';

export default function ChatButton() {
  const { toggleWhich } = useShowInRightPanel();
  const { messages } = useChatContext();
  const hasUnreadMessage = messages.find((message) => !message.isRead);

  return (
    <ChatButtonIcon
      onClick={() => {
        analyticEvent({ label: 'Open Chat', action: ACTION_CALL_TO_ACTION });
        toggleWhich(RightPanelKeyEnum.CHAT);
      }}
      isBadged={!!hasUnreadMessage}
    />
  );
}
