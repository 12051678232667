import tr from '../../../utils/tr';
import React from 'react';
import ErrorWrapper from '../../ErrorWrapper/ErrorWrapper';
import { makeStyles } from '@material-ui/styles';
import AppointmentBreadcrumb from '../AppointmentBreadcrumb/AppointmentBreadcrumb';
import AppointmentPageWrap from '../AppointmentPageWrap/AppointmentPageWrap';

const useStyles = makeStyles({
  outerWrap: {
    height: '100%',
  },
  outerFlexWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrap: {
    flex: 1,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default function AppointmentMissing() {
  const classes = useStyles();

  return (
    <AppointmentPageWrap
      className={classes.outerWrap}
      innerClassName={classes.outerFlexWrap}
    >
      <AppointmentBreadcrumb
        steps={[
          {
            text: tr('VIEW ALL APPOINTMENTS ##appt-detail crumb'),
            url: '/appointment',
          },
        ]}
      />
      <div className={classes.wrap} data-testid="errorWrap">
        <ErrorWrapper
          title={tr('Appointment not found ##appt-missing')}
          subtitle={tr(
            'This appointment could not be found. Please select another appointment. ##appt-missing'
          )}
          icon="/static/error_outline.svg"
        />
      </div>
    </AppointmentPageWrap>
  );
}
