import { useEffect, useState } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';
import { RoomState } from './RoomState';

export default function useRoomState() {
  const { room } = useVideoContext();
  const [state, setState] = useState<RoomState>(RoomState.DISCONNECTED);

  useEffect(() => {
    const setRoomState = () =>
      setState((room.state as RoomState) || RoomState.DISCONNECTED);
    setRoomState();
    room
      .on('disconnected', setRoomState)
      .on('reconnected', setRoomState)
      .on('reconnecting', setRoomState);
    return () => {
      room
        .off('disconnected', setRoomState)
        .off('reconnected', setRoomState)
        .off('reconnecting', setRoomState);
    };
  }, [room]);

  return state;
}
