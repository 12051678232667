import React from 'react';
import useAuthBaseContext from '../useAuthBaseContext/useAuthBaseContext';
import { SESSION_PROPERTY_USER_AUTH_ROLE } from '../../dynatraceConstants';
import useIsAllowedToBeHost from '../useIsAllowedToBeHost/useIsAllowedToBeHost';
import { getLogIdentifier } from '../../utils';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';
import { reportError, reportInfo } from '@ascension/report-event';

export default function useReportAuthErrors() {
  const {
    email,
    familyName,
    givenName,
    userRoles,
    userScopes,
    isLoggedIn,
    baseAccessToken,
  } = useAuthBaseContext();
  const isAllowedToBeHost = useIsAllowedToBeHost();

  React.useEffect(() => {
    if (isLoggedIn) {
      reportInfo('Auth: Is logged in');
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    if (baseAccessToken) {
      reportInfo('Auth: Acquired baseAccessToken');
    }
  }, [baseAccessToken]);

  React.useEffect(() => {
    // Report if has email/scope but not authorized
    if (
      (email || userRoles.length > 0 || userScopes.length > 0) &&
      !isAllowedToBeHost
    ) {
      analyticEvent({
        label: 'User email address blocked event',
        action: ACTION_CALL_TO_ACTION,
      });
      reportError('Auth: Email/roles/scopes not authorized to be a host');
    }
    // Report email address (fallback to name)
    const identifier = getLogIdentifier({ email, givenName, familyName });
    reportInfo(`Auth: Identifier: ${identifier}`);
    if (window.dtrum) {
      window.dtrum.identifyUser(identifier);
    }
    // Report rolls
    if (userRoles) {
      reportInfo(`Auth: Roles: ${JSON.stringify(userRoles)}`);
      userRoles.forEach((role) => {
        if (window.dtrum) {
          window.dtrum.sendSessionProperties(undefined, undefined, {
            [SESSION_PROPERTY_USER_AUTH_ROLE]: role,
          });
        }
      });
    }
  }, [isAllowedToBeHost, userRoles, email, familyName, givenName, userScopes]);
}
