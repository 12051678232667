import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import { createStyles, CSSProperties, makeStyles } from '@material-ui/styles';
import {
  formatInviteTarget,
  getTextDirectionOriginIsRight,
} from '../../../utils';
import { colors } from '../../../theme';
import clsx from 'clsx';
import { Theme } from '@material-ui/core/styles';
import AppointmentParticipantStatus from './AppointmentParticipantStatus';
import { ApptParticipantType } from '../../../state/appt/apptTypes';

const noListItem = {
  listStyle: 'none',
  margin: 0,
  padding: 0,
};
const cell: CSSProperties = {
  ...noListItem,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontWeight: 500,
};
const noOverflow: CSSProperties = {
  wordBreak: 'break-word',
};

export interface AppointmentParticipantInterface {
  participant: ApptParticipantType;
  onRemove: (participant: ApptParticipantType) => void;
  showName?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {},
    rowList: {
      ...noListItem,
      display: 'grid',
      gridTemplateColumns: '4fr 4fr 1fr',
      borderBottom: `0.5px ${colors.grayscale.gray12} solid`,
      gridGap: theme.spacing(1),
      paddingTop: '5px',
      paddingBottom: '5px',
    },
    rowListRightDirection: {
      gridTemplateColumns: '2.5fr 2.5fr 1fr',
    },
    rowListTwoColumns: {
      gridTemplateColumns: '8fr 1fr',
    },
    rowListTwoColumnsRightDirection: {
      gridTemplateColumns: '5fr 1fr',
    },
    noListItem,
    cell,
    rightCell: { ...noListItem, textAlign: 'right' },
    noOverflow,
    subCell: {
      ...noOverflow,
    },
  })
);

export default function AppointmentParticipant({
  participant,
  onRemove,
  showName = true,
}: AppointmentParticipantInterface) {
  const classes = useStyles();

  const handleRemove = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onRemove(participant);
  };

  let rowListStyleClass;
  if (showName) {
    rowListStyleClass =
      getTextDirectionOriginIsRight() && classes.rowListRightDirection;
  } else {
    rowListStyleClass = getTextDirectionOriginIsRight()
      ? classes.rowListTwoColumnsRightDirection
      : classes.rowListTwoColumns;
  }

  return (
    <li className={classes.noListItem}>
      <ul className={clsx(classes.rowList, rowListStyleClass)}>
        {showName && (
          <li className={classes.cell}>
            <div className={clsx(classes.subCell)}>
              {participant.name ||
                (participant.type === 'INTERPRETER' ? 'Interpreter' : '')}
            </div>
            {participant.type !== 'INTERPRETER' && (
              <AppointmentParticipantStatus
                className={classes.subCell}
                participantStatuses={participant.statuses}
                participantType={participant.type}
              />
            )}
          </li>
        )}
        <li className={classes.cell}>
          <div className={classes.noOverflow}>
            {participant.content
              ? formatInviteTarget(participant.content)
              : participant.type === 'INTERPRETER'
              ? 'VOYCE'
              : ''}
          </div>
          {!showName && (
            <AppointmentParticipantStatus
              className={classes.subCell}
              participantStatuses={participant.statuses}
              participantType={participant.type}
            />
          )}
        </li>
        <li className={classes.rightCell}>
          <IconButton
            aria-label="close"
            className={classes.deleteButton}
            onClick={handleRemove}
          >
            <CloseIcon />
          </IconButton>
        </li>
      </ul>
    </li>
  );
}
