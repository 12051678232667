import React from 'react';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import tr from '../../../utils/tr';
import SpinnerButton from '../../SpinnerButton/SpinnerButton';
import { useHistory } from 'react-router-dom';
import useGetNewApptId from '../../../hooks/appt/useGetNewApptId/useGetNewApptId';
import useIsMountedRef from '../../../hooks/appt/useIsMountedRef/useIsMountedRef';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../../AnalyticsConfiguration/analyticsConstant';

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  /* istanbul ignore next */
  createStyles({
    addApptBtn: {
      backgroundColor: `${theme.palette.grey[100]} !important`,
    },
  })
);

export default function AddAppointmentButton() {
  const classes = useStyles();
  const history = useHistory();
  const getNewAppointmentId = useGetNewApptId();
  const [gettingAppt, setGettingAppt] = React.useState(false);
  const isMountedRef = useIsMountedRef();

  const addAppointmentHandler = React.useCallback(async () => {
    analyticEvent({ label: 'Add Appointment', action: ACTION_CALL_TO_ACTION });
    setGettingAppt(true);
    const appointmentId = await getNewAppointmentId();
    if (appointmentId) {
      history.push('/appointment/');
      history.replace(`/appointment/${appointmentId}/add`);
    }
    if (isMountedRef.current) setGettingAppt(false);
  }, [getNewAppointmentId, history, isMountedRef]);

  return (
    <SpinnerButton isWaiting={gettingAppt}>
      <Button
        fullWidth
        className={classes.addApptBtn}
        variant="outlined"
        color="primary"
        data-testid="addApptButton"
        onClick={addAppointmentHandler}
        startIcon={gettingAppt ? '' : <AddIcon />}
        disabled={gettingAppt}
      >
        {gettingAppt ? '' : tr('Add Appointment ##summary button')}
      </Button>
    </SpinnerButton>
  );
}
