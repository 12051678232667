import React from 'react';
import { makeStyles } from '@material-ui/styles';
import theme, { colors } from '../../../theme';
import CheckAndJoinButton from '../CheckAndJoinButton/CheckAndJoinButton';
import InvitedParticipantList from '../InvitedParticipantList/InvitedParticipantList';
import AppointmentMissing from '../AppointmentMissing/AppointmentMissing';
import useCurrentAppt from '../../../hooks/appt/useCurrentAppt/useCurrentAppt';
import AppointmentPageWrap from '../AppointmentPageWrap/AppointmentPageWrap';
import tr from '../../../utils/tr';
import AppointmentBreadcrumb from '../AppointmentBreadcrumb/AppointmentBreadcrumb';
import useApptListForDisplay from '../../../hooks/appt/useApptListForDisplay/useApptListForDisplay';
import Appointment, {
  AppointmentDetailType,
} from '../AppointmentsList/Appointment';
import { Divider, useMediaQuery } from '@material-ui/core';
import AppointmentPageHeader from '../AppointmentPageHeader/AppointmentPageHeader';
import {AscensionSpinner} from "../../AscensionSpinner/AscensionSpinner";

/* istanbul ignore next */
const useStyles = makeStyles({
  greyBackgroundWrap: {
    backgroundColor: colors.grayscale.gray11,
  },
  noBottomPaddingWrap: {
    paddingBottom: '0px',
  },
  noTopPaddingWrap: {
    paddingTop: '0px',
  },
    loading:{
        height: '100%',
    },
    loadingsvg: {
        height: '100%',
        margin: "auto"
    }
});

export default function AppointmentDetail() {
  const classes = useStyles();
  const { currentAppointment, appointmentsLoaded } = useCurrentAppt();
  const apptListForDisplay = useApptListForDisplay();
  const appointmentForDisplay:
    | AppointmentDetailType
    | undefined = apptListForDisplay.find((x) => x.id === currentAppointment?.id);
  const screenIsSmall = useMediaQuery(theme.breakpoints.down('sm'));

    if(!appointmentsLoaded) {
        return (<div className={classes.loading}><AscensionSpinner className={classes.loadingsvg} /></div>);
    }

  if (!currentAppointment) return <AppointmentMissing />;

  return (
    <>
      <AppointmentPageWrap
        className={
          screenIsSmall
            ? classes.greyBackgroundWrap
            : classes.noBottomPaddingWrap
        }
      >
        <AppointmentBreadcrumb
          steps={[
            {
              text: tr('VIEW ALL APPOINTMENTS ##appt-detail breadcrumb'),
              url: '/appointment',
            },
          ]}
        />
        {screenIsSmall && appointmentForDisplay && (
          <Appointment appointmentDetail={appointmentForDisplay} />
        )}
      </AppointmentPageWrap>
      <AppointmentPageWrap
        className={screenIsSmall ? undefined : classes.noTopPaddingWrap}
      >
        <AppointmentPageHeader appointment={currentAppointment} />
        <CheckAndJoinButton appointment={currentAppointment} />
      </AppointmentPageWrap>
      <Divider />
      <AppointmentPageWrap>
        <InvitedParticipantList
          invitedParticipants={currentAppointment.participants}
          disabled={currentAppointment.appointmentCancelled === true}
        />
      </AppointmentPageWrap>
    </>
  );
}
