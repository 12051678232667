import { useVideoRoomState } from '../../hooks/useVideoRoomState/VideoRoomStateProvider';
import React from 'react';
import { getTimestampFromSeconds } from '../../utils';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import useAuthBaseContext from '../../hooks/useAuthBaseContext/useAuthBaseContext';
import { createStyles } from '@material-ui/core/styles';
import tr from '../../utils/tr';

const useStyles = makeStyles(() =>
  createStyles({
    wrap: {
      position: 'absolute',
      top: '8px',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      minWidth: '180px',
      fontWeight: 500,
      fontSize: '1em',
      textAlign: 'center',
      padding: '0.4em 0.6em 0.2em',
      zIndex: 2,
      borderRadius: '4px',
      color: 'rgba(0, 0, 0, 0.6)',
      boxShadow:
        '0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1)',
      letterSpacing: '0.5px',
    },
    timer: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  })
);

export default function CallTimer({ className }: { className?: string }) {
  const classes = useStyles();
  const { callDurationInSeconds } = useVideoRoomState();
  const { isLoggedIn } = useAuthBaseContext();
  if (!isLoggedIn) {
    return null;
  }

  return (
    <div className={clsx(classes.wrap, className)}>
      {`${tr('Call Duration ##appt-callduration label')}: `}
      <span className={classes.timer}>
        {getTimestampFromSeconds(callDurationInSeconds)}
      </span>
    </div>
  );
}
