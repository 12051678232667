import useDevice from './useDevice';
import useTrackDisabled from './useTrackDisabled';
import { AudioTrackType, DeviceType, OnErrorType } from './UseMediaTypes';

interface UseAudioTrackInterface {
  devices: DeviceType[];
  refreshDevices: () => void;
  disableDesired?: boolean;
  deviceIdDesired?: string | null;
  onError: OnErrorType;
  retryKey: object;
}

export default function useAudioTrack({
  devices,
  refreshDevices,
  disableDesired = false,
  deviceIdDesired,
  onError,
  retryKey,
}: UseAudioTrackInterface) {
  const { track, deviceId } = useDevice({
    which: 'audio',
    deviceIdDesired,
    devices: devices,
    refreshDevices,
    onError,
    retryKey,
  });
  const disabled = useTrackDisabled({ track, disableDesired, onError });

  return {
    disabled,
    deviceId,
    track: track as AudioTrackType,
  };
}
