import React from 'react';
import { ApptParticipantType, ApptType } from '../../../state/appt/apptTypes';
import useEditParticipant from '../../../components/appt/EditParticipant/useEditParticipant';

export enum ParticipantTempStatusEnum {
  ADDED = 'added',
  REMOVED = 'removed',
}

export type ApptParticipantWithTempStatusType = ApptParticipantType & {
  tempStatus?: ParticipantTempStatusEnum;
};

// Hook just for component AppointmentUpdateForm
export default function useAppointmentUpdateForm({
  appointment,
}: {
  appointment: ApptType;
}) {
  const [participants, setParticipants] = React.useState<
    ApptParticipantWithTempStatusType[]
  >(appointment.participants);
  const [inviteMessage, setInviteMessage] = React.useState<string>(
    appointment.inviteMessage || ''
  );
  const { EditParticipant, ...editParticipantProps } = useEditParticipant({
    allowNoName: true,
    hideName: false,
  });

  React.useEffect(() => {
    setParticipants([...appointment.participants]);
  }, [appointment]);

  const onAddParticipant = React.useCallback(
    (newParticipant: ApptParticipantWithTempStatusType) => {
      // Flag as "added"
      newParticipant.tempStatus = ParticipantTempStatusEnum.ADDED;
      setParticipants((currParticipants) => [
        ...currParticipants,
        newParticipant,
      ]);

      // Force update
      editParticipantProps.setName('');
      editParticipantProps.setContent('');
    },
    [editParticipantProps]
  );

  const onRemoveParticipant = React.useCallback(
    (participant: ApptParticipantType) => {
      setParticipants((currParticipants) =>
        currParticipants.map((currParticipant) => {
          return {
            ...currParticipant,
            tempStatus:
              currParticipant.id === participant.id
                ? ParticipantTempStatusEnum.REMOVED
                : currParticipant.tempStatus,
          };
        })
      );
    },
    []
  );

  return {
    participants,
    onRemoveParticipant,
    onAddParticipant,
    inviteMessage,
    setInviteMessage,
    EditParticipant,
    editParticipantProps,
  };
}
