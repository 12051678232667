import React from 'react';
import { TimeSensitiveMessage } from './messageTypes';
import { SentMetadata } from './useSendMessage';

type UseResendProps<T> = {
  messages: TimeSensitiveMessage<T>[];
  resendMessage: (id: string) => (T & SentMetadata) | undefined;
  autoResend: boolean;
  onMessageNotReceived?: (message: T & SentMetadata) => void;
};

export const notAlreadyResent = <T>(messages: TimeSensitiveMessage<T>[]) => {
  // Find which messages have been resent
  const resent = messages.reduce((result, message) => {
    if (message.resendOf) {
      result[message.resendOf] = true;
      result[message.id] = true;
    }
    return result;
  }, {} as Record<string, boolean>);

  // Remove messages which are either resent or fully ACKed
  return messages.filter(
    (message) =>
      message.receivedByCount !== message.sentToCount && !resent[message.id]
  );
};

const useHandleNotReceived = <T>({
  messages,
  resendMessage,
  autoResend,
  onMessageNotReceived,
}: UseResendProps<T>) => {
  // Process failed messages, if requested
  React.useEffect(() => {
    if (onMessageNotReceived) {
      messages
        .filter((message) => message.pastConfirmedDeadline)
        .forEach((message) =>
          onMessageNotReceived({
            ...message.message,
            id: message.id,
            sentById: message.sentById,
            sentByMe: message.sentByMe,
            pastConfirmedDeadline: message.pastConfirmedDeadline,
          })
        );
    }
  }, [messages, onMessageNotReceived]);

  // Auto-resend if requested
  React.useEffect(() => {
    // If not auto-resending, do not search to resend
    if (!autoResend) {
      return;
    }

    // Resend all past due messages
    notAlreadyResent(messages)
      .filter((message) => message.pastConfirmedDeadline)
      .forEach((message) => resendMessage(message.id));
  }, [autoResend, messages, resendMessage]);
};
export default useHandleNotReceived;
