import React from 'react';
import useTrack from '../../hooks/useTrack/useTrack';
import AudioTrack from '../AudioTrack/AudioTrack';
import VideoTrack from '../VideoTrack/VideoTrack';

import {
  LocalTrackPublication,
  RemoteTrackPublication,
  Track,
} from 'twilio-video';
import { IAudioTrack, IVideoTrack } from '../../types';

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  isLocal: boolean;
  disableAudio?: boolean;
  disableVideo?: boolean;
  videoPriority?: Track.Priority | null;
  isMain?: boolean;
}

export default function Publication({
  publication,
  isLocal,
  disableAudio,
  disableVideo,
  videoPriority,
  isMain,
}: PublicationProps) {
  const track = useTrack(publication);

  if (!track.track) return null;

  switch (track.track.kind) {
    case 'video':
      if (disableVideo) {
        return null;
      }
      return (
        <VideoTrack
          track={track as IVideoTrack}
          priority={videoPriority}
          isLocal={isLocal}
          isMain={isMain}
          // If screen-share, contain the video instead of covering
          sizing={isMain ? 'contain' : 'cover'}
          // if track has name of "screen", it is screen share
          isScreenShare={track.track.name.includes('screen')}
        />
      );
    case 'audio':
      return disableAudio ? null : <AudioTrack track={track as IAudioTrack} />;
    default:
      return null;
  }
}
