import React from 'react';
import { SetReservations } from './reservationTypes';

type ClaimReservationProps = {
  setReservations: SetReservations;
};

export default function useClaimReservation({
  setReservations,
}: ClaimReservationProps) {
  // Remove a reservation from the list, if valid
  return React.useCallback(
    (claimedReservationTag: string) => {
      setReservations((reservations) => {
        const toRemove = reservations.find(
          (reservation) => reservation.tag === claimedReservationTag
        );
        if (toRemove) {
          return reservations.filter(
            (reservation) => reservation.id !== toRemove.id
          );
        }
        return reservations;
      });
    },
    [setReservations]
  );
}
