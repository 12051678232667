import React from 'react';
import { useAuthMethods } from '@ascension/ascensionid-sdk';
import { BrowserAuthError } from '@azure/msal-browser';
import useAuthBaseContext from './useAuthBaseContext';

export default function useAuthRedirects() {
  const { login } = useAuthMethods();
  const { requireLogin, setRequireLogin } = useAuthBaseContext();

  React.useEffect(() => {
    if (!!login && requireLogin) {
      // If the login() function is called before the previous login() function
      // finishes then this error will be thrown. We catch the error and do not
      // require another login.
      login().catch((err: BrowserAuthError) => {
        if (err.errorCode === 'interaction_in_progress') {
          setRequireLogin(false);
        }
      });
    }
  }, [login, requireLogin, setRequireLogin]);
}
