import React from 'react';
import { IAudioTrack } from '../../types';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';

interface AudioTrackProps {
  track: IAudioTrack;
}

// Add the missing setSinkId to the default type
interface HTMLAudioElementWithSinkId extends HTMLAudioElement {
  setSinkId: (sinkId: string) => void;
}

export default function AudioTrack({ track }: AudioTrackProps) {
  const { audioOutDeviceId } = useVideoContext();
  const audioEl = React.useRef<HTMLAudioElementWithSinkId | null>(null);
  const lastTrack = React.useRef<LocalAudioTrack | RemoteAudioTrack | null>(
    null
  );

  const cleanup = React.useCallback(() => {
    if (lastTrack.current && audioEl.current) {
      try {
        lastTrack.current.detach(audioEl.current);
      } catch (_) {
        // do nothing
      }
    }
  }, []);

  React.useEffect(() => {
    const currTrack = track.track;
    // If element & track present, and track is changing, tear down old and add new
    if (audioEl.current && currTrack && currTrack !== lastTrack.current) {
      cleanup();
      currTrack.attach(audioEl.current);
      lastTrack.current = currTrack;
    }
  }, [cleanup, track]);

  React.useEffect(() => {
    if (audioEl.current?.setSinkId && audioOutDeviceId) {
      audioEl.current.setSinkId(audioOutDeviceId);
    }
  }, [audioOutDeviceId]);

  React.useEffect(() => {
    // Cleanup at component tear-down
    return cleanup;
  }, [cleanup]);

  return (
    <audio ref={audioEl} data-cy-audio-track-name={track.track.name} autoPlay />
  );
}
