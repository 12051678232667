import { Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';
import BrowserIsOk from './components/BrowserIsOk/BrowserIsOk';
import AuthBase from './components/AuthBase/AuthBase';
import ToastProvider from './components/ToastProvider/ToastProvider';
import Landing from './components/Landing/Landing';
import VideoApp from './VideoApp';
import AutoLogoutDialog from './components/AutoLogoutDialog/AutoLogoutDialog';
import CallComplete from './components/CallComplete/CallComplete';
import SecureRoute from './components/SecureRoute/SecureRoute';
import Logout from './components/Logout/Logout';
import TermsPage from './components/TermsPage/TermsPage';
import AppointmentPage from './components/appt/AppointmentPage/AppointmentPage';
import Components from './components/Demo/Components';
import useSetupTelemetry from './hooks/useSetupTelemetry/useSetupTelemetry';
import ApptStateProvider from './state/appt/ApptStateProvider';
import { FeatureContextProvider } from './hooks/useFeatureContext/useFeatureContext';
import GeneralError from './components/appt/GeneralError/GeneralError';
import WatchTimeMismatch from './components/appt/WatchTimeMismatch/WatchTimeMismatch';
import { RouteEnum } from './RouteEnum';
import { getTextDirectionOriginIsRight } from './utils';

export default function Routes() {
  useSetupTelemetry();

  return (
    <div style={getTextDirectionOriginIsRight() ? { textAlign: 'right' } : {}}>
      <BrowserIsOk>
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <AuthBase>
            <ApptStateProvider>
              <div style={{ position: 'relative' }}>
                <ToastProvider>
                  <FeatureContextProvider>
                    <Switch>
                      <Route path={RouteEnum.CALL + ':URLRoomToken'}>
                        <VideoApp />
                      </Route>
                      <Route path={RouteEnum.FEEDBACK + ':URLRoomToken'}>
                        <AutoLogoutDialog />
                        <CallComplete feedbackWasSent={false} />
                      </Route>
                      <Route path={RouteEnum.COMPLETE + ':URLRoomToken'}>
                        <AutoLogoutDialog />
                        <CallComplete feedbackWasSent={true} />
                      </Route>
                      <SecureRoute
                        path={RouteEnum.APPOINTMENT + '/:URLRoomToken?'}
                      >
                        <AutoLogoutDialog />
                        <AppointmentPage />
                      </SecureRoute>
                      <Route path={RouteEnum.LOGOUT}>
                        <Logout />
                      </Route>
                      <Route path={RouteEnum.TERMS}>
                        <TermsPage />
                      </Route>
                      <Route path={RouteEnum.COMPONENTS}>
                        <Components />
                      </Route>
                      <Redirect to="/" />
                    </Switch>
                  </FeatureContextProvider>
                </ToastProvider>
                <WatchTimeMismatch />
                <GeneralError />
              </div>
            </ApptStateProvider>
          </AuthBase>
        </Switch>
      </BrowserIsOk>
    </div>
  );
}
