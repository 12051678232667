import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/styles';
import MenuBar from '../MenuBar/MenuBar';
import {
  APPLICATION_TITLE_HEIGHT,
  FLOATING_BUTTON_POSITION,
} from '../../constants';
import { colors } from '../../theme';

const useStyles = makeStyles({
  wrapper: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.grayscale.white,
  },
  centerWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '1.2em',
    marginTop: APPLICATION_TITLE_HEIGHT + -FLOATING_BUTTON_POSITION,
  },
});

export default function NotificationPage({
  children,
}: {
  children: ReactNode;
}) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <MenuBar />
      <div className={classes.centerWrapper}>{children}</div>
    </div>
  );
}
