import { makeStyles } from '@material-ui/styles';
import { WrappedParticipant } from '../useFilteredParticipants/useFilteredParticipants';

const useStyles = makeStyles({
  disabledElement: {
    pointerEvents: 'none',
  },
  containerOne: {
    // One giant panel
    gridTemplateAreas:
      '"videoItem0 videoItem0 videoItem0 videoItem0 videoItem0 videoItem0 ' +
      'videoItem0 videoItem0 videoItem0 videoItem0 videoItem0 videoItem0" ' +
      '"videoItem0 videoItem0 videoItem0 videoItem0 videoItem0 videoItem0 ' +
      'videoItem0 videoItem0 videoItem0 videoItem0 videoItem0 videoItem0"',
  },
  containerTwo: {
    // Two above/below panels
    gridTemplateAreas:
      '"videoItem0 videoItem0 videoItem0 videoItem0 videoItem0 videoItem0 ' +
      'videoItem0 videoItem0 videoItem0 videoItem0 videoItem0 videoItem0" ' +
      '"videoItem1 videoItem1 videoItem1 videoItem1 videoItem1 videoItem1 ' +
      'videoItem1 videoItem1 videoItem1 videoItem1 videoItem1 videoItem1"',
    '@media (min-aspect-ratio: 1/1)': {
      // Two side-by-side panels
      gridTemplateAreas:
        '"videoItem0 videoItem0 videoItem0 videoItem0 videoItem0 videoItem0 ' +
        'videoItem1 videoItem1 videoItem1 videoItem1 videoItem1 videoItem1" ' +
        '"videoItem0 videoItem0 videoItem0 videoItem0 videoItem0 videoItem0 ' +
        'videoItem1 videoItem1 videoItem1 videoItem1 videoItem1 videoItem1"',
    },
  },
  containerThree: {
    // Two panels on top, one centered on the bottom
    gridTemplateAreas:
      '"videoItem0 videoItem0 videoItem0 videoItem0 videoItem0 videoItem0 ' +
      'videoItem1 videoItem1 videoItem1 videoItem1 videoItem1 videoItem1" ' +
      '". . . videoItem2 videoItem2 videoItem2 ' +
      'videoItem2 videoItem2 videoItem2 . . ."',
    '@media (min-aspect-ratio: 3/2)': {
      // Three panels side-by-side
      gridTemplateAreas:
        '"videoItem0 videoItem0 videoItem0 videoItem0 videoItem1 videoItem1 ' +
        'videoItem1 videoItem1 videoItem2 videoItem2 videoItem2 videoItem2" ' +
        '"videoItem0 videoItem0 videoItem0 videoItem0 videoItem1 videoItem1 ' +
        'videoItem1 videoItem1 videoItem2 videoItem2 videoItem2 videoItem2" ',
    },
  },
  containerFour: {
    // Four panels in a grid
    gridTemplateAreas:
      '"videoItem0 videoItem0 videoItem0 videoItem0 videoItem0 videoItem0 ' +
      'videoItem1 videoItem1 videoItem1 videoItem1 videoItem1 videoItem1" ' +
      '"videoItem2 videoItem2 videoItem2 videoItem2 videoItem2 videoItem2 ' +
      'videoItem3 videoItem3 videoItem3 videoItem3 videoItem3 videoItem3"',
  },
});

export default function useParticipantStyle(
  isMinimized: boolean,
  filteredParticipants: WrappedParticipant[]
) {
  const classes = useStyles();
  let whichContainerStyle = classes.containerOne;
  const showingMoreThanSelf = !isMinimized && filteredParticipants.length > 1;
  // Set container layout
  if (showingMoreThanSelf) {
    if (filteredParticipants.length === 2)
      whichContainerStyle = classes.containerTwo;
    else if (filteredParticipants.length === 3)
      whichContainerStyle = classes.containerThree;
    else if (filteredParticipants.length === 4)
      whichContainerStyle = classes.containerFour;
  }
  return whichContainerStyle;
}
