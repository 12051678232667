import { useApptStateContext } from '../../../state/appt/ApptStateProvider';
import { AppointmentDetailType } from '../../../components/appt/AppointmentsList/Appointment';
import tr from '../../../utils/tr';
import { getApptDisplayStatus } from '../../../state/appt/apptStateUtils';
import React from 'react';
import { updateRelativeApptTimesEveryNSeconds } from '../../../utils/settings';
import {
  filterParticipantListForDisplay,
  getFirstParticipantName,
} from '../../../components/appt/utils';
import { sortApptListForDisplay, flagClosestTime } from '../apptHookUtils';

// Get appoint list adapted to side-bar
export default function useApptListForDisplay(): AppointmentDetailType[] {
  const { state } = useApptStateContext();

  const makeList = React.useCallback((): AppointmentDetailType[] => {
    const list = !state.appointments
      ? []
      : state.appointments.map(
          (appointment): AppointmentDetailType => {
            const filteredParticipants = filterParticipantListForDisplay(
              appointment.participants
            );
            const name =
              getFirstParticipantName(filteredParticipants) ||
              tr('New Appointment ##appt-list');

            const status = getApptDisplayStatus({
              startTime: appointment.startTime,
              endTime: appointment.endTime,
              appointmentCancelled: appointment.appointmentCancelled,
              type: appointment.type,
            });
            return {
              id: appointment.id,
              startTime: appointment.startTime,
              name,
              status,
              messageCount: 0,
              inviteCount: filteredParticipants.length,
              callDuration: 0,
              waitDuration: 0,
              waitCount: 0,
              isClosestTime: false,
              type: appointment.type,
            };
          }
        );

    return flagClosestTime(sortApptListForDisplay(list));
  }, [state.appointments]);

  const [listForDisplay, setListForDisplay] = React.useState<
    AppointmentDetailType[]
  >(makeList);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setListForDisplay(makeList);
    }, updateRelativeApptTimesEveryNSeconds * 1000);
    // Run now
    setListForDisplay(makeList);
    return () => clearInterval(interval);
  }, [makeList]);

  return listForDisplay;
}
