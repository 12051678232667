import { DevicesType, DeviceType } from './UseMediaTypes';
import { getStandardFacing } from './utils';

export const defaultDevices: DevicesType = {
  audioIn: [],
  audioOut: [],
  videoIn: [],
};

export default function getDevices(): Promise<DevicesType> {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices
      .enumerateDevices()
      .then(function (devices) {
        const newDevices = devices.reduce((cumm, curr) => {
          cumm = { ...cumm };
          const newDevice: DeviceType = {
            id: curr.deviceId,
            name: curr.label,
            facing: getStandardFacing(curr.label),
          };
          if (curr.kind === 'audioinput')
            cumm.audioIn = [...cumm.audioIn, newDevice];
          else if (curr.kind === 'audiooutput')
            cumm.audioOut = [...cumm.audioOut, newDevice];
          else if (curr.kind === 'videoinput')
            cumm.videoIn = [...cumm.videoIn, newDevice];
          return cumm;
        }, defaultDevices);
        resolve(newDevices);
      })
      .catch(function (err: Error) {
        reject('Error obtaining devices: ' + err.name + ': ' + err.message);
      });
  });
}
