import {
  FeaturesType,
  FeatureGroupsType,
  FeatureGroupType,
} from './featureTypes';
import React, { useContext } from 'react';
import {
  features as defaultFeatures,
  featureGroups as defaultFeatureGroups,
  FeatureEnum,
  FeatureGroupEnum,
} from './features';
import FeatureBanner from '../../components/FeatureTooltip/FeatureBanner';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useFeatureIsDisplayable from './useFeatureIsDisplayable';
import useFeatureTooltipRegister from './useFeatureTooltipRegister';
import useFeatureGroupSelectDismiss from './useFeatureGroupSelectDismiss';
import useFeatureDismissFeature from './useFeatureDismissFeature';
dayjs.extend(utc);

type TooltipContextType = {
  features: FeaturesType;
  register: (featureId: FeatureEnum) => void;
  deregister: (featureId: FeatureEnum) => void;
  selectGroup: () => void;
  visibleGroup: FeatureGroupType | null;
  dismissGroup: () => void;
  dismissFeature: (featureId: FeatureEnum) => void;
};

const defaultFunction = () => console.error('TooltipProvider is not present');

const FeatureContext = React.createContext<TooltipContextType>({
  features: defaultFeatures,
  register: defaultFunction,
  deregister: defaultFunction,
  visibleGroup: null,
  dismissGroup: defaultFunction,
  dismissFeature: defaultFunction,
  selectGroup: defaultFunction,
});

export function FeatureContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [features, setFeatures] = React.useState<FeaturesType>(defaultFeatures);
  const [featureGroups, setFeatureGroups] = React.useState<FeatureGroupsType>(
    defaultFeatureGroups
  );
  const [registeredFeatureIds, setRegisteredFeatureIds] = React.useState<
    FeatureEnum[]
  >([]);
  const firstDisplayableFeature = Object.values(features).find(
    (feature) => feature.isDisplayable && !feature.isDismissed
  );
  const visibleGroupId = (Object.keys(featureGroups).find(
    (currFeatureGroupId) =>
      currFeatureGroupId === firstDisplayableFeature?.groupId
  ) || null) as FeatureGroupEnum | null;
  useFeatureIsDisplayable({ registeredFeatureIds, featureGroups, setFeatures });
  const { register, deregister } = useFeatureTooltipRegister({
    setRegisteredFeatureIds,
  });
  const { selectGroup, dismissGroup } = useFeatureGroupSelectDismiss({
    setFeatures,
    visibleGroupId,
    setFeatureGroups,
  });
  const dismissFeature = useFeatureDismissFeature({ setFeatures });

  return (
    <FeatureContext.Provider
      value={{
        features,
        register,
        deregister,
        visibleGroup: visibleGroupId && featureGroups[visibleGroupId],
        dismissFeature,
        selectGroup,
        dismissGroup,
      }}
    >
      <FeatureBanner>{children}</FeatureBanner>
    </FeatureContext.Provider>
  );
}

export default function useFeatureContext() {
  return useContext(FeatureContext);
}
