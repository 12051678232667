import { useEffect, useState } from 'react';
import { Participant, NetworkQualityStats } from 'twilio-video';
import Telemetry from '../../utils/telemetry';

interface CallQualityStats {
  participantId: string;
  networkQualityLevel: number | null;
  networkQualityStats: unknown;
  [key: string]: unknown;
}

export default function useParticipantNetworkQualityLevel(
  participant: Participant
) {
  const [networkQualityLevel, setNetworkQualityLevel] = useState(
    participant.networkQualityLevel
  );

  useEffect(() => {
    let cNetworkQualityStats: NetworkQualityStats | null = null;

    const handleNetworkQualityLevelChange = (
      newNetworkQualityLevel: number,
      networkQualityStats: NetworkQualityStats
    ) => {
      setNetworkQualityLevel(newNetworkQualityLevel);
      cNetworkQualityStats = networkQualityStats;
      Telemetry.acquireCurrent();
    };

    setNetworkQualityLevel(participant.networkQualityLevel);

    Telemetry.register<CallQualityStats>('callQualityStats', () => {
      return {
        participantId: participant.sid,
        networkQualityLevel: participant.networkQualityLevel,
        networkQualityStats: cNetworkQualityStats,
      };
    });

    participant.on(
      'networkQualityLevelChanged',
      handleNetworkQualityLevelChange
    );
    return () => {
      participant.off(
        'networkQualityLevelChanged',
        handleNetworkQualityLevelChange
      );
    };
  }, [participant]);

  return networkQualityLevel;
}
