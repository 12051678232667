import {
  emitter,
  ReportErrorEventType,
  ReportInfoEventType,
} from '@ascension/report-event';
import { userIdentifier } from './userIdentifier';

const reporter = (
  reportContent: ReportErrorEventType | ReportInfoEventType,
  isError: boolean
) => {
  const stackTrace =
    'stackTrace' in reportContent ? reportContent.stackTrace : '';
  if (
    !window?.location?.href?.includes('video.ascension.org') ||
    window?.location?.search?.includes('enableReport=true')
  ) {
    if (isError) {
      console.error(userIdentifier, reportContent.message, stackTrace);
    } else {
      console.log(userIdentifier, reportContent.message, stackTrace);
    }
  }
};

export default function setReportListeners() {
  emitter.on('error', (reportError: ReportErrorEventType) =>
    reporter(reportError, true)
  );
  emitter.on('info', (reportInfo: ReportInfoEventType) =>
    reporter(reportInfo, false)
  );
}
