import React from 'react';
import { devicePresent } from './utils';
import { DeviceType, FacingModeType } from './UseMediaTypes';

interface UseVideoTrackInterface {
  devices: DeviceType[];
  deviceIdDesired?: string | null;
  facingDesired?: FacingModeType;
}

export default function useSpeaker({
  devices,
  deviceIdDesired,
}: UseVideoTrackInterface) {
  const [deviceId, setDeviceId] = React.useState<string | null>(null);

  React.useEffect(() => {
    // If device present, switch to it
    if (deviceIdDesired && devicePresent(deviceIdDesired, devices)) {
      setDeviceId(deviceIdDesired);
      return;
    }
    // Otherwise fallback to first or to null
    setDeviceId(devices[0]?.id || null);
  }, [deviceIdDesired, devices]);
  return {
    deviceId,
  };
}
