import React from 'react';
import { ChatContext } from './ChatProvider';

export default () => {
  const context = React.useContext(ChatContext);
  if (!context) {
    throw new Error(
      'useChatContext must be used within a ChatProvider from ChatContext'
    );
  }
  return context;
};
