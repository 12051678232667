/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core/styles';
import tr from '../../utils/tr';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    invitationCharactersLeft: {
      ...theme.custom.subscriptStyle,
      position: 'relative',
      top: '-6px',
    },
  })
);

export default function CharactersLeft({
  chars,
  charsAllowed,
}: {
  chars: string;
  charsAllowed: number;
}) {
  const classes = useStyles();

  let charactersLeft = charsAllowed - chars.length;
  if (charactersLeft < 0) {
    charactersLeft = 0;
  }

  return (
    <div id="charsLeft" className={classes.invitationCharactersLeft}>
      {tr('Characters left: ##input subtext') + ' ' + charactersLeft}
    </div>
  );
}
