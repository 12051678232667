import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { colors } from '../../theme';
import Typography from '@material-ui/core/Typography';
import tr from '../../utils/tr';

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    color: colors.grayscale.white,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    padding: '1em',
    pointerEvents: 'none',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontSize: '28px',
      lineHeight: '34px',
      fontWeight: 400,
      textAlign: 'center',
    },
  },
  root: {
    gridArea: 'mainParticipant',
    boxSizing: 'content-box',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

export default function WaitingBackdrop({
  showing = false,
  styles = {},
}: {
  showing?: boolean;
  styles?: object;
}) {
  const classes = useStyles();
  return (
    <div
      id="waitingBackdrop"
      style={{ opacity: showing ? 1 : 0, ...styles }}
      className={classes.backdrop}
    >
      <Typography>
        {tr('Waiting for other participants to join ##video title')}
      </Typography>
    </div>
  );
}
