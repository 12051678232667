interface Kvp {
  [key: string]: unknown | null;
}

export const abbreviate = (input: Record<string, unknown>) => {
  const abbreviated: Kvp | null = {};
  let counter = 0;
  if (input) {
    Object.keys(input)
      .sort()
      .forEach((key) => {
        const v: unknown | null = input[key.toString()];
        let converted: unknown = null;
        let abbreviatedKey =
          key.length > 2 ? key.charAt(0) + key.slice(-1)[0] : key;
        converted = v;
        if (typeof v == 'object' && v != null) {
          converted = abbreviate(v as Record<string, unknown>);
        }
        if (Array.isArray(v)) {
          converted = v.map((entry) => abbreviate(entry));
        }
        if (abbreviatedKey in abbreviated) {
          abbreviatedKey = abbreviatedKey + counter.toString();
          counter += 1;
        }
        abbreviated[abbreviatedKey] = converted;
      });
  }
  return abbreviated;
};
