import React from 'react';
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import { Participant as IParticipant, Track } from 'twilio-video';
import { makeStyles } from '@material-ui/styles';
import PersonBackdrop from '../PersonBackdrop/PersonBackdrop';
import { styled } from '@material-ui/core/styles';
import { colors } from '../../theme';
import PinOverlay from '../Controls/PinOverlay/PinOverlay';
import PinButton from '../Controls/PinButton/PinButton';
import VideoAudioStats from '../VideoAudioStats/VideoAudioStats';
import { useVideoRoomState } from '../../hooks/useVideoRoomState/VideoRoomStateProvider';
const Container = styled('div')({
  height: '100%',
  gridArea: 'videoItem',
});

interface ParticipantProps {
  style?: object;
  participant: IParticipant;
  disableAudio?: boolean;
  disableVideo?: boolean;
  enableScreenShare?: boolean;
  onClick?: () => void;
  isDominant?: boolean;
  hideOverlay?: boolean;
  priority?: Track.Priority | null;
  isMain?: boolean;
}

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  inner: {
    position: 'relative',
  },
  dominantBorderOverlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    boxSizing: 'border-box',
    border: `solid 3px ${colors.secondary.green}`,
    zIndex: 2,
  },
  clickableArea: {
    height: '100%',
    width: '100%',
    pointerEvents: 'all',
  },
});

export default function Participant({
  style = {},
  participant,
  disableAudio,
  disableVideo,
  enableScreenShare,
  onClick = () => {
    // default empty on click implementation
  },
  hideOverlay = false,
  isDominant = false,
  priority = null,
  isMain = false,
}: ParticipantProps) {
  const styles = useStyles();

  const { setShowingToolbar } = useVideoRoomState();

  const onScreenClick = () => {
    if (isMain) {
      setShowingToolbar(true);
    }
  };

  return (
    <Container className={styles.inner} style={{ ...style }}>
      {isDominant && <div className={styles.dominantBorderOverlay} />}
      {!hideOverlay && (
        <PinOverlay onClick={onClick}>
          {(onPinButtonClick) => (
            <div style={{ pointerEvents: 'none' }}>
              <PinButton onClick={onPinButtonClick} />
            </div>
          )}
        </PinOverlay>
      )}
      <div
        className={styles.clickableArea}
        onClick={onScreenClick}
        data-testid="videoClickArea"
      >
        <ParticipantInfo
          participant={participant}
          enableScreenShare={enableScreenShare}
        >
          <ParticipantTracks
            participant={participant}
            disableAudio={disableAudio}
            disableVideo={disableVideo}
            enableScreenShare={enableScreenShare}
            videoPriority={priority}
            isMain={isMain}
          />
          <VideoAudioStats participant={participant} />
          <PersonBackdrop zIndex={-1} alt="No Video" />
        </ParticipantInfo>
      </div>
    </Container>
  );
}
