import React from 'react';
import { FormControl, MenuItem, Select, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MiscMediaMessage from '../MiscMediaMessage/MiscMediaMessage';
import DeviceRow from '../DeviceRow/DeviceRow';
import tr from '../../../../utils/tr';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles({
  audioLevelWrap: {
    position: 'relative',
    top: '4px',
    marginRight: '9px',
  },
  select: {
    overflow: 'hidden',
  },
});

export default function AudioOutputList() {
  const classes = useStyles();
  const {
    devices: { audioOut: audioOutDevices },
    setAudioOutDeviceIdDesired,
    audioOutDeviceId,
  } = useVideoContext();

  const activeOutputLabel = audioOutDevices.find(
    (device) => device.id === audioOutDeviceId
  )?.name;
  let showingMessage = 'multiple_options';
  if (audioOutDevices.length === 0) {
    showingMessage = 'no_options';
  } else if (audioOutDevices.length === 1) {
    showingMessage = 'one_option';
  }

  if (showingMessage === 'no_options') {
    return null;
  }
  return (
    <FormControl fullWidth>
      {showingMessage !== 'no_options' && (
        <InputLabel id="select-audio-output">
          {tr('Speaker ##device label')}
        </InputLabel>
      )}
      <DeviceRow>
        {showingMessage === 'multiple_options' && (
          <Select
            labelId="select-audio-output"
            className={classes.select}
            onChange={(e) =>
              setAudioOutDeviceIdDesired(e.target.value as string)
            }
            value={audioOutDeviceId || audioOutDevices[0]?.id || ''}
            label={tr('Audio Output ##device label')}
          >
            {audioOutDevices.map((device) => (
              <MenuItem value={device.id} key={device.id}>
                {device.name}
              </MenuItem>
            ))}
          </Select>
        )}
        {showingMessage === 'one_option' && (
          <MiscMediaMessage
            text={
              activeOutputLabel ||
              tr('Unknown audio output device ##device label')
            }
          />
        )}
      </DeviceRow>
    </FormControl>
  );
}
