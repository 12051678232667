import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core/styles';
import tr from '../../../utils/tr';
import OverlayTextbox from '../../OverlayTextbox/OverlayTextbox';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../../AnalyticsConfiguration/analyticsConstant';
import CharactersLeft from '../../CharactersLeft/CharactersLeft';
import { maxSMSTextLength } from '../../../utils/settings';
import React from 'react';
import {
  makeFullInvitation,
  makeInvitationLink,
  addSpacesAfterPeriods,
} from '../../../utils';
import { trimInviteString } from '../utils';
import { ApptTypeEnum } from '../../../state/appt/apptTypes';
import dayjs, { Dayjs } from 'dayjs';

interface InviteMessageInterface {
  domain: string;
  roomId: string;
  inviteMessage: string;
  setInviteMessage: React.Dispatch<React.SetStateAction<string>>;
  appointmentType: ApptTypeEnum | undefined;
  appointmentStartTime: Dayjs;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appointmentNoteStyle: {
      ...theme.custom.subscriptStyle,
      fontSize: '0.8em',
    },
  })
);

export default function InviteMessage({
  domain,
  roomId,
  inviteMessage,
  setInviteMessage,
  appointmentType,
  appointmentStartTime,
}: InviteMessageInterface) {
  const classes = useStyles();

  const handleOnChange = React.useCallback(
    (event: React.FormEvent<HTMLTextAreaElement>) => {
      event.preventDefault();
      const textBox = event.target as HTMLTextAreaElement;
      setInviteMessage(
        trimInviteString(addSpacesAfterPeriods(textBox.value), domain, roomId)
      );
    },
    [roomId, domain, setInviteMessage]
  );

  return (
    <>
      <Typography variant="h6">
        {tr('Invite message ##appt-add label')}
      </Typography>
      <OverlayTextbox
        onChange={handleOnChange}
        textValue={inviteMessage}
        overlayValue={makeInvitationLink({ domain, token: '...' })}
        onClick={() => {
          analyticEvent({
            label: 'Click Custom Invite Message',
            action: ACTION_CALL_TO_ACTION,
          });
        }}
        disabled={appointmentType === ApptTypeEnum.ATHENA}
      />
      <CharactersLeft
        chars={makeFullInvitation({
          domain,
          token: roomId,
          invitationText: inviteMessage,
        })}
        charsAllowed={maxSMSTextLength}
      />
      {(appointmentType === ApptTypeEnum.ATHENA ||
        appointmentStartTime.diff(dayjs.utc(), 'minute') > 0) && (
        <div className={classes.appointmentNoteStyle}>
          {tr(
            'Note: when an invitee receives the text message, it will include information about when the appointment starts ##appt-add subtext'
          )}
        </div>
      )}
    </>
  );
}
