import React from 'react';
import useVolume from '../useVolume/useVolume';
import { AudioTrackType } from '../../components/VideoProvider/useMedia/UseMediaTypes';

export const LocalVolumeContext = React.createContext<number | null>(0);

interface LocalVolumeProviderContext {
  children: React.ReactNode;
  track: AudioTrackType;
  disabled: boolean;
}

export const LocalVolumeProvider = ({
  children,
  track,
  disabled,
}: LocalVolumeProviderContext) => {
  const volume = useVolume(track, disabled);

  return (
    <LocalVolumeContext.Provider value={volume}>
      {children}
    </LocalVolumeContext.Provider>
  );
};

export const useLocalVolumeContext = () => {
  return React.useContext(LocalVolumeContext);
};
