/* istanbul ignore file */
import AppointmentOptions, {
  AppointmentOptionsEnum,
} from '../appt/AppointmentOptions/AppointmentOptions';
import React from 'react';
import { Button, Typography } from '@material-ui/core';
import CopyButton from '../appt/CopyButton/CopyButton';
import AppointmentBreadcrumb from '../appt/AppointmentBreadcrumb/AppointmentBreadcrumb';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import AppointmentParticipantList from '../appt/AppointmentParticipantList/AppointmentParticipantList';
import AppointmentPage from './AppointmentPage';
import AppointmentEditCancel from '../appt/AppointmentEditCancel/AppointmentEditCancel';
import AppointmentDateSelectMenu from '../appt/AppointmentDate/AppointmentDateSelectMenu';
import AppointmentDateSelectPicker from '../appt/AppointmentDate/AppointmentDateSelectPicker';
import {
  ApptContentEnum,
  ApptParticipantStatusNameEnum,
  ApptParticipantType,
} from '../../state/appt/apptTypes';
import useEditParticipant from '../appt/EditParticipant/useEditParticipant';
import Appointment from '../appt/AppointmentsList/Appointment';
import { AppointmentDetailStatusEnum } from '../../hooks/appt/apptHookTypes';

dayjs.extend(utc);

export default function Components() {
  const [option1, setOption1] = React.useState(AppointmentOptionsEnum.NOW);
  const [option2, setOption2] = React.useState(AppointmentOptionsEnum.NOW);
  const [option3, setOption3] = React.useState(AppointmentOptionsEnum.NOW);
  const { EditParticipant, ...editParticipantProps1 } = useEditParticipant({
    hideName: false,
  });
  const { ...editParticipantProps2 } = useEditParticipant({
    allowNoName: false,
  });
  const { ...editParticipantProps3 } = useEditParticipant({
    allowNoName: true,
  });
  const [appointmentsListDate, setAppointmentsListDate] = React.useState(
    dayjs.utc()
  );

  const participantsWithNames: ApptParticipantType[] = [
    {
      id: 1,
      name: 'Uttar Pradesh',
      content: 'uttar@pradesh.com',
      type: ApptContentEnum.EMAIL,
      statuses: [
        {
          statusType: ApptParticipantStatusNameEnum.INVITE_SENT,
          timeStamp: dayjs.utc().subtract(10, 'minute'),
        },
      ],
    },
    {
      id: 2,
      name: 'Telangana',
      content: '5551112222',
      type: ApptContentEnum.PHONE,
      statuses: [
        {
          statusType: ApptParticipantStatusNameEnum.PARTICIPANT_CONNECTED,
          timeStamp: dayjs.utc(),
        },
      ],
    },
    {
      id: 3,
      name: '',
      content: '5553334444',
      type: ApptContentEnum.PHONE,
      statuses: [
        {
          statusType: ApptParticipantStatusNameEnum.INVITE_DELIVERED,
          timeStamp: dayjs.utc().subtract(10, 'minute'),
        },
      ],
    },
    {
      id: 4,
      name: "This isn't valid",
      content: 'invite here',
      type: ApptContentEnum.NONE,
      statuses: [],
    },
  ];
  const participantsWithoutNames: ApptParticipantType[] = [
    {
      id: 1,
      name: '',
      content: 'uttar@pradesh.com',
      type: ApptContentEnum.EMAIL,
      statuses: [],
    },
    {
      id: 2,
      name: '',
      content: '5551112222',
      type: ApptContentEnum.PHONE,
      statuses: [
        {
          statusType: ApptParticipantStatusNameEnum.INVITE_SENT,
          timeStamp: dayjs.utc().subtract(10, 'minute'),
        },
      ],
    },
  ];

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement | null>(
    null
  );
  const [
    pickerAnchorEl,
    setPickerAnchorEl,
  ] = React.useState<HTMLElement | null>(null);
  let apptDateSelectVariantIndex = -1;
  apptDateSelectVariantIndex = appointmentsListDate.isSame(dayjs(), 'day')
    ? 0
    : apptDateSelectVariantIndex;
  apptDateSelectVariantIndex =
    apptDateSelectVariantIndex === -1 &&
    appointmentsListDate.isSame(dayjs().add(+1, 'day'), 'day')
      ? 1
      : apptDateSelectVariantIndex;
  apptDateSelectVariantIndex =
    apptDateSelectVariantIndex === -1 &&
    appointmentsListDate.isSame(dayjs().add(-1, 'day'), 'day')
      ? 2
      : apptDateSelectVariantIndex;
  apptDateSelectVariantIndex =
    apptDateSelectVariantIndex === -1 ? 3 : apptDateSelectVariantIndex;

  return (
    <>
      <Typography variant="h3">
        Copy Button - disabled w/ tooltip (works with mouse & touch)
      </Typography>
      <CopyButton
        strToBeCopied="you copied this!"
        textCopy="Copy Link"
        tooltipText="Here is some tooltip text"
        textCopied="Link Copied	"
        disabled
      />
      <br />
      <br />
      <br />
      <Typography variant="h3">Appointment Options</Typography>
      <AppointmentOptions
        onChange={setOption1}
        value={option1}
        strToBeCopied="You copied this text"
      />
      <br />
      <br />
      <br />
      <Typography variant="h3">Appointment Options - expired</Typography>
      <AppointmentOptions
        onChange={setOption2}
        value={option2}
        strToBeCopied="You copied this text"
        appointmentExpiration={dayjs.utc().subtract(1, 'second')}
      />
      <br />
      <br />
      <br />
      <Typography variant="h3">Appointment Options - later disabled</Typography>
      <AppointmentOptions
        onChange={setOption3}
        value={option3}
        strToBeCopied="You copied this text"
        disableOption={AppointmentOptionsEnum.LATER}
      />
      <br />
      <br />
      <br />
      <Typography variant="h3">Edit New Participant - without name</Typography>
      <EditParticipant
        {...editParticipantProps1}
        onSubmit={(res) => alert('Submitted: ' + JSON.stringify(res))}
        isInCallPage={false}
      />
      <br />
      <br />
      <br />
      <Typography variant="h3">Edit New Participant - require name</Typography>
      <EditParticipant
        {...editParticipantProps2}
        onSubmit={(res) => alert('Submitted: ' + JSON.stringify(res))}
        isInCallPage={false}
      />
      <br />
      <br />
      <br />
      <Typography variant="h3">Edit New Participant - allow no name</Typography>
      <EditParticipant
        {...editParticipantProps3}
        onSubmit={(res) => alert('Submitted: ' + JSON.stringify(res))}
        isInCallPage={false}
      />
      <br />
      <br />
      <br />
      <Typography variant="h3">Breadcrumb - cancel</Typography>
      <AppointmentBreadcrumb
        steps={[
          {
            url: 'https://somewhereouttherebeneaththepalemoonlight.com',
            text: 'CANCEL',
          },
        ]}
      />{' '}
      <br />
      <br />
      <br />
      <Typography variant="h3">Breadcrumb - multi-step</Typography>
      <AppointmentBreadcrumb
        steps={[
          {
            url: '/',
            text: 'HOME',
          },
          {
            url: '/appointment',
            text: 'APPOINTMENTS',
          },
        ]}
      />
      <br />
      <br />
      <br />
      <Typography variant="h3">Invalid Error Appointment</Typography>
      <Appointment
        appointmentDetail={{
          id: 'appt1',
          mobile: '555-232-9421',
          status: AppointmentDetailStatusEnum.INVITE_ERROR,
          startTime: dayjs(),
          callDuration: 17,
          isClosestTime: false,
        }}
        isSelected={true}
      />
      <br />
      <br />
      <br />
      <Typography variant="h3">
        New Appt Participant List - with names
      </Typography>
      <AppointmentParticipantList
        participants={participantsWithNames}
        onRemove={(participant) => alert('Removing: ' + participant.id)}
      />
      <br />
      <br />
      <br />
      <Typography variant="h3">
        New Appt Participant List - without names
      </Typography>
      <AppointmentParticipantList
        participants={participantsWithoutNames}
        onRemove={(participant) => alert('Removing: ' + participant.id)}
      />
      <br />
      <br />
      <br />
      <Typography variant="h3">Date Picker</Typography>
      <Button
        color="primary"
        onClick={(ev) => setMenuAnchorEl(ev.currentTarget)}
      >
        Show Picker
      </Button>
      <AppointmentDateSelectMenu
        menuAnchorEl={menuAnchorEl}
        setMenuAnchorEl={setMenuAnchorEl}
        setPickerAnchorEl={setPickerAnchorEl}
        setAppointmentsListDate={setAppointmentsListDate}
        apptDateSelectVariantIndex={apptDateSelectVariantIndex}
      />
      <AppointmentDateSelectPicker
        pickerAnchorEl={pickerAnchorEl}
        setPickerAnchorEl={setPickerAnchorEl}
        appointmentsListDate={appointmentsListDate}
        setAppointmentsListDate={setAppointmentsListDate}
      />
      <br />
      <br />
      <br />
      <Typography variant="h3">Edit or Cancel Appointment</Typography>
      <AppointmentEditCancel
        appointmentId="appt1"
        appointmentCancelHandler={() => {
          // empty implementation
        }}
        disabled={false}
      />
      <AppointmentPage />
    </>
  );
}
