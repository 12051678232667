import React from 'react';
import { TimeSensitiveMessage } from './messageTypes';

type ClearMessagesProps<T> = {
  messages: TimeSensitiveMessage<T>[];
  setMessages: React.Dispatch<React.SetStateAction<TimeSensitiveMessage<T>[]>>;
};

export const useClearOldMessages = <T>({
  messages,
  setMessages,
}: ClearMessagesProps<T>) => {
  React.useEffect(() => {
    // Find which messages have been resent
    const resent = messages.reduce((result, message) => {
      if (message.resendOf) {
        result[message.resendOf] = true;
      }
      return result;
    }, {} as Record<string, boolean>);

    // Remove messages which are either resent or fully ACKed
    const outstandingMessages = messages.filter(
      (message) =>
        message.receivedByCount !== message.sentToCount && !resent[message.id]
    );

    // If there were removed messages, update the list
    if (outstandingMessages.length !== messages.length) {
      setMessages(outstandingMessages);
    }
  }, [messages, setMessages]);
};
