import { useEffect, useMemo, useState } from 'react';
import { RemoteParticipant } from 'twilio-video';
import useVideoContext from '../useVideoContext/useVideoContext';
import useSelectedParticipant from '../../components/VideoProvider/useSelectedParticipant/useSelectedParticipant';
import { isNonPlaceholder } from './participantAssertions';
import useReservationsContext from '../useReservations/ReservationContext';

export type PlaceholderParticipant = {
  key: string;
  identity: string;
  isPlaceholder: true;
};

export default function useParticipants() {
  const [, setSelectedParticipant] = useSelectedParticipant();
  const { room } = useVideoContext();
  const [participants, setParticipants] = useState<RemoteParticipant[]>([]);

  // Handle participant connection/disconnection
  useEffect(() => {
    const participantConnected = (participant: RemoteParticipant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };
    const participantDisconnected = (participant: RemoteParticipant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p.identity !== participant.identity)
      );
      setSelectedParticipant((currentlySelected) => {
        // Unpin if the disconnecting participant is the one pinned, or if there are no others (eg., pinned one is self)
        return currentlySelected?.identity === participant.identity ||
          participants.length <= 0
          ? null
          : currentlySelected;
      });
    };
    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    return () => {
      room.off('participantConnected', participantConnected);
      room.off('participantDisconnected', participantDisconnected);
    };
  }, [room, setSelectedParticipant, participants]);

  // This restricts updates to the room's participants to actual, impactful changes.
  // If you just use `room.participants` as the starting value, certain site features break.
  useEffect(() => {
    const resultArray = Array.from(room.participants?.values() || []);
    if (
      resultArray.map((participant) => participant.identity).join() !==
      participants
        .filter(isNonPlaceholder)
        .map((participant) => participant.identity)
        .join()
    ) {
      setParticipants(resultArray);
    }
  }, [participants, room.participants]);

  // Clear reservations as they are claimed
  const { reservations, claimReservation } = useReservationsContext();
  useEffect(() => {
    participants
      .map((participant) => participant.identity.split('::TAG::'))
      .filter((pair) => pair.length > 1)
      .map((pair) => pair[1].split('::ID::')[0])
      .forEach((tag) => claimReservation(tag));
  }, [claimReservation, participants]);

  // Set up participants set
  return useMemo(
    () => [
      ...participants,
      ...reservations.map(
        (reservation) =>
          ({
            identity: reservation.tag,
            key: reservation.id,
            isPlaceholder: true,
          } as PlaceholderParticipant)
      ),
    ],
    [participants, reservations]
  );
}
