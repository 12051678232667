import { fetchWithTimeout, getDefaultHeaders } from '../../../utils';
import {
  ApptFetchErrorTypeEnum,
  ApptFetchErrorAboutEnum,
  ApptFetchResponseType,
} from '../apptHookTypes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  IncomingApptDetailsType,
  TokenApptDetailsType,
  CallEndpointNameEnum,
} from '../../../state/appt/apptTypes';

dayjs.extend(utc);

export default function getTokenApptDetails({
  participantToken,
  identity = '',
  endPointName = CallEndpointNameEnum.JOIN,
}: {
  participantToken: string;
  identity?: string;
  endPointName?: CallEndpointNameEnum;
}): Promise<ApptFetchResponseType<TokenApptDetailsType>> {
  return new Promise((resolve) => {
    const headers = getDefaultHeaders();
    const { SCHEDULING_SERVICE } = window.config;
    const params = new window.URLSearchParams({ identity });

    headers.set('Content-Type', 'application/json');
    let url = '';
    if (endPointName === CallEndpointNameEnum.INFO) {
      url = `${SCHEDULING_SERVICE}/info/${participantToken}`;
    } else {
      url = `${SCHEDULING_SERVICE}/join/${participantToken}?${params}`;
    }
    fetchWithTimeout(url, {
      method: 'GET',
      headers,
    })
      .then(
        async (
          res
        ): Promise<{
          twilioToken?: string;
          content: IncomingApptDetailsType;
        }> => {
          if (!res.ok) {
            let responseType = ApptFetchErrorTypeEnum.ACCESS_DENIED;
            if (res.status >= 500) {
              responseType = ApptFetchErrorTypeEnum.SERVER_ERROR;
            } else if (res.status >= 400) {
              responseType = ApptFetchErrorTypeEnum.INVALID_REQUEST;
            }
            resolve({
              error: {
                status: res.status,
                message: 'No valid response when obtaining appointment details',
                type: responseType,
                about: ApptFetchErrorAboutEnum.GET_TOKEN_APPOINTMENT_DETAILS,
              },
            });
          }
          const twilioToken = res.headers.get('x-access-token') || undefined;
          return { twilioToken, content: await res.json() };
        }
      )
      .then(
        ({
          twilioToken,
          content: { startTime, expiration, appointmentId },
        }) => {
          if (!appointmentId) {
            resolve({
              error: {
                message: 'Invalid response when obtaining appointment details',
                about: ApptFetchErrorAboutEnum.GET_TOKEN_APPOINTMENT_DETAILS,
              },
            });
            return;
          }
          const convertedAppt: TokenApptDetailsType = {
            appointmentId,
            startTime: dayjs.utc(startTime),
            expiration: dayjs.utc(expiration),
            twilioToken,
          };
          resolve({
            content: convertedAppt,
          });
        }
      )
      .catch((err) => {
        resolve({
          error: {
            message: 'Error in requesting appointment id: ' + err,
            about: ApptFetchErrorAboutEnum.GET_TOKEN_APPOINTMENT_DETAILS,
          },
        });
      });
  });
}
