import React from 'react';
import Button from '@material-ui/core/Button';
import tr from '../../../utils/tr';
import { createStyles, makeStyles } from '@material-ui/styles';

/* istanbul ignore next */
const useStyles = makeStyles(() =>
  createStyles({
    resendButton: {
      fontSize: '16px',
      padding: '6px 0px',
    },
  })
);

export default function ResendButton({
  resendInvite,
}: {
  resendInvite: () => void;
}) {
  const classes = useStyles();
  return (
    <Button
      variant="text"
      color="primary"
      className={classes.resendButton}
      onClick={resendInvite}
    >
      {tr('RESEND ##new-appt apptResend button')}
    </Button>
  );
}
