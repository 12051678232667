import React from 'react';
import { Button, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ErrorWrapper from '../ErrorWrapper/ErrorWrapper';
import useAuthBaseContext from '../../hooks/useAuthBaseContext/useAuthBaseContext';
import NotificationPage from '../NotificationPage/NotificationPage';
import tr from '../../utils/tr';

const useStyles = makeStyles({
  errorButton: {
    width: '50%',
  },
});

export default function SSONotHostEmail() {
  const classes = useStyles();
  const { interactiveLogout } = useAuthBaseContext();

  return (
    <NotificationPage>
      <Container id="notificationMessage">
        <ErrorWrapper
          title={tr('Authorization Required ##authorize title')}
          subtitle={
            <span>
              {tr(
                'Sorry, you are not authorized to access this app. ##authorize subtitle'
              )}
            </span>
          }
          icon="/static/warning-icon.svg"
          iconAlt="Error"
        >
          <Button
            className={classes.errorButton}
            variant="contained"
            color="primary"
            onClick={interactiveLogout}
          >
            {tr('Logout ##authorize button')}
          </Button>
        </ErrorWrapper>
      </Container>
    </NotificationPage>
  );
}
