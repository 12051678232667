import { Breadcrumbs, Button, createStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../../AnalyticsConfiguration/analyticsConstant';

type BreadcrumbStep = {
  text: string;
  url: string;
};

interface AppointmentBreadcrumbInterface {
  steps: BreadcrumbStep[];
}

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  /* istanbul ignore next */
  createStyles({
    link: {
      display: 'flex',
      fontSize: '0.9em',
      flexDirection: 'row',
      alignItems: 'center',
      fontWeight: 600,
    },
    icon: {
      marginRight: theme.spacing(0.5),
      height: 'calc(1em - 11px)',
    },
  })
);

export default function AppointmentBreadcrumb({
  steps,
}: AppointmentBreadcrumbInterface) {
  const classes = useStyles();

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {steps.map((step, i) => (
        <Button
          color="primary"
          to={step.url}
          className={classes.link}
          key={step.url + i}
          component={Link}
          onClick={() => {
            analyticEvent({ label: step.text, action: ACTION_CALL_TO_ACTION });
          }}
        >
          {i === 0 && <ArrowBackIosIcon className={classes.icon} />}
          {step.text}
        </Button>
      ))}
    </Breadcrumbs>
  );
}
