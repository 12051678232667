import React from 'react';
import useVolume from '../../hooks/useVolume/useVolume';
import {
  LocalAudioTrackNullableType,
  RemoteAudioTrackNullableType,
} from '../VideoProvider/VideoProviderTypes';
import AudioLevelIcon from './AudioLevelIcon';

export default function AudioLevelIndicator({
  size,
  audioTrack,
  background,
  foreground,
  enabled,
}: {
  size?: number;
  audioTrack: LocalAudioTrackNullableType | RemoteAudioTrackNullableType;
  background?: string;
  foreground?: string;
  enabled: boolean;
}) {
  const volume = useVolume(audioTrack, !enabled);

  return (
    <AudioLevelIcon
      volume={volume}
      size={size}
      background={background}
      foreground={foreground}
    />
  );
}
