import {
  LOCALSTORAGE_FEATURE_ALREADY_SEEN_PREFIX,
  LOCALSTORAGE_FEATURE_GROUP_ALREADY_SEEN_PREFIX,
} from '../../constants';

export const rememberFeatureGroupIsDismissed = (
  groupId: string,
  setIsDismissed = true
) => {
  window.localStorage.setItem(
    LOCALSTORAGE_FEATURE_GROUP_ALREADY_SEEN_PREFIX + groupId,
    setIsDismissed ? 'true' : 'false'
  );
};

export const recallFeatureGroupIsDismissed = (groupId: string) => {
  return (
    window.localStorage.getItem(
      LOCALSTORAGE_FEATURE_GROUP_ALREADY_SEEN_PREFIX + groupId
    ) === 'true' || false
  );
};

export const rememberFeatureIsDismissed = (
  featureId: string,
  setIsDismissed = true
) => {
  window.localStorage.setItem(
    LOCALSTORAGE_FEATURE_ALREADY_SEEN_PREFIX + featureId,
    setIsDismissed ? 'true' : 'false'
  );
};

export const recallFeatureIsDismissed = (featureId: string) => {
  return (
    window.localStorage.getItem(
      LOCALSTORAGE_FEATURE_ALREADY_SEEN_PREFIX + featureId
    ) === 'true' || false
  );
};
