import React from 'react';
import useAuthBaseContext from '../../hooks/useAuthBaseContext/useAuthBaseContext';
import useAutoLogoutWarning from '../../hooks/useAuthBaseContext/useAutoLogoutWarning';
import { Button, createStyles, Container } from '@material-ui/core';
import ResponsiveDialog from '../ResponsiveDialog/ResponsiveDialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/styles';
import theme from '../../theme';
import tr from '../../utils/tr';
import { useAuthContext } from '@ascension/ascensionid-sdk';

const useStyles = makeStyles(
  createStyles({
    wrap: {
      fontSize: '22px',
      lineHeight: '32px',
      textAlign: 'center',
      '& .MuiTypography-root.MuiTypography-h3': {
        fontSize: '20px',
        textTransform: 'unset',
        lineHeight: '32px',
        textAlign: 'center',
      },
      '& .MuiButtonBase-root': {
        width: '100%',
        maxWidth: '400px',
        margin: 0,
        marginTop: '10px',
      },
    },
  })
);

export default function AutoLogoutDialog() {
  const classes = useStyles();
  const { isLoggedIn } = useAuthBaseContext();
  const { addOnSecondsIdleListener } = useAuthContext();
  const [isShowAutoLogoutWarning, setIsShowAutoLogoutWarning] = React.useState<
    boolean
  >(false);

  useAutoLogoutWarning({
    isLoggedIn,
    addOnSecondsIdleListener,
    isShowAutoLogoutWarning,
    setIsShowAutoLogoutWarning,
  });

  return (
    <ResponsiveDialog open={isShowAutoLogoutWarning}>
      <Container
        style={{
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
        }}
      >
        <div id="errorWrap" className={classes.wrap}>
          <DialogContentText>
            {tr('You will be signed out if no activity. ##logout title')}
          </DialogContentText>
          <Button
            color="primary"
            variant="contained"
            data-test="stayLoggedInButton"
          >
            {tr('Stay Logged In ##logout button')}
          </Button>
        </div>
      </Container>
    </ResponsiveDialog>
  );
}
