import { fetchWithTimeout, getDefaultHeaders } from '../../../utils';
import {
  ApptFetchErrorAboutEnum,
  ApptFetchErrorTypeEnum,
  ApptFetchResponseType,
} from '../apptHookTypes';
import { convertIncomingApptType } from './fetchUtils';
import { ApptType, IncomingApptType } from '../../../state/appt/apptTypes';

interface GetApptDetailsInterface {
  baseAccessToken: string;
  appointmentId: string;
}

export default function getApptDetails({
  baseAccessToken,
  appointmentId,
}: GetApptDetailsInterface): Promise<ApptFetchResponseType<ApptType>> {
  return new Promise((resolve) => {
    const headers = getDefaultHeaders();
    headers.set('Authorization', `Bearer ${baseAccessToken}`);
    const { SCHEDULING_SERVICE } = window.config;

    fetchWithTimeout(`${SCHEDULING_SERVICE}/appointment/${appointmentId}`, {
      method: 'GET',
      headers,
    })
      .then(
        (res): Promise<IncomingApptType> => {
          if (!res.ok) {
            let responseType = ApptFetchErrorTypeEnum.ACCESS_DENIED;
            if (res.status >= 500) {
              responseType = ApptFetchErrorTypeEnum.SERVER_ERROR;
            } else if (res.status >= 400) {
              responseType = ApptFetchErrorTypeEnum.INVALID_REQUEST;
            }
            resolve({
              error: {
                status: res.status,
                message:
                  'No valid response when requesting the appointment detail',
                type: responseType,
                about: ApptFetchErrorAboutEnum.GET_APPOINTMENT_DETAILS,
              },
            });
          }
          return res.json();
        }
      )
      .then((appt) => {
        const convertedAppt = convertIncomingApptType([appt])[0];
        resolve({
          content: convertedAppt,
        });
      })
      .catch((err) => {
        resolve({
          error: {
            message: 'Failure in requesting appointment detail: ' + err,
            about: ApptFetchErrorAboutEnum.GET_APPOINTMENT_DETAILS,
          },
        });
      });
  });
}
