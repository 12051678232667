import React from 'react';
import useMemory from '../useMemory/useMemory';
import {
  useSendNotification,
  NotificationProperties,
} from './useSendNotification';

type NotificationsProps = {
  children: React.ReactNode;
};

type NotificationContextType = {
  notificationsPermission: NotificationPermission;
  notificationsError: boolean;
  setNotificationsError: (newError: boolean) => void;
  requestNotifications: () => void;
  sendNotification: (title: string, options?: NotificationProperties) => void;
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
};

const NotificationContext = React.createContext<NotificationContextType | null>(
  null
);

export function NotificationProvider({ children }: NotificationsProps) {
  const [notificationsPermission, setNotificationsPermission] = React.useState(
    window?.Notification?.permission || null
  );
  const [notificationsError, setNotificationsError] = React.useState(false);
  const [active, setActive] = useMemory('notificationsActive', true);

  const sendNotification = useSendNotification({
    notificationsPermission,
    active,
  });

  const onPermission = (permission: NotificationPermission) => {
    setNotificationsPermission(permission);
    // Set "active" on granting permission
    if (permission === 'granted') {
      setActive(true);
    }
  };

  const requestNotifications = () => {
    if (!('Notification' in window)) return;
    if (notificationsPermission === 'denied') {
      setNotificationsError(true);
    } else {
      const permissionPromise = window.Notification.requestPermission(
        onPermission
      );
      if (
        typeof permissionPromise !== 'undefined' &&
        'then' in permissionPromise
      ) {
        permissionPromise.then(onPermission);
      }
    }
  };
  return (
    <NotificationContext.Provider
      value={{
        notificationsPermission,
        notificationsError,
        setNotificationsError,
        requestNotifications,
        sendNotification,
        active,
        setActive,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export default function useNotifications() {
  const notifications = React.useContext(NotificationContext);
  if (!notifications)
    throw new Error(
      'useNotification can only be called inside NotificationProvider'
    );
  return notifications;
}
