import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import {
  useAuthContext,
  useAuthMethods,
  useIsLoggedIn,
} from '@ascension/ascensionid-sdk';
import { AccountInfo } from '@azure/msal-browser';
import { IdTokenClaims } from '@azure/msal-common';
import { expirationCheck, getIsExpired } from './authUtils';
import { expirationCheckBufferNSeconds } from '../../utils/settings';

interface AuthBaseContextProviderType {
  children: ReactNode;
}

export interface AuthBaseContextType {
  isLoggedIn: boolean;
  familyName: string | null;
  givenName: string | null;
  email: string | null;
  userRoles: string[];
  userScopes: string[];
  baseAccessToken: string | null;
  expiresOn: null | Date;
  interactiveLogout: () => void;
  requireLogin: boolean;
  setRequireLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthBaseContextTypeDefault = {
  isLoggedIn: false,
  familyName: null,
  givenName: null,
  email: null,
  userRoles: [],
  userScopes: [],
  baseAccessToken: null,
  expiresOn: null,
  interactiveLogout: () => {
    // empty implementation
  },
  requireLogin: false,
  setRequireLogin: () => {
    // empty implementation
  },
};

interface IdTokenInterface {
  family_name: string;
  given_name: string;
  email: string;
  user_scopes: string[];
  user_roles: string[];
}

export const AuthBaseContext = React.createContext<AuthBaseContextType>(
  AuthBaseContextTypeDefault
);

export const AuthBaseContextProvider = ({
  children,
}: AuthBaseContextProviderType) => {
  const { authResponse, hasCheckingAuthResDone } = useAuthContext();
  const { logout } = useAuthMethods();
  const isLoggedIn = useIsLoggedIn();
  const [baseAccessToken, setBaseAccessToken] = React.useState<string | null>(
    null
  );
  const [account, setAccount] = React.useState<AccountInfo | null>(null);
  const [expiresOn, setExpiresOn] = React.useState<null | Date>(null);
  const [requireLogin, setRequireLogin] = React.useState<boolean>(false);

  const setToLoggedOut = React.useCallback(() => {
    setRequireLogin(true);
  }, []);

  const setToLoggedIn = React.useCallback(() => {
    setRequireLogin(false);
  }, []);

  React.useEffect(() => {
    if (authResponse && authResponse.accessToken) {
      // Only set the access token if it's not expired
      if (!getIsExpired(authResponse.expiresOn, 0)) {
        setBaseAccessToken(authResponse.accessToken);
      }
      setAccount(authResponse.account);
      setExpiresOn(authResponse.expiresOn);
      expirationCheck(
        setToLoggedOut,
        setToLoggedIn,
        authResponse.expiresOn,
        expirationCheckBufferNSeconds
      );
    }
    if (hasCheckingAuthResDone && authResponse === null) {
      setRequireLogin(true);
    }
  }, [authResponse, hasCheckingAuthResDone, setToLoggedOut, setToLoggedIn]);

  const idTokenClaims = account?.idTokenClaims as IdTokenClaims &
    IdTokenInterface;

  const userRoles =
    idTokenClaims && idTokenClaims.user_roles ? idTokenClaims.user_roles : [];

  const userScopes =
    idTokenClaims && idTokenClaims.user_scopes ? idTokenClaims.user_scopes : [];

  const interactiveLogout = React.useCallback(() => {
    logout();
  }, [logout]);

  return (
    <AuthBaseContext.Provider
      value={{
        isLoggedIn,
        familyName: idTokenClaims ? idTokenClaims.family_name : null,
        givenName: idTokenClaims ? idTokenClaims.given_name : null,
        email: idTokenClaims ? idTokenClaims.email : null,
        userRoles,
        userScopes,
        baseAccessToken,
        expiresOn,
        interactiveLogout,
        requireLogin,
        setRequireLogin,
      }}
    >
      {children}
    </AuthBaseContext.Provider>
  );
};

AuthBaseContextProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default AuthBaseContextProvider;
