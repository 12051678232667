import React from 'react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Controls from '../Controls/Controls';
import VideoView from '../VideoView/VideoView';
import { useLogStats } from '../VideoProvider/useLogStats/useLogStats';
import useNetworkSpeedTest from '../../hooks/useNetworkSpeedTest/useNetworkSpeedTest';
import Telemetry from '../../utils/telemetry';
import useDynatraceVideoTelemetry from '../../hooks/useDynatraceVideoTelemetry/useDynatraceVideoTelemetry';
import { useVideoRoomState } from '../../hooks/useVideoRoomState/VideoRoomStateProvider';
import ScreenShareError from '../ScreenShareError/ScreenShareError';
import NetworkQualityWarning from '../NetworkQualityWarning/NetworkQualityWarning';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_PAGE_LOAD } from '../../AnalyticsConfiguration/analyticsConstant';

export default function Room() {
  const { room } = useVideoContext();
  const { showingToolbar } = useVideoRoomState();
  useDynatraceVideoTelemetry();

  React.useEffect(() => {
    analyticEvent({ label: 'In Call', action: ACTION_PAGE_LOAD });
  }, []);

  React.useEffect(() => {
    Telemetry.room = room;
    Telemetry.start();
    // Disconnect room when component unloads
    // to fix problem that occurs sometimes
    // on mobile where video continues streaming
    // and can even result in duplicates on rejoin
    return () => {
      Telemetry.room = null;
      Telemetry.stop();

      if (room) {
        room.disconnect();
      }
    };
  }, [room]);

  useLogStats();
  useNetworkSpeedTest();

  return (
    <>
      <NetworkQualityWarning />
      <VideoView />
      {showingToolbar && <Controls />}
      <ScreenShareError />
    </>
  );
}
