import useEffectOnce from '../../useEffectOnce/useEffectOnce';
import { useApptStateContext } from '../../../state/appt/ApptStateProvider';
import React from 'react';

export default function useScrollToApptForDisplay({
  isClosestTime,
  somethingIsSelected,
  elementRef,
  isSelected,
}: {
  isClosestTime: boolean;
  somethingIsSelected: boolean;
  elementRef: React.MutableRefObject<HTMLLIElement | null>;
  isSelected: boolean;
}) {
  const { clickRefreshBtn } = useApptStateContext();
  const scrollToElement = React.useCallback(() => {
    if (elementRef.current && 'scrollIntoViewIfNeeded' in elementRef.current) {
      const typedElement = elementRef.current as HTMLLIElement & {
        scrollIntoViewIfNeeded: () => void;
      };
      return typedElement.scrollIntoViewIfNeeded();
    } else if (elementRef.current) elementRef.current.scrollIntoView();
  }, [elementRef]);

  // If selected, always scroll to element
  React.useEffect(() => {
    if (isSelected) scrollToElement();
  }, [isSelected, scrollToElement]);

  React.useEffect(() => {
    if (clickRefreshBtn && !somethingIsSelected && isClosestTime) {
      scrollToElement();
    }
  }, [clickRefreshBtn, somethingIsSelected, scrollToElement, isClosestTime]);

  // If first-load and closest time and nothing is selected, scroll
  useEffectOnce(() => {
    if (isClosestTime && !somethingIsSelected) {
      scrollToElement();
    }
  }, [somethingIsSelected, isClosestTime, scrollToElement]);
}
