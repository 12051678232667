import useDevice from './useDevice';
import useTrackDisabled from './useTrackDisabled';
import useClearTrack from './useClearTrack';
import {
  DeviceType,
  FacingModeType,
  OnErrorType,
  VideoTrackType,
} from './UseMediaTypes';

interface UseVideoTrackInterface {
  devices: DeviceType[];
  refreshDevices: () => void;
  disableDesired?: boolean;
  deviceIdDesired?: string | null;
  onError: OnErrorType;
  retryKey: object;
}

export default function useVideoTrack({
  devices,
  refreshDevices,
  disableDesired = false,
  deviceIdDesired,
  onError,
  retryKey,
}: UseVideoTrackInterface) {
  const { track, setTrack, trackDeviceId, deviceId } = useDevice({
    which: 'video',
    deviceIdDesired,
    devices,
    refreshDevices,
    onError,
    retryKey,
    disableDesired,
  });
  const disabled = useTrackDisabled({
    track,
    disableDesired,
    onError,
  });
  useClearTrack({ trackDeviceId, devices, setTrack });

  return {
    disabled,
    deviceId,
    facing: (track.track?.mediaStreamTrack.getSettings().facingMode === 'user'
      ? 'user'
      : 'environment') as FacingModeType,
    track: track as VideoTrackType,
  };
}
