import React from 'react';
import useSendReliably from '../useSendReliably';
import {
  ReservationType,
  ReservatonListPayload,
  SetReservations,
} from './reservationTypes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useVideoContext from '../useVideoContext/useVideoContext';
import { RemoteTrackPublication } from 'twilio-video';
dayjs.extend(utc);

type ReceiveReservationsProps = {
  reservations: ReservationType[];
  setReservations: SetReservations;
};

export default function useReceiveReservations({
  reservations,
  setReservations,
}: ReceiveReservationsProps) {
  const [, setReceivedList] = React.useState(false);
  const [sentTo, setSentTo] = React.useState<string[]>([]);
  const [self, setSelf] = React.useState<string | undefined>();

  // Receiving reservations
  const { sendMessage: doPushReservation } = useSendReliably<
    ReservatonListPayload
  >({
    messageType: 'reservation-list',
    receiveMessage: (reservationList) => {
      setReceivedList((received) => {
        if (received) {
          return received;
        }

        // Clear out self entry
        const cleanList = reservationList.list;
        const remove = cleanList.findIndex((entry) => entry.tag === self);
        if (remove >= 0) {
          cleanList.splice(remove, 1);
        }

        // Update reservations
        setReservations((oldReservations) => [
          ...oldReservations,
          ...cleanList.map((reservation) => ({
            tag: reservation.tag,
            id: `${Math.random()}`, // The ID doesn't need a UUID level of uniqueness or overhead
            sentAt: dayjs(reservation.sentAt),
          })),
        ]);

        // Prevent overwrite
        return true;
      });
    },
    autoResend: true,
  });

  // Effect to push non-empty reservation list to the new participant
  const { room } = useVideoContext();
  React.useEffect(() => {
    if (reservations.length <= 0) {
      return;
    }

    const onParticipantAdd = (track: RemoteTrackPublication) => {
      // Ignore non-data tracks
      if (track.kind !== 'data') {
        return;
      }
      const id = Array.from(room.participants.values()).find(
        (p) =>
          !!Array.from(p.dataTracks.values())
            .map((participantTrack) => participantTrack.trackSid)
            .find((sid) => track.trackSid === sid)
      )?.identity;

      // Send
      if (id && !sentTo.find((sentId) => sentId === id)) {
        doPushReservation(
          {
            list: reservations.map((reservation) => ({
              tag: reservation.tag,
              sentAt: reservation.sentAt.toISOString(),
            })),
          },
          id
        );
        setSentTo([...sentTo, id]);
      }
    };
    room.addListener('trackPublished', onParticipantAdd);
    return () => {
      room.removeListener('trackPublished', onParticipantAdd);
    };
  }, [doPushReservation, reservations, room, sentTo]);

  return setSelf;
}
