import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import React, { FormEvent } from 'react';
import { makeStyles } from '@material-ui/styles';
import useTransitionIsDone from '../../hooks/useTransitionIsDone/useTransitionIsDone';
import clsx from 'clsx';

const inputTopPad = '12px';
const inputSidePad = '10px';
const bottomPad = '16px';

interface StylePropInterface {
  hasOverlay: boolean;
}

const useStyles = makeStyles({
  invitationTextareaWrap: {
    position: 'relative',
  },
  invitationTextarea: {
    width: '100%',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    padding: `${inputTopPad} ${inputSidePad}`,
    paddingBottom: ({ hasOverlay }: StylePropInterface) =>
      hasOverlay ? `calc(1em + ${bottomPad} + 4px)` : inputTopPad,
    resize: 'vertical',
    minHeight: '6em',
    maxHeight: '6em',
  },
  invitationTextareaAfterTransition: {
    maxHeight: 'unset',
  },
  linkOverlay: {
    position: 'absolute',
    left: 0,
    bottom: bottomPad,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: `calc(100% - ${inputSidePad})`,
    padding: '2px',
    opacity: 0.5,
    paddingLeft: inputSidePad,
  },
});

interface OverlayTextboxInterface {
  textValue?: string;
  overlayValue?: string;
  onChange: (event: FormEvent<HTMLTextAreaElement>) => void;
  onClick?: null | (() => void);
  disabled?: boolean;
}

export default function OverlayTextbox({
  textValue = '',
  overlayValue = '',
  onChange,
  onClick,
  disabled = false,
}: OverlayTextboxInterface) {
  const transitionIsDone = useTransitionIsDone();
  const hasOverlay = !!overlayValue;
  const classes = useStyles({ hasOverlay });
  const textboxClicked = React.useRef(false);

  const handleOnClick = React.useCallback(() => {
    if (!textboxClicked.current) {
      textboxClicked.current = true;
      if (onClick) {
        onClick();
      }
    }
  }, [onClick]);

  return (
    <div className={classes.invitationTextareaWrap}>
      <TextareaAutosize
        className={clsx(
          classes.invitationTextarea,
          transitionIsDone && classes.invitationTextareaAfterTransition
        )}
        value={textValue}
        onInput={onChange}
        onClick={handleOnClick}
        disabled={disabled}
        data-testid="invite-message"
      />
      {hasOverlay && (
        <div id="linkOverlay" className={classes.linkOverlay}>
          {overlayValue}
        </div>
      )}
    </div>
  );
}
