import React from 'react';
import {
  getAudioElement,
  appendDomElement,
  HTMLAudioElementWithSinkId,
} from './utils';

export default function useAudioToSpeaker(
  stream?: MediaStream | null,
  activeSinkId?: string | null
) {
  const audioElementRef = React.useRef<HTMLAudioElementWithSinkId | null>(null);

  React.useEffect(() => {
    const audioEl = getAudioElement();
    audioEl.setAttribute('data-cy-audio-track', 'play-sound');

    // Append
    appendDomElement(audioEl);

    audioElementRef.current = audioEl;
    return () => {
      // Tear down
      if (audioElementRef.current) {
        audioElementRef.current.pause();
        audioElementRef.current.remove();
        audioElementRef.current.srcObject = null;
        audioElementRef.current = null;
      }
    };
  }, []);

  React.useEffect(() => {
    if (stream && audioElementRef.current) {
      // Attach audio stream
      audioElementRef.current.srcObject = stream;

      // Play and set sinkId (in supported browsers)
      audioElementRef.current.play();
      if (audioElementRef.current?.setSinkId && activeSinkId) {
        audioElementRef.current.setSinkId(activeSinkId);
      }
    }
    return () => {
      if (audioElementRef.current) {
        audioElementRef.current.pause();
      }
    };
  }, [stream, activeSinkId]);
}
