import React from 'react';
import getSoundAnalyser from './getSoundAnalyser';
import { AnyAudioTrackType } from './UseVolumeTypes';
import { isIOS } from 'react-device-detect';
import { reportError } from '@ascension/report-event';

const WAIT_CYCLES_TO_RETRY = 5;

const increment = (x: number) => x + 1;

const nullAnalyser = {
  analyser: null,
  closeAnalyser: () => {
    // empty implementation
  },
};

export default function useVolume(track: AnyAudioTrackType, disabled: boolean) {
  const [volume, setVolume] = React.useState<number | null>(null);
  const [zeroVolumeCount, setZeroVolumeCount] = React.useState<number>(0);
  const trackDeviceId = track.track?.mediaStreamTrack.getSettings().deviceId;
  const [reinitAudioHandle, setReinitAudioHandle] = React.useState({});

  React.useEffect(() => {
    if (!track.track || disabled) {
      setVolume(0);
      return;
    }
    let { analyser, closeAnalyser } = isIOS
      ? nullAnalyser
      : getSoundAnalyser(track.track);
    const reinitializeAnalyser = () => {
      if (closeAnalyser) closeAnalyser();
      if (!track.track) return;
      const newAnalyser = getSoundAnalyser(track.track);
      analyser = newAnalyser.analyser;
      closeAnalyser = newAnalyser.closeAnalyser;
    };
    if (!analyser) return reportError('Audio analyser missing');

    const sampleArray = new Uint8Array(analyser.frequencyBinCount);
    const interval = setInterval(() => {
      if (!analyser) return;
      analyser.getByteFrequencyData(sampleArray);
      const values = sampleArray.reduce((prev, curr) => prev + curr, 0);
      const length = sampleArray.length;

      const newVolume =
        Math.round(
          Math.min(21, Math.max(0, Math.log10(values / length / 3) * 14)) * 10
        ) / 10;
      if (newVolume === 0) {
        setZeroVolumeCount(increment);
      } else {
        setZeroVolumeCount(0);
      }
      setVolume(newVolume);
    }, 200);
    window.addEventListener('focus', reinitializeAnalyser);
    return () => {
      if (closeAnalyser) closeAnalyser();
      clearInterval(interval);
      window.removeEventListener('focus', reinitializeAnalyser);
    };
    // Include "disabled" to force refresh if muted/unmuted
  }, [disabled, track, trackDeviceId, reinitAudioHandle]);

  React.useEffect(() => {
    if (zeroVolumeCount && zeroVolumeCount >= WAIT_CYCLES_TO_RETRY) {
      setReinitAudioHandle({});
      setZeroVolumeCount(0);
    }
  }, [zeroVolumeCount]);

  return volume;
}
