import React from 'react';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DoneIcon from '@material-ui/icons/Done';

/* istanbul ignore next */
const useStyles = makeStyles(() =>
  /* istanbul ignore next */
  createStyles({
    dateVariantText: {
      minWidth: '108px',
    },
    dateVariantIcon: {
      height: '24px',
      justifyContent: 'flex-end',
    },
  })
);

export default function AppointmentDateMenuItem({
  dateVariant,
  isSelected,
}: {
  dateVariant: string;
  isSelected: boolean;
}) {
  const classes = useStyles();

  return (
    <>
      <ListItemText className={classes.dateVariantText}>
        {dateVariant}
      </ListItemText>
      {isSelected && (
        <ListItemIcon className={classes.dateVariantIcon}>
          <DoneIcon fontSize="small" />
        </ListItemIcon>
      )}
    </>
  );
}
