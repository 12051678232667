import React from 'react';
import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import BarButton from '../BarButton/BarButton';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import tr from '../../../utils/tr';

const MUTE_TEXT = tr('Mute ##video audio button');

export default function ToggleAudioButton({
  disabled = false,
}: {
  disabled?: boolean;
}) {
  const {
    audioError,
    audioInDisabled,
    setAudioInDisableDesired,
    retryAudioAcquire,
  } = useVideoContext();

  const handleToggle = () => {
    setAudioInDisableDesired(!audioInDisabled);
    if (audioError) {
      retryAudioAcquire();
    }
  };

  return !audioInDisabled && !audioError ? (
    <BarButton onClick={handleToggle} text={MUTE_TEXT}>
      <Mic titleAccess="Disable Audio Button" />
    </BarButton>
  ) : (
    <BarButton
      onClick={handleToggle}
      text={MUTE_TEXT}
      disabled={disabled}
      selected
      highlighted
    >
      <MicOff titleAccess="Enable Audio Button" />
    </BarButton>
  );
}
