// Used to ensure query names are correct
import React from 'react';

export enum RightPanelKeyEnum {
  MEDIA_SETTINGS = 'media_settings',
  CHAT = 'chat',
  INVITE = 'invite',
  INTERPRETER = 'interpreter'
}

export type RightPanelContentType = {
  component: React.ReactNode;
  title: string;
  back?: RightPanelKeyEnum;
  scroll?: 'off';
};
