export const MAIN_TOOLBAR_HEIGHT_UNITS = 10; // height unit is 8px so 10 = 80px
export const VIDEO_TOOLBAR_HEIGHT_UNITS = 8; // height unit is 8px so 10 = 80px
export const DEFAULT_TOOLBAR_HEIGHT_UNITS = 8;
export const DEFAULT_TOOLBAR_MOBILE_HEIGHT_UNITS = 7;
export const BLOCK_VIDEO_BUTTONS_FOR_MS = 2000;
export const APPLICATION_TITLE_HEIGHT = 16;
export const LOGO_HEIGHT = 28;
export const FLOATING_BUTTON_POSITION = -52;
export const QUERY_SHOW_IN_RIGHT_PANEL = 'right_panel';
export const QUERY_TEST_AUDIO_VIDEO_STEP = 'test_media_step';
export const API_INVITE_TARGET_SUCCESS = 'Success';
export const API_INVITE_TARGET_INVALID = 'Invalid';
export const API_INVITE_TARGET_FAILURE = 'Failure';
export const API_INVITE_EMAIL_TYPE = 'EMAIL';
export const API_INVITE_OTHER_TYPE = 'OTHER';
export const API_INVITE_PHONE_TYPE = 'PHONE_NUMBER';
export const QUERY_CALL_DURATION = 'call_duration';
export const QUERY_CALL_PARTICIPANTS = 'call_participants';
export const LOCALSTORAGE_FEATURE_ALREADY_SEEN_PREFIX = 'feature-already-seen-';
export const LOCALSTORAGE_FEATURE_GROUP_ALREADY_SEEN_PREFIX =
  'feature-group-already-seen-';
export const WARNING_BANNER_PRESENT_LISTENER = 'present';
export const SHOW_WARNING_WHEN_QUALITY_BELOW = 2;
export const AVERAGE_NETWORK_QUALITY_OVER_N_SECONDS = 5;
export const BLOCK_QUALITY_WARNING_IF_WITHIN_N_SECONDS = 2 * 60;
export const APPT_LASTS_N_HOURS = 12;
export const DEFAULT_INVITATION_TEXT =
  'Ascension has invited you to a virtual visit.';
export const PARTICIPANT_ID_PREFIX = 'temp-id-';
export const SELECTED_APPOINTMENT_LIST_ITEM_CLASS_NAME =
  'selected-appointment-list-item';
export const TIME_FORMAT_FOR_API_GET = 'YYYY-MM-DDTHH:mm:ss';
export const TIME_FORMAT_FOR_API_POST = 'YYYY-MM-DD HH:mm:ss';
export const ME = 'Me';
export const MIN_OPEN_BEFORE_START = 60;
