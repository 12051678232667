import useToastContext from '../../hooks/useToastContext/useToastContext';
import React from 'react';
import tr from '../../utils/tr';
import useBrowserIsOk from '../../hooks/useBrowserIsOk/useBrowserIsOk';

export default function Ios15Warning() {
  const { add: addToast } = useToastContext();
  const {
    features: { isBadIos15 },
  } = useBrowserIsOk();

  React.useEffect(() => {
    if (isBadIos15) {
      addToast({
        message: tr(
          'We are experiencing an audio issue with iOS 15. To increase audio volume, please consider muting yourself while not speaking. ##toast-warn'
        ),
        hold: true,
      });
    }
  }, [addToast, isBadIos15]);

  return null;
}
