import React from 'react';
import useSelectedParticipant from '../../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useParticipants from '../../../hooks/useParticipants/useParticipants';
import { makeStyles } from '@material-ui/styles';
import { colors } from '../../../theme';

const useStyles = makeStyles({
  wrap: {
    fontSize: '21px',
    backgroundColor: 'white',
    borderRadius: '1em',
    width: '44px',
    height: '44px',
    textAlign: 'center',
    paddingTop: '10px',
    fontWeight: 'bold',
    color: colors.primaryPalette.brandBlue,
  },
});

export default function PlusParticipants() {
  const styles = useStyles();
  const participants = useParticipants();
  const [, setSelectedParticipant] = useSelectedParticipant();
  const handleClick = () => {
    setSelectedParticipant(null);
  };
  const plusCount = participants.length - 1;
  if (plusCount <= 0) return null;
  return (
    <div
      id="plusParticipantCount"
      className={styles.wrap}
      onClick={handleClick}
    >
      +{participants.length - 1}
    </div>
  );
}
