import React from 'react';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import BarButton from '../BarButton/BarButton';
import useShowInRightPanel from '../../../hooks/useShowInRightPanel/useShowInRightPanel';
import { RightPanelKeyEnum } from '../../../interfaces/RightPanelContentTypes';
import tr from '../../../utils/tr';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../../AnalyticsConfiguration/analyticsConstant';
import { useVideoRoomState } from '../../../hooks/useVideoRoomState/VideoRoomStateProvider';

export default function InviteButton() {
  const { getAppointmentDetail } = useVideoRoomState();
  const { toggleWhich } = useShowInRightPanel();
  const hasInvitePanel = window.location.href.includes(
    RightPanelKeyEnum.INVITE
  );

  const handleClick = () => {
    analyticEvent({
      label: 'Invite Logo In Call Click',
      action: ACTION_CALL_TO_ACTION,
    });
    toggleWhich(RightPanelKeyEnum.INVITE);
    if (!hasInvitePanel) {
      getAppointmentDetail();
    }
  };

  return (
    <BarButton onClick={handleClick} text={tr('Invite ##invite button')}>
      <PersonAddIcon titleAccess="Invite Button" />
    </BarButton>
  );
}
