import {
  maxPossibleCallDurationInSeconds,
  socketFallbackRetryAtSeconds,
  socketRetryAtSeconds,
} from './utils/settings';
import React from 'react';
import { VideoProvider } from './components/VideoProvider';
import { SocketProvider } from './hooks/useSocketContext/useSocketContext';
import socketIdentifier from './utils/socketIdentifier';
import VideoRoomStateProvider from './hooks/useVideoRoomState/VideoRoomStateProvider';
import App from './App';
import {
  getDefaultConnectionOptions,
  isExpiredOrInvalidOrFutureTokenError,
} from './utils';
import WithLocalVolume from './components/WithLocalVolume/WithLocalVolume';
import { NotificationProvider } from './hooks/useNotifications/useNotifications';
import { reportError } from '@ascension/report-event';
import Ios15Warning from './components/Ios15Warning/Ios15Warning';
import { useApptStateContext } from './state/appt/ApptStateProvider';
import NotificationPage from './components/NotificationPage/NotificationPage';
import LoadingAnimation from './components/LoadingAnimation/LoadingAnimation';
import ErrorPage from './components/ErrorPage/ErrorPage';
import tr from './utils/tr';
import { tokenStatusEnum } from './components/ErrorDialog/VideoErrorType';
import useTokenAppointmentDetails from './hooks/appt/useTokenAppointmentDetails/useTokenAppointmentDetails';

export default function VideoApp() {
  const { SOCKET_SERVICE } = window.config;
  const SELECTED_SOCKET_SERVICE =
    'Cypress' in window ? 'ws://localhost:7001' : SOCKET_SERVICE;
  const maxMessagePulls = maxPossibleCallDurationInSeconds / 3;
  const logHandler = React.useCallback((msg) => reportError(msg), []);
  const { setError, tokenError } = useApptStateContext();
  const defaultTitle = tr('Error ##error-default title');
  const tokenApptDetails = useTokenAppointmentDetails();

  window.onblur = function () {
    const activeElement = document.activeElement;
    if (
      activeElement &&
      activeElement.getAttribute('class')?.includes('MuiRating')
    ) {
      (document.activeElement as HTMLElement).blur();
    }
  };

  if (tokenError === undefined)
    return (
      <NotificationPage>
        <LoadingAnimation />
      </NotificationPage>
    );

  if (tokenError && isExpiredOrInvalidOrFutureTokenError(tokenError)) {
    return (
      <ErrorPage
        title={tokenError.title || defaultTitle}
        subtitle={tokenError.message}
        icon={
          tokenError?.tokenStatus === tokenStatusEnum.EXPIRED ||
          tokenError?.tokenStatus === tokenStatusEnum.FUTURE
            ? '/static/clock.svg'
            : '/static/error_outline.svg'
        }
      />
    );
  }

  return (
    <VideoProvider options={getDefaultConnectionOptions()} onError={setError}>
      <SocketProvider
        baseURL={SELECTED_SOCKET_SERVICE}
        maxN={maxMessagePulls}
        identifier={socketIdentifier}
        onLog={logHandler}
        logLevel="error"
        retryAtSeconds={socketRetryAtSeconds}
        fallbackRetryAtSeconds={socketFallbackRetryAtSeconds}
      >
        <NotificationProvider>
          <VideoRoomStateProvider tokenApptDetails={tokenApptDetails}>
            <WithLocalVolume>
              <App />
            </WithLocalVolume>
          </VideoRoomStateProvider>
        </NotificationProvider>
        <Ios15Warning />
      </SocketProvider>
    </VideoProvider>
  );
}
