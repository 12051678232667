import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { pageChangeListener } from '../../AnalyticsConfiguration/AnalyticsConfiguration';
import { v4 as uuidv4 } from 'uuid';

const ID_KEY = 'vcAnonymousId'

const getId = () => {
  const oldId = window.localStorage.getItem(ID_KEY);
  if (!oldId) {
    const newId = uuidv4();
    window.localStorage.setItem(ID_KEY, newId);
    return newId;
  }
  return oldId;
};

export default function useSetupAnalytics() {
  React.useEffect(() => {
    const { ANALYTICS_ID } = window.config;
    if (!ANALYTICS_ID) {
      return;
    }
    amplitude.init(ANALYTICS_ID, undefined, {
      deviceId: getId(),
      disableCookies: true,
      trackingOptions: {
        ipAddress: false,
      }
    });
    pageChangeListener({ state: {}, ...window.location }, 'REPLACE');
  }, []);
}
