import React, { PropsWithChildren, ReactElement } from 'react';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import { VideoErrorType } from './VideoErrorType';
import { Container, createStyles, Grid, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { makeStyles } from '@material-ui/styles';
import theme, { colors, transitionSpeed } from '../../theme';
import ResponsiveDialog from '../ResponsiveDialog/ResponsiveDialog';
import tr from '../../utils/tr';
import useHeldState from '../../hooks/useHeldState/useHeldState';

interface ErrorDialogProps {
  dismissError: () => void;
  error: VideoErrorType | null;
}

export const useStylesForErrorDialog = makeStyles(
  createStyles({
    wrap: {
      fontSize: '22px',
      lineHeight: '32px',
      textAlign: 'center',
      '& .MuiTypography-root.MuiTypography-h3': {
        fontSize: '20px',
        textTransform: 'unset',
        lineHeight: '32px',
        textAlign: 'center',
      },
      '& .MuiButtonBase-root': {
        width: '100%',
        maxWidth: '400px',
        margin: 0,
      },
    },
    icon: {
      width: 'unset',
      height: 'unset',
      marginTop: theme.spacing(4),
      color: colors.notifications.error,
    },
    buttonWrap: {
      marginTop: `${theme.spacing(1)}px`,
      marginBottom: 0,
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    subtitle: {
      fontSize: '0.6em',
    },
  })
);

function ErrorDialog({
  dismissError,
  error,
}: PropsWithChildren<ErrorDialogProps>) {
  const heldError = useHeldState<VideoErrorType | null>({
    expectedState: error,
    holdForMs: transitionSpeed + 200,
  });
  const {
    message,
    title,
    icon,
    onClose,
    buttonText,
    altButtonText,
    onAltButton,
  } = heldError || {};
  const classes = useStylesForErrorDialog();

  const displayTitle: string = title === undefined ? 'ERROR' : title;

  const iconDisplay: ReactElement = React.cloneElement(
    icon || <ErrorOutlineIcon />,
    {
      width: '84px',
      height: '84px',
      className: classes.icon,
    }
  );

  const handleOkayClick = () => {
    if (onClose) {
      onClose();
    }
    dismissError();
  };

  const hasAltButton = !!(onAltButton && altButtonText);

  return (
    <ResponsiveDialog open={error !== null} onDismiss={dismissError}>
      <Container
        style={{
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
        }}
      >
        <div id="errorWrap" className={classes.wrap}>
          {iconDisplay}
          {title && <Typography variant="h3">{displayTitle}</Typography>}
          <DialogContentText>{message}</DialogContentText>
          <Grid container spacing={3} className={classes.buttonWrap}>
            <Grid item xs={hasAltButton ? 6 : 12}>
              <Button
                onClick={handleOkayClick}
                color="primary"
                variant="contained"
                autoFocus
              >
                {buttonText || tr('OKAY ##error button')}
              </Button>
            </Grid>

            {hasAltButton && (
              <Grid item xs={6}>
                <Button onClick={onAltButton} color="primary">
                  {altButtonText}
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </Container>
    </ResponsiveDialog>
  );
}

export default ErrorDialog;
