export const waitForSignIn = 10000;
export const fetchTimeout = 45000;
export const twilioRoomIDRegex = /(RM[a-f0-9]{8})/;
export const maxSMSTextLength = 200;
export const maxFeedbackTextLength = 500;
export const hostEmailAddressDomains = ['@ascension.org'];
export const hostUserRoles = ['DS-video-chat'];
export const hostUserScopes = ['video-chat-api.chat.create'];
export const blockedPhoneNumberPrefixes = [800, 888, 877, 866, 855, 844, 833];
export const refreshAccessTokenBufferNSeconds = 2 * 60; // buffer refresh 2 minutes
export const expirationCheckBufferNSeconds = 60; // buffer check expiration in 1 minutes
export const logoutAfterIdleMinutes = 15; // logout after 15 minutes inactive
export const checkAutoLogoutEveryNSeconds = 60; // show logout warning at 1 minute
export const maxInvitesAtATime = 3;
export const logOutWaitNSecondsBeforeForwarding = 3;
export const dynatraceTelemetryEveryNSeconds = 60;
export const maxRoomListeners = 30;
export const toastLastsNSeconds = 5;
export const maxChatInput = 500;
export const hideToolbarDelayNSeconds = 5;
export const retryChatMessagesTimes = 10;
export const expectReceiveByNSeconds = 4;
export const checkExpirationInPreroomEveryNSeconds = 10;
export const maxPossibleCallDurationInSeconds = 60 * 60 * 16;
export const socketRetryAtSeconds = [0, 2, 4, 8];
export const socketFallbackRetryAtSeconds = 15;
export const socketIsFailedIfNotConnectedByNSeconds = 10;
export const playTestSoundForNSeconds = 2;
export const delayTestSoundForNSeconds = 1;
export const delayMicIsWorkingSuccessByNSec = 1;
export const delayMicIsWorkingFailureByExtraNSec = 3;
export const updateSocketExpiresAtEveryNSeconds = 10;
export const disableToggleVideoButtonForNSeconds = 1;
export const debounceSocketParticipantsNSeconds = 3;
export const forceRedirects = [
  {
    from: 'https://video-chat.pub.cloud-03.pcf.ascension.org',
    to: 'https://video.ascension.org',
  },
];
export const rememberTouchForNSeconds = 2;
export const updateRelativeApptTimesEveryNSeconds = 10;
export const considerApptLateAfterNMinutes = 20;
export const checkApptsEveryNSeconds = 600;
export const pauseNSecondsBeforeRetry = 2;
export const delayNSecondsBeforeAutoscroll = 0.5;
export const delayNSecondsBeforeHideTooltip = 0.3;
export const clockMisalignmentUpToNSeconds = 60;
export const disableRefreshButtonNSeconds = 5;
