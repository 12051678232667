import {
  GetExpectedApptStatusesResultType,
  SuccessOrFailedApptStatusForDisplayEnum,
} from '../getExpectedApptStatusMap';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { MenuItem } from '@material-ui/core';
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { colors } from '../../../theme';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import tr from '../../../utils/tr';
import {
  getTextDirectionOriginIsRight,
  getLocalizationTimeString,
} from '../../../utils';
dayjs.extend(localizedFormat);

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  /* istanbul ignore next */
  createStyles({
    row: {
      cursor: 'unset',
    },
    icon: {
      color: colors.primaryPalette.brandBlue,
    },
    iconNegative: {
      width: '13px',
      height: '2px',
      position: 'relative',
      left: '4px',
      marginRight: '11px',
      backgroundColor: colors.grayscale.disabled2,
    },
    iconDisabled: {
      color: colors.grayscale.disabled2,
    },
    iconFailed: {
      color: colors.notifications.error,
    },
    text: {
      paddingLeft: theme.spacing(2),
    },
    textDisabled: {
      color: colors.grayscale.disabled2,
    },
    timeTextRow: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: '0.85em',
      color: colors.grayscale.disabled2,
      direction: getTextDirectionOriginIsRight() ? 'rtl' : 'ltr',
    },
  })
);

export default function InvitedParticipantStatusRows({
  expectedApptStatuses,
  participantDisplayName,
}: {
  expectedApptStatuses: GetExpectedApptStatusesResultType[];
  participantDisplayName: string;
}) {
  const classes = useStyles();
  const withTimeText = [...expectedApptStatuses]
    .reverse()
    .find(({ timeText, timeStamp }) => timeText && timeStamp);

  return (
    <>
      {expectedApptStatuses.map(
        ({ displayStatus, successOrFailed, timeStamp, text }) => (
          <MenuItem className={classes.row} key={displayStatus}>
            {!successOrFailed && (
              <div
                className={classes.iconNegative}
                aria-label={`${participantDisplayName}: ${text}: ${tr(
                  'Not active ##participant-icon aria'
                )}`}
              />
            )}
            {successOrFailed ===
              SuccessOrFailedApptStatusForDisplayEnum.SUCCESS && (
              <DoneIcon
                className={classes.icon}
                aria-label={`${participantDisplayName}: ${text}: ${tr(
                  'Succeeded ##participant-icon aria'
                )}`}
              />
            )}
            {successOrFailed ===
              SuccessOrFailedApptStatusForDisplayEnum.FAILED && (
              <CloseIcon
                className={classes.iconFailed}
                aria-label={`${participantDisplayName}: ${text}: ${tr(
                  'Failed ##participant-icon aria'
                )}`}
              />
            )}
            <span
              className={clsx(classes.text, !timeStamp && classes.textDisabled)}
              aria-label={`${participantDisplayName}: ${text}: ${tr(
                'Inactive ##participant-icon aria'
              )}`}
            >
              {text}
            </span>
          </MenuItem>
        )
      )}
      {withTimeText && (
        <MenuItem className={clsx(classes.row, classes.timeTextRow)}>
          {withTimeText.timeText}
          <span style={{ whiteSpace: 'pre' }}>
            {withTimeText.timeStamp
              ? ' ' + getLocalizationTimeString(withTimeText.timeStamp)
              : ''}
          </span>
        </MenuItem>
      )}
    </>
  );
}
