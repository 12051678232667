import {
  ApptFetchErrorAboutEnum,
  ApptFetchErrorTypeEnum,
} from '../../../hooks/appt/apptHookTypes';
import tr from '../../../utils/tr';

export type ApptDisplayErrorType = {
  title?: string;
  message: string;
};

type ApptDisplayErrorsType = {
  [aboutKey in ApptFetchErrorAboutEnum]: {
    [typeKey in ApptFetchErrorTypeEnum]: ApptDisplayErrorType;
  };
};

const apptErrors: ApptDisplayErrorsType = {
  [ApptFetchErrorAboutEnum.ADD_APPOINTMENT]: {
    [ApptFetchErrorTypeEnum.SERVER_ERROR]: {
      message: tr(
        'Server cannot add appointment. Please try again. ##appt-error title'
      ),
    },
    [ApptFetchErrorTypeEnum.INVALID_REQUEST]: {
      message: tr(
        "Server couldn't process request. Please try again. ##appt-error title"
      ),
    },
    [ApptFetchErrorTypeEnum.ACCESS_DENIED]: {
      title: tr('Appointment not created. ##appt-error title'),
      message: tr(
        'The system is currently unavailable. Please try again. ##appt-error title'
      ),
    },
    [ApptFetchErrorTypeEnum.GENERAL]: {
      title: tr('Appointment not created. ##appt-error title'),
      message: tr(
        'Check your internet connection. Please try again. ##appt-error title'
      ),
    },
  },
  [ApptFetchErrorAboutEnum.UPDATE_APPOINTMENT]: {
    [ApptFetchErrorTypeEnum.SERVER_ERROR]: {
      message: tr(
        "Server couldn't update appointment. Please try again. ##appt-error title"
      ),
    },
    [ApptFetchErrorTypeEnum.INVALID_REQUEST]: {
      message: tr(
        'Appointment text is invalid. Please remove any links or <tags> in your message. ##appt-error title'
      ),
    },
    [ApptFetchErrorTypeEnum.ACCESS_DENIED]: {
      title: tr('Appointment not updated. ##appt-error title'),
      message: tr('Login and try again. ##appt-error title'),
    },
    [ApptFetchErrorTypeEnum.GENERAL]: {
      title: tr('Appointment not updated. ##appt-error title'),
      message: tr(
        'Check your internet connection. Please try again. ##appt-error title'
      ),
    },
  },
  [ApptFetchErrorAboutEnum.CANCEL_APPOINTMENT]: {
    [ApptFetchErrorTypeEnum.SERVER_ERROR]: {
      message: tr(
        "Server couldn't cancel appointment. Please try again. ##appt-error title"
      ),
    },
    [ApptFetchErrorTypeEnum.INVALID_REQUEST]: {
      message: tr(
        'Your changes could not be saved. Please try again. ##appt-error title'
      ),
    },
    [ApptFetchErrorTypeEnum.ACCESS_DENIED]: {
      title: tr('Appointment not cancelled. ##appt-error title'),
      message: tr('Login and try again. ##appt-error title'),
    },
    [ApptFetchErrorTypeEnum.GENERAL]: {
      title: tr('Appointment not cancelled. ##appt-error title'),
      message: tr(
        'Check your internet connection. Please try again. ##appt-error title'
      ),
    },
  },
  [ApptFetchErrorAboutEnum.GET_APPOINTMENT_LIST]: {
    [ApptFetchErrorTypeEnum.SERVER_ERROR]: {
      message: tr(
        "Server couldn't retrieve list. Please try again. ##appt-error title"
      ),
    },
    [ApptFetchErrorTypeEnum.INVALID_REQUEST]: {
      message: tr(
        "Server couldn't process request. Please try again. ##appt-error title"
      ),
    },
    [ApptFetchErrorTypeEnum.ACCESS_DENIED]: {
      title: tr('Appointment list not obtained. ##appt-error title'),
      message: tr(
        'The system is currently unavailable. Please try again. ##appt-error title'
      ),
    },
    [ApptFetchErrorTypeEnum.GENERAL]: {
      title: tr('Appointment list not available. ##appt-error title'),
      message: tr('Check your internet connection. ##appt-error title'),
    },
  },
  [ApptFetchErrorAboutEnum.RESEND_INVITE]: {
    [ApptFetchErrorTypeEnum.SERVER_ERROR]: {
      message: tr(
        "Server couldn't send invite. Please try again. ##appt-error title"
      ),
    },
    [ApptFetchErrorTypeEnum.INVALID_REQUEST]: {
      message: tr(
        "Server couldn't process request. Please try again. ##appt-error title"
      ),
    },
    [ApptFetchErrorTypeEnum.ACCESS_DENIED]: {
      title: tr('Invitation not sent. ##appt-error title'),
      message: tr(
        'The system is currently unavailable. Please try again. ##appt-error title'
      ),
    },
    [ApptFetchErrorTypeEnum.GENERAL]: {
      title: tr('Unable to send invitation. ##appt-error title'),
      message: tr(
        'Check your internet connection. Please try again. ##appt-error title'
      ),
    },
  },
  [ApptFetchErrorAboutEnum.GET_TOKEN_APPOINTMENT_DETAILS]: {
    [ApptFetchErrorTypeEnum.SERVER_ERROR]: {
      message: tr(
        "Server couldn't process request. Please try again. ##appt-error title"
      ),
    },
    [ApptFetchErrorTypeEnum.INVALID_REQUEST]: {
      message: tr(
        "Server couldn't process request. Please try again. ##appt-error message"
      ),
    },
    [ApptFetchErrorTypeEnum.ACCESS_DENIED]: {
      title: tr('Access denied. ##appt-error title'),
      message: tr(
        'The system is currently unavailable. Please try again. ##appt-error message'
      ),
    },
    [ApptFetchErrorTypeEnum.GENERAL]: {
      title: tr('Appointment information not available. ##appt-error title'),
      message: tr(
        'Check your internet connection. Please try again. ##appt-error message'
      ),
    },
  },
  [ApptFetchErrorAboutEnum.GET_CLINICIAN]: {
    [ApptFetchErrorTypeEnum.SERVER_ERROR]: {
      message: tr(
        "Server couldn't process request. Please try again. ##appt-error title"
      ),
    },
    [ApptFetchErrorTypeEnum.INVALID_REQUEST]: {
      message: tr(
        "Server couldn't process request. Please try again. ##appt-error message"
      ),
    },
    [ApptFetchErrorTypeEnum.ACCESS_DENIED]: {
      title: tr('Access denied. ##appt-error title'),
      message: tr(
        'The system is currently unavailable. Please try again. ##appt-error message'
      ),
    },
    [ApptFetchErrorTypeEnum.GENERAL]: {
      title: tr('Clinician information not available. ##appt-error title'),
      message: tr(
        'Check your internet connection. Please try again. ##appt-error message'
      ),
    },
  },
  [ApptFetchErrorAboutEnum.GET_APPOINTMENT_DETAILS]: {
    [ApptFetchErrorTypeEnum.SERVER_ERROR]: {
      message: tr(
        "Server couldn't process request. Please try again. ##appt-error title"
      ),
    },
    [ApptFetchErrorTypeEnum.INVALID_REQUEST]: {
      message: tr(
        "Server couldn't process request. Please try again. ##appt-error message"
      ),
    },
    [ApptFetchErrorTypeEnum.ACCESS_DENIED]: {
      title: tr('Access denied. ##appt-error title'),
      message: tr(
        'The system is currently unavailable. Please try again. ##appt-error message'
      ),
    },
    [ApptFetchErrorTypeEnum.GENERAL]: {
      title: tr('Appointment information not available. ##appt-error title'),
      message: tr(
        'Check your internet connection. Please try again. ##appt-error message'
      ),
    },
  },
  [ApptFetchErrorAboutEnum.GET_INTERPRETER_LANGUAGES]: {
    [ApptFetchErrorTypeEnum.SERVER_ERROR]: {
      message: tr(
        "Server couldn't process request. Please try again. ##appt-error title"
      ),
    },
    [ApptFetchErrorTypeEnum.INVALID_REQUEST]: {
      message: tr(
        "Server couldn't process request. Please try again. ##appt-error message"
      ),
    },
    [ApptFetchErrorTypeEnum.ACCESS_DENIED]: {
      title: tr('Access denied. ##appt-error title'),
      message: tr(
        'The system is currently unavailable. Please try again. ##appt-error message'
      ),
    },
    [ApptFetchErrorTypeEnum.GENERAL]: {
      title: tr(
        'Interpreter language information not available. ##appt-error title'
      ),
      message: tr(
        'Check your internet connection. Please try again. ##appt-error message'
      ),
    },
  },
  [ApptFetchErrorAboutEnum.GET_INTERPRETER]: {
    [ApptFetchErrorTypeEnum.SERVER_ERROR]: {
      message: tr(
        "Server couldn't process request. Please try again. ##appt-error title"
      ),
    },
    [ApptFetchErrorTypeEnum.INVALID_REQUEST]: {
      message: tr(
        "Server couldn't process request. Please try again. ##appt-error message"
      ),
    },
    [ApptFetchErrorTypeEnum.ACCESS_DENIED]: {
      title: tr('Access denied. ##appt-error title'),
      message: tr(
        'The system is currently unavailable. Please try again. ##appt-error message'
      ),
    },
    [ApptFetchErrorTypeEnum.GENERAL]: {
      title: tr(
        'Interpreter language information not available. ##appt-error title'
      ),
      message: tr(
        'Check your internet connection. Please try again. ##appt-error message'
      ),
    },
  },
};

export default apptErrors;
