import React, { useCallback } from 'react';
import { StatsReport } from 'twilio-video';
import { VideoTrackStats } from '../../../interfaces/VideoTrackStats';
import Telemetry from '../../../utils/telemetry';
import useStats from '../useStats/useStats';

type TrackSid = string;
type Timestamp = number;
type PacketLoss = number;
type RoundTripTime = number;
type ResolutionHeight = number;
type ResolutionWidth = number;
type Mbps = number;
type FramesPerSecond = number;

interface LoggedStats {
  i?: TrackSid;
  p?: PacketLoss;
  ow?: ResolutionWidth;
  oh?: ResolutionHeight;
  m?: Mbps;
  f?: FramesPerSecond;
}

interface SummaryLoggedStats {
  t?: Timestamp;
  r?: RoundTripTime;
  lv?: LoggedStats; // local video stats
  la?: LoggedStats; // local audio stats
  rv?: LoggedStats[]; // remote video stats
  ra?: LoggedStats[]; // remote audio stats
}

export interface FullStats {
  timestamp?: number | null;
  trackSid?: string | null;
  mbps?: number | null;
  roundTripTime?: number | null;
  resolutionWidth?: number | null;
  resolutionHeight?: number | null;
  packetsLost?: number | null;
  frameRate?: number | null;
}

export function useLogStats() {
  const { acquireLatest } = useStats();

  const statsToLogInfo = useCallback(
    ({
      trackSid,
      timestamp,
      roundTripTime,
      dimensions,
      packetsLost,
      frameRate,
      mbps,
    }: VideoTrackStats): FullStats => {
      return {
        timestamp,
        trackSid,
        mbps,
        roundTripTime,
        resolutionWidth: dimensions && dimensions.width,
        resolutionHeight: dimensions && dimensions.height,
        packetsLost,
        frameRate,
      };
    },
    []
  );

  const buildAbbStats = (stats: FullStats): LoggedStats => {
    const abbStats: LoggedStats = {};

    if (stats.trackSid) {
      abbStats.i = stats.trackSid;
    }
    if (stats.packetsLost) {
      abbStats.p = stats.packetsLost;
    }
    if (stats.resolutionWidth) {
      abbStats.ow = stats.resolutionWidth;
    }
    if (stats.resolutionHeight) {
      abbStats.oh = stats.resolutionHeight;
    }
    if (stats.mbps) {
      abbStats.m = stats.mbps;
    }
    if (stats.frameRate) {
      abbStats.f = stats.frameRate;
    }

    return abbStats;
  };

  const statsLogger = useCallback(
    (updatedStats: StatsReport): SummaryLoggedStats => {
      /* Using function to abbreviate rather than change original object to ensure readability */
      const abbreviateStats = (stats: FullStats): LoggedStats =>
        buildAbbStats(stats);
      const localVideoStats = statsToLogInfo(
        (updatedStats.localVideoTrackStats &&
          updatedStats.localVideoTrackStats[0]) ||
          {}
      );
      const localAudioStats = statsToLogInfo(
        (updatedStats.localAudioTrackStats &&
          updatedStats.localAudioTrackStats[0]) ||
          {}
      );

      const remoteVideoTrackStats = (updatedStats.remoteVideoTrackStats || [])
        .map(statsToLogInfo)
        .map(abbreviateStats);
      const remoteAudioTrackStats = (updatedStats.remoteAudioTrackStats || [])
        .map(statsToLogInfo)
        .map(abbreviateStats);

      const summaryStats: SummaryLoggedStats = {};
      const lvs = abbreviateStats(localVideoStats);
      if (Object.keys(lvs).length) {
        summaryStats.lv = lvs;
      }
      const las = abbreviateStats(localAudioStats);
      if (Object.keys(las).length) {
        summaryStats.la = las;
      }
      if (localVideoStats.timestamp) {
        summaryStats.t = localVideoStats.timestamp;
      }
      if (localVideoStats.roundTripTime) {
        summaryStats.r = localVideoStats.roundTripTime;
      }
      if (remoteVideoTrackStats.length) {
        summaryStats.rv = remoteVideoTrackStats;
      }
      if (remoteAudioTrackStats.length) {
        summaryStats.ra = remoteAudioTrackStats;
      }

      return summaryStats;
    },
    [statsToLogInfo]
  );

  const updateToLatest = useCallback(async () => {
    const res = await acquireLatest();
    return statsLogger(res) as { [key: string]: unknown };
  }, [acquireLatest, statsLogger]);

  React.useEffect(() => {
    Telemetry.register('webRtcStats', updateToLatest);
  }, [updateToLatest]);
}
