import React from 'react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import ResponsiveDialog from '../ResponsiveDialog/ResponsiveDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles } from '@material-ui/styles';
import VideoAudioErrorContent from '../VideoAudioErrorContent/VideoAudioErrorContent';
import { Container } from '@material-ui/core';
import theme from '../../theme';
import tr from '../../utils/tr';
import { ErrorType } from '../VideoProvider/useMedia/UseMediaTypes';

const useStyles = makeStyles(
  createStyles({
    title: {
      textAlign: 'center',
    },
  })
);

export default function VideoAudioError() {
  const [errorPick, setErrorPick] = React.useState<ErrorType | null>(null);
  const { videoError, audioError, otherError } = useVideoContext();
  const classes = useStyles();

  // Cause changes in errors to unset and then set, to cause a refresh of the error dialogue window state
  React.useEffect(() => {
    setErrorPick(() => null);
    let errorTimeout = null as NodeJS.Timeout | null;
    if (videoError || audioError || otherError) {
      errorTimeout = setTimeout(() => {
        setErrorPick((currError) => {
          return currError || videoError || audioError || otherError || null;
        });
      }, 0);
    }
    return () => {
      if (errorTimeout) clearTimeout(errorTimeout);
    };
  }, [otherError, videoError, audioError, setErrorPick]);

  if (!errorPick) return null;
  return (
    <ResponsiveDialog open={true}>
      <Container
        style={{
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
        }}
      >
        <DialogTitle className={classes.title}>
          {errorPick.type === 'other'
            ? tr('Camera or microphone error. ##warning title')
            : tr('Access is required. ##warning title')}
        </DialogTitle>
        <VideoAudioErrorContent errorType={errorPick.type || 'other'} />
      </Container>
    </ResponsiveDialog>
  );
}
