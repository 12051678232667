import React from 'react';
import {
  ChatContextType,
  ChatMessageType,
  ReceivedChatMessageType,
  SendChatMessageType,
} from './chatTypes';
import { reportError } from '@ascension/report-event';
import useSendReliably from '../useSendReliably';

const onError = (err: string) => reportError('Chat error: ' + err);

const defaultSendMessage = () => {
  console.error('Chat not instantiated');
  return false;
};
const defaultMarkAllAsRead = () => {
  // default empty defaultMarkAllAsRead implementation
};

const chatContextDefault = {
  sendMessage: defaultSendMessage,
  messages: [],
  markAllAsRead: defaultMarkAllAsRead,
} as ChatContextType;

export const ChatContext = React.createContext<ChatContextType>(
  chatContextDefault
);

export const ChatProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = React.useState<ChatMessageType[]>([]);

  const addMessage = React.useCallback(
    (message: ChatMessageType) =>
      setMessages((oldMessages) => [...oldMessages, message]),
    []
  );
  const addRemoteMessage = React.useCallback(
    (message: ReceivedChatMessageType) =>
      setMessages((oldMessages) => [...oldMessages, { ...message, sentByMe: false }]),
    []
  );

  const onMessageNotReceived = React.useCallback(
    (errMessage: ChatMessageType) => {
      setMessages((oldMessages) => {
        // Only update if there is an update to be made
        const toChange = oldMessages.find(
          (message) => message.id === errMessage.id
        );
        if (!toChange) {
          return oldMessages;
        }

        // Update messages
        return oldMessages.map((message) => {
          if (message.id === errMessage.id) {
            message.pastConfirmedDeadline = true;
          }
          return message;
        });
      });
    },
    []
  );

  const { sendMessage: doSendMessage } = useSendReliably<SendChatMessageType>({
    messageType: 'chatTest',
    receiveMessage: addRemoteMessage,
    autoResend: false,
    onMessageNotReceived,
    onError,
  });

  const markAllAsRead = React.useCallback(() => {
    // Set all as read
    setMessages((oldMessages) => {
      // If no unread, don't change anything
      if (!oldMessages.find((message) => !message.isRead)) return oldMessages;
      // If there is an unread, set as read (and clone to force update)
      return oldMessages.map((message) => {
        message.isRead = true;
        return { ...message };
      });
    });
  }, [setMessages]);

  const sendMessage = React.useCallback(
    (message: SendChatMessageType) => {
      const sentMessage = doSendMessage(message);
      if (sentMessage) {
        addMessage(sentMessage);
        return true;
      }
      return false;
    },
    [addMessage, doSendMessage]
  );

  return (
    <ChatContext.Provider
      value={{
        sendMessage,
        messages,
        markAllAsRead,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
