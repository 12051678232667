import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { makeStyles } from '@material-ui/styles';
import { colors } from '../../theme';
import { createStyles, Theme } from '@material-ui/core/styles';

interface ErrorWrapperI {
  title: string;
  belowTitle?: React.ReactNode;
  subtitle?: React.ReactNode | string;
  children?: React.ReactNode;
  icon?: string;
  iconAlt?: string;
}

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorWrapper: {
      position: 'relative',
      padding: '0 15%',
      [theme.breakpoints.down('xs')]: {
        padding: '0',
      },
    },
    errorIcon: {
      color: colors.grayscale.gray7,
      fontSize: '4em',
      width: '1em',
      height: '1em',
    },
  })
);

export default function ErrorWrapper({
  title,
  belowTitle,
  subtitle,
  children,
  icon,
  iconAlt,
}: ErrorWrapperI) {
  const classes = useStyles();
  return (
    <div className={classes.errorWrapper}>
      {icon && (
        <img
          src={icon}
          alt={iconAlt || 'Error'}
          className={classes.errorIcon}
        />
      )}
      {!icon && <ErrorOutlineIcon className={classes.errorIcon} />}
      <h3>{title}</h3>
      {belowTitle ? belowTitle : null}
      <p>{subtitle}</p>
      {children ? children : null}
    </div>
  );
}
