import React from 'react';
import useAudioToSpeaker from '../useAudioToSpeaker/useAudioToSpeaker';
import getAudioContext from '../../utils/getAudioContext/getAudioContext';

export default function usePlaySound(
  play: boolean,
  activeSinkId?: string | null
) {
  const [stream, setStream] = React.useState<MediaStream | null>(null);
  useAudioToSpeaker(stream, activeSinkId);

  React.useEffect(() => {
    let src: OscillatorNode | null = null;
    let mod: OscillatorNode | null = null;
    let context: AudioContext | null = null;
    if (play) {
      context = getAudioContext() || null;
      if (!context) return;
      const dest = context.createMediaStreamDestination();

      const gain = context.createGain();
      gain.gain.value = 0.4; // then it's summed with 0.5, so [0,1]
      gain.connect(dest);

      //defaults to A440Hz, sine wave
      src = context.createOscillator();
      src.frequency.value = 500;
      src.connect(gain);
      src.start(0);

      const scaler = context.createGain();
      scaler.gain.value = 0.4; // we need it to be [-0.5,0.5]
      scaler.connect(gain.gain);

      // Now let's create a modulator to turn beeps on and off
      mod = context.createOscillator();
      mod.connect(scaler); // Mod signal is [-1,1]
      mod.start(0);
      mod.frequency.value = 1.5; // Start at 2Hz
      mod.type = 'square';

      setStream(dest.stream);
    }
    return () => {
      if (src) src.stop();
      if (mod) mod.stop();
      if (context) context.close();
      src = null;
      mod = null;
      context = null;
      setStream(null);
    };
  }, [play]);
}
