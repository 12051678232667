import { useState, useEffect } from 'react';
import {
  LocalVideoTrackNullableType,
  RemoteVideoTrackNullableType,
} from '../../components/VideoProvider/VideoProviderTypes';
import { transitionSpeed } from '../../theme';
import useDebounced from '../useDebounced/useDebounced';

// The 'switchedOff' event is emitted when there is not enough bandwidth to support
// a track. See: https://www.twilio.com/docs/video/tutorials/using-bandwidth-profile-api#understanding-track-switch-offs

export default function useIsTrackSwitchedOff(
  track: LocalVideoTrackNullableType | RemoteVideoTrackNullableType
) {
  const currentValue = (track && track.track?.isSwitchedOff) || false;
  const [isSwitchedOff, setIsSwitchedOff] = useState(currentValue);
  const debouncedIsSwitchedOff = useDebounced({
    value: isSwitchedOff,
    debounceByMs: transitionSpeed,
  });

  useEffect(() => {
    // Reset the value if the 'track' variable changes
    setIsSwitchedOff(currentValue);

    if (track.track) {
      const handleSwitchedOff = () => setIsSwitchedOff(true);
      const handleSwitchedOn = () => setIsSwitchedOff(false);
      track.track.on('switchedOff', handleSwitchedOff);
      track.track.on('switchedOn', handleSwitchedOn);
      return () => {
        if (track.track) {
          track.track.off('switchedOff', handleSwitchedOff);
          track.track.off('switchedOn', handleSwitchedOn);
        }
      };
    }
  }, [track, currentValue]);

  return debouncedIsSwitchedOff;
}
