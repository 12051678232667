// Add the missing setSinkId to the default type
export interface HTMLAudioElementWithSinkId extends HTMLAudioElement {
  setSinkId: (sinkId: string) => void;
}

// Extracted here to facilitate mocking for tests. I have not been able to mock this yet
// without breaking jest.
export function getAudioElement() {
  return document.createElement('audio') as HTMLAudioElementWithSinkId;
}

// Extracted here to facilitate mocking for tests. I have not been able to mock this yet
// without breaking jest.
export function appendDomElement(element: HTMLElement) {
  return document.body.appendChild(element);
}
