import { useState, useCallback, useRef } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';
import {
  LocalParticipant,
  LocalTrack,
  LocalTrackPublication,
  LocalTrackPublishOptions,
  Track,
} from 'twilio-video';
import { isFirefox } from 'react-device-detect';
import { getErrorType } from '../../components/VideoProvider/useMedia/utils';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';
import { reportError } from '@ascension/report-event';

interface MediaStreamTrackPublishOptions {
  name?: string;
  priority: Track.Priority;
}

// Fix type to prevent incorrect rejection of mediaStream
interface LocalParticipantWithMediaStreamInterface extends LocalParticipant {
  publishTrack: (
    track: LocalTrack | MediaStreamTrack,
    options?: LocalTrackPublishOptions
  ) => Promise<LocalTrackPublication>;
}

export default function useScreenShareToggle() {
  const { room, onError, setScreenShareError } = useVideoContext();
  const [isSharing, setIsSharing] = useState(false);
  /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
  const stopScreenShareRef = useRef<() => void>(null!);

  const shareScreen = useCallback(() => {
    navigator.mediaDevices
      .getDisplayMedia({
        audio: false,
        video: {
          frameRate: 10,
          height: 1080,
          width: 1920,
        },
      })
      .then((stream) => {
        const track = stream.getTracks()[0];

        // All video tracks are published with 'low' priority. This works because the video
        // track that is displayed in the 'ParticipantMaximized' component will have it's priority
        // set to 'high' via track.setPriority()
        (room.localParticipant as LocalParticipantWithMediaStreamInterface)
          .publishTrack(track, {
            name: 'screen', // Tracks can be named to easily find them later
            priority: 'low', // Priority is set to high by the subscriber when the video track is rendered
          } as MediaStreamTrackPublishOptions)
          .then((trackPublication) => {
            stopScreenShareRef.current = () => {
              room.localParticipant.unpublishTrack(track);
              // TODO: remove this if the SDK is updated to emit this event
              room.localParticipant.emit('trackUnpublished', trackPublication);
              track.stop();
              setIsSharing(false);
            };

            track.onended = stopScreenShareRef.current;
            setIsSharing(true);
          })
          .catch((e) => {
            onError(e);
            reportError(`Error in publishing to screen-share`);
          });
      })
      .catch((e) => {
        // Don't display an error if the user closes the screen share dialog
        if (
          (e.name !== 'AbortError' && e.name !== 'NotAllowedError') ||
          e.message.includes('system') ||
          e.message.includes('agent')
        ) {
          setScreenShareError({
            error: 'Error in obtaining screens-share',
            type: getErrorType(isFirefox, e.toString()),
          });
        }
        reportError(`Error in obtaining screens-share`);
      });
  }, [room, onError, setScreenShareError]);

  const toggleScreenShare = useCallback(() => {
    !isSharing ? shareScreen() : stopScreenShareRef.current();
    analyticEvent({ label: 'Share Screen', action: ACTION_CALL_TO_ACTION });
  }, [isSharing, shareScreen, stopScreenShareRef]);

  return [isSharing, toggleScreenShare] as const;
}
