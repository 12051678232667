import React from 'react';
import { DeviceType } from './UseMediaTypes';

interface UseRememberInterface {
  devices: DeviceType[];
  currentDeviceId: string | null;
  deviceIdDesired: string | null;
  setDeviceIdDesired: React.Dispatch<React.SetStateAction<string | null>>;
  deviceType: 'audio-in' | 'audio-out' | 'video-in';
}

export default function useRememberDevice({
  devices,
  currentDeviceId,
  deviceIdDesired,
  setDeviceIdDesired,
  deviceType,
}: UseRememberInterface) {
  React.useEffect(() => {
    if (!deviceIdDesired) return;
    try {
      window.localStorage.setItem(deviceType, deviceIdDesired);
    } catch (err) {
      // do nothing
    }
  }, [deviceType, deviceIdDesired]);

  React.useEffect(() => {
    const timeout = window.setTimeout(() => {
      const lastDesired = window.localStorage.getItem(deviceType) || null;
      // If a device is being displayed (wait until permissions/initial request done)
      if (currentDeviceId && lastDesired && currentDeviceId !== lastDesired) {
        if (devices.find((device) => device.id === lastDesired)) {
          setDeviceIdDesired(lastDesired);
        }
      }
    }, 1000);
    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    }
  }, [setDeviceIdDesired, devices, currentDeviceId, deviceType]);
}
