import { useCallback } from 'react';
import { QUERY_SHOW_IN_RIGHT_PANEL } from '../../constants';
import { getFromMap } from './rightPanelContentMap';
import { RightPanelKeyEnum } from '../../interfaces/RightPanelContentTypes';
import { useHistory } from 'react-router-dom';
import useQuery from '../useQuery/useQuery';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';

export function sendAnalyticEvent(panelName: string) {
  switch (panelName) {
    case RightPanelKeyEnum.INVITE:
      return analyticEvent({
        label: 'Invite Panel Close',
        action: ACTION_CALL_TO_ACTION,
      });
    case RightPanelKeyEnum.MEDIA_SETTINGS:
      return analyticEvent({
        label: 'Media Setting Panel Close',
        action: ACTION_CALL_TO_ACTION,
      });
    case RightPanelKeyEnum.CHAT:
      return analyticEvent({
        label: 'Chat Panel Close',
        action: ACTION_CALL_TO_ACTION,
      });
    default:
      return analyticEvent({
        label: 'Close Side Panel',
        action: ACTION_CALL_TO_ACTION,
      });
  }
}

const ensurePrefix = (content: string, prefix: string) =>
  !content || content.startsWith(prefix) ? content : `${prefix}${content}`;

export default function useShowInRightPanel() {
  const history = useHistory();
  const query = useQuery();
  const showingWhichQuery = query.get(QUERY_SHOW_IN_RIGHT_PANEL);
  const showingWhich = getFromMap(showingWhichQuery);

  const hide = useCallback(() => {
    sendAnalyticEvent(
      history.location.search ? history.location.search.split('=')[1] : ''
    );

    const queryParams = new URLSearchParams(history.location.search);
    queryParams.delete('right_panel');
    history.replace({
      search: ensurePrefix(queryParams.toString(), '?')
    });
  }, [history]);

  const showWhich = useCallback(
    (which: RightPanelKeyEnum) => {
      const queryParams = new URLSearchParams(history.location.search);
      queryParams.set('right_panel', which);
      history.replace({
        search: ensurePrefix(queryParams.toString(), '?'),
      });
    },
    [history]
  );

  const toggleWhich = useCallback(
    (which: RightPanelKeyEnum) => {
      if (showingWhich && showingWhichQuery === which) {
        return hide();
      }
      return showWhich(which);
    },
    [hide, showWhich, showingWhich, showingWhichQuery]
  );

  return {
    showWhich,
    toggleWhich,
    hide,
    showingWhich,
    showingWhichQuery: showingWhich ? showingWhichQuery : null,
  };
}
