import React from 'react';
import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import tr from '../../../utils/tr';
import {
  getTextDirectionOriginIsRight,
  getLocalizationTimeString,
} from '../../../utils';
import { useHistory } from 'react-router-dom';
import { ApptRootType, ApptTypeEnum } from '../../../state/appt/apptTypes';
import { SELECTED_APPOINTMENT_LIST_ITEM_CLASS_NAME } from '../../../constants';
import { colors } from '../../../theme';
import { AppointmentDetailStatusEnum } from '../../../hooks/appt/apptHookTypes';
import { AccessTime as AccessTimeIcon } from '@material-ui/icons';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CallIcon from '@material-ui/icons/Call';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { appointmentHeaderTextMap } from '../AppointmentPageHeader/AppointmentPageHeader';
import useScrollToApptForDisplay from '../../../hooks/appt/useScrollToApptForDisplay/useScrollToApptForDisplay';

export type AppointmentDetailType = ApptRootType & {
  mobile?: string;
  name?: string;
  status: AppointmentDetailStatusEnum;
  messageCount?: number;
  inviteCount?: number;
  callDuration?: number;
  waitDuration?: number;
  waitCount?: number;
  isClosestTime: boolean;
  type?: ApptTypeEnum;
};

const apptIconStyle = {
  width: '18px',
  height: '18px',
  paddingRight: '5px',
};
const content: {
  [key in AppointmentDetailStatusEnum]: {
    component: React.ReactNode;
    color: string;
    statusBackgroundColor: string;
    backgroundColor: string;
  };
} = {
  [AppointmentDetailStatusEnum.COMPLETED]: {
    component: <CheckIcon style={apptIconStyle} />,
    color: '',
    statusBackgroundColor: colors.grayscale.gray11,
    backgroundColor: colors.grayscale.gray13,
  },
  [AppointmentDetailStatusEnum.WAITING]: {
    component: (
      <AccessTimeIcon
        style={{ ...apptIconStyle, color: colors.secondary.violetDark }}
      />
    ),
    color: colors.secondary.violetDark,
    statusBackgroundColor: '',
    backgroundColor: '',
  },
  [AppointmentDetailStatusEnum.NOW]: {
    component: (
      <AccessTimeIcon
        style={{ ...apptIconStyle, color: colors.secondary.violet }}
      />
    ),
    color: colors.secondary.violet,
    statusBackgroundColor: colors.secondary.redLight,
    backgroundColor: '#FFFFFF',
  },
  [AppointmentDetailStatusEnum.SCHEDULED]: {
    component: <CalendarTodayIcon style={apptIconStyle} />,
    color: '',
    statusBackgroundColor: colors.secondary.blueLight2,
    backgroundColor: '#FFFFFF',
  },
  [AppointmentDetailStatusEnum.IN_CALL]: {
    component: (
      <CallIcon
        style={{ ...apptIconStyle, color: colors.primaryPalette.brandBlue }}
      />
    ),
    color: colors.primaryPalette.brandBlue,
    statusBackgroundColor: '',
    backgroundColor: '',
  },
  [AppointmentDetailStatusEnum.INVITE_ERROR]: {
    component: (
      <ErrorOutlineIcon
        style={{ ...apptIconStyle, color: colors.notifications.error }}
      />
    ),
    color: colors.notifications.error,
    statusBackgroundColor: '',
    backgroundColor: '',
  },
  [AppointmentDetailStatusEnum.CANCELLED]: {
    component: <NotInterestedIcon style={apptIconStyle} />,
    color: '',
    statusBackgroundColor: colors.grayscale.gray11,
    backgroundColor: colors.grayscale.gray13,
  },
};

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  /* istanbul ignore next */
  createStyles({
    apptCard: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      boxShadow:
        '0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
      '&:hover': {
        border: ' 1px solid rgba(0, 0, 0, 0.5) !important',
        boxShadow:
          '0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1)',
      },
    },
    completed: {
      backgroundColor: theme.palette.grey[300],
    },
    next: {
      backgroundColor: 'white',
    },
    selected: {
      backgroundColor: '#D1EAFC !important',
      border: ' 1px solid #D1EAFC !important',
    },
    waitIcon: {
      color: colors.secondary.violetDark,
    },
    inviteErrorIcon: {
      color: 'red',
    },
    callIcon: {
      color: colors.primaryPalette.brandBlue,
    },
    dotSeparator: {
      height: '5px',
      width: '5px',
      borderRadius: '50%',
      display: 'inline-block',
      paddingRight: '5px',
      position: 'relative',
      top: '-2px',
    },
    dotSeparatorWaiting: {
      backgroundColor: colors.secondary.violetDark,
    },
    dotSeparatorNext: {
      backgroundColor: theme.palette.grey[500],
    },
    apptIconContainer: {
      width: '48px',
      height: '48px',
      borderRadius: '24px',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: colors.grayscale.gray11,
      margin: '12px 20px 0 12px',
      alignSelf: 'start',
    },
    apptIcon: {
      alignSelf: 'center',
      width: '32px',
    },
    apptData: {
      flex: 1,
      overflow: 'hidden',
    },
    apptDetailHeader: {
      padding: '16px 5px 0px 5px',
      fontSize: '12px',
      fontWeight: 500,
    },
    apptStatus: {
      paddingLeft: '5px',
      paddingRight: '5px',
      textTransform: 'uppercase',
      width: 'fit-content',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '10px',
      height: '20px',
    },
    rightPadding: {
      paddingRight: '5px',
    },
    leftPadding: {
      paddingLeft: '5px',
    },
    apptName: {
      padding: '0px 5px',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    apptDetailFooter: {
      padding: '0px 5px 12px 5px',
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 500,
      direction: getTextDirectionOriginIsRight() ? 'rtl' : 'ltr',
    },
    apptSelect: {
      width: '45px',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '5px',
    },
  })
);

export default function Appointment({
  appointmentDetail,
  isSelected = false,
  somethingIsSelected = false,
}: {
  appointmentDetail: AppointmentDetailType;
  isSelected?: boolean;
  somethingIsSelected?: boolean;
}) {
  const classes = useStyles();
  const history = useHistory();
  const elementRef = React.useRef<HTMLLIElement | null>(null);

  const {
    id,
    messageCount = 0,
    name,
    mobile,
    status,
    startTime,
    callDuration = 0,
    inviteCount = 0,
    waitDuration = 0,
    waitCount = 1,
  } = appointmentDetail;

  const onClick = () => {
    history.push(`/appointment/${id}`);
  };

  useScrollToApptForDisplay({
    isSelected,
    isClosestTime: appointmentDetail.isClosestTime,
    elementRef,
    somethingIsSelected,
  });

  const thisContent = content[status];

  return (
    <li
      ref={elementRef}
      className={clsx(
        classes.apptCard,
        status === AppointmentDetailStatusEnum.COMPLETED
          ? classes.completed
          : classes.next,
        isSelected ? classes.selected : null,
        isSelected ? SELECTED_APPOINTMENT_LIST_ITEM_CLASS_NAME : ''
      )}
      style={{
        backgroundColor: thisContent.backgroundColor,
        border: thisContent.backgroundColor
          ? `1px solid ${thisContent.backgroundColor}`
          : 'none',
      }}
      data-testid={`appointment-${id}`}
      onClick={onClick}
    >
      <div className={classes.apptIconContainer}>
        <img
          className={classes.apptIcon}
          src={
            appointmentDetail.type === ApptTypeEnum.ATHENA
              ? '/static/AthenaHealth.png'
              : '/static/VideoChatLogo.png'
          }
          alt={
            appointmentDetail.type === ApptTypeEnum.ATHENA
              ? 'Athena Logo'
              : 'Video Chat Logo'
          }
        />
      </div>
      <div className={classes.apptData}>
        <div className={classes.apptDetailHeader}>
          <div
            className={classes.apptStatus}
            style={{
              color: thisContent.color,
              backgroundColor: thisContent.statusBackgroundColor,
            }}
          >
            {thisContent.component}
            {appointmentHeaderTextMap[status]}
          </div>
          {waitDuration > 0 && (
            <span className={classes.rightPadding}>
              {waitDuration}
              {tr('M ##new-appt suffix-minute')}
            </span>
          )}
          {messageCount > 0 && (
            <span>
              <span
                className={clsx(
                  classes.dotSeparator,
                  classes.dotSeparatorWaiting
                )}
              />
              <span className={classes.leftPadding}>
                {messageCount}{' '}
                {messageCount === 1
                  ? tr('NEW MESSAGE ##appt-message count')
                  : tr('NEW MESSAGES ##appt-message count')}
              </span>
            </span>
          )}
        </div>
        <div className={classes.apptName}>
          {name || mobile} {waitCount > 1 && <span>...</span>}
        </div>
        <div className={classes.apptDetailFooter}>
          <span
            className={
              getTextDirectionOriginIsRight()
                ? classes.leftPadding
                : classes.rightPadding
            }
          >
            {getLocalizationTimeString(startTime)}
          </span>
          <span
            className={clsx(classes.dotSeparator, classes.dotSeparatorNext)}
          />

          <span
            className={
              getTextDirectionOriginIsRight()
                ? classes.rightPadding
                : classes.leftPadding
            }
          >
            {status === AppointmentDetailStatusEnum.COMPLETED
              ? `${tr(
                  'call lasted ##finished-appt prefix-calltime'
                )} ${callDuration}${tr('m ##finished-appt suffix-minute')}`
              : `${inviteCount} ${tr('invited ##new-appt suffix-invited')}`}
          </span>
        </div>
      </div>
      <div className={classes.apptSelect}>
        <IconButton>
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </li>
  );
}
