import React from 'react';
import Telemetry from '../../utils/telemetry';
import { getRandomString, getDefaultHeaders } from '../../utils';
import * as ReactRouterDom from 'react-router-dom';

export default function useNetworkSpeedTest() {
  const { search } = ReactRouterDom.useLocation();
  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const { API_BASE, ENABLE_SPEED_TEST, SPEED_TEST_LENGTH } = window.config;
    // Make random string here for upload, to avoid recreating it every time
    const randomString = getRandomString(parseInt(SPEED_TEST_LENGTH) || 100);
    // Stop if speed test is disabled
    if ((searchParams.get('enableSpeedTest') || ENABLE_SPEED_TEST) !== 'true')
      return;
    // Download
    Telemetry.register(
      'speedDownload',
      async () => {
        const start = performance.now();
        const headers = getDefaultHeaders();
        return fetch(`${API_BASE}speed/download`, {
          method: 'GET',
          cache: 'no-store',
          headers,
        }).then((res) => {
          if (res.status !== 200) {
            return Promise.reject('Failed to download');
          }
          return {
            uploadSpeed: Math.round(performance.now() - start),
          };
        });
      },
      6
    );
    // Upload
    Telemetry.register(
      'speedUpload',
      async () => {
        // Prepare headers
        const headers = getDefaultHeaders();
        headers.set('Content-Type', 'application/json');
        const start = performance.now();
        return fetch(`${API_BASE}speed/upload`, {
          method: 'POST',
          body: JSON.stringify({
            content: randomString,
          }),
          cache: 'no-store',
          headers,
        }).then((res) => {
          if (res.status !== 200) {
            return Promise.reject('Failed to upload');
          }
          return {
            downloadSpeed: Math.round(performance.now() - start),
          };
        });
      },
      6
    );
  }, [search]);
}
