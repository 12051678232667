import { NotificationProperties } from './useSendNotification';

export const setCloseAction = (
  notif: Notification | undefined,
  options: NotificationProperties | undefined
) => {
  if (notif) {
    notif.onclick = (ev: Event) => {
      if (options?.onclick) {
        options.onclick(ev);
      }
      if (options?.closeOnClick) {
        notif.close();
      }
    };
  }
};

export const spinUpServiceWorker = (
  callback: (serviceWorker: ServiceWorkerRegistration) => void
) => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/notification-service-worker.js')
      .then(() => {
        navigator.serviceWorker.ready.then(callback);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
