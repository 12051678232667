import React from 'react';
import ScreenShare from '@material-ui/icons/ScreenShare';
import StopScreenShare from '@material-ui/icons/StopScreenShare';
import useScreenShareToggle from '../../../hooks/useScreenShareToggle/useScreenShareToggle';
import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import BarButton from '../BarButton/BarButton';
import tr from '../../../utils/tr';

const SCREEN_SHARE_TEXT = tr('Screen Share ##video button');
const STOP_SCREEN_SHARE_TEXT = tr('Screen Share ##video button');

export default function ToggleScreenShareButton(props: { disabled?: boolean }) {
  const [isScreenShared, toggleScreenShare] = useScreenShareToggle();
  const screenShareParticipant = useScreenShareParticipant();
  const { room } = useVideoContext();
  const disableScreenShareButton =
    screenShareParticipant && screenShareParticipant !== room.localParticipant;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const isScreenShareSupported =
    // eslint-disable-next-line @typescript-eslint/unbound-method
    navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled =
    props.disabled || disableScreenShareButton || !isScreenShareSupported;

  return isScreenShared ? (
    <BarButton
      onClick={toggleScreenShare}
      text={STOP_SCREEN_SHARE_TEXT}
      disabled={isDisabled}
      selected
      highlighted
    >
      <StopScreenShare titleAccess="Disable Screenshare Button" />
    </BarButton>
  ) : (
    <BarButton
      onClick={toggleScreenShare}
      text={SCREEN_SHARE_TEXT}
      disabled={isDisabled}
    >
      <ScreenShare titleAccess="Enable Screenshare Button" />
    </BarButton>
  );
}
