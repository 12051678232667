import { DatatrackMessageSendType, TimeSensitiveMessage } from './messageTypes';
import { useCurrentParticipants, useMessageUtils } from './useMessageUtils';
import React from 'react';
import { SentMetadata } from './useSendMessage';
import { isAck } from './useMessageConversions';

interface UseTrackListenerInterface<T> {
  messageType: string;
  setMessages: React.Dispatch<React.SetStateAction<TimeSensitiveMessage<T>[]>>;
  acceptMessage: (message: T & SentMetadata) => void;
  onError: (err: string) => void;
}

export default function useTrackListener<T>({
  messageType,
  setMessages,
  acceptMessage,
  onError,
}: UseTrackListenerInterface<T>) {
  const [received, setReceived] = React.useState<Record<string, unknown>>({});
  const { confirmMessageReceived, sendConfirmation } = useMessageUtils({
    messageType,
    setMessages,
    onError,
  });
  const { currentParticipantId } = useCurrentParticipants();

  return React.useCallback(
    (content: string) => {
      let packet: DatatrackMessageSendType;

      try {
        packet = JSON.parse(content) as DatatrackMessageSendType;
      } catch (e) {
        onError('There was an error parsing the packet');
        return;
      }

      // If my message or not handled by this handler, ignore
      if (
        currentParticipantId === packet.sentById ||
        (packet.sentToId && currentParticipantId !== packet.sentToId) ||
        !packet.type.startsWith(messageType)
      ) {
        return;
      }

      // Check if message already handled
      const id: string = packet.resendOf || packet.id;
      if (packet.type === messageType && !received[id]) {
        // Parse the packet's contents
        let incomingContent;
        try {
          incomingContent = JSON.parse(packet.content);
        } catch (e) {
          onError("There was an error parsing the packet's contents");
          return;
        }

        // Record message
        setReceived((alreadyReceived) => ({
          ...alreadyReceived,
          [id]: true,
        }));
        acceptMessage({
          ...incomingContent,
          id,
          sentById: packet.sentById,
          pastConfirmedDeadline: false,
        });
      }

      // If an ACK, record it; otherwise, send an ACK
      if (isAck(packet.type)) {
        confirmMessageReceived(packet.id);
      } else {
        sendConfirmation(packet.id);
      }
    },
    [
      acceptMessage,
      confirmMessageReceived,
      currentParticipantId,
      messageType,
      onError,
      received,
      sendConfirmation,
    ]
  );
}
