import React, { FormEvent } from 'react';
import { makeStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/core';
import tr from '../../utils/tr';

export type BrowserIconType = {
  icon: string;
  name: string;
  url: string | null;
};

const useStyles = makeStyles(
  createStyles({
    listItem: {
      display: 'block',
    },
    image: {
      height: '60px',
      width: 'auto',
    },
    subText: {
      display: 'block',
      fontSize: '0.7em',
      textDecoration: 'none',
    },
  })
);

export default function BrowserIcon({ icon, name, url }: BrowserIconType) {
  const classes = useStyles();
  const handleClick = (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    // 'noreferrer' automatically includes 'noopener': https://developer.mozilla.org/en-US/docs/Web/API/Window/open
    if (url) window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <li onClick={handleClick} className={classes.listItem}>
      <img src={icon} alt={`${name} icon`} className={classes.image} />
      {url ? (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.subText}
        >
          {tr('Download ##browser icon') + ' ' + name}
        </a>
      ) : (
        <span className={classes.subText}>{name}</span>
      )}
    </li>
  );
}
