import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { colors } from '../../../../theme';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';

const useStyles = makeStyles({
  wrap: {
    backgroundColor: colors.grayscale.gray12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default function VideoStandin(
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) {
  const classes = useStyles();
  return (
    <div className={classes.wrap} {...props}>
      <VideocamOffIcon titleAccess="Camera off" />
    </div>
  );
}
