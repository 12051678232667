import React from 'react';
import { TrackType } from './UseMediaTypes';

// This is a patch for the Chromium bug on Android 11 devices.
// Remove once bug patched https://bugs.chromium.org/p/chromium/issues/detail?id=1148532
export default function useReleaseTrack() {
  return React.useCallback((track: TrackType) => {
    const trackId = track.track?.id;
    if (!trackId) return;

    const el: null | HTMLVideoElement = document.querySelector(
      `video[data-trackid="${trackId}"]`
    );

    if (
      el?.srcObject &&
      'removeTrack' in el.srcObject &&
      track.track?.mediaStreamTrack
    ) {
      el.srcObject.removeTrack(track.track?.mediaStreamTrack);
    }
  }, []);
}
