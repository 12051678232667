import React from 'react';
import {
  isAndroid,
  isIOS,
  isSafari,
  isChrome,
  isFirefox,
} from 'react-device-detect';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import clsx from 'clsx';
import RefreshButton from '../RefreshButton/RefreshButton';
import tr from '../../utils/tr';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      '& ol': {
        paddingInlineStart: theme.spacing(3) + 'px',
      },
      '& li': {
        marginBottom: '1em',
        paddingInlineStart: theme.spacing(2) + 'px',
      },
    },
    liBottom: {
      '& li:first-child': {
        marginBottom: '0px !important',
      },
    },
    button: {
      width: '100%',
    },
    screenshot: {
      marginLeft: `-${theme.spacing(2)}px`,
      paddingRight: theme.spacing(1) + 'px',
    },
    icon: {
      height: '1.4em',
      marginLeft: '0.4em',
      marginRight: '0.4em',
      marginBottom: '-0.2em',
    },
    threedotsIcon: {
      marginLeft: '0.4em',
      marginRight: '0.4em',
      marginBottom: '0.2em',
    },
  })
);

export default function NotificationErrorContent() {
  const classes = useStyles();

  // Site permissions: iOS Safari
  if (isSafari && isIOS) {
    return (
      <div className={clsx(classes.wrap, classes.liBottom)}>
        {tr(
          'Video Chat needs to access to be able to send you notifications. ##warning isafari'
        ) + ' '}
        {tr(
          'Please follow these steps to enable those devices in your Site Settings. ##warning isafari'
        )}
        <ol>
          <li>
            {tr('To do this, click the ##warning isafari') + ' '}
            <img
              src="/static/screen-ios-safari-aa.svg"
              alt={tr(
                'Safari site accessibility button ##warning isafariimage'
              )}
              className={classes.icon}
            />{' '}
            {tr(
              'accessibility icon in the address bar of your browser. ##warning isafari'
            )}
            <img
              src="/static/screen-ios-safari-sitesettings.svg"
              alt={tr(
                'site-settings location on iOS Safari ##warning isafariimage'
              )}
              width="100%"
              className={classes.screenshot}
            />
          </li>
          <li>
            {tr('Look for ##warning isafari') + ' '}
            <b>{tr('"Website Settings" ##warning isafari')}</b>
            {tr(', and select ##warning isafari') + ' '}
            <b>{tr('"Allow" ##warning isafari')}</b>
            {' ' + tr('for Notifications. ##warning isafari')}
          </li>
          <li>
            {tr(
              'Refresh this page after you complete those steps to join the Video Chat. ##warning safari'
            )}
          </li>
        </ol>
        <RefreshButton className={classes.button} />
      </div>
    );
  }

  // Site permissions: Desktop Safari
  if (isSafari && !isIOS) {
    return (
      <div className={classes.wrap}>
        {tr(
          'Video Chat needs to access to be able to send you notifications. ##warning dsafari'
        ) + ' '}
        {tr(
          'Please follow these steps to enable those devices in your Site Settings. ##warning dsafari'
        )}
        <ol>
          <li>
            {tr('To do this, click ##warning dsafari') + ' '}
            <b>{tr('"Safari" ##warning dsafari')}</b>
            {' ' +
              tr('in the menu bar and then select ##warning dsafari') +
              ' '}
            <b>{tr('"Preferences." ##warning dsafari')}</b>
          </li>
          <li>
            {tr('Click on the ##warning dsafari') + ' '}
            <b>{tr('"Websites" ##warning dsafari')}</b>
            {' ' + tr('tab and then select ##warning dsafari') + ' '}
            <b>{tr('"Notifications." ##warning dsafari')}</b>
          </li>
          <li>
            {tr('Change the setting for ##warning dsafari') + ' '}
            <b>{tr('"video.ascension.org" ##warning dsafari')}</b>
            {' ' + tr('from ##warning dsafari') + ' '}
            <b>{tr('"Deny" ##warning dsafari')}</b>
            {' ' + tr('to ##warning dsafari') + ' '}
            <b>{tr('"Allow." ##warning dsafari')}</b>
          </li>
          <li>
            {tr(
              'Refresh this page after you complete those steps to join the Video Chat. ##warning dsafari'
            )}
          </li>
        </ol>
        <RefreshButton className={classes.button} />
      </div>
    );
  }

  // Site permissions: Android Firefox
  if (isFirefox && isAndroid) {
    return (
      <div className={clsx(classes.wrap, classes.liBottom)}>
        {tr(
          'Video Chat needs to access to be able to send you notifications. ##warning afirefox'
        ) + ' '}
        {tr(
          'Please follow these steps to enable those devices in your Site Settings. ##warning afirefox'
        )}
        <ol>
          <li>
            {tr('To do this, click the ##warning afirefox') + ' '}
            <img
              src="/static/screen-android-firefox-threedots.svg"
              alt={tr('Lock ##warning afirefoximage')}
              className={classes.threedotsIcon}
            />
            {' ' +
              tr(
                'menu in the address bar of your browser. ##warning afirefox'
              ) +
              ' '}
            <img
              src="/static/screen-android-firefox-menu.svg"
              alt={tr(
                'site-settings location on Android Chrome ##warning afirefoximage'
              )}
              width="100%"
              className={classes.screenshot}
            />
          </li>
          <li>
            {tr('Select ##warning afirefox') + ' '}
            <b>{tr('"Settings" ##warning afirefox')}</b>
            {' ' + tr('then ##warning afirefox') + ' '}
            <b>{tr('"Site Permissions" ##warning afirefox')}</b>.
            {' ' +
              tr(
                'Make sure that Notifications are not blocked by Android. ##warning afirefox'
              )}
          </li>
          <li>
            {tr('Select ##warning afirefox') + ' '}
            <b>{tr('"Exceptions" ##warning afirefox')}</b>
            {' ' + tr('and look for ##warning afirefox') + ' '}
            <b>&quot;{window.location.host}&quot;</b>
            {' ' + tr('and select it. ##warning afirefox') + ' '}
            {' ' + tr('Press ##warning afirefox') + ' '}
            <b>{tr('"Clear Permissions" ##warning afirefox')}</b>.
          </li>
          <li>
            {tr(
              'Refresh this page after you complete those steps to join the Video Chat. ##warning afirefox'
            )}
          </li>
        </ol>
        <RefreshButton className={classes.button} />
      </div>
    );
  }
  // Site permissions: Desktop Firefox
  if (isFirefox && !isAndroid) {
    return (
      <div className={clsx(classes.wrap, classes.liBottom)}>
        {tr(
          'Video Chat needs to access to be able to send you notifications. ##warning dfirefox'
        ) + ' '}
        {tr(
          'Please follow these steps to enable those devices in your Site Settings. ##warning dfirefox'
        )}
        <ol>
          <li>
            {tr('To do this, click on the ##warning dfirefox') + ' '}
            <img
              src="/static/screen-desktop-firefox-settingicon.svg"
              alt={tr('Lock ##warning dfirefoximage')}
              className={classes.icon}
            />
            {' ' +
              tr(
                'permissions icon in the address bar of your browser. ##warning dfirefox'
              ) +
              ' '}
            <img
              src="/static/screen-desktop-firefox-sitesettings.svg"
              alt={tr(
                'site-settings location on Android Chrome ##warning dfirefoximage'
              )}
              width="100%"
              className={classes.screenshot}
            />
          </li>
          <li>
            {tr('Select ##warning dfirefox') + ' '}
            <b>&quot;x&quot;</b>
            {' ' +
              tr('to remove the block for Notifications. ##warning dfirefox')}
          </li>
          <li>
            {tr(
              'Refresh this page after you complete those steps to join the Video Chat. ##warning dfirefox'
            )}
          </li>
        </ol>
        <RefreshButton className={classes.button} />
      </div>
    );
  }

  // Site permissions: Android Chrome
  if (isChrome && isAndroid) {
    return (
      <div className={clsx(classes.wrap, classes.liBottom)}>
        {tr(
          'Video Chat needs to access to be able to send you notifications. ##warning achrome'
        ) + ' '}
        {tr(
          'Please follow these steps to enable those devices in your Site Settings. ##warning achrome'
        )}
        <ol>
          <li>
            {tr('To do this, click on the ##warning achrome') + ' '}
            <img
              src="/static/screen-desktop-chrome-lock.svg"
              alt={tr('Lock ##warning dchromeimage')}
              className={classes.icon}
            />
            {' ' + tr('lock icon by the address bar. ##warning achrome') + ' '}
            <img
              src="/static/screen-android-chrome-sitesettings.svg"
              alt={tr(
                'site-settings location on Android Chrome ##warning achromeimage'
              )}
              width="100%"
              className={classes.screenshot}
            />
          </li>
          <li>
            {tr('Select ##warning achrome') + ' '}
            <b>{tr('"Permissions" ##warning achrome')}</b>
            {tr(', and then switch ##warning achrome') + ' '}
            <b>{tr('"Notifications" ##warning achrome')}</b>
            {tr(', to ##warning achrome')}
            <b>{' ' + tr('"Allow." ##warning achrome')}</b>
          </li>
          <li>
            {tr(
              'Refresh this page after you complete those steps to join the Video Chat. ##warning achrome'
            )}
          </li>
        </ol>
        <RefreshButton className={classes.button} />
      </div>
    );
  }

  // Site permissions/fallback: Desktop Chrome
  return (
    <div className={clsx(classes.wrap, classes.liBottom)}>
      {tr(
        'Video Chat needs to access to be able to send you notifications. ##dchrome'
      ) + ' '}
      {tr(
        'Please follow these steps to enable those devices in your Site Settings. ##dchrome'
      )}
      <ol>
        <li>
          {tr('To do this, click on the ##warning dchrome') + ' '}
          <img
            src="/static/screen-desktop-chrome-lock.svg"
            alt={tr('Lock ##warning dchromeimage')}
            className={classes.icon}
          />
          {' ' +
            tr(
              'lock icon in the address bar of your browser. ##warning dchrome'
            ) +
            ' '}
          <img
            src="/static/screen-desktop-chrome-sitesettings.svg"
            alt={tr(
              'site-settings location on Android Chrome ##warning dchromeimage'
            )}
            width="100%"
            className={classes.screenshot}
          />
        </li>
        <li>
          {tr('Change ##warning dchrome') + ' '}
          <b>{tr('"Notifications" ##warning dchrome')}</b>
          {tr(', to ##warning dchrome') + ' '}
          <b>{tr('"Allow." ##warning dchrome')}</b>
        </li>
        <li>
          {tr(
            'Refresh this page after you complete those steps to join the Video Chat. ##warning dchrome'
          )}
        </li>
      </ol>
      <RefreshButton className={classes.button} />
    </div>
  );
}
