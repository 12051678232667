import React from 'react';
import { Container } from '@material-ui/core';
import theme from '../../theme';
import useAuthBaseContext from '../../hooks/useAuthBaseContext/useAuthBaseContext';
import LogoutError from './LogoutError';
import RoomFullError from './RoomFullError';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import ApptError from '../appt/ApptError/ApptError';
import { useVideoRoomState } from '../../hooks/useVideoRoomState/VideoRoomStateProvider';
import InviteAppointmentUpdateForm from '../appt/InviteAppointmentUpdate/InviteAppointmentUpdateForm';

export default function InvitePanel() {
  const { isLoggedIn } = useAuthBaseContext();
  const { room } = useVideoContext();
  const roomParticipants = Array.from(room?.participants?.values() || []);
  const [isRoomFull, setIsRoomFull] = React.useState<boolean>(false);
  const { roomCurrentAppt, getAppointmentDetail } = useVideoRoomState();

  React.useEffect(() => {
    if (roomParticipants.length >= 3) {
      setIsRoomFull(true);
    } else {
      setIsRoomFull(false);
    }
  }, [roomParticipants]);

  return (
    <Container
      style={{
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      }}
    >
      {isRoomFull && isLoggedIn && <RoomFullError />}
      {roomCurrentAppt ? (
        <InviteAppointmentUpdateForm
          appointment={roomCurrentAppt}
          hideName
          refetchAppointment={getAppointmentDetail}
        />
      ) : (
        <LogoutError />
      )}
      <ApptError />
    </Container>
  );
}
