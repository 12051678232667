import {
  createLocalAudioTrack,
  CreateLocalTrackOptions,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalVideoTrack,
} from 'twilio-video';
import { GetTrackInterface } from './UseMediaTypes';

export function getAudioTrack({
  deviceId,
}: GetTrackInterface): Promise<LocalAudioTrack | LocalVideoTrack> {
  return new Promise((resolve, reject) => {
    const options: CreateLocalTrackOptions = {};
    if (deviceId) {
      options.deviceId = { exact: deviceId };
    }
    createLocalAudioTrack(options)
      .then((track) => {
        resolve(track);
      })
      .catch((err: Error) => {
        reject('Error in acquiring Twilio Audio Track: ' + err.toString());
      });
  });
}

export function getVideoTrack({
  deviceId,
  facingMode,
}: GetTrackInterface): Promise<LocalVideoTrack | LocalAudioTrack> {
  return new Promise((resolve, reject) => {
    const options: CreateLocalTrackOptions = {};
    if (deviceId) {
      options.deviceId = { exact: deviceId };
    }
    if (facingMode) {
      options.facingMode = facingMode;
    }

    createLocalVideoTrack(options)
      .then((track) => {
        resolve(track);
      })
      .catch((err: Error) => {
        reject('Error in acquiring Twilio Video Track: ' + err.toString());
      });
  });
}
