import { Grid, Typography } from '@material-ui/core';
import tr from '../../utils/tr';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import NotificationsToggleButton from '../NotificationsToggleButton/NotificationsToggleButton';
import useNotifications from '../../hooks/useNotifications/useNotifications';

const useStyles = makeStyles({
  textAlignRight: {
    textAlign: 'right',
  },
});

export default function NotificationsToggleBar() {
  const classes = useStyles();
  const { active } = useNotifications();

  return (
    <Grid container alignItems="center">
      <Grid item xs={8}>
        <Typography>
          {active
            ? tr('Notifications on ##preroom status')
            : tr('Notifications off ##preroom status')}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.textAlignRight}>
        <NotificationsToggleButton />
      </Grid>
    </Grid>
  );
}
