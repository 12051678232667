import React from 'react';
import { LocalDataTrack, LocalTrackPublication, Room } from 'twilio-video';
import { LocalDataTrackStatusEnum } from './DatatrackTypes';

interface UseLocalDatatrackInterface {
  room: Room;
  datatrackRef: React.MutableRefObject<LocalDataTrack>;
}

export default function useLocalDatatrack({
  room,
  datatrackRef,
}: UseLocalDatatrackInterface) {
  const [
    localDatatrack,
    setLocalDatatrack,
  ] = React.useState<LocalDataTrack | null>(null);
  const [localDatatrackStatus, setLocalDatatrackStatus] = React.useState<
    | LocalDataTrackStatusEnum.CONNECTED
    | LocalDataTrackStatusEnum.CONNECTING
    | LocalDataTrackStatusEnum.DISCONNECTED
  >(LocalDataTrackStatusEnum.DISCONNECTED);

  React.useEffect(() => {
    const trackPublishedListener = (publication: LocalTrackPublication) => {
      // Wait until localDataTrack is connected before providing it
      if (publication.kind === 'data') {
        setLocalDatatrack(datatrackRef.current);
        setLocalDatatrackStatus(LocalDataTrackStatusEnum.CONNECTED);
      }
    };
    if (room.localParticipant) {
      room.localParticipant.on('trackPublished', trackPublishedListener);
    }
    return () => {
      setLocalDatatrack(null);
      if (room.localParticipant) {
        room.localParticipant.off('trackPublished', trackPublishedListener);
      }
    };
  }, [datatrackRef, setLocalDatatrackStatus, setLocalDatatrack, room]);

  return { localDatatrack, localDatatrackStatus, setLocalDatatrackStatus };
}
