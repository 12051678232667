export const getIsExpired = (
  expiresOn: Date | null,
  bufferTokenRefreshInSeconds: number
) => {
  if (!expiresOn) return true;
  const bufferWarningMs = bufferTokenRefreshInSeconds * 1000;
  // Get expiration in Unix, and subtract the buffer-length
  const expiresOnMinusBuffer = expiresOn.getTime() - bufferWarningMs;
  const now = new Date().getTime();
  return now > expiresOnMinusBuffer;
};

export const expirationCheck = (
  setToLoggedOut: () => void,
  setToLoggedIn: () => void,
  expiresOn: Date | null,
  bufferTokenRefreshInSeconds: number
) => {
  const isExpired = getIsExpired(expiresOn, bufferTokenRefreshInSeconds);
  // If past the buffered expiration, require login
  if (isExpired) {
    setToLoggedOut();
  } else {
    setToLoggedIn();
  }
};
