import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import clsx from 'clsx';

import EndCallButton from './EndCallButton/EndCallButton';
import ToggleAudioButton from './ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from './ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from './ToogleScreenShareButton/ToggleScreenShareButton';
import InviteButton from './InviteButton/InviteButton';

import useRoomState from '../../hooks/useRoomState/useRoomState';
import { isMobile } from '../../utils';
import useBrowserIsOk from '../../hooks/useBrowserIsOk/useBrowserIsOk';
import { useVideoRoomState } from '../../hooks/useVideoRoomState/VideoRoomStateProvider';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
    },
    controlsWrap: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: 'repeat(auto-fit, 100px)',
      justifyContent: 'center',
    },
  })
);

export default function Controls() {
  const classes = useStyles();
  const roomState = useRoomState();
  const { features } = useBrowserIsOk();
  const isReconnecting = roomState === 'reconnecting';
  const isUserActive = true;
  const showControls = isUserActive || roomState === 'disconnected';
  const { roomCurrentAppt } = useVideoRoomState();

  return (
    <div className={classes.container}>
      <div className={clsx(classes.controlsWrap, { showControls })}>
        <ToggleAudioButton disabled={isReconnecting} />
        <ToggleVideoButton disabled={isReconnecting} />
        {roomState !== 'disconnected' && (
          <>
            {!isMobile && features.screensharePresent && (
              <ToggleScreenShareButton disabled={isReconnecting} />
            )}
            {roomCurrentAppt && <InviteButton />}
            <EndCallButton />
          </>
        )}
      </div>
    </div>
  );
}
