import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import useShowInRightPanel from '../../hooks/useShowInRightPanel/useShowInRightPanel';
import { RightPanelKeyEnum } from '../../interfaces/RightPanelContentTypes';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';
import tr from '../../utils/tr';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { isMobile } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    settings: {
      padding: `${theme.spacing(0)}px ${theme.spacing(2)}px `,
      textTransform: 'uppercase',
      fontSize: `${theme.spacing(1.75)}px `,
      lineHeight: `${theme.spacing(2)}px `,
      [theme.breakpoints.down('xs')]: {
        padding: `${theme.spacing(0)}px ${theme.spacing(1)}px `,
        minWidth: 'auto',
      },
    },
  })
);

export default function MediaSettingsButton() {
  const classes = useStyles();
  const { toggleWhich } = useShowInRightPanel();

  return (
    <div aria-label="media settings">
      <Button
        color="primary"
        className={classes.settings}
        onClick={() => {
          toggleWhich(RightPanelKeyEnum.MEDIA_SETTINGS);
          analyticEvent({
            label: 'Open Media Settings',
            action: ACTION_CALL_TO_ACTION,
          });
        }}
        endIcon={<SettingsIcon />}
      >
        {!isMobile ? tr('Settings ##settings label') : ''}
      </Button>
    </div>
  );
}
