import React from 'react';
import { setCloseAction, spinUpServiceWorker } from './utils';

type SendNotificationProps = {
  notificationsPermission: NotificationPermission;
  active: boolean;
};

export type NotificationProperties = NotificationOptions & {
  onclick?: (ev: Event) => void;
  closeOnClick?: boolean;
};

/**
 * This hook is for internal use in useNotifications only, at this time.
 * It may be moved outside eventually as a proper, standalone hook.
 */
export function useSendNotification({
  notificationsPermission,
  active,
}: SendNotificationProps) {
  const serviceWorkerNeeded = React.useRef(false);
  const serviceWorker = React.useRef<ServiceWorkerRegistration | null>(null);

  const sendNotification = React.useCallback(
    (title: string, options: NotificationProperties | undefined) => {
      if (notificationsPermission !== 'granted' || !active) {
        return;
      }

      if (!serviceWorkerNeeded.current) {
        try {
          // Default: Use standard notifications API
          const notif = new Notification(title, options);
          setCloseAction(notif, options);
        } catch (err) {
          // Backup: Register a service worker for sending notifications
          serviceWorkerNeeded.current = true;
          spinUpServiceWorker(
            (registeredServiceWorker: ServiceWorkerRegistration) => {
              serviceWorker.current = registeredServiceWorker;
              sendNotification(title, options);
            }
          );
        }
      } else if (serviceWorker.current) {
        serviceWorker.current.showNotification(title, options).catch(() => {
          navigator.serviceWorker
            .getRegistration(window.location.href)
            .then((reg) => {
              if (!reg) {
                // Re-register the service worker
                spinUpServiceWorker(
                  (registeredServiceWorker: ServiceWorkerRegistration) => {
                    serviceWorker.current = registeredServiceWorker;
                    sendNotification(title, options);
                  }
                );
              } else {
                serviceWorker.current = reg;
                sendNotification(title, options);
              }
            });
        });
      }
    },
    [active, notificationsPermission]
  );

  return sendNotification;
}
