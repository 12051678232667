import React from 'react';
import useLocalNetworkQuality from '../../hooks/useLocalNetworkQuality/useLocalNetworkQuality';
import useToastContext from '../../hooks/useToastContext/useToastContext';
import tr from '../../utils/tr';
import dayjs, { Dayjs } from 'dayjs';
import {
  SHOW_WARNING_WHEN_QUALITY_BELOW,
  AVERAGE_NETWORK_QUALITY_OVER_N_SECONDS,
  BLOCK_QUALITY_WARNING_IF_WITHIN_N_SECONDS,
} from '../../constants';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default function NetworkQualityWarning() {
  const blockUntilRef = React.useRef<Dayjs | null>(null);
  const quality = useLocalNetworkQuality(
    AVERAGE_NETWORK_QUALITY_OVER_N_SECONDS
  );
  const { add: addToast } = useToastContext();

  React.useEffect(() => {
    // If no-average/low-average, and no block, add toast
    if (
      quality.average !== null &&
      quality.average < SHOW_WARNING_WHEN_QUALITY_BELOW &&
      (!blockUntilRef.current || dayjs.utc().isAfter(blockUntilRef.current))
    ) {
      // Block further calls for a while
      blockUntilRef.current = dayjs
        .utc()
        .add(BLOCK_QUALITY_WARNING_IF_WITHIN_N_SECONDS, 'second');
      addToast({
        message: tr(
          'Low bandwidth is currently affecting your connection. Quality will improve when your bandwidth increases.'
        ),
      });
    }
  }, [quality, addToast]);

  return null;
}
