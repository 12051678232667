import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import DotsIcon from './DotsIcon';

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface SpinnerButtonProps {
  isWaiting: boolean;
  dotsSize?: number;
  children: React.ReactNode;
  progressStyleClass?: string;
}

const SpinnerButton = ({
  isWaiting,
  dotsSize = 5,
  children,
  progressStyleClass,
}: SpinnerButtonProps) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {children}
      {isWaiting && (
        <DotsIcon
          size={dotsSize}
          className={clsx(classes.buttonProgress, progressStyleClass)}
        />
      )}
    </div>
  );
};

export default SpinnerButton;
