import React from 'react';
import { AuthBaseContext } from './AuthBaseContextProvider';

export default () => {
  const context = React.useContext(AuthBaseContext);
  if (!context) {
    throw new Error(
      'useAuthBaseContext must be used within AuthBaseContextProvider.'
    );
  }

  return context;
};
