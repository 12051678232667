import React from 'react';

let clipId = 0;
const getUniqueClipId = () => clipId++;

export default function AudioLevelIcon({
  volume,
  size = 24,
  background = 'rgba(255, 255, 255, 0.1)',
  foreground = '#0c0',
}: {
  volume: number | null;
  size?: number;
  background?: string;
  foreground?: string;
}) {
  const ref = React.useRef<SVGRectElement>(null);

  React.useEffect(() => {
    const SVGClipElement = ref.current;
    if (SVGClipElement) {
      SVGClipElement.setAttribute('y', String(21 - (volume || 0)));
    }
  }, [volume]);

  // Each instance of this component will need a unique HTML ID
  const clipPathId = `audio-level-clip-${getUniqueClipId()}`;

  return (
    <svg
      className="audioLevel"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      height={`${size}px`}
      width={`${size}px`}
      data-testid="svg"
    >
      <defs>
        <clipPath id={clipPathId} data-testid="clippath">
          <rect
            ref={ref}
            x="0"
            y="21"
            width="24"
            height="24"
            data-testid="rectangleFill"
          />
        </clipPath>
      </defs>
      <path
        fill={background}
        data-testid="path1"
        d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z"
      >
        {' '}
      </path>
      <path
        fill={foreground}
        data-testid="path2"
        clipPath={`url(#${clipPathId})`}
        d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z"
      >
        {' '}
      </path>
    </svg>
  );
}
