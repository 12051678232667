/* istanbul ignore file */
import React from 'react';
import { Typography } from '@material-ui/core';
import { InvitedParticipantType } from '../appt/AppointmentPageTypes';
import ChatButtonOnAppt from '../appt/ChatButtonOnAppt/ChatButtonOnAppt';
import CheckAndJoinButton from '../appt/CheckAndJoinButton/CheckAndJoinButton';
import {
  ApptContentEnum,
  ApptParticipantStatusNameEnum,
  ApptType,
} from '../../state/appt/apptTypes';
import dayjs from 'dayjs';
import InvitedParticipantInfoButton from '../appt/InvitedParticipantInfoButton/InvitedParticipantInfoButton';
import InvitedParticipantList from '../appt/InvitedParticipantList/InvitedParticipantList';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const mockAppt: ApptType = {
  id: 'appt1',
  startTime: dayjs.utc(),
  endTime: dayjs.utc().add(12, 'hour'),
  participants: [
    { id: 1, type: ApptContentEnum.PHONE, statuses: [], token: 'token1' },
  ],
  ownedBy: 'test1',
  ministry: 'testministry',
};

const invitedParticipantsWithNames: InvitedParticipantType[] = [
  {
    id: 11,
    name: 'Uttar Pradesh',
    content: 'uttar@pradesh.com',
    type: ApptContentEnum.EMAIL,
    statuses: [
      {
        statusType: ApptParticipantStatusNameEnum.INVITE_SENT,
        timeStamp: dayjs.utc().subtract(10, 'minute'),
      },
    ],
    isWaiting: true,
    waitingDuration: 5,
  },
  {
    id: 12,
    name: 'Telangana',
    content: '5551112222',
    type: ApptContentEnum.PHONE,
    statuses: [
      {
        statusType: ApptParticipantStatusNameEnum.INVITE_DELIVERED,
        timeStamp: dayjs.utc().subtract(10, 'minute'),
      },
    ],
    isWaiting: false,
  },
  {
    id: 13,
    name: 'Mingyuan Li',
    content: '5553334444',
    type: ApptContentEnum.PHONE,
    statuses: [
      {
        statusType: ApptParticipantStatusNameEnum.INVITE_DELIVERED,
        timeStamp: dayjs.utc().subtract(10, 'minute'),
      },
    ],
    isWaiting: true,
    waitingDuration: 10,
  },
];
const invitedParticipantsWithoutNames: InvitedParticipantType[] = [
  {
    id: 11,
    name: '',
    content: 'uttar@pradesh.com',
    type: ApptContentEnum.EMAIL,
    statuses: [
      {
        statusType: ApptParticipantStatusNameEnum.INVITE_SENT,
        timeStamp: dayjs.utc().subtract(10, 'minute'),
      },
    ],
    isWaiting: true,
    waitingDuration: 15,
  },
  {
    id: 12,
    name: '',
    content: '5551112222',
    type: ApptContentEnum.PHONE,
    statuses: [
      {
        statusType: ApptParticipantStatusNameEnum.INVITE_DELIVERED,
        timeStamp: dayjs.utc().subtract(10, 'minute'),
      },
    ],
    isWaiting: false,
  },
  {
    id: 13,
    name: '',
    content: '5552329421',
    type: ApptContentEnum.PHONE,
    statuses: [
      {
        statusType: ApptParticipantStatusNameEnum.INVITE_FAILED,
        timeStamp: dayjs.utc().subtract(10, 'minute'),
      },
    ],
    isWaiting: false,
  },
];

const mockStatusesSent = [
  {
    statusType: ApptParticipantStatusNameEnum.INVITE_SENT,
    timeStamp: dayjs.utc().subtract(10, 'minute'),
  },
];

const mockStatusesCreated = [
  {
    statusType: ApptParticipantStatusNameEnum.INVITE_CREATED,
    timeStamp: dayjs.utc().subtract(10, 'minute'),
  },
];

const mockStatusesDelivered = [
  {
    statusType: ApptParticipantStatusNameEnum.INVITE_SENT,
    timeStamp: dayjs.utc().subtract(5, 'minute'),
  },
  {
    statusType: ApptParticipantStatusNameEnum.INVITE_DELIVERED,
    timeStamp: dayjs.utc().subtract(4, 'minute'),
  },
];

const mockStatusesFailed = [
  {
    statusType: ApptParticipantStatusNameEnum.INVITE_CREATED,
    timeStamp: dayjs.utc().subtract(5, 'minute'),
  },
  {
    statusType: ApptParticipantStatusNameEnum.INVITE_FAILED,
    timeStamp: dayjs.utc().subtract(4, 'minute'),
  },
];

export default function AppointmentPage() {
  return (
    <>
      <br />
      <Typography variant="h3">Invited Participants with name</Typography>
      <InvitedParticipantList
        invitedParticipants={invitedParticipantsWithNames}
        disabled={false}
      />
      <br />
      <Typography variant="h3">SMS Statuses not sent</Typography>
      <InvitedParticipantInfoButton
        id={1}
        statuses={[]}
        type={ApptContentEnum.PHONE}
        participantDisplayName="Joe"
      />
      <br />
      <Typography variant="h3">SMS Statuses sent</Typography>
      <InvitedParticipantInfoButton
        id={1}
        statuses={mockStatusesCreated}
        type={ApptContentEnum.PHONE}
        participantDisplayName="Picket"
      />
      <br />
      <Typography variant="h3">SMS Statuses delivered</Typography>
      <InvitedParticipantInfoButton
        id={1}
        statuses={mockStatusesDelivered}
        type={ApptContentEnum.PHONE}
        participantDisplayName="Helmer"
      />
      <br />
      <Typography variant="h3">SMS Statuses failed</Typography>
      <InvitedParticipantInfoButton
        id={1}
        statuses={mockStatusesFailed}
        type={ApptContentEnum.PHONE}
        participantDisplayName="Jupiter"
      />
      <br />
      <Typography variant="h3">Email Statuses not sent</Typography>
      <InvitedParticipantInfoButton
        id={1}
        statuses={[]}
        type={ApptContentEnum.EMAIL}
        participantDisplayName="Picket"
      />
      <br />
      <Typography variant="h3">Email Statuses sent</Typography>
      <InvitedParticipantInfoButton
        id={1}
        statuses={mockStatusesSent}
        type={ApptContentEnum.EMAIL}
        participantDisplayName="Jo"
      />
      <br />
      <br />
      <Typography variant="h3">Invited Participants without name</Typography>
      <InvitedParticipantList
        invitedParticipants={invitedParticipantsWithoutNames}
        disabled={false}
      />
      <br />
      <Typography variant="h3">Invited Participants - disabled</Typography>
      <InvitedParticipantList
        invitedParticipants={invitedParticipantsWithNames}
        disabled
      />
      <br />
      <br />
      <Typography variant="h3">Inactive Chat Button</Typography>
      <ChatButtonOnAppt hasMessage={false} />
      <br />
      <Typography variant="h3">Chat Button with message</Typography>
      <ChatButtonOnAppt hasMessage={true} />
      <br />
      <Typography variant="h3">Check Devices and Join Visit</Typography>
      <CheckAndJoinButton appointment={mockAppt} />
      <br />
      <Typography variant="h3">
        Check Devices and Join Visit When Appt is Canceled
      </Typography>
      <CheckAndJoinButton appointment={mockAppt} />
    </>
  );
}
