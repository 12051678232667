import React from 'react';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import BarButton from '../BarButton/BarButton';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import tr from '../../../utils/tr';
import { disableToggleVideoButtonForNSeconds } from '../../../utils/settings';

const DISABLE_VIDEO_TEXT = tr('Video ##video video button');

export default function ToggleVideoButton({
  disabled,
}: {
  disabled?: boolean;
}) {
  const {
    videoError,
    videoInDisabled,
    setVideoInDisableDesired,
    retryVideoAcquire,
  } = useVideoContext();
  const [temporarilyDisabled, setTemporarilyDisabled] = React.useState(false);

  const handleToggle = () => {
    setVideoInDisableDesired(!videoInDisabled);
    setTemporarilyDisabled(true);
    if (videoError) {
      retryVideoAcquire();
    }
    setTimeout(() => {
      setTemporarilyDisabled(false);
    }, disableToggleVideoButtonForNSeconds * 1000);
  };

  // On new state, clear the temporary disable
  React.useEffect(() => {
    setTemporarilyDisabled(false);
  }, [videoInDisabled]);

  return !videoInDisabled && !videoError ? (
    <BarButton
      onClick={handleToggle}
      text={DISABLE_VIDEO_TEXT}
      disabled={disabled || temporarilyDisabled}
    >
      <Videocam titleAccess="Disable Video Button" />
    </BarButton>
  ) : (
    <BarButton
      onClick={handleToggle}
      text={DISABLE_VIDEO_TEXT}
      selected
      highlighted
      disabled={disabled || temporarilyDisabled}
    >
      <VideocamOff titleAccess="Enable Video Button" />
    </BarButton>
  );
}
