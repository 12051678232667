import React from 'react';
import { Button, InputAdornment } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import tr from '../../utils/tr';

interface CopyFieldInterface {
  str: string;
  onCopy?: () => void;
}

export default function CopyField({ str, onCopy }: CopyFieldInterface) {
  const linkRef = React.useRef<null | HTMLInputElement>(null);
  const [isCopied, setIsCopied] = React.useState<boolean>(false);

  const handleCopyLink = () => {
    if (!linkRef.current) {
      return;
    }
    linkRef.current.focus();
    linkRef.current.select();
    linkRef.current.setSelectionRange(0, 99999);
    document.execCommand('copy');
    if (onCopy) onCopy();
    setIsCopied(true);
  };

  return (
    <Input
      id="linkToShare"
      value={str}
      inputRef={linkRef}
      endAdornment={
        <InputAdornment position="end">
          <Button
            onClick={handleCopyLink}
            variant="contained"
            color={isCopied ? 'secondary' : 'primary'}
            style={{ minWidth: '100px' }}
          >
            {isCopied
              ? tr('Copied ##invite button')
              : tr('Copy ##invite button')}
          </Button>
        </InputAdornment>
      }
    />
  );
}
