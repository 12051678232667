import React from 'react';
import { createStyles, DialogProps, Theme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import {
  colors,
  otherSpacing,
  transitionSpeed,
  dialogZIndex,
} from '../../theme';
import clsx from 'clsx';
import { DialogBar } from './DialogBar/DialogBar';
import { ResponsiveDialogCloseButton } from './ResponsiveDialogCloseButton/ResponsiveDialogCloseButton';
import { getTextDirectionOriginIsRight } from '../../utils';

interface ResponsiveDesignInterface extends DialogProps {
  onDismiss?: () => void;
  onBackClick?: () => void;
  open: boolean;
  children?: React.ReactNode;
  title?: string;
  scrollStyle?: string;
  isForInterpreter?: boolean;
}

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogWrap: {
      width: '100vw',
    },
    scrollArea: {
      width: '100%',
      overflowY: 'auto',
    },
    closeButton: {
      position: 'absolute',
      right: 0,
      top: 0,
      color: theme.palette.grey[500],
      padding: otherSpacing[2],
    },
    rightPanelBar: {
      flex: 1,
    },
  })
);

export default function ResponsiveDialog({
  onDismiss = () => {
    // default empty on dismiss implementation
  },
  open = false,
  children = null,
  title = '',
  scrollStyle = '',
  onBackClick,
  isForInterpreter = false,
  ...props
}: ResponsiveDesignInterface) {
  const [isShowing, setIsShowing] = React.useState(open);
  const [delayIsShowing, setDelayIsShowing] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    setIsShowing(open);
  }, [open]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayIsShowing(isShowing);
    }, transitionSpeed);
    return () => clearTimeout(timeout);
  }, [isShowing, setDelayIsShowing]);

  const handleDismiss = () => {
    setIsShowing(false);
    if (onDismiss) onDismiss();
  };

  return (
    <Dialog
      open={isShowing || delayIsShowing}
      onClose={handleDismiss}
      fullWidth={true}
      className={clsx(
        !isForInterpreter ? classes.dialogWrap : null,
        !delayIsShowing || !isShowing ? 'outOfView' : null
      )}
      {...props}
      style={{
        zIndex: dialogZIndex,
        ...(getTextDirectionOriginIsRight() ? { textAlign: 'right' } : {}),
        ...(isForInterpreter ? { position: 'absolute' } : {}),
      }}
      {...(isForInterpreter
        ? {
            BackdropProps: { style: { position: 'absolute' } },
            container: () => document.getElementById('rightPanelRouterWrap'),
          }
        : {})}
    >
      {title ? (
        <DialogBar
          onBackClick={onBackClick}
          title={title}
          onDismiss={handleDismiss}
        />
      ) : (
        <ResponsiveDialogCloseButton
          onDismiss={handleDismiss}
          color={colors.primaryPalette.brandBlue}
        />
      )}
      <div className={clsx(classes.scrollArea, scrollStyle)}>{children}</div>
    </Dialog>
  );
}
