import React from 'react';
import { VideoTrackType } from '../UseMediaTypes';
import { GaussianBlurBackgroundProcessor } from '@twilio/video-processors';
import useMemory from '../../../../hooks/useMemory/useMemory';

export default function useBlur(track: VideoTrackType) {
  const [blurDesired, setBlurDesired] = useMemory('blurBackground', false);
  const [blurred, setBlurred] = React.useState<boolean>(false);
  const [isLoadingBlurBG, setIsLoadingBlurBG] = React.useState<boolean>(false);

  React.useEffect(() => {
    const turnOn = async () => {
      if (!track.track) return;
      const gaussianBlurProcessor = new GaussianBlurBackgroundProcessor({
        assetsPath: '/static/twilio-assets',
        maskBlurRadius: 5,
        blurFilterRadius: 15,
      });
      await gaussianBlurProcessor.loadModel();
      track.track.addProcessor(gaussianBlurProcessor);
      setBlurred(true);
    };

    if (blurDesired) {
      turnOn();
    }

    return () => {
      if (track.track && track.track.processor) {
        track.track.removeProcessor(track.track.processor);
        setBlurred(false);
      }
    };
  }, [track, blurDesired]);

  const isTrackEnabled: boolean = track.track ? track.track?.isEnabled : false;

  React.useEffect(() => {
    if (blurDesired && !blurred && isTrackEnabled) {
      setIsLoadingBlurBG(true);
    } else {
      setIsLoadingBlurBG(false);
    }
  }, [blurDesired, blurred, isTrackEnabled]);

  return {
    blurDesired,
    setBlurDesired,
    blurred,
    isLoadingBlurBG,
  };
}
