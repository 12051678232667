import { reportError } from '@ascension/report-event';
import { analyticEvent } from '@ascension/analytic-event';
import { CATEGORY_ERROR } from '../../../AnalyticsConfiguration/analyticsConstant';
import React from 'react';
import { ApptFetchErrorAboutEnum, ApptFetchErrorType } from '../apptHookTypes';

const mapErrorToGA: {
  [key in ApptFetchErrorAboutEnum]: string;
} = {
  [ApptFetchErrorAboutEnum.GET_APPOINTMENT_LIST]: 'Get Appointment Error',
  [ApptFetchErrorAboutEnum.ADD_APPOINTMENT]: 'Add Appointment Error',
  [ApptFetchErrorAboutEnum.UPDATE_APPOINTMENT]: 'Update Appointment Error',
  [ApptFetchErrorAboutEnum.CANCEL_APPOINTMENT]: 'Cancel Appointment Error',
  [ApptFetchErrorAboutEnum.RESEND_INVITE]: 'Resend Invite',
  [ApptFetchErrorAboutEnum.GET_TOKEN_APPOINTMENT_DETAILS]:
    'Get Token Appointment Details',
  [ApptFetchErrorAboutEnum.GET_CLINICIAN]: 'Get clinicians list',
  [ApptFetchErrorAboutEnum.GET_APPOINTMENT_DETAILS]: 'Get Appointment Details',
  [ApptFetchErrorAboutEnum.GET_INTERPRETER_LANGUAGES]:
    'Get Interpreter Languages',
  [ApptFetchErrorAboutEnum.GET_INTERPRETER]: 'Get Interpreter',
};

export default function useSendApptError(error: ApptFetchErrorType | null) {
  React.useEffect(() => {
    if (!error) return;
    const errorType = error.type ? ` ${error.type}` : '';
    const errorStatus = error.status ? ` (status ${error.status})` : '';
    reportError(`${error.about}${errorType}${errorStatus}: ${error.message}`);
    analyticEvent({
      label: mapErrorToGA[error.about],
      action: CATEGORY_ERROR,
    });
  }, [error]);
}
