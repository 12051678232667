import tr from '../../utils/tr';
import {
  Container,
  List,
  Button,
  createStyles,
  Grid,
  TextField,
  Typography,
  ListItem,
} from '@material-ui/core';
import React, { useState } from 'react';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import siteTheme, { colors } from '../../theme';
import useInterpreterLanguagesList from '../../hooks/useInterpreterLanguagesList/useInterpreterLanguagesList';
import useInterpreter, {
  UseInterpreterProps,
} from '../../hooks/useInterpreter/useInterpreter';
import { useApptStateContext } from '../../state/appt/ApptStateProvider';
import { InterpreterLanguageType } from '../../state/appt/apptTypes';
import { AscensionSpinner } from '../AscensionSpinner/AscensionSpinner';
import InterpreterConfirmDialog from '../InterpreterConfirmDialog/InterpreterConfirmDialog';
import useReservationsContext from '../../hooks/useReservations/ReservationContext';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReportProblemOutlined from '@material-ui/icons/ReportProblemOutlined';
import { useStylesForErrorDialog } from '../ErrorDialog/ErrorDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridButton: {
      width: '100%',
      maxWidth: 'unset',
      marginTop: theme.spacing(1),
      bottom: '0px',
      background: 'white',
      borderTop: '1px solid rgba(0,0,0,0.12)',
      marginLeft: theme.spacing(-2.5),
      paddingLeft: `${theme.spacing(2.5)}px !important`,
      paddingRight: `${theme.spacing(2.5)}px !important`,
      flexGrow: 1,
      paddingTop: theme.spacing(2),
    },
    errorStyle: {
      color: theme.palette.error.main,
    },
    warningIcon: {
      color: colors.notifications.warning2,
      width: '84px',
      height: '84px',
    },
    languageLabel: {
      fontSize: '14px',
    },
    languageStatus: {
      fontSize: '10px',
      marginLeft: '12px',
      background: '#E9FBE7',
      borderRadius: '12px',
      paddingLeft: '6px',
      paddingRight: '6px',
      height: '16px',
      marginTop: '4px',
      lineHeight: '16px',
      color: '#0C5C34',
    },
    voiceOnly: {
      fontSize: '10px',
      marginLeft: '12px',
      borderRadius: '12px',
      paddingLeft: '6px',
      paddingRight: '6px',
      height: '16px',
      marginTop: '4px',
      lineHeight: '16px',
      color: '#B74900',
      background: '#FFEFCC',
    },
    mostRequestedSection: {
      marginBottom: '20px',
    },
    formGrid: {
      position: 'relative',
      height: '75vh',
      fontWeight: 500,
    },
    page: {
      height: '65vh',
      overflowY: 'scroll',
    },
    title: {
      fontWeight: 500,
    },
    waitWrap: {
      height: '65vh',
      fontSize: '1.2em',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    languageItem: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    selectedLanguage: {
      background: '#D1EAFC',
      display: 'flex',
      justifyContent: 'space-between',
    },
    successIcon: {
      color: '#1E69D2',
    },
    searchLanguage: {
      paddingRight: theme.spacing(2.5),
    },
  })
);
interface DisplayInterpreterListProps {
  interpreterList: InterpreterLanguageType[];
  classes: Record<string, string>;
  selectedLanguage: string | undefined;
  scrollToSelected?: boolean;
  setScrollToSelected?: (x: boolean) => void;
  onLanguageSelected: (selected: InterpreterLanguageType) => void;
  title?: string;
}

const DisplayInterpreterList = ({
  interpreterList,
  classes,
  selectedLanguage,
  scrollToSelected,
  setScrollToSelected,
  onLanguageSelected,
  title,
}: DisplayInterpreterListProps) => {
  const refs = interpreterList.reduce((temp, value) => {
    temp[value.LanguageName] = React.createRef();
    return temp;
  }, {} as Record<string, React.RefObject<HTMLLIElement>>);

  React.useEffect(() => {
    const languageElement = refs[selectedLanguage || '']?.current;
    if (scrollToSelected && setScrollToSelected && languageElement) {
      setScrollToSelected(false);
      languageElement.scrollIntoView({
        behavior: 'auto',
        block: 'start',
      });
    }
  }, [refs, scrollToSelected, selectedLanguage, setScrollToSelected]);

  return (
    <>
      {title && <Typography className={classes.title}>{tr(title)}</Typography>}
      <List>
        {interpreterList.map((interpreter) => (
          <ListItem
            onClick={() => onLanguageSelected(interpreter)}
            key={interpreter.LanguageId}
            ref={refs[interpreter.LanguageName]}
            className={
              interpreter.LanguageName === selectedLanguage
                ? classes.selectedLanguage
                : classes.languageItem
            }
          >
            <div className={classes.languageLabel}>
              {interpreter.LanguageName}
            </div>
            {interpreter.LanguageName === selectedLanguage && (
              <SuccessIcon className={classes.successIcon} />
            )}
            {/* TODO - add back once we show the language status */}
            {/* <div className={interpreter.status === 'VOICE ONLY' ? classes.voiceOnly : classes.languageStatus }>{interpreter.status}</div> */}
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default function AddInterpreter() {
  const classes = useStyles();
  const [language, setLanguage] = useState<string>('');
  const [scrollToSelected, setScrollToSelected] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>(
    undefined
  );
  const { createReservation, cancelReservationForAll } = useReservationsContext();
  const errorClasses = useStylesForErrorDialog();

  const [selectedLanguageId, setSelectedLanguageId] = useState<
    number | undefined
  >(undefined);
  const [searchResult, setSearchResult] = useState<InterpreterLanguageType[]>(
    []
  );
  // TODO: setConnectToInterpreter to true when start connect to interpreter service and set to false when successfully connect
  const [isConnectToInterpreter, setIsConnectToInterpreter] = useState<boolean>(
    false
  );
  const [isRequestInterpreterError, setIsRequestInterpreterError] = useState(
    false
  );
  const [
    showMultiInterpreterWarning,
    setShowMultiInterpreterWarning,
  ] = useState(false);
  const { dispatch } = useApptStateContext();

  const {
    interpreterLanguages,
    isInterpreterLanguagesApiCallInFlight,
  } = useInterpreterLanguagesList({ dispatch });
  const {
    inviteInterpreter: getUseInterpreter,
    retryLastInvite,
    interpretersCount,
  } = useInterpreter();
  const mostRequestedInterpreterLanguages = interpreterLanguages.filter(
    (candidateLanguage) => candidateLanguage.LanguageName === 'Spanish'
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    return false;
  };

  const handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLanguage(event.target.value);
    if (event.target.value) {
      setSearchResult(
        interpreterLanguages.filter((interpreter) =>
          interpreter.LanguageName.toLowerCase().startsWith(
            event.target.value.toLowerCase()
          )
        )
      );
    } else {
      setSearchResult([]);
    }
    setSelectedLanguage(undefined);
  };

  const onLanguageSelected = (selected: InterpreterLanguageType) => {
    // if language is already selected, toggle it
    if (selectedLanguage === selected.LanguageName) {
      setSelectedLanguage(undefined);
      setSelectedLanguageId(undefined);
    } else {
      setSelectedLanguage(selected.LanguageName);
      setSelectedLanguageId(selected.LanguageId);
    }
    setLanguage('');
    setScrollToSelected(true);
    setSearchResult([]);
  };

  const searchNotFound: boolean =
    language !== undefined && language.length > 0 && searchResult.length === 0;

  const interpreterHandler = async (
    callback: (props: UseInterpreterProps) => Promise<void>
  ) => {
    if (selectedLanguageId) {
      if (interpretersCount > 0 && !showMultiInterpreterWarning) {
        setShowMultiInterpreterWarning(true);
        return;
      }

      if (interpretersCount > 0) {
        cancelReservationForAll('interpreter');
      }

      setShowMultiInterpreterWarning(false);
      setIsConnectToInterpreter(true);
      await callback({
        dispatch,
        language: selectedLanguage || '',
        languageId: selectedLanguageId,
        setIsRequestInterpreterError,
        createReservation,
      });
      setIsConnectToInterpreter(false);
    }
  };
  const requestInterpreterHandler = async () =>
    interpreterHandler(getUseInterpreter);
  const retryInterpreterHandler = async () =>
    interpreterHandler(retryLastInvite);

  // Render the loading logo if an API call is in-progress
  if (isInterpreterLanguagesApiCallInFlight || isConnectToInterpreter) {
    return (
      <Container className={classes.waitWrap}>
        <AscensionSpinner />
        <p>{tr('Loading... ##interpreter loading')}</p>
      </Container>
    );
  }

  // Otherwise, render the side panel
  return (
    <>
      <Container
        style={{
          marginTop: siteTheme.spacing(3),
          marginBottom: siteTheme.spacing(3),
          paddingRight: siteTheme.spacing(1),
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container className={classes.formGrid}>
            <Grid item xs={12} className={classes.page}>
              <TextField
                label={tr('Search ##addparticipant label')}
                inputProps={{ 'data-testid': 'language' }}
                InputLabelProps={{ shrink: true }}
                value={language}
                onChange={handleLanguageChange}
                fullWidth
                placeholder={tr('Search by language ##addparticipant')}
                className={classes.searchLanguage}
              />
              {mostRequestedInterpreterLanguages.length > 0 && !language && (
                <DisplayInterpreterList
                  interpreterList={mostRequestedInterpreterLanguages}
                  classes={classes}
                  selectedLanguage={selectedLanguage}
                  onLanguageSelected={onLanguageSelected}
                  title="Most Requested ##most-requested label"
                />
              )}
              {interpreterLanguages.length > 0 && !language && (
                <DisplayInterpreterList
                  interpreterList={interpreterLanguages}
                  classes={classes}
                  selectedLanguage={selectedLanguage}
                  onLanguageSelected={onLanguageSelected}
                  title="Online now ##online-now label"
                  scrollToSelected={scrollToSelected}
                  setScrollToSelected={setScrollToSelected}
                />
              )}
              {searchResult.length > 0 && (
                <DisplayInterpreterList
                  interpreterList={searchResult}
                  classes={classes}
                  selectedLanguage={selectedLanguage}
                  onLanguageSelected={onLanguageSelected}
                />
              )}
              {searchNotFound && (
                <Typography>
                  {tr(
                    'Sorry, an interpreter for this language is not available at this time. ##search-not-found label'
                  )}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} className={classes.gridButton}>
              <Button
                type="submit"
                data-testid="requestInterpreter"
                color="primary"
                variant="contained"
                fullWidth
                onClick={requestInterpreterHandler}
                disabled={searchNotFound || !selectedLanguage}
              >
                {tr('Request interpreter ##requestinterpreter button')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
      {isRequestInterpreterError && (
        <InterpreterConfirmDialog
          icon={
            <ErrorOutlineIcon
              width="64px"
              height="64px"
              className={errorClasses.icon}
            />
          }
          onCancelClick={() => setIsRequestInterpreterError(false)}
          onOkayClick={retryInterpreterHandler}
          message={tr(
            'Unable to connect with an interpreter. ##interpreter-error content'
          )}
          cancelButtonLabel={tr('CANCEL ##interpreter-error-cancel button')}
          okayButtonLabel={tr('RETRY ##interpreter-error-retry button')}
        ></InterpreterConfirmDialog>
      )}
      {showMultiInterpreterWarning && (
        <InterpreterConfirmDialog
          icon={<ReportProblemOutlined className={classes.warningIcon} />}
          onCancelClick={() => setShowMultiInterpreterWarning(false)}
          onOkayClick={requestInterpreterHandler}
          message={tr(
            'Requesting another interpreter will disconnect the current interpreter. Are you sure you want to proceed? ##interpreter-error content'
          )}
          cancelButtonLabel={tr('CANCEL ##interpreter-error-cancel button')}
          okayButtonLabel={tr('YES ##interpreter-error-retry button')}
        ></InterpreterConfirmDialog>
      )}
    </>
  );
}
