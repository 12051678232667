import React from 'react';
import socketIdentifier from '../../utils/socketIdentifier';
import useSocketContext from '../useSocketContext/useSocketContext';
import { JoinedParticipantType } from '../useSocketContext/socketTypes';
import useNotifications from '../useNotifications/useNotifications';
import { SocketParticipantEvents } from '../useSocketContext/useSocketParticipants';
import tr from '../../utils/tr';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../AnalyticsConfiguration/analyticsConstant';

export default function useNotifyParticipantJoin() {
  const {
    notificationsPermission,
    sendNotification,
    active: notificationsActive,
  } = useNotifications();
  const { socketParticipantListener } = useSocketContext();

  React.useEffect(() => {
    if (
      notificationsPermission !== 'granted' ||
      sendNotification === undefined
    ) {
      return;
    }

    const onParticipantAdd = (participant: JoinedParticipantType) => {
      if (notificationsActive && participant.id !== socketIdentifier) {
        sendNotification(
          tr(
            'A participant has joined your video call. ##browser notification title'
          ),
          {
            body: tr(
              'Please return to Video Chat and join. ##browser notification body'
            ),
            icon: `${process.env.PUBLIC_URL}/logo192.png`,
            badge: `${process.env.PUBLIC_URL}/logo192.png`,
            data: {
              url: window.location.href,
            },
            onclick: () => {
              analyticEvent({
                label: 'Clicked Participant Joined Notification',
                action: ACTION_CALL_TO_ACTION,
              });
              window.parent.parent.focus();
            },
            closeOnClick: true,
          }
        );
      }
    };
    socketParticipantListener.addListener(
      SocketParticipantEvents.added,
      onParticipantAdd
    );
    return () => {
      socketParticipantListener.removeListener(
        SocketParticipantEvents.added,
        onParticipantAdd
      );
    };
  }, [
    notificationsActive,
    notificationsPermission,
    socketParticipantListener,
    sendNotification,
  ]);
}
