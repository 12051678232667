import tr from '../../utils/tr';
import { FeaturesType, FeatureGroupsType } from './featureTypes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export enum FeatureGroupEnum {
  APPT = 'appt',
}

export enum FeatureEnum {
  ADD_APPT = 'add-appt',
  UPDATE_APPT = 'update-appt',
  COPY_LINK = 'copy-link',
  ADD_PARTICIPANT = 'add-participant',
  INVITE_MESSAGE = 'invite-message',
  JOIN_VISIT = 'join-visit',
}

export const featureGroups: FeatureGroupsType = {
  [FeatureGroupEnum.APPT]: {
    barTitle: tr('NEW ##feature-group title'),
    barText: tr('Learn more about new features. ##feature-group text'),
    expirationUTC: dayjs.utc('2022-03-01T05:00:00'),
  },
};

export const features: FeaturesType = {
  [FeatureEnum.ADD_APPT]: {
    groupId: FeatureGroupEnum.APPT,
    title: tr('NEW ##feature-appointment title'),
    text: tr('Click button to add appointment ##feature text'),
  },
  [FeatureEnum.COPY_LINK]: {
    groupId: FeatureGroupEnum.APPT,
    title: tr('NEW ##feature-link title'),
    text: tr('Copy link to use for direct messaging ##feature text'),
  },
  [FeatureEnum.ADD_PARTICIPANT]: {
    groupId: FeatureGroupEnum.APPT,
    title: tr('NEW ##feature-participant title'),
    text: tr('To save name and phone number click add ##feature text'),
  },
  [FeatureEnum.UPDATE_APPT]: {
    groupId: FeatureGroupEnum.APPT,
    title: tr('NEW ##feature-participant title'),
    text: tr('Link will be sent after updating appointment ##feature text'),
  },
  [FeatureEnum.INVITE_MESSAGE]: {
    groupId: FeatureGroupEnum.APPT,
    title: tr('NEW ##feature-invite title'),
    text: tr('Edit the message to be sent out to participants ##feature text'),
  },
  [FeatureEnum.JOIN_VISIT]: {
    groupId: FeatureGroupEnum.APPT,
    title: tr('NEW ##feature-join title'),
    text: tr('Click join to enter video chat ##feature text'),
  },
};
