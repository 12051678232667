import React from 'react';
import { Button, createStyles, Theme, Tooltip } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/styles';
import { useApptStateContext } from '../../../state/appt/ApptStateProvider';
import { analyticEvent } from '@ascension/analytic-event';
import { ACTION_CALL_TO_ACTION } from '../../../AnalyticsConfiguration/analyticsConstant';
import {
  disableRefreshButtonNSeconds,
  delayNSecondsBeforeHideTooltip,
} from '../../../utils/settings';
import tr from '../../../utils/tr';
import {
  recallFeatureIsDismissed,
  rememberFeatureIsDismissed,
} from '../../../hooks/useFeatureContext/featureUtils';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from '../../../theme';

/* istanbul ignore next */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: `${theme.palette.grey[100]} !important`,
    },
    wrap: {
      // suppress pointer to allow touch-scrolling
      pointerEvents: 'none',
      '& .MuiTooltip-tooltip': {
        backgroundColor: colors.secondary.violetDark,
        color: colors.grayscale.white,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(3),
        minWidth: '200px',
        fontSize: '0.9em',
      },
    },
    arrow: {
      '& .MuiTooltip-arrow': {
        color: colors.secondary.violetDark,
      },
    },
    closeButton: {
      // turn-on pointer-events only for close-button
      pointerEvents: 'auto',
      position: 'absolute',
      top: '2px',
      right: '-1px',
      height: '30px',
      minWidth: '30px',
      color: colors.grayscale.white,
      fontSize: '6px',
    },
    text: {
      marginLeft: theme.spacing(1),
      fontWeight: 'normal',
      marginRight: theme.spacing(1),
    },
    tooltip: {
      maxWidth: '400px',
      padding: theme.spacing(1),
    },
  })
);

const feature = {
  id: 'refresh-appt',
  title: tr('NEW ##feature-refresh title'),
  text: tr('Refresh to see the latest appointments ##feature text'),
};

export default function RefreshApptListButton() {
  const classes = useStyles();
  const { getAppointments, setClickRefreshBtn } = useApptStateContext();
  const [isDisable, setIsDisable] = React.useState<boolean>(false);
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const [isDismissed, setIsDismissed] = React.useState<boolean>(false);

  const onClick = () => {
    setIsDisable(true);
    setClickRefreshBtn(true);
    getAppointments();
    analyticEvent({
      label: 'Refresh AppointmentList',
      action: ACTION_CALL_TO_ACTION,
    });
    setTimeout(() => {
      setIsDisable(false);
      setClickRefreshBtn(false);
    }, disableRefreshButtonNSeconds * 1000);
  };

  React.useEffect(() => {
    setIsDismissed(recallFeatureIsDismissed(feature.id));
  }, []);

  return (
    <Tooltip
      disableHoverListener={isDismissed}
      open={isHovering && !isDismissed}
      interactive
      arrow
      disableFocusListener
      disableTouchListener
      leaveDelay={delayNSecondsBeforeHideTooltip * 1000}
      onOpen={() => setIsHovering(true)}
      onClose={() => setIsHovering(false)}
      classes={{
        popperInteractive: classes.wrap,
        popperArrow: classes.arrow,
        tooltip: classes.tooltip,
      }}
      placement={'bottom'}
      title={
        <>
          <span color="inherit" data-testid="feature-tooltip-title">
            {feature.title}
          </span>
          <span
            color="inherit"
            className={classes.text}
            data-testid="feature-tooltip-text"
          >
            {feature.text}
          </span>
          <Button
            className={classes.closeButton}
            onClick={() => {
              rememberFeatureIsDismissed(feature.id);
              setIsDismissed(true);
            }}
          >
            <CloseIcon fontSize="small" data-testid="close-button" />
          </Button>
        </>
      }
    >
      <Button
        color="primary"
        className={classes.button}
        onClick={onClick}
        disabled={isDisable}
        startIcon={<RefreshIcon />}
      >
        {tr('Refresh ##appt-list refresh button')}
      </Button>
    </Tooltip>
  );
}
