import {
  ApptContentEnum,
  ApptParticipantStatusNameEnum,
  ApptParticipantStatusType,
} from '../../state/appt/apptTypes';
import tr from '../../utils/tr';
import { Dayjs } from 'dayjs';

export enum ExpectedApptDisplayStatusMapEnum {
  inviteSent = 'inviteSent',
  inviteDelivered = 'inviteDelivered',
}

type ExpectedApptTargetStatusOptionMap = {
  displayStatus:
    | ExpectedApptDisplayStatusMapEnum.inviteSent
    | ExpectedApptDisplayStatusMapEnum.inviteDelivered;
  successText: string;
  successStatus: ApptParticipantStatusNameEnum;
  successTimeText?: string;
  failedText?: string;
  failedStatus?: ApptParticipantStatusNameEnum;
};

type ExpectedApptTargetStatusMap = {
  [ApptContentEnum.PHONE]: ExpectedApptTargetStatusOptionMap[];
  [ApptContentEnum.EMAIL]: ExpectedApptTargetStatusOptionMap[];
};

const getExpectedApptStatusMap: ExpectedApptTargetStatusMap = {
  [ApptContentEnum.PHONE]: [
    {
      displayStatus: ExpectedApptDisplayStatusMapEnum.inviteSent,
      successStatus: ApptParticipantStatusNameEnum.INVITE_CREATED,
      successText: tr('Invite sent ##invite-status sms'),
    },
    {
      displayStatus: ExpectedApptDisplayStatusMapEnum.inviteDelivered,
      successStatus: ApptParticipantStatusNameEnum.INVITE_DELIVERED,
      successText: tr('Invite delivered ##invite-status sms'),
      successTimeText: tr('Invite delivered at ##invite-status sms'),
      failedStatus: ApptParticipantStatusNameEnum.INVITE_FAILED,
      failedText: tr('Invite failed ##invite-status sms'),
    },
  ],
  [ApptContentEnum.EMAIL]: [
    {
      displayStatus: ExpectedApptDisplayStatusMapEnum.inviteSent,
      successStatus: ApptParticipantStatusNameEnum.INVITE_CREATED,
      successText: tr('Invite sent ##invite-status sms'),
      successTimeText: tr('Invite sent at ##invite-status sms'),
      failedStatus: ApptParticipantStatusNameEnum.INVITE_FAILED,
      failedText: tr('Invite failed ##invite-status sms'),
    },
  ],
};

export enum SuccessOrFailedApptStatusForDisplayEnum {
  SUCCESS = 'success',
  FAILED = 'failed',
}

export type GetExpectedApptStatusesResultType = {
  displayStatus: ExpectedApptDisplayStatusMapEnum;
  text?: string;
  timeText?: string;
  successOrFailed: SuccessOrFailedApptStatusForDisplayEnum | null;
  timeStamp?: Dayjs;
};

export default function getExpectedApptStatuses(
  statuses: ApptParticipantStatusType[],
  type: ApptContentEnum
): GetExpectedApptStatusesResultType[] {
  //TODO: remove this after backend starts sending status
  if (type === ApptContentEnum.INTERPRETER) {
    return [
      {
        displayStatus: ExpectedApptDisplayStatusMapEnum.inviteSent,
        text: tr('Invite sent ##invite-status sms'),
        successOrFailed: SuccessOrFailedApptStatusForDisplayEnum.SUCCESS,
      },
    ];
  }

  if (type !== ApptContentEnum.EMAIL && type !== ApptContentEnum.PHONE) {
    return [];
  }
  return getExpectedApptStatusMap[type].map((option) => {
    const matchingFailedStatus =
      option.failedStatus &&
      statuses.find(({ statusType }) => statusType === option.failedStatus);
    const matchingSuccessStatus =
      option.successStatus &&
      statuses.find(({ statusType }) => statusType === option.successStatus);
    let successOrFailed = null;
    if (matchingSuccessStatus) {
      successOrFailed = SuccessOrFailedApptStatusForDisplayEnum.SUCCESS;
    } else if (matchingFailedStatus) {
      successOrFailed = SuccessOrFailedApptStatusForDisplayEnum.FAILED;
    }

    return {
      displayStatus: option.displayStatus,
      text: matchingFailedStatus ? option.failedText : option.successText,
      successOrFailed,
      timeStamp: matchingFailedStatus
        ? matchingFailedStatus.timeStamp
        : matchingSuccessStatus?.timeStamp,
      timeText: matchingSuccessStatus ? option.successTimeText : undefined,
    };
  });
}
