import React from 'react';
import { makeStyles } from '@material-ui/styles';
import theme, { borderRadius, colors } from '../../theme';
import ChatMessage from './ChatMessage';
import { ChatMessageType } from '../../hooks/useChatContext/chatTypes';

const useStyles = makeStyles({
  wrap: {
    flex: 1,
    padding: 0,
    paddingRight: theme.spacing(2),
    margin: 0,
    marginBottom: theme.spacing(2),
    overflowY: 'auto',
  },
  item: {
    position: 'relative',
    width: 'max-content',
    minWidth: '100px',
    listStyleType: 'none',
    borderRadius: borderRadius[1],
    maxWidth: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    backgroundColor: colors.secondary.violetDark,
    fontWeight: 400,
  },
  itemFromMe: {
    backgroundColor: colors.primaryPalette.brandBlue,
  },
  name: {
    position: 'absolute',
    top: '-1.6em',
    left: '0.2em',
    overflow: 'hidden',
    fontSize: '0.9em',
    whiteSpace: 'nowrap',
    maxWidth: '200px',
    textOverflow: 'ellipsis',
  },
  text: {
    padding: theme.spacing(2),
    color: colors.grayscale.white,
    overflowWrap: 'break-word',
  },
});

export default function ChatList({
  messages = [],
  messagesBkgColors = [],
}: {
  messages: ChatMessageType[];
  messagesBkgColors: (string | null)[];
}) {
  const classes = useStyles();
  const listRef = React.useRef<null | HTMLUListElement>(null);

  React.useLayoutEffect(() => {
    const scrollDown = () => {
      // Wait until load finished; no teardown needed as it executes on next thread loop
      setTimeout(() => {
        if (listRef.current) {
          listRef.current.scrollTo(0, listRef.current.scrollHeight);
        }
      }, 0);
    };
    scrollDown();
  }, [messages]);

  return (
    <ul ref={listRef} className={classes.wrap}>
      {messages.map((message, index) => (
        <ChatMessage
          message={message}
          key={message.id}
          bkgColor={
            index < messagesBkgColors.length ? messagesBkgColors[index] : null
          }
        />
      ))}
    </ul>
  );
}
